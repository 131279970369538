import { Routes } from '@angular/router';
import { PrivateCleaningComponent } from '../private-cleaning/private-cleaning.component';
import { CompanyCleaningComponent } from '../company-cleaning/company-cleaning.component';
import { LoginComponent } from '../auth/login/login.component';
import { ProfileComponent } from '../auth/profile/profile.component';
import { RegisterComponent } from '../auth/register/register.component';
import { authGuard } from '../services/auth/guard/auth.guard';
import { PasswordresetComponent } from '../auth/passwordreset/passwordreset.component';
import { SuccessComponent } from '../redirect/success/success.component';
import { FailedComponent } from '../redirect/failed/failed.component';
import { CallbackComponent } from '../auth/callback/callback.component';
import { SocialregisterComponent } from '../auth/socialregister/socialregister.component';
import { SuccessCardComponent } from '../redirect/success-card/success-card.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: PrivateCleaningComponent,
  },
  {
    path: 'private',
    component: PrivateCleaningComponent,
  },
  {
    path: 'company',
    component: CompanyCleaningComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: 'socialregister',
    component: SocialregisterComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [authGuard],
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'reset-password',
    component: PasswordresetComponent,
  },
  {
    path: 'success',
    component: SuccessComponent,
  },
  {
    path: 'failed',
    component: FailedComponent
  },
  {
    path: 'success-card',
    component: SuccessCardComponent
  },
  {
    path: "**",
    redirectTo: ''
  }
];
