import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, tap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PricingService {
  private _price = new BehaviorSubject<number>(0);
  price$ = this._price.asObservable();

  constructor(private http: HttpClient) { }

  fetchPrice(hours:any, recurrence:string, materialsIncluded:boolean, type:string) {
    let params = new HttpParams()
      .set('hours', hours.toString())
      .set('recurrence', recurrence)
      .set('materialsIncluded', materialsIncluded.toString())
      .set('type', type)

    this.http.get<any>(`${environment.apiUrl}/cleanings/pricings`, { params, headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'ngrok-skip-browser-warning': '123',
    }) }).subscribe(
      (response) => {
        const price = response.data.totalPrice;
        this._price.next(price);
      },
      (error) => {
        console.error('Error fetching price:', error);
      }
    );
  }

  fetchPriceProfile(hours: any, recurrence: string, materialsIncluded: boolean, type: string) {
    let params = new HttpParams()
      .set('hours', hours.toString())
      .set('recurrence', recurrence)
      .set('materialsIncluded', materialsIncluded.toString())
      .set('type', type);
  
    return this.http.get<any>(`${environment.apiUrl}/cleanings/pricings`, {
      params,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': '123',
      })
    }).pipe(
      tap(response => {
        const price = response.data.totalPrice;
        this._price.next(price);
      }),
      catchError(error => {
        console.error('Error fetching price:', error);
        return throwError(error);
      })
    );
  }
}
