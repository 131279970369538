import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class BookingService {
  public token = localStorage.getItem('token')

  constructor(private http: HttpClient) { }

  requestBooking(credentials:any,token:any){
    return this.http.post(`${environment.apiUrl}/cleanings/booking-requests`, credentials, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'ngrok-skip-browser-warning': '123'
      })
    }).pipe(
      catchError((error:any) => {
        console.error(error);
        return throwError(error)
      })
    )
  }

  discountValidation(credentials:any,token:any){
    return this.http.post(`${environment.apiUrl}/cleanings/discount-code/validate`, credentials, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'ngrok-skip-browser-warning': '123'
      })
    }).pipe(
      catchError((error:any) => {
        console.error(error);
        return throwError(error)
      })
    )
  }

  createCleaning(credentials:any,token:any){
    return this.http.post(`${environment.apiUrl}/cleanings`, credentials, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'ngrok-skip-browser-warning': '123'
      })
    }).pipe(
      catchError((error:any) => {
        console.error(error);
        return throwError(error)
      })
    )
  }

  getTimeSlots(zip:any,type:string){
    return this.http.get(`${environment.apiUrl}/cleanings/schedule/?zip=${zip}&type=${type}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        'ngrok-skip-browser-warning': '123'
      })
    }).pipe(
      catchError((error:any) => {
        console.error(error);
        return throwError(error)  
      })
    )
  }

  listBookingRequests(){
    return this.http.get(`${environment.apiUrl}/booking-requests`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        'ngrok-skip-browser-warning': '123'
      })
    })
  }

  listCleanings(token:any){
    return this.http.get(`${environment.apiUrl}/cleanings`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'ngrok-skip-browser-warning': '123'
      })
    })
  }


  getZipCode(){
    return this.http.get(`${environment.apiUrl}/cleanings/zip`,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': '123'
      })
    })
  }

  cancelCleanings(credentials: any, token:any){
    return this.http.put(`${environment.apiUrl}/cleanings/cancel`, credentials,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'ngrok-skip-browser-warning': '123'
      })
    }).pipe(
      catchError((error:any) => {
        console.error(error);
        return throwError(error)  
      })
    )
  }
  
}
