import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslateModule } from '@ngx-translate/core';
import { AlertService } from '../../services/core/alert.service';
import { GoogleAuthService } from '../../services/OAuth/googleauth.service';
import { FacebookAuthService } from '../../services/OAuth/facebookauth.service';

@Component({
  selector: 'app-socialregister',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule, TranslateModule,],
  templateUrl: './socialregister.component.html',
  styleUrl: './socialregister.component.scss',
  animations: [
    trigger('fadeInOut', [
        state('in', style({ opacity: 1 })),
        transition(':enter', [
            style({ opacity: 0 }),
            animate('700ms ease-out', style({ opacity: 1 })),
        ]),
        transition(':leave', [
            animate('700ms ease-in', style({ opacity: 0 })),
        ]),
    ]),
],
})
export class SocialregisterComponent implements OnInit {
  public dogCount = 0;
  public catCount = 0;
  public otherPetCount = 0;
  public clientType: string = "PRIVATE" 
  
  registerForm!: FormGroup;
  petsModal: boolean = false

  termsAccepted: boolean = true
  userData: any = {};

  firstName: any = ''
  lastName: any = ''
  email: any = ''
  token: any
  provider: any = ''

  constructor(
    private fb: FormBuilder, 
    private auth: AuthService, 
    private route: Router, 
    private alertService: AlertService, 
    private cdr: ChangeDetectorRef, 
    private fbAuthService: FacebookAuthService,
    private authService: GoogleAuthService
  ){}



  ngOnInit(): void {
    this.showData()
    this.registerForm = this.fb.group({
      clientType: ['PRIVATE'],
      firstName: ['' || this.firstName, Validators.required],
      lastName: ['' || this.lastName, Validators.required],
      birthDate: ['',Validators.required],
      companyName: ['' || null],
      email: [{value: '' || this.email, disabled: true}, [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, this.swissPhoneNumberValidator()]],
      newsletter: [false],
      language: ['German'],
      sex: ['', [Validators.required]],
      role: ['USER'],
      stv: [false],
      acceptedTermsAndConditions: [false, [Validators.required]],
      referralSource: ['', [Validators.required]],
      otherReferralSource: ['' || null],
      address: this.fb.group({
        street: ['',Validators.required],
        streetNr: ['',Validators.required],
        city: ['', Validators.required],
        zipCode: ['',Validators.required],
        entryCode: ['1234'],
        entranceInstructions: [null],
        additionalInformation: ['' || null],
        dog: [0],
        dogText: ['' || null],
        cat: [0],
        catText: ['' || null],
        otherAnimal: [0],
        otherAnimalText: ['' || null],
        isDefaultAddress: true
      })
    });
    this.registerForm.get('referralSource')?.valueChanges.subscribe({
      next: (res) => {
        if(res === 'OTHER' || res === 'EMPFEHLUNG'){
          this.registerForm.get('otherReferralSource')?.addValidators([Validators.required])
        } else {
          this.registerForm.get('otherReferralSource')?.removeValidators([Validators.required])
          this.registerForm.get('otherReferralSource')?.reset();
        }
      }
    })
  }

  swissPhoneNumberValidator(): ValidatorFn {
    const swissPhoneNumberPattern = /(\b(0041|0)|\B\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}\b/;
  
      return (control: AbstractControl): { [key: string]: any } | null => {
        const isValid = swissPhoneNumberPattern.test(control.value);
        return isValid ? null : { 'invalidSwissPhoneNumber': { value: control.value } };
      };
    }

  splitUserName(name: string): { firstName: string; lastName: string } {
    const [firstName, ...lastNameParts] = name.split(' ');
    const lastName = lastNameParts.join(' ');
    return { firstName, lastName };
  }

  showData() {
    const profile = this.authService.getProfile();
    if(profile) {
        this.token = this.authService.getToken()
        this.firstName = profile['given_name']
        this.lastName = profile['family_name']
        this.email = profile['email']
        this.provider = 'Google'
    } else if(this.fbAuthService.getToken()){
      this.token = this.fbAuthService.getToken()
      let data = this.fbAuthService.getProfile()
      this.userData = data
      this.firstName = this.splitUserName(this.userData?.name).firstName
      this.lastName = this.splitUserName(this.userData?.name).lastName
      this.email = this.userData.email
      this.provider = 'Facebook'
    }
  }

  handleUserType(type: any) {
    if(type === 'PRIVATE') {
      this.clientType = type
      this.registerForm.get('companyName')?.setValue(null)
      this.registerForm.get('clientType')?.setValue(this.clientType)
    } else {
      this.clientType = type
      this.registerForm.get('clientType')?.setValue(this.clientType)
    }
  }

  setPets(action: string, petType: string) {
    if (action === 'increase') {
      if (petType === 'dog') {
        this.dogCount = this.dogCount + 1;    
        this.registerForm.get('address.dog')?.setValue(this.dogCount)  
      } else if (petType === 'cat') {
        this.catCount = this.catCount + 1;
        this.registerForm.get('address.cat')?.setValue(this.catCount)  

      } else if (petType === 'other') {
        this.otherPetCount = this.otherPetCount + 1;
        this.registerForm.get('address.otherAnimal')?.setValue(this.otherPetCount)  

      }
    } else if (action === 'decrease') {
      if (petType === 'dog') {
        if (this.dogCount !== 0) {
          this.dogCount = this.dogCount - 1;
        this.registerForm.get('address.dog')?.setValue(this.dogCount)           
        }
      } else if (petType === 'cat') {
        if (this.catCount !== 0) {
          this.catCount = this.catCount - 1;
        this.registerForm.get('address.cat')?.setValue(this.catCount)  

        }
      } else if (petType === 'other') {
        if (this.otherPetCount !== 0) {
          this.otherPetCount = this.otherPetCount - 1;
        this.registerForm.get('address.otherAnimal')?.setValue(this.otherPetCount)  

        }
      }
    }
  }

  toggleModal(){
    this.petsModal = !this.petsModal
  }

  removeDogs(){
    this.dogCount = 0
    this.registerForm.get('address.dog')?.setValue(this.dogCount)
    this.registerForm.get('address.dogText')?.setValue(null)       
  }

  removeCats(){
    this.catCount = 0
    this.registerForm.get('address.cat')?.setValue(this.catCount)
    this.registerForm.get('address.catText')?.setValue(null)       

  }

  removeOthers(){
    this.otherPetCount = 0
    this.registerForm.get('address.otherAnimal')?.setValue(this.otherPetCount)
    this.registerForm.get('address.otherAnimalText')?.setValue(null)       

  }
    
  cancelPets() {
    this.petsModal = !this.petsModal
  }

  register(){
    if(this.registerForm.valid){
          const value = this.registerForm.get('birthDate')?.value
          const dateValue = new Date(value)
          const isoString = dateValue.toISOString()
          this.registerForm.get('birthDate')?.setValue(isoString)
           const registerObj = {
              ...this.registerForm.value,
              email: this.email,
              token: this.token,
              provider: this.provider
            }

          this.auth.socialRegister(registerObj).subscribe(
            (res: any) => {
              console.log(res);
              if(res.success){
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('user', res.data.id)
                this.route.navigate(['profile'])
              }
            },
            (error: any) => {
              console.error(error);
              this.alertService.error(error.error.error,{autoClose: true})
            }
          );
        } else {
          this.alertService.error('Bitte füllen sie alle erforderlichen felder aus',{ autoClose: true })
          this.registerForm.markAllAsTouched()
        }
  } 

  handleOtherReferralSource(event: any) {
    const value = event.target?.value
    if(value === 'other') {
      this.registerForm.get('referralSource')?.setValue(value)
    } else {
      this.registerForm.get('referralSource')?.setValue(value)
    }
  }

  toggleTerms(){
    this.termsAccepted = !this.termsAccepted
    this.registerForm.get('acceptedTermsAndConditions')?.setValue(!this.termsAccepted)
    this.cdr.detectChanges()
  }

  openFile() {
    window.open("assets/terms/AGB_Pebra_Reinigung_Schweiz_AG_2024.pdf")
  }
}
