import { Pipe, PipeTransform } from '@angular/core';
import { CleaningType } from '../../enum';

@Pipe({
  name: 'cleaningType',
  standalone: true
})
export class CleaningTypePipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case CleaningType.BASIC_CLEANING:
        return 'regular cleaning';
      case CleaningType.DEEP_CLEANING:
        return CleaningType.DEEP_CLEANING;
      case CleaningType.SPRING_CLEANING:
        return CleaningType.SPRING_CLEANING;
      case CleaningType.MOVE_IN_OUT_CLEANING:
        return CleaningType.MOVE_IN_OUT_CLEANING;
      case CleaningType.CUSTOM_CLEANING:
        return CleaningType.CUSTOM_CLEANING;
      default:
        return 'unknown';
    }
  }
}
