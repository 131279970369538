<app-stepper
  class="w-[100%]"
  [stepTemplates]="
    stepTemplates.length > 0
      ? stepTemplates
      : [standartStep1, standartStep2, standartStep3, standartStep4]
  "
  [currentSubStep]="currentSubStep"
  [steps]="selectedCleaningSteps"
  [currentStep]="currentStep"
  [stepButton]="stepButton.bind(this)"
  [disableNext]="isNextDisabled.bind(this)"
  [displayButtons]="!modalOpen"
  [isLastStep]="checkLastStep.bind(this)"
  (addressFormEmitter)="getAddressForm($event)"
  [zip]="zipFromQuery"
  [city]="cityFromZip"
  [token]="hasToken"
  [price]="price"
  [displayPrice]="displayPrice"
  [isLoginButton]="changeToLogin"
  [isRegisterButton]="changeToRegister"
  [changeButton]="changeButton"
  [loginFromStepper]="loginFunction.bind(this)"
  (functionEmitter)="handleFunction($event)"
  [registerFromStepper]="registerFunction.bind(this)"
  [hours]="getHours()"
  [loading]="regLoading"
  [isCompany]="isCompany"
  [hasAcceptedTerms]="hasAcceptedTerms"
  [handleSteps]="handleSteps.bind(this)"
  [selectedCleaningType]="selectedRecurringType"
>
</app-stepper>
@if (openCommentModal){
  <app-modal
    [closeModal]="closeModal.bind(this)"
    name="comment"
    (commentAdd)="onCommentAdd($event)"
    [commentVal]="commentValue"
  >
  </app-modal>
}

@if(isTermsOpen) {
  <div class="absolute terms-modal-company">
    <app-terms 
      (isTermsChange)="handlecloseTerms($event)"
      [isTermsOpen]="isTermsOpen"
      (isTermsAccepted)="handleTermsAccepted($event)"
      [termsAccepted]="acceptedTerms"
      [postRequest]="postRequest"
      (isPostEnabled)="handlePostRequest($event)"
    ></app-terms>
  </div>
}

@if(openCabinetsModal){
  <app-modal
  [closeModal]="closeModal.bind(this)"
  name="cabinets"
  (cabinetsAddedChange)="onCabinetsAddedChange($event)"
  (cabinetsDuration)="cabinetDurationHandler($event)"
  (cabinetRemark)="cabinetRemarks($event)"
  [cabinetRemarks]="getRemark()"
  [resetCabinets]="deleteCabinets.bind(this)"
  >
  </app-modal>
}

@if(redirecting){
  <div
    class="absolute w-fit h-fit bg-[#EEE0F1] rounded-lg text-violet-400 font-bold text-[20px] p-4 -top-20 right-[30%]"
  >
    <p class="animate-pulse">{{ 'PRIVATE.Bitte haben Sie etwas Geduld bei der Weiterleitung zur Zahlung...' | translate }}</p>
  </div>
}
  
<ng-template #standartStep1>
  @if(currentSubStep === 1){
    @if(selectZipFromStart){
      <h1 class="text-[20px] font-bold">{{ 'PRIVATE.Bitte wählen Sie Ihre Postleitzahl, um fortzufahren!' | translate }}</h1>
      <form class="md:py-5 md:block flex flex-col relative">
        <input
          class="md:inline-block block md:w-[50%] border-0 py-3 mt-5 pl-7 pr-20 bg-blue-secondary text-gray-ducktertiary placeholder:text-gray-ducktertiary md:rounded-tl-xl md:rounded-bl-xl rounded-xl md:rounded-none"
          type="text"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="searchText"
          placeholder="{{ 'PRIVATE.Ihre Postleitzahl' | translate }}"
        />
        @if(searchText.length > 0) {
        <div class="z-50 drop my-2 rounded-2xl bg-white max-h-[200px] h-fit md:w-[50%] overflow-y-scroll absolute border-[1px] border-[#E9F3F4]">
          <ul class="p-2">
            <li class="hover:bg-[#E9F3F4] rounded-lg"
              *ngFor="let zip of zipcodes[0] | filter : searchText : ['zip', 'city', 'cityZip']" (click)="selectZipCode(zip.zip, zip.city)">
              <p class="p-2 inline-block cursor-pointer">
                {{ zip.zip }} {{ zip.city }}
              </p>
            </li>
          </ul>
        </div>
        }
      </form>
    } @else {
      <ng-container *ngIf="currentSubStep === 1">
        <div class="flex flex-wrap justify-between py-2 text-center">
          @for(item of cards; track $index){
          <div
            (click)="selectCleaningType(item.id)"
            [ngClass]="{
              'rounded-tl-md  shadow-xl rounded-br-md flex flex-col gap-2 justify-center items-center min-h-44 w-full md:w-[49%] cursor-pointer border-transparent border-2 hover:border-[#61BFD19f] hover:border-2': true,
              'bg-[#61BFD1]': item.id === selectedCleaningType
            }"
          >
            <p
              [ngClass]="{
                'text-[24px] font-bold': true,
                'text-white': item.id === selectedCleaningType
              }"
            >
              {{ "COMPANY." + item.title | translate }}
            </p>
            <p
              [ngClass]="{
                'text-[16px] px-2 p-1': true,
                'text-white': item.id === selectedCleaningType
              }"
            >
              {{ "COMPANY." + item.description | translate }}
            </p>
          </div>
          }
        </div>
      </ng-container>
    }
  } 
  @if(currentSubStep === 2){
  <div class="flex flex-col gap-3 items-center justify-center p-6">
    <app-custom-select
      [options]="getOptions()"
      [title]="selectedType || 'Auswählen'"
      (selected)="updateSelectedType($event)"
      class="min-w-[40%]"
    ></app-custom-select>
    @if(selectedType === 'Andere'){
      <form [formGroup]="bookingForm">
        <textarea placeholder="Remark" class="bg-gray-200 rounded-3xl p-4 min-w-[40%] mt-4" formControlName="propertyType"></textarea>
      </form>
    }
  </div>
  } 
  @if(currentSubStep === 3){
  <div class="flex items-center justify-center p-6">
    <div class="relative">
      <form [formGroup]="bookingForm">
        <input
          type="number" 
          (ngModelChange)="triggerMeters()" 
          formControlName="officeSize" 
          class="bg-[#e8eaf3] p-4 rounded-lg text-black" 
          placeholder="{{'COMPANY.Ihre Eingabe' | translate}}"
        />
      </form>
      <p class="absolute right-7 top-[30%] font-bold text-[16px]">m</p>
      <p class="absolute right-5 top-[30%] font-bold text-[12px]">2</p>
    </div>
  </div>
  } 
  @if(currentSubStep === 4){
  <ng-container>
    <div class="flex flex-wrap justify-between gap-4 py-2">
      @for(item of secondCards; track $index){
      <div
        (click)="selectSecond(item.id, item.value)"
        [ngClass]="{
          'bg-[#61BFD1] border-2 text-white border-transparent':
            item.id === selectedSecond,
          'cursor-pointer shadow-xl rounded flex flex-col gap-2 justify-center items-center min-h-24 w-full md:w-[48%] hover:border-[#61bfd19f] hover:border-2': true
        }"
      >
        <p class="text-[24px] font-bold">{{ "PRIVATE." + item.title | translate }}</p>
        @if(item.title === 'Wöchentlich'){
        <p
          [ngClass]="{
            'bg-violet-100 text-violet-400 font-bold px-2 p-1 text-[16px] rounded-md': true,
            ' bg-white': item.id === selectedSecond
          }"
        >
          {{'PRIVATE.Unsere Empfehlung' | translate}}
        </p>
        }
      </div>
      }
    </div>
  </ng-container>
  }
</ng-template>
<ng-template #standartStep2>
  <div class="flex flex-col">
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [checked]="suppliesState === 'true'"
        class="regular-checkbox"
        (change)="onSupplyChange(true)"
      />
      <span class="flex flex-col md:flex-row gap-0 md:gap-2">
        <p>{{"PRIVATE.Ja, ich besitze das erforderliche" | translate}}</p>
        <p (click)="openMaterials()" class="font-bold z-10 cursor-pointer text-blue-500 underline">{{'PRIVATE.Reinigunsmaterial' | translate}}.</p>
        </span>
    </label>
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [checked]="suppliesState === 'false'"
        class="regular-checkbox"
        (change)="onSupplyChange(false)"
      />
      {{ "PRIVATE.Nein - Ich bin mit dem Aufpreis von CHF 10.00 pro Reinigungsstunde einverstanden." | translate }}
    </label>
    @if(displayWarning){
      <p>{{ "PRIVATE.nomaterials" | translate }}</p>
    }
    @if(materialModal){
      <div class="absolute md:w-[640px] lg:w-[640px] h-[520px] md:h-[580px] lg:h-[580px] px-6 md:top-5 lg:top-5 top-0 md:right-[20%] lg:right-[20%] right-0 overflow-y-auto border-[1px] border-gray-700 bg-white shadow-lg p-4 z-50">
        <div class="flex flex-col ">
          <span class="flex justify-between w-full items-center py-2">
            <p class="font-bold text-[24px]">{{'MATERIALS.Benötigte Reingungsmaterialien' | translate}}</p>
            <img src="../assets/icons/exit.svg" alt="quit" class="w-6 h-6 cursor-pointer" (click)="closeMaterials()">
          </span>
          <div class="flex flex-col justify-between gap-2">
            <ul class="list-disc list-inside my-2 font-bold">
              <li>{{'MATERIALS.Staubsauger' | translate}}</li>
              <li>{{'MATERIALS.Mikrofasertücher (aus hygienischen Gründen mehrere Tücher)' | translate}}</li>
              <li>{{'MATERIALS.Putzschwamm Kratzfrei' | translate}}</li>
              <li>{{'MATERIALS.Mop-Set mit zwei Tüchern plus Eimer' | translate}}</li>
              <li>{{'MATERIALS.Swiffer Staubmagnet' | translate}}</li>
              <li>{{'MATERIALS.Entkalker' | translate}}</li>
              <li>{{'MATERIALS.Badreiniger' | translate}}</li>
              <li>{{'MATERIALS.Fettlöser' | translate}}</li>
              <li>{{'MATERIALS.Glasreiniger' | translate}}</li>
              <li>{{'MATERIALS.Herdreiniger' | translate}}</li>
              <li>{{'MATERIALS.Handschuhe' | translate}}</li>
              <li>{{'MATERIALS.WC Reiniger' | translate}}</li>
              <li>{{'MATERIALS.Allzweckreiniger' | translate}}</li>
            </ul>
    
            <p class="mt-4 text-black">{{'MATERIALS.cleaninginfo' | translate}}</p>
  
               <button class="btn-primary w-[50%] self-center" (click)="closeMaterials()">{{'MATERIALS.understood' | translate}}</button>
          </div>
        </div>
      </div>
    }
  </div>
</ng-template>
<ng-template #standartStep3>
  <div class="flex flex-col">
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [value]="true"
        [checked]="recycling"
        class="regular-checkbox"
        (change)="recyclingChane(true)"
      />
      {{ "PRIVATE.Ja, ich bin daran interessiert." | translate }}
    </label>
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [value]="false"
        [checked]="!recycling"
        class="regular-checkbox"
        (change)="recyclingChane(false)"
      />
      {{ "PRIVATE.Nein, aktuell habe ich kein Interesse." | translate }}
    </label>
    @if(recycling){
      <p>
        {{ "PRIVATE.recycleinfo" | translate }}
      </p>
      Re+
    }
  </div>
</ng-template>
<ng-template #standartStep4>
  @if(currentSubStep === 1){ 
    @if(token){
       <div class="max-h-[370px] gap-2 flex flex-col overflow-y-auto">
      @for(address of userAddresses; track $index){
        <div
        [ngClass]="{
          'w-full rounded-lg p-4 flex justify-between border-[1px] capitalize cursor-pointer hover:border-[#95e2e9]': true,
          'bg-[#61BFD1] text-white': selectedAddress === address.id   ,
          'opacity-55 border-[#61bfd185] hover:border-[#61bfd185] cursor-not-allowed' : convertInt(address.zipCode) !== convertInt(this.zip)
        }"
        (click)="convertInt(address.zipCode) === convertInt(this.zip) ? selectExpressAddress(address.id) : alertAddress()"
      >
        <span class="w-[20%]">
          <p class="font-bold">Name:</p>
          {{ user.firstName }} {{ " " }} {{ user.lastName }}
        </span>
          <span class="w-[20%]">
            <p class="font-bold">{{'AUTH.companyname' | translate}}:</p>
            {{ user.companyName }} 
          </span>
        <span class="w-[20%]">
          <p class="font-bold">{{'AUTH.address' | translate}}:</p>
          {{ address.street }} {{ " " }} {{ address.streetNr }}
        </span>
        <span class="w-[20%]">
          <p class="font-bold">{{'AUTH.zip' | translate}}:</p>
          {{ address.zipCode }}
        </span>
        <span class="w-[20%]">
          <p class="font-bold">{{'AUTH.city' | translate}}:</p>
          {{ address.country === 'Switzerland' ? 'Schweiz' : '' }}, {{ " " }}, {{ " " }} {{ address.city }}
        </span>
      </div>
    }
    </div>
    <!-- COME HERE -->
    } 
    @else { 
      @if(confirm){
        <app-company-contact-form
          [login]="login"
          [toggleLogin]="toggleLogin.bind(this)"
          [loginForm]="loginForm"
          [registerForm]="registerForm"
          [loginFunction]="loginFunction.bind(this)"
          [registerFunction]="registerFunction.bind(this)"
          [zip]="zipFromQuery"
          [city]="cityFromZip"
          [markTouched]="markAllControlsAsTouched"
          (confirmPassword)="onConfirmPasswordChange($event)"
          (confirmationChange)="onConfirmationChange($event)"
          [hasAcceptedTerms]="hasAcceptedTerms"
          (hasAcceptedTermsChange)="toggleAcceptedTerms($event)"
        ></app-company-contact-form>
      } 
     }
    }
    <!-- COME HERE -->
    @if(currentSubStep === 2) { @if(userAddresses.length > 0){
    <!-- @if(!confirm){ -->
      <form [formGroup]="bookingForm">
    <div class="max-h-[380px] w-full overflow-y-auto gap-6 flex flex-col">
      <p class="text-black text-[22px] font-bold">{{ "PROFILE.details" | translate }}</p>
      <span
        class="flex flex-col max-h-[300px] gap-4 px-10 overflow-y-auto w-full"
      >
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PRIVATE.Vollständiger Name' | translate}}</p>
          <p class="text-black">{{user.firstName}} {{user.lastName}}</p>
        </span>
        <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]" *ngIf="user.companyName"
      >
        <p class="w-[50%]">{{'AUTH.companyname' | translate}}</p>
        <p class="text-black">{{user.companyName}}</p>
      </span>
        <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">E-Mail</p>
          <p class="text-black">{{user.email}}</p>
        </span>
        <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'AUTH.phone' | translate}}</p>
          <p class="text-black">{{user.phoneNumber}}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'AUTH.address' | translate}}</p>
          <p class="text-black">{{address.street}} {{address.streetNr}}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PRIVATE.zipcity' | translate}}</p>
          <p class="text-black">{{address.city}} {{address.zipCode}}</p>
        </span>
        <span
        *ngIf="this.user.paymentType === null && address.additionalInformation !== null"
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ 'PRIVATE.addressremark' | translate }}</p>
          <p class="text-black">{{address.additionalInformation}}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PROFILE_MODALS.cleaningtype' | translate}}</p>
          <p class="text-black capitalize">{{ bookingForm.get("serviceType")?.value.toLowerCase() === 'private' ? 'Privat' : ('AUTH.company' | translate)}}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PROFILE_MODALS.oftencleaning' | translate}}</p>
          <p class="text-black">{{ 'PRIVATE.' + bookingForm.get("recurrence")?.value | translate }}</p>
        </span>
        <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
      >
      <p class="w-[50%]">{{'COMPANY.Bürogrösse' | translate}}</p>
      <p class="text-black">{{bookingForm.get("officeSize")?.value }} m2</p>
      </span>
      <span
      class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
    >
    <p class="w-[50%]">{{'COMPANY.Räumlichkeit' | translate}}</p>
    <p class="text-black">{{bookingForm.get("propertyType")?.value }}</p>
    </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PRIVATE.Reinigungsmaterial vorhanden?' | translate}}</p>
          <p class="text-black">
            {{ bookingForm.get("materialsIncluded")?.value ? ('PROFILE_MODALS.no' | translate) : ('PROFILE_MODALS.yes' | translate) }}
          </p>
        </span>
        <span
        *ngIf="this.bookingForm.get('recurrence')?.value === 'WINDOW_CLEANING' || this.bookingForm.get('recurrence')?.value === 'END_OF_TENANCY'"
        class="flex justify-between items-center w-full py-2 border-b-2 border-[#acacac]"
      >
      <p class="w-[50%]">{{'PRIVATE.Rabattcode' | translate}}</p>
      <form [formGroup]="discountForm" class="flex flex-col">
        <input 
        [ngClass]="{
          'custom-green border-green-600': correctAnswer === true,
          'custom-red border-red-500': correctAnswer === false
        }" 
        (blur)="checkDiscountCode(this.bookingForm.get('recurrence')?.value)"
        formControlName="couponCode" 
        class="border outline-none text-black" 
        type="text" 
        placeholder="{{'PRIVATE.Rabattcode' | translate}}">
          <span class="text-black">
            @if(correctAnswer === true) {
              {{'PRIVATE.Rabattcode erfolgreich verwendet' | translate}}
            } @else if (correctAnswer === false) {
              {{'PRIVATE.Rabattcode ungültig' | translate}}
            }
          </span>
      </form>
      </span>
      </span>

      <span class="flex gap-3">
        <input type="checkbox" (change)="toggleTerms()">
        <p class="text-violet-400">
          {{'AUTH.termstext' | translate}} <a class="underline cursor-pointer text-blue-500" (click)="openFile()">{{'AUTH.terms' | translate}}</a>
        </p>
      </span>
    </div>
  </form>

    } @else {
    <p class="text-[20p] font-bold">Redirecting to payment page...</p>
    } } @if(currentSubStep === 3){
    <div class="flex flex-col gap-4">
      <p class="text-[#7E7E7E] text-[20px]">
        {{
          "PRIVATE.Sie werden in wenigen Minuten eine Bestätigung per Mail erhalten."
            | translate
        }}
      </p>
      <p class="text-[12px]">
        {{
          "PRIVATE.Sie haben keine Bestätigung erhalten? Bitte setzten Sie sich direkt mit uns in Verbindung."
            | translate
        }}
      </p>
    </div>
    }
</ng-template>

<ng-template #expressStep1>
  @if(currentSubStep === 1){
    @if(selectZipFromStart){
      <h1 class="text-[20px] font-bold">{{ 'PRIVATE.Bitte wählen Sie Ihre Postleitzahl, um fortzufahren!' | translate }}</h1>

      <form class="md:py-5 md:block flex flex-col relative">
        <input
          class="md:inline-block block md:w-[50%] border-0 py-3 mt-5 pl-7 pr-20 bg-blue-secondary text-gray-ducktertiary placeholder:text-gray-ducktertiary md:rounded-tl-xl md:rounded-bl-xl rounded-xl md:rounded-none"
          type="text"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="searchText"
          placeholder="{{ 'PRIVATE.Ihre Postleitzahl' | translate }}"
        />
        @if(searchText.length > 0) {
        <div class="z-50 drop my-2 rounded-2xl bg-white max-h-[200px] h-fit md:w-[50%] overflow-y-scroll absolute border-[1px] border-[#E9F3F4]">
          <ul class="p-2">
            <li class="hover:bg-[#E9F3F4] rounded-lg"
              *ngFor="let zip of zipcodes[0] | filter : searchText : ['zip', 'city', 'cityZip']" (click)="selectZipCode(zip.zip, zip.city)">
              <p class="p-2 inline-block cursor-pointer">
                {{ zip.zip }} {{ zip.city }}
              </p>
            </li>
          </ul>
        </div>
        }
      </form>
    }
    @else {
      <ng-container *ngIf="currentSubStep === 1">
        <div class="flex flex-wrap justify-between py-2 text-center">
          @for(item of cards; track $index){
          <div
            (click)="selectCleaningType(item.id)"
            [ngClass]="{
              'rounded-tl-md  shadow-xl rounded-br-md flex flex-col gap-2 justify-center items-center min-h-44 w-full md:w-[49%] cursor-pointer border-transparent border-2 hover:border-[#61BFD19f] hover:border-2': true,
              'bg-[#61BFD1]': item.id === selectedCleaningType
            }"
          >
            <p
              [ngClass]="{
                'text-[24px] font-bold': true,
                'text-white': item.id === selectedCleaningType
              }"
            >
              {{ 'COMPANY.' + item.title | translate}}
            </p>
            <p
              [ngClass]="{
                'text-[16px] px-2 p-1': true,
                'text-white': item.id === selectedCleaningType
              }"
            >
              {{ 'COMPANY.' + item.description | translate}}
            </p>
          </div>
          }
        </div>
      </ng-container>
    }
  } 
  @if(currentSubStep === 2){
  <div class="flex flex-col gap-4 items-center justify-center p-6">
    <app-custom-select
      [options]="getOptions()"
      [title]="selectedType || 'Auswählen'"
      (selected)="updateSelectedType($event)"
      class="min-w-[40%]"
    ></app-custom-select>
    @if(selectedType === 'Andere'){
      <form [formGroup]="bookingForm">
        <textarea placeholder="{{ 'AUTH.remark' | translate}}" class="bg-gray-200 rounded-3xl p-4 min-w-[40%] mt-4" formControlName="propertyType"></textarea>
      </form>
    }
  </div>
  } 
  @if(currentSubStep === 3){
    <ng-container>
      <div class="flex flex-col gap-8 py-4 items-center">
        <div
          class="flex items-center gap-8 text-center justify-between p-4 md:p-14 lg:p-14 rounded-tl-lg rounded-br-lg shadow-xl min-w-[50%]"
        >
          <button
            class="w-12 h-12 px-2 bg-[#61BFD1] rounded-lg flex items-center justify-center"
            (click)="setHours('decrease')"
          >
            <img src="../assets/icons/minus.svg" alt="minus" />
          </button>
          <div>
            <p class="text-[24px] text-[#61BFD1] font-bold">
              {{ getHours() }}
              {{ "PRIVATE.Stunden" | translate }}
            </p>
          </div>
          <button
            class="w-12 h-12 bg-[#61BFD1] px-2 rounded-lg flex items-center justify-center"
            (click)="setHours('increase')"
          >
            <img src="../assets/icons/plus.svg" alt="plus" />
          </button>
        </div>
        <div class="flex flex-col md:flex-row lg:flex-row gap-6">
          <button
            (click)="openAddComment()"
            class="border-[1px] disabled:opacity-25 border-[#61BFD1] font-bold rounded-[6px] px-12 py-4 hover:bg-[#61bfd13a]"
          >
            {{ "PRIVATE.Bemerkung hinzufügen" | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  }
</ng-template>
<ng-template #expressStep2>
  <div class="flex flex-wrap gap-4">
    <ng-container>
      @for(item of additionalServices; track $index){
      <div
        (click)="selectExtraService(item)"
        [ngClass]="{
          'flex p-6 flex-col w-full md:max-w-[32%] shadow-xl rounded-tl-lg rounded-br-lg cursor-pointer border-[1px] border-transparent hover:border-[#61BFD1]': true,
          'bg-[#61BFD1] text-white': checkSelectedAddOn(item.id)
        }"
      >
        <div class="flex items-center gap-4 capitalize">
          <img
            [src]="checkSelectedAddOn(item.id) ? item.iconActive : item.icon"
            alt="window"
            class="max-w-[28px]"
          />
          {{ "PRIVATE." + item.name | translate }}
        </div>
        <div class="text-[24px] font-bold pt-3">{{'PRIVATE.' + item.description | translate}}</div>
      </div>
      }
    </ng-container>
  </div>

</ng-template>
<ng-template #expressStep3>
  @if(expressTimeSlots && expressTimeSlots.length > 0){
    <div class="flex flex-col gap-2 md:max-h-[280px] lg:max-h-[280px]">
      <div class="flex w-full md:w-[73%] justify-between">
        <div class="flex p-2 bg-[#E9EBF4] rounded-lg">{{getHours()}} {{ "PRIVATE.Stunden" | translate }}</div>
        <div class="flex md:w-[30%] lg:w-[30%] w-[50%] justify-between items-center">
          <button (click)="prevMonth()">
            <img src="../assets/icons/Vector-left.svg" alt="" />
          </button>
          <p>{{ 'DATE.' + (viewDate | date : "MMMM") | translate }} {{ viewDate | date : "y" }}</p>
          <button (click)="changeMonth()">
            <img src="../assets/icons/Vector-right.svg" alt="" />
          </button>
        </div>
      </div>
      <div class="flex w-full flex-col md:flex-row lg:flex-row justify-between">
        <app-calendar
          [disabledDays]="expressTimeSlots"
          class="md:w-[72%] lg:w-[72%] w-[100%] shadow-lg"
          [viewDate]="viewDate"
          (selectedDate)="getSelectedDay($event)"
          (viewDateUpdate)="updateViewDate($event)"
          ></app-calendar>
        <div class="w-[100%] md:w-[26%] mt-6 md:mt-0 items-center justify-center md:justify-normal md:items-start flex flex-wrap md:flex-nowrap md:flex-col gap-4 overflow-auto md:h-[290px]">
          @for(item of selectedExpressDate; track $index){
            @for(slot of item.schedule; track $index){
                <button
                [disabled]="!checkExceedExpress(slot.time.substring(0,2))"
                  (click)="selectCleaningHour(slot.time)"
                  [ngClass]="{
                    'md:w-[80%] w-auto md:mt-0 lg:mt-0 rounded-br-lg self-end rounded-tl-lg border-[1px] md:border-0 rounded-xl md:rounded-none md:shadow-lg p-4 disabled:text-gray-500 disabled:bg-gray-50': true,
                    'bg-[#61BFD1] text-white': selectedExpressHour === slot.time
                  }"
                >
                  {{slot.time}}
                </button>
            }
          }
        </div>
      </div>
    </div>
  }
  @else if(loadingSchedule){
    <div class="flex w-full h-[370px] bg-slate-300 rounded-lg animate-pulse"></div>
  }@else {
    <div class="text-[20px] font-bold text-black">Zurzeit sind keine Termine für diese Region verfügbar.</div>
  }
</ng-template>
<ng-template #expressStep4>
  <div class="flex flex-col">
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [checked]="suppliesState === 'true'"
        class="regular-checkbox"
        (change)="onSupplyChange(true)"
      />
      <span class="flex flex-col md:flex-row gap-0 md:gap-2">
        <p>{{"PRIVATE.Ja, ich besitze das erforderliche" | translate}}</p>
        <p (click)="openMaterials()" class="font-bold z-10 cursor-pointer text-blue-500 underline">{{'PRIVATE.Reinigunsmaterial' | translate}}.</p>
        </span>
    </label>
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [checked]="suppliesState === 'false'"
        class="regular-checkbox"
        (change)="onSupplyChange(false)"
      />
      {{ "PRIVATE.Nein - Ich bin mit dem Aufpreis von CHF 50.00 einverstanden." | translate }}
    </label>
    @if(displayWarning){
      <p>{{ "PRIVATE.nomaterials" | translate }}</p>
    }
    @if(materialModal){
      <div class="absolute md:w-[640px] lg:w-[640px] h-[520px] md:h-[580px] lg:h-[580px] px-6 md:top-5 lg:top-5 top-0 md:right-[20%] lg:right-[20%] right-0 overflow-y-auto border-[1px] border-gray-700 bg-white shadow-lg p-4 z-50">
        <div class="flex flex-col ">
          <span class="flex justify-between w-full items-center py-2">
            <p class="font-bold text-[24px]">{{'MATERIALS.Benötigte Reingungsmaterialien' | translate}}</p>
            <img src="../assets/icons/exit.svg" alt="quit" class="w-6 h-6 cursor-pointer" (click)="closeMaterials()">
          </span>
          <div class="flex flex-col justify-between gap-2">
            <ul class="list-disc list-inside my-2 font-bold">
              <li>{{'MATERIALS.Staubsauger' | translate}}</li>
              <li>{{'MATERIALS.Mikrofasertücher (aus hygienischen Gründen mehrere Tücher)' | translate}}</li>
              <li>{{'MATERIALS.Putzschwamm Kratzfrei' | translate}}</li>
              <li>{{'MATERIALS.Mop-Set mit zwei Tüchern plus Eimer' | translate}}</li>
              <li>{{'MATERIALS.Swiffer Staubmagnet' | translate}}</li>
              <li>{{'MATERIALS.Entkalker' | translate}}</li>
              <li>{{'MATERIALS.Badreiniger' | translate}}</li>
              <li>{{'MATERIALS.Fettlöser' | translate}}</li>
              <li>{{'MATERIALS.Glasreiniger' | translate}}</li>
              <li>{{'MATERIALS.Herdreiniger' | translate}}</li>
              <li>{{'MATERIALS.Handschuhe' | translate}}</li>
              <li>{{'MATERIALS.WC Reiniger' | translate}}</li>
              <li>{{'MATERIALS.Allzweckreiniger' | translate}}</li>
            </ul>
    
            <p class="mt-4 text-black">{{'MATERIALS.cleaninginfo' | translate}}</p>
  
               <button class="btn-primary w-[50%] self-center" (click)="closeMaterials()">{{'MATERIALS.understood' | translate}}</button>
          </div>
        </div>
      </div>
    }
  </div>
</ng-template>
<ng-template #expressStep5>
  <div class="flex flex-col">
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
      type="radio"
      [value]="true"
      [checked]="recycling"
      class="regular-checkbox"
      (change)="recyclingChane(true)"
      />
      {{ "PRIVATE.Ja, ich bin daran interessiert." | translate }}
    </label>
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
      type="radio"
      [value]="false"
      [checked]="!recycling"
      class="regular-checkbox"
      (change)="recyclingChane(false)"
      />
      {{ "PRIVATE.Nein, aktuell habe ich kein Interesse." | translate }}
    </label>
    @if(recycling){
      <p>
        {{ "PRIVATE.recycleinfo" | translate }}
      </p>
      Re+
    }
  </div>
</ng-template>
<ng-template #expressStep6>
  @if(currentSubStep === 1){ 
    @if(token){
       <div class="max-h-[370px] gap-2 flex flex-col overflow-y-auto">
      @for(address of userAddresses; track $index){
        <div
        [ngClass]="{
          'w-full rounded-lg p-4 flex justify-between border-[1px] capitalize cursor-pointer hover:border-[#95e2e9]': true,
          'bg-[#61BFD1] text-white': selectedAddress === address.id   ,
          'opacity-55 border-[#61bfd185] hover:border-[#61bfd185] cursor-not-allowed' : convertInt(address.zipCode) !== convertInt(this.zip)
        }"
        (click)="convertInt(address.zipCode) === convertInt(this.zip) ? selectExpressAddress(address.id) : alertAddress()"
      >
          <span class="w-[20%]">
            <p class="font-bold">Name:</p>
            {{ user.firstName }} {{ " " }} {{ user.lastName }}
          </span>
          <span class="w-[20%]">
            <p class="font-bold">{{'AUTH.companyname' | translate}}:</p>
            {{ user.companyName }} 
          </span>
        <span class="w-[20%]">
          <p class="font-bold">{{'AUTH.address' | translate}}:</p>
          {{ address.street }} {{ " " }} {{ address.streetNr }}
        </span>
        <span class="w-[20%]">
          <p class="font-bold">{{'AUTH.zip' | translate}}:</p>
          {{ address.zipCode }}
        </span>
        <span class="w-[20%]">
          <p class="font-bold">{{'AUTH.city' | translate}}:</p>
          {{ address.country === 'Switzerland' ? 'Schweiz' : '' }}, {{ " " }}, {{ " " }} {{ address.city }}
        </span>
      </div>
    }
    </div>
    <!-- COME HERE -->
    } 
    @else { 
      @if(confirm){
        <app-company-contact-form
          [login]="login"
          [toggleLogin]="toggleLogin.bind(this)"
          [loginForm]="loginForm"
          [registerForm]="registerForm"
          [loginFunction]="loginFunction.bind(this)"
          [registerFunction]="registerFunction.bind(this)"
          [zip]="zipFromQuery"
          [city]="cityFromZip"
          [markTouched]="markAllControlsAsTouched"
          (confirmPassword)="onConfirmPasswordChange($event)"
          (confirmationChange)="onConfirmationChange($event)"
          [hasAcceptedTerms]="hasAcceptedTerms"
          (hasAcceptedTermsChange)="toggleAcceptedTerms($event)"
        ></app-company-contact-form>
      } 
     }
    }
    <!-- COME HERE -->
    @if(currentSubStep === 2) { @if(userAddresses.length > 0){
    <!-- @if(!confirm){ -->
      <form [formGroup]="expressForm">
    <div class="max-h-[380px] w-full overflow-y-auto gap-6 flex flex-col">
      <p class="text-black text-[22px] font-bold">{{ "PROFILE.details" | translate }}</p>
      <span
        class="flex flex-col max-h-[300px] gap-4 px-10 overflow-y-auto w-full"
      >
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PRIVATE.Vollständiger Name' | translate}}</p>
          <p class="text-black">{{user.firstName}} {{user.lastName}}</p>
        </span>
        <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]" *ngIf="user.companyName"
      >
        <p class="w-[50%]">{{'AUTH.companyname' | translate}}</p>
        <p class="text-black">{{user.companyName}}</p>
      </span>
        <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">E-Mail</p>
          <p class="text-black">{{user.email}}</p>
        </span>
        <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'AUTH.phone' | translate}}</p>
          <p class="text-black">{{user.phoneNumber}}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'AUTH.address' | translate}}</p>
          <p class="text-black">{{address.street}} {{address.streetNr}}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PRIVATE.zipcity' | translate}}</p>
          <p class="text-black">{{address.city}} {{address.zipCode}}</p>
        </span>
        <span
        *ngIf="this.user.paymentType === null && address.additionalInformation !== null"
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ 'PRIVATE.addressremark' | translate }}</p>
          <p class="text-black">{{address.additionalInformation}}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PROFILE_MODALS.cleaningtype' | translate}}</p>
          <p class="text-black">{{ expressForm.get("serviceType")?.value.toLowerCase() === 'private' ? 'Privat' : ('AUTH.company' | translate) }}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ "PROFILE_MODALS.oftencleaning" | translate }}</p>
          <p class="text-black">{{ 'PRIVATE.' + expressForm.get("recurrence")?.value | translate }}</p>
        </span>
      <span
      class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
    >
    <p class="w-[50%]">{{'COMPANY.Räumlichkeit' | translate}}</p>
    <p class="text-black">{{ bookingForm.get("propertyType")?.value }}</p>
    </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PRIVATE.Zusätzliche Angaben' | translate}}</p>
          <p class="text-black">{{expressForm.get('specialInstruction')?.value}}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ "PROFILE_MODALS.startdate" | translate }}</p>
          <p class="text-black">
            {{ 'DATE.' + (expressForm.get("startDate")?.value | date : "EEEE") | translate }}, {{expressForm.get("startDate")?.value | date : "d"}} {{  'DATE.' + (expressForm.get("startDate")?.value | date : "MMMM") | translate }} {{expressForm.get("startDate")?.value | date : "y"}}
          </p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PRIVATE.Reinigungsmaterial vorhanden?' | translate}}</p>
          <p class="text-black">
            {{ expressForm.get("materialsIncluded")?.value ? ('PROFILE_MODALS.no' | translate) : ('PROFILE_MODALS.yes' | translate) }}
          </p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PRIVATE.Gesamtbetrag' | translate}}</p>
          <p class="text-black">{{ price }} CHF</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PROFILE.duration' | translate}}</p>
          <p class="text-black">{{ getHours() }} {{'PROFILE.hours' | translate}}</p>
        </span>
        <h1 class="font-bolD text-[20px]">{{'PRIVATE.Zusatzleistungen' | translate}}</h1>
        @for(service of expressForm.get('additionalServices')?.value | keyvalue; track service){
          <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
          *ngIf="service.value && service.key !== 'cabinetRemark'"
        >
          <p class="w-[50%]">{{"PRIVATE." + summaryText(service.key) | translate}}</p>
          <p *ngIf="service.key === 'ironingDuration' || service.key === 'cabinetDuration'" class="text-black">{{ service.value }}min</p>
          <p *ngIf="summaryText(service.key) === 'Kühlschrank' || summaryText(service.key) === 'Backofen'" class="text-black">30 min</p>

          </span>

          <span
            class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
            *ngIf="service.value && service.key === 'cabinetRemark'"
          >
          <p class="w-[50%]">{{'PRIVATE.Bemerkung zu den Schränken' | translate}}</p>
          <p class="text-black">{{ service.value }}</p>
        </span>
        
        }
        <span
        class="flex justify-between items-center w-full py-2 border-b-2 border-[#acacac]"
      >
      <p class="w-[50%]">{{'PRIVATE.Rabattcode' | translate}}</p>
      <form [formGroup]="discountForm" class="flex flex-col">
        <input 
        [ngClass]="{
          'custom-green border-green-600': correctAnswer === true,
          'custom-red border-red-500': correctAnswer === false
        }" 
        (blur)="checkDiscountCode('EXPRESS')"
        formControlName="couponCode" 
        class="border outline-none text-black" 
        type="text" 
        placeholder="{{'PRIVATE.Rabattcode' | translate}}">
          <span class="text-black">
            @if(correctAnswer === true) {
              {{'PRIVATE.Rabattcode erfolgreich verwendet' | translate}}
            } @else if (correctAnswer === false) {
              {{'PRIVATE.Rabattcode ungültig' | translate}}
            }
          </span>
      </form>
      </span>
      </span>
      <span class="flex gap-3">
        <input type="checkbox" (change)="toggleTerms()">
        <p class="text-violet-400">
          {{'AUTH.termstext' | translate}} <a class="underline cursor-pointer text-blue-500" (click)="openFile()">{{'AUTH.terms' | translate}}</a>
        </p>
      </span>
    </div>
  </form>

    } @else {
    <p class="text-[20p] font-bold">Redirecting to payment page...</p>
    } } @if(currentSubStep === 3){
    <div class="flex flex-col gap-4">
      <p class="text-[#7E7E7E] text-[20px]">
        {{
          "PRIVATE.Sie werden in wenigen Minuten eine Bestätigung per Mail erhalten."
            | translate
        }}
      </p>
      <p class="text-[12px]">
        {{
          "PRIVATE.Sie haben keine Bestätigung erhalten? Bitte setzten Sie sich direkt mit uns in Verbindung."
            | translate
        }}
      </p>
    </div>
    }
</ng-template>


<ng-template #individualStep1>
  @if(currentSubStep === 1){
    @if(selectZipFromStart){
      <h1 class="text-[20px] font-bold">{{'PRIVATE.Bitte wählen Sie Ihre Postleitzahl, um fortzufahren!' | translate}}</h1>

      <form class="md:py-5 md:block flex flex-col relative">
        <input
          class="md:inline-block block md:w-[50%] border-0 py-3 mt-5 pl-7 pr-20 bg-blue-secondary text-gray-ducktertiary placeholder:text-gray-ducktertiary md:rounded-tl-xl md:rounded-bl-xl rounded-xl md:rounded-none"
          type="text"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="searchText"
          placeholder="{{ 'PRIVATE.Ihre Postleitzahl' | translate }}"
        />
        @if(searchText.length > 0) {
        <div class="z-50 drop my-2 rounded-2xl bg-white max-h-[200px] h-fit md:w-[50%] overflow-y-scroll absolute border-[1px] border-[#E9F3F4]">
          <ul class="p-2">
            <li class="hover:bg-[#E9F3F4] rounded-lg"
              *ngFor="let zip of zipcodes[0] | filter : searchText : ['zip', 'city', 'cityZip']" (click)="selectZipCode(zip.zip, zip.city)">
              <p class="p-2 inline-block cursor-pointer">
                {{ zip.zip }} {{ zip.city }}
              </p>
            </li>
          </ul>
        </div>
        }
      </form>
    } @else {
      <ng-container *ngIf="currentSubStep === 1">
        <div class="flex flex-wrap justify-between py-2 text-center">
          @for(item of cards; track $index){
          <div
            (click)="selectCleaningType(item.id)"
            [ngClass]="{
              'rounded-tl-md  shadow-xl rounded-br-md flex flex-col gap-2 justify-center items-center min-h-44 w-full md:w-[49%] cursor-pointer border-transparent border-2 hover:border-[#61BFD19f] hover:border-2': true,
              'bg-[#61BFD1]': item.id === selectedCleaningType
            }"
          >
            <p
              [ngClass]="{
                'text-[24px] font-bold': true,
                'text-white': item.id === selectedCleaningType
              }"
            >
              {{'PRIVATE.' + item.title | translate}}
            </p>
            <p
              [ngClass]="{
                'text-[16px] px-2 p-1': true,
                'text-white': item.id === selectedCleaningType
              }"
            >
              {{'PRIVATE.' + item.description | translate}}
            </p>
          </div>
          }
        </div>
      </ng-container>
    }
  } 
  @if(currentSubStep === 2){
  <div class="flex items-center justify-center p-6">
    <app-custom-select
      [options]="getOptions()"
      [title]="selectedType || 'Auswählen'"
      (selected)="updateSelectedType($event)"
      class="min-w-[40%]"
    ></app-custom-select>
  </div>
  } 
  @if(currentSubStep === 3){
  <div class="flex items-center justify-center p-6">
    <div class="relative">
      <form [formGroup]="bookingForm">
        <input
          type="number" 
          (ngModelChange)="triggerMeters()" 
          formControlName="officeSize" 
          class="bg-[#e8eaf3] p-4 rounded-lg text-black" 
          placeholder="{{'COMPANY.Ihre Eingabe' | translate}}"
        />
      </form>
      <p class="absolute right-7 top-[30%] font-bold text-[16px]">m</p>
      <p class="absolute right-5 top-[30%] font-bold text-[12px]">2</p>
    </div>
  </div>
  } 
  @if(currentSubStep === 4){
  <ng-container>
    <div class="flex flex-wrap justify-between gap-4 py-2">
      @for(item of secondCards; track $index){
      <div
        (click)="selectSecond(item.id, item.value)"
        [ngClass]="{
          'bg-[#61BFD1] border-2 text-white border-transparent':
            item.id === selectedSecond,
          'cursor-pointer shadow-xl rounded flex flex-col gap-2 justify-center items-center min-h-24 w-full md:w-[48%] hover:border-[#61bfd19f] hover:border-2': true
        }"
      >
        <p class="text-[24px] font-bold">{{'PRIVATE.' + item.title | translate}}</p>
        @if(item.title === 'Wöchentlich'){
        <p
          [ngClass]="{
            'bg-violet-100 text-violet-400 font-bold px-2 p-1 text-[16px] rounded-md': true,
            ' bg-white': item.id === selectedSecond
          }"
        >
        {{'PRIVATE.Unsere Empfehlung' | translate}}
        </p>
        }
      </div>
      }
    </div>
  </ng-container>
  }
</ng-template>
<ng-template #individualStep2>
  <ng-container>
    <div class="flex flex-col">
      <div class="shadow w-full bg-white rounded flex flex-col md:flex-row gap-2 md:gap-16 p-4 items-center">
        <div class="flex justify-between w-[80%] md:w-[29%] ">
          <button
            class="w-7 h-7 bg-[#61BFD1] rounded-lg flex items-center justify-center"
            (click)="setDays('decrease')"
          >
            <img src="../assets/icons/minus.svg" alt="plus" class="w-4" />
          </button>
          <p class="text-[20px]">{{ cleaningDays }}</p>
          <button
            class="w-7 h-7 bg-[#61BFD1] rounded-lg flex items-center justify-center"
            (click)="setDays('increase')"
          >
            <img src="../assets/icons/plus.svg" alt="plus" class="w-4" />
          </button>
        </div>
        {{'PRIVATE.Mal pro Woche' | translate}}
      </div>
      <form [formGroup]="individualForm">
        <div formArrayName="formArray">
          <div
            *ngFor="let formArrayGroup of individualFormGroups; let i = index"
          >
            <div
              [formGroup]="formArrayGroup"
              class="w-full flex flex-col md:flex-row justify-between rounded"
            >
              <div class="md:w-[49%] py-2">
                <app-custom-select
                  [options]="getDays()"
                  [title]="this.formArrayGroup.get('day')?.value"
                  (selected)="updateSelectedDay(i, $event)"
                ></app-custom-select>
              </div>

              <div class="md:w-[49%] py-2">
                <app-custom-select
                  [options]="individualHours"
                  [title]="
                    this.formArrayGroup.get('timeFrame')?.value ===
                    'AFTER_NOON'
                      ? 'Nachmittag'
                      : 'Vormittag'
                  "
                  [subTitle]="getSubtitle(formArrayGroup)"
                  (selected)="updateSelectedHour(i, $event)"
                ></app-custom-select>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div
        class="overflow-y-scroll hide max-h-[320px] flex flex-col gap-5"
      ></div>
    </div>
  </ng-container>
</ng-template>
<ng-template #individualStep3>
  <div class="flex flex-col">
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [checked]="suppliesState === 'true'"
        class="regular-checkbox"
        (change)="onSupplyChange(true)"
      />
      <span class="flex flex-col md:flex-row gap-0 md:gap-2">
        <p>{{"PRIVATE.Ja, ich besitze das erforderliche" | translate}}</p>
        <p (click)="openMaterials()" class="font-bold z-10 cursor-pointer text-blue-500 underline">{{'PRIVATE.Reinigunsmaterial' | translate}}.</p>
        </span>
    </label>
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [checked]="suppliesState === 'false'"
        class="regular-checkbox"
        (change)="onSupplyChange(false)"
      />
      {{ "PRIVATE.Nein - Ich bin mit dem Aufpreis von CHF 10.00 pro Reinigungsstunde einverstanden." | translate }}
    </label>
    @if(displayWarning){
      <p>{{ "PRIVATE.nomaterials" | translate }}</p>
    }
    @if(materialModal){
      <div class="absolute md:w-[640px] lg:w-[640px] h-[520px] md:h-[580px] lg:h-[580px] px-6 md:top-5 lg:top-5 top-0 md:right-[20%] lg:right-[20%] right-0 overflow-y-auto border-[1px] border-gray-700 bg-white shadow-lg p-4 z-50">
        <div class="flex flex-col ">
          <span class="flex justify-between w-full items-center py-2">
            <p class="font-bold text-[24px]">{{'MATERIALS.Benötigte Reingungsmaterialien' | translate}}</p>
            <img src="../assets/icons/exit.svg" alt="quit" class="w-6 h-6 cursor-pointer" (click)="closeMaterials()">
          </span>
          <div class="flex flex-col justify-between gap-2">
            <ul class="list-disc list-inside my-2 font-bold">
              <li>{{'MATERIALS.Staubsauger' | translate}}</li>
              <li>{{'MATERIALS.Mikrofasertücher (aus hygienischen Gründen mehrere Tücher)' | translate}}</li>
              <li>{{'MATERIALS.Putzschwamm Kratzfrei' | translate}}</li>
              <li>{{'MATERIALS.Mop-Set mit zwei Tüchern plus Eimer' | translate}}</li>
              <li>{{'MATERIALS.Swiffer Staubmagnet' | translate}}</li>
              <li>{{'MATERIALS.Entkalker' | translate}}</li>
              <li>{{'MATERIALS.Badreiniger' | translate}}</li>
              <li>{{'MATERIALS.Fettlöser' | translate}}</li>
              <li>{{'MATERIALS.Glasreiniger' | translate}}</li>
              <li>{{'MATERIALS.Herdreiniger' | translate}}</li>
              <li>{{'MATERIALS.Handschuhe' | translate}}</li>
              <li>{{'MATERIALS.WC Reiniger' | translate}}</li>
              <li>{{'MATERIALS.Allzweckreiniger' | translate}}</li>
            </ul>
    
            <p class="mt-4 text-black">{{'MATERIALS.cleaninginfo' | translate}}</p>
  
               <button class="btn-primary w-[50%] self-center" (click)="closeMaterials()">{{'MATERIALS.understood' | translate}}</button>
          </div>
        </div>
      </div>
    }
  </div>
</ng-template>
<ng-template #individualStep4>
  <div class="flex flex-col">
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
      type="radio"
      [value]="true"
      [checked]="recycling"
      class="regular-checkbox"
      (change)="recyclingChane(true)"
      />
      {{ "PRIVATE.Ja, ich bin daran interessiert." | translate }}
    </label>
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
      type="radio"
      [value]="false"
      [checked]="!recycling"
      class="regular-checkbox"
      (change)="recyclingChane(false)"
      />
      {{ "PRIVATE.Nein, aktuell habe ich kein Interesse." | translate }}
    </label>
    @if(recycling){
      <p>
        {{ "PRIVATE.recycleinfo" | translate }}
      </p>
      Re+
    }
  </div>
</ng-template>
<ng-template #individualStep5>
  @if(currentSubStep === 1){ 
    @if(token){
       <div class="max-h-[370px] gap-2 flex flex-col overflow-y-auto">
      @for(address of userAddresses; track $index){
        <div
        [ngClass]="{
          'w-full rounded-lg p-4 flex justify-between border-[1px] capitalize cursor-pointer hover:border-[#95e2e9]': true,
          'bg-[#61BFD1] text-white': selectedAddress === address.id   ,
          'opacity-55 border-[#61bfd185] hover:border-[#61bfd185] cursor-not-allowed' : convertInt(address.zipCode) !== convertInt(this.zip)
        }"
        (click)="convertInt(address.zipCode) === convertInt(this.zip) ? selectExpressAddress(address.id) : alertAddress()"
      >
        <span class="w-[20%]">
          <p class="font-bold">Name:</p>
          {{ user.firstName }} {{ " " }} {{ user.lastName }}
        </span>
          <span class="w-[20%]">
            <p class="font-bold">{{'AUTH.companyname' | translate}}:</p>
            {{ user.companyName }} 
          </span>
        <span class="w-[20%]">
          <p class="font-bold">{{'AUTH.address' | translate}}:</p>
          {{ address.street }} {{ " " }} {{ address.streetNr }}
        </span>
        <span class="w-[20%]">
          <p class="font-bold">{{'AUTH.zip' | translate}}:</p>
          {{ address.zipCode }}
        </span>
        <span class="w-[20%]">
          <p class="font-bold">{{'AUTH.city' | translate}}:</p>
          {{ address.country === 'Switzerland' ? 'Schweiz' : '' }}, {{ " " }} {{ address.city }}
        </span>
      </div>
    }
    </div>
    <!-- COME HERE -->
    } 
    @else { 
      @if(confirm){
        <app-company-contact-form
          [login]="login"
          [toggleLogin]="toggleLogin.bind(this)"
          [loginForm]="loginForm"
          [registerForm]="registerForm"
          [loginFunction]="loginFunction.bind(this)"
          [registerFunction]="registerFunction.bind(this)"
          [zip]="zipFromQuery"
          [city]="cityFromZip"
          [markTouched]="markAllControlsAsTouched"
          (confirmPassword)="onConfirmPasswordChange($event)"
          (confirmationChange)="onConfirmationChange($event)"
          [hasAcceptedTerms]="hasAcceptedTerms"
          (hasAcceptedTermsChange)="toggleAcceptedTerms($event)"
        ></app-company-contact-form>
      } 
     }
    }
    <!-- COME HERE -->
    @if(currentSubStep === 2) { @if(userAddresses.length > 0){
    <!-- @if(!confirm){ -->
      <form [formGroup]="bookingForm">
    <div class="max-h-[380px] w-full overflow-y-auto gap-6 flex flex-col">
      <p class="text-black text-[22px] font-bold">{{ "PROFILE.details" | translate }}</p>
      <span
        class="flex flex-col max-h-[300px] gap-4 px-10 overflow-y-auto w-full"
      >
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PRIVATE.Vollständiger Name' | translate}}</p>
          <p class="text-black">{{user.firstName}} {{user.lastName}}</p>
        </span>
        <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]" *ngIf="user.companyName"
      >
        <p class="w-[50%]">{{'AUTH.companyname' | translate}}</p>
        <p class="text-black">{{user.companyName}}</p>
      </span>
        <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">E-Mail</p>
          <p class="text-black">{{user.email}}</p>
        </span>
        <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'AUTH.phone' | translate}}</p>
          <p class="text-black">{{user.phoneNumber}}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'AUTH.address' | translate}}</p>
          <p class="text-black">{{address.street}} {{address.streetNr}}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PRIVATE.zipcity' | translate}}</p>
          <p class="text-black">{{address.city}} {{address.zipCode}}</p>
        </span>
        <span
        *ngIf="this.user.paymentType === null && address.additionalInformation !== null"
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ 'PRIVATE.addressremark' | translate }}</p>
          <p class="text-black">{{address.additionalInformation}}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PROFILE_MODALS.cleaningtype' | translate}}</p>
          <p class="text-black capitalize">{{ bookingForm.get("serviceType")?.value.toLowerCase() === 'private' ? 'Privat' : ("AUTH.company" | translate)}}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ "PROFILE_MODALS.oftencleaning" | translate }}</p>
          <p class="text-black">{{ 'PRIVATE.' + bookingForm.get("recurrence")?.value | translate }}</p>
        </span>
        <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
      >
      <p class="w-[50%]">{{'COMPANY.Bürogrösse' | translate}}</p>
      <p class="text-black">{{bookingForm.get("officeSize")?.value }} m2</p>
      </span>
      <span
      class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
    >
    <p class="w-[50%]">{{'COMPANY.Räumlichkeit' | translate}}</p>
    <p class="text-black">{{bookingForm.get("propertyType")?.value | translate}}</p>
    </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PRIVATE.Reinigungsmaterial vorhanden?' | translate}}</p>
          <p class="text-black">
            {{ bookingForm.get("materialsIncluded")?.value ? ('PROFILE_MODALS.no' | translate) : ('PROFILE_MODALS.yes' | translate) }}
          </p>
        </span>
        @if(bookingForm.get('cleaningDays')?.value){
        <h1 class="font-bold text-[20px]">{{'PRIVATE.Reinigungstage' | translate}}</h1>

          @for(days of bookingForm.get('cleaningDays')?.value; track $index){
            <span
              class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
            >
              <p class="w-[50%]">{{ 'PRIVATE.' + days.day | translate }}</p>
              <p class="text-black">{{ days.timeFrame === 'BEFORE_NOON' ? ('PRIVATE.Vormittag' | translate) : ('PRIVATE.Nachmittag' | translate) }}</p>
            </span>
            }
        }
      </span>
      <span class="flex gap-3">
        <input type="checkbox" (change)="toggleTerms()">
        <p class="text-violet-400">
          {{'AUTH.termstext' | translate}} <a class="underline cursor-pointer text-blue-500" (click)="openFile()">{{'AUTH.terms' | translate}}</a>
        </p>
      </span>
    </div>
  </form>

    } @else {
    <p class="text-[20p] font-bold">Redirecting to payment page...</p>
    } } @if(currentSubStep === 3){
    <div class="flex flex-col gap-4">
      <p class="text-[#7E7E7E] text-[20px]">
        {{
          "PRIVATE.Sie werden in wenigen Minuten eine Bestätigung per Mail erhalten."
            | translate
        }}
      </p>
      <p class="text-[12px]">
        {{
          "PRIVATE.Sie haben keine Bestätigung erhalten? Bitte setzten Sie sich direkt mit uns in Verbindung."
            | translate
        }}
      </p>
    </div>
    }
</ng-template>
