import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './terms.component.html',
  styleUrl: './terms.component.scss'
})
export class TermsComponent {
  
  @Input() isTermsOpen: boolean = false
  @Output() isTermsChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() termsAccepted: boolean = false
  @Output() isTermsAccepted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() postRequest: boolean = false
  @Output() isPostEnabled: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  closeTerms() {
    this.isTermsOpen = false
    this.termsAccepted = false
    this.isTermsChange.emit(this.isTermsOpen)
  }

  toggleTerms(){
    this.termsAccepted = !this.termsAccepted
    this.isTermsAccepted.emit(this.termsAccepted)
  }
  
  handleAcceptedTerms () {
    if(this.termsAccepted) {
      this.postRequest = !this.postRequest
      this.isPostEnabled.emit(this.postRequest)
      this.isTermsOpen = false
      this.termsAccepted = false
      this.isTermsChange.emit(this.isTermsOpen)
      this.isTermsAccepted.emit(this.termsAccepted)
    } else {
      this.isTermsOpen = false
      this.termsAccepted = false
      this.isTermsChange.emit(this.isTermsOpen)
      this.isTermsAccepted.emit(this.termsAccepted)
    }
  }
  
  openFile() {
    window.open("assets/terms/AGB_Pebra_Reinigung_Schweiz_AG_2024.pdf")
  }

}
