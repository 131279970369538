<div class="bg-white mx-3 rounded-tl-lg rounded-br-lg h-[70vh] overflow-y-auto" (keyup.enter)="register()">
    <div class="flex flex-col px-5 p-12 items-center justify-center text-start">
      <span class="flex flex-col items-start gap-4 w-full md:w-[80%] md:px-8">
        <div class="flex flex-col gap-2 w-full h-full justify-between">
          <div class="flex flex-col md:flex-row justify-between">
            <p class="text-[28px] font-bold">{{ 'AUTH.socialregister' | translate}}</p>
            <div class="flex gap-2">
              <label 
                [ngClass]="{'bg-[#E9F3F4]': clientType === 'PRIVATE'}" 
                class="custom-checkbox text-[20px] text-center w-[140px] h-[45px] flex gap-3 items-center py-2">
                <input
                  type="radio"
                  [checked]="clientType === 'PRIVATE'"
                  class="hidden-checkbox"
                  (change)="handleUserType('PRIVATE')"
                />
                {{ 'AUTH.private' | translate}}
              </label>
              <label 
              [ngClass]="{'bg-[#E9F3F4]': clientType === 'COMPANY'}" 
              class="custom-checkbox text-[20px] text-center w-[140px] h-[45px] flex gap-3 items-center py-2">
                <input
                  [checked]="clientType === 'COMPANY'"
                  type="radio"
                  class="hidden-checkbox"
                  (change)="handleUserType('COMPANY')"
                />
                {{ 'AUTH.company' | translate}}
              </label>
            </div>
          </div>
            <form [formGroup]="registerForm">
              <div class="flex flex-wrap gap-6 text-[#346973]">
                <input 
                  formControlName="firstName" 
                  type="text" 
                  placeholder="{{ 'AUTH.firstname' | translate}}" 
                  class="w-[98%] md:w-[48%]">

                <input
                  formControlName="lastName"
                  type="text"
                  placeholder="{{ 'AUTH.lastname' | translate}}"
                  class="w-[98%] md:w-[48%]"
                />
                <input 
                  *ngIf="clientType === 'COMPANY'"
                  formControlName="companyName" 
                  type="text" placeholder="{{ 'AUTH.companyname' | translate}}" 
                  class="w-[98%] md:w-[48%]">
                <select
                  name="gender"
                  formControlName="sex"
                  placeholder="Geschlecht"
                  class="w-[98%] md:w-[48%]"
                >
                  <option value="" selected disabled hidden>{{ 'AUTH.gender' | translate}}</option>
                  <option value="MALE">{{ 'AUTH.male' | translate}}</option>
                  <option value="FEMALE">{{ 'AUTH.female' | translate}}</option>
                  <option value="DIVERSE">{{ 'AUTH.diverse' | translate}}</option>
                </select>
                <input
                  formControlName="birthDate"
                  type="date"
                  placeholder="{{ 'AUTH.birthdate' | translate}}"
                  class="w-[98%] md:w-[48%]"
                />
                <input
                  formControlName="email"
                  type="email"
                  placeholder="E-mail"
                  class="w-[98%] md:w-[48%]"
                />
                <input
                  formControlName="phoneNumber"
                  type="text"
                  placeholder="{{ 'AUTH.phone' | translate}}"
                  class="w-[98%] md:w-[48%]"
                />
                <select formControlName="referralSource" class="w-[98%] md:w-[48%]">
                  <option value="" selected disabled hidden>{{'PRIVATE.referraltext' | translate }}</option>
                  <option value="GOOGLE">Google</option>
                  <option value="FACEBOOK">Facebook</option>
                  <option value="FLYER">Flyer</option>
                  <option value="INSTAGRAM">Instagram</option>
                  <option value="LINKEDIN">LinkedIn</option>
                  <option value="PASCALZUBERBUHLER">Pascal Zuberbühler</option>
                  <option value="EMPFEHLUNG">Empfehlung</option>
                  <option value="OTHER">{{'AUTH.other' | translate}}</option>
                </select>
                <input
                formControlName="otherReferralSource"
                *ngIf="this.registerForm.get('referralSource')?.value === 'OTHER' || this.registerForm.get('referralSource')?.value === 'EMPFEHLUNG'"
                type="text" [placeholder]="this.registerForm.get('referralSource')?.value === 'OTHER' ? ('AUTH.other' | translate) : ('AUTH.recommended' | translate)" class="w-[98%] md:w-[48%]">
              </div>
            </form>
        </div>
      <div class="flex flex-col gap-2 w-full h-full relative">
        <p class="text-[28px] font-bold">{{ 'AUTH.address' | translate}}</p>
        <form [formGroup]="registerForm">
          @if(petsModal){
          <div
            formGroupName="address"
            class="absolute top-2 w-full center-register flex flex-col bg-[#E9F3F4] shadow-lg p-6 z-10"
          >
            <div class="md:w-full bg-transparent flex justify-end gap-3">
              <span
                class="flex p-2 rounded-full cursor-pointer"
                (click)="toggleModal()"
                ><img src="../../assets/icons/exit.svg" alt=""
              /></span>
            </div>
            <div class="flex justify-between mt-4 flex-col md:flex-row">
              <div
                class="flex w-full md:w-[32%] my-5 md:my-0 flex-col p-6 gap-8 rounded-tl-lg rounded-br-lg"
              >
                <span class="flex items-center gap-2">
                  <p class="text-[16px]">{{ "AUTH.dog" | translate }}</p>
                </span>
                <textarea
                  formControlName="dogText"
                  name=""
                  id=""
                  cols="25"
                  rows="2"
                  placeholder="{{ 'AUTH.remark'| translate }}"
                  class="bg-gray-200 rounded-3xl p-4"
                ></textarea>
                <div
                  class="flex items-center gap-8 text-center justify-between"
                >
                  <button
                    (click)="setPets('decrease', 'dog')"
                    class="w-12 h-12 bg-[#61BFD1] rounded-lg px-2 flex items-center justify-center"
                  >
                    <img src="../assets/icons/minus.svg" alt="minus" />
                  </button>
                  <div>
                    <p class="text-[24px] font-bold">
                      {{ dogCount }}
                    </p>
                  </div>
                  <button
                    (click)="setPets('increase', 'dog')"
                    class="w-12 h-12 bg-[#61BFD1] rounded-lg px-2 flex items-center justify-center"
                  >
                    <img src="../assets/icons/plus.svg" alt="plus" />
                  </button>
                </div>
              </div>
              <div
                class="flex w-full md:w-[32%] flex-col p-6 gap-8 bg-[#61BFD1] rounded-tl-lg rounded-br-lg"
              >
                <span class="flex items-center gap-2">
                  <p class="text-[16px] text-white">
                    {{ 'AUTH.cat'| translate }}
                  </p>
                </span>
                <textarea
                  formControlName="catText"
                  name=""
                  id=""
                  cols="25"
                  rows="2"
                  placeholder="{{ 'AUTH.remark'| translate }}"
                  class="bg-gray-200 rounded-3xl p-4"
                ></textarea>
                <div
                  class="flex items-center gap-8 text-center justify-between"
                >
                  <button
                    (click)="setPets('decrease', 'cat')"
                    class="w-12 h-12 bg-white rounded-lg flex items-center justify-center"
                  >
                    <img src="../assets/icons/minus-trq.svg" alt="minus" />
                  </button>
                  <div>
                    <p class="text-[24px] text-white font-bold">
                      {{ catCount }}
                    </p>
                  </div>
                  <button
                    (click)="setPets('increase', 'cat')"
                    class="w-12 h-12 bg-white rounded-lg flex items-center justify-center"
                  >
                    <img src="../assets/icons/plus-trq.svg" alt="plus" />
                  </button>
                </div>
              </div>
              <div
                class="flex w-full md:w-[32%] my-5 md:my-0 flex-col p-6 gap-8 rounded-tl-lg rounded-br-lg"
              >
                <span class="flex items-center gap-2">
                  <p class="text-[16px]">{{ 'AUTH.other'| translate }}</p>
                </span>
                <textarea
                  formControlName="otherAnimalText"
                  name=""
                  id=""
                  cols="25"
                  rows="2"
                  placeholder="{{ 'AUTH.remark'| translate }}"
                  class="bg-gray-200 rounded-3xl p-4"
                ></textarea>
                <div
                  class="flex items-center gap-8 text-center justify-between"
                >
                  <button
                    (click)="setPets('decrease', 'other')"
                    class="w-12 h-12 bg-[#61BFD1] rounded-lg px-2 flex items-center justify-center"
                  >
                    <img src="../assets/icons/minus.svg" alt="minus" />
                  </button>
                  <div>
                    <p class="text-[24px] text-[#61BFD1] font-bold">
                      {{ otherPetCount }}
                    </p>
                  </div>
                  <button
                    (click)="setPets('increase', 'other')"
                    class="w-12 h-12 bg-[#61BFD1] rounded-lg px-2 flex items-center justify-center"
                  >
                    <img src="../assets/icons/plus.svg" alt="plus" />
                  </button>
                </div>
              </div>
            </div>
            <div class="bg-transparent flex gap-5 w-full justify-end p-4">
              <button class="btn-primary-outlined" (click)="toggleModal()">
                {{ 'AUTH.cancel'| translate }}
              </button>
              <button class="btn-primary" (click)="toggleModal()">
                {{ 'AUTH.confirm'| translate }}
              </button>
            </div>
          </div>
          }
        </form>
        <form [formGroup]="registerForm">
          <div
            formGroupName="address"
            class="flex flex-wrap gap-6 text-[#346973]"
          >
            <input
              formControlName="street"
              type="text"
              placeholder="{{ 'AUTH.street'| translate }}"
              class="w-[98%] md:w-[89%]"
            />
            <input
              formControlName="streetNr"
              type="text"
              placeholder="Nr."
              class="w-[98%] md:w-[7%]"
            />
            <input
              formControlName="zipCode"
              type="text"
              placeholder="{{ 'AUTH.zip'| translate }}"
              class="w-[98%] md:w-[48%]"
            />
            <input
              formControlName="city"
              type="text"
              placeholder="{{ 'AUTH.city'| translate }}"
              class="w-[98%] md:w-[48%]"
            />
            <span class="flex items-center gap-6 w-[97%]">
              <button
                (click)="toggleModal()"
                class="bg-[#E9F3F4] rounded-lg p-4 outline-none border-[1px] border-transparent"
              >
              {{ 'AUTH.animals'| translate }}
              </button>
              @if(dogCount){
              <span class="relative">
                <span
                  class="absolute cursor-pointer rounded-full bg-red-200 text-red-500 flex items-center justify-center w-[24px] h-[24px] -top-2 -right-2"
                  (click)="removeDogs()"
                  >x</span
                >
                <button
                  disabled
                  class="bg-[#E9F3F4] rounded-lg p-4 outline-none border-[1px] text-[#a5a5a5] border-transparent"
                >
                {{ 'AUTH.dog'| translate }}
                </button>
              </span>
              } @if(catCount){
              <span class="relative">
                <span
                  class="absolute cursor-pointer rounded-full bg-red-200 text-red-500 flex items-center justify-center w-[24px] h-[24px] -top-2 -right-2"
                  (click)="removeCats()"
                  >x</span
                >
                <button
                  disabled
                  class="bg-[#E9F3F4] rounded-lg p-4 outline-none border-[1px] text-[#a5a5a5] border-transparent"
                >
                {{ 'AUTH.cat'| translate }}
                </button>
              </span>
              } @if(otherPetCount){
              <span class="relative">
                <span
                  class="absolute cursor-pointer rounded-full bg-red-200 text-red-500 flex items-center justify-center w-[24px] h-[24px] -top-2 -right-2"
                  (click)="removeOthers()"
                  >x</span
                >
                <button
                  disabled
                  class="bg-[#E9F3F4] rounded-lg p-4 outline-none border-[1px] text-[#a5a5a5] border-transparent"
                >
                {{ 'AUTH.other'| translate }}
                </button>
              </span>
              }
            </span>
            <textarea
              formControlName="additionalInformation"
              name=""
              id=""
              cols="35"
              rows="5"
              placeholder="{{ 'AUTH.remark'| translate }}"
              class="bg-[#E9F3F4] rounded-3xl p-4 w-[98%]"
            ></textarea>
          </div>
        </form>
      </div>
      <span class="flex gap-3">
        <input type="checkbox" (change)="toggleTerms()">
        <p class="text-violet-400">
          {{ 'AUTH.termstext'| translate }} <a class="underline cursor-pointer text-blue-500" (click)="openFile()">{{ 'AUTH.terms'| translate }}.</a>
        </p>
      </span>
      <button 
        [disabled]="termsAccepted"
        [ngClass]="{'disabled opacity-50': termsAccepted}"
        class="btn-primary self-end" (click)="register()">
        {{ 'AUTH.register'| translate }}
      </button>
      </span>
  </div>
</div>
