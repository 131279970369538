import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AlertService } from '../../services/core/alert.service';
import { TermsComponent } from "../../components/terms/terms.component";
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleAuthService } from '../../services/OAuth/googleauth.service';
import { FacebookAuthService } from '../../services/OAuth/facebookauth.service';


@Component({
    selector: 'app-login',
    standalone: true,
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
    imports: [FormsModule, ReactiveFormsModule, TermsComponent, CommonModule, TranslateModule,]
})
export class LoginComponent implements OnInit {
  public newUserLogin: boolean = false;
  public isTermsOpen: boolean = false
  public termsAccepted: boolean = false
  public postRequest: boolean = false
  loginScreen: boolean = true;
  forgotPass: boolean = false;
  loginForm!: FormGroup;
  forgotForm!: FormGroup;
  
  hide: boolean = true
  password = 'password'
  hideOldPAss = true
  hideNewPass = true
  hideNewPassSecond = true
  oldpass = 'password'
  newpass = 'password'
  newpassSecond = 'password'

  errorAlert: boolean = false;
  errorMessage = '';
  temporaryForm!: FormGroup;
  token: any;

  constructor(
    private route: Router,
    private auth: AuthService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private googleAuth: GoogleAuthService,
    private fbAuthService: FacebookAuthService
  ) {}
  


  ngOnInit(): void {
    this.fbAuthService.loadFBSDK();
    const token = localStorage.getItem('token');
    if (token) {
      this.route.navigate(['/profile']);
    } else {
      this.loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
      });

      this.forgotForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
      });
    }
    this.activatedRoute.queryParams.subscribe((params: any) => {
      let param = params;
      this.token = param;

      if (param && Object.keys(param).length > 0) {
        this.loginScreen = false;
        this.forgotPass = false;
        this.newUserLogin = !this.newUserLogin;
        this.temporaryForm = this.fb.group({
          oldPassword: ['', Validators.required],
          newPassword: ['', Validators.required],
          confirmPassword: ['', Validators.required],
        });
      } else {
        this.loginScreen = true;
        this.forgotPass = false
        this.newUserLogin = false
      }
    });
  }

  loginWithGoogle() {
    this.googleAuth.login();
  }

  onFacebookLogin() {
    this.fbAuthService.login().then((response: any) => {
      this.fbAuthService.loginToBackend()
    }).catch((error: any) => {
      console.log('Facebook login failed', error);
    });
  }

  login() {
    if (this.loginForm.valid) {
      this.auth.login(this.loginForm).subscribe({
        next: (res) => {
          if (res) {
            // console.log(res);
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('user', res.data.id);
            this.route.navigate(['profile']);
          }
        },
        error: (err) => {
          if (err) {
            if(err.error.error === "ValidationError: \"password\" length must be at least 6 characters long") {
              this.alertService.error("Das Passwort muss mindestens 6 Zeichen lang sein", {autoClose: true})
            } else if (err.error.error === "ValidationError: \"email\" must be a valid email") {
              this.alertService.error("E-Mail muss eine gültige E-Mail sein", {autoClose: true})
            } else if (err.error.error === "Sie müssen die Allgemeinen Geschäftsbedingungen akzeptieren, um fortzufahren") {
              this.handlecloseTerms(true)
            } else {
              this.alertService.error("E-Mail oder Passwort falsch", {autoClose: true})
            }   
          }
        },
      });
    } else {
      this.errorMessage = 'Ungültige Eingaben';
      this.errorAlert = true;
    }
  }

  closeAlert() {
    this.errorAlert = false;
  }

  register() {
    this.route.navigate(['register']);
  }
  resetPassword() {
    this.forgotPass = !this.forgotPass;
    this.loginScreen = !this.loginScreen;
  }
  backToLogin() {
    this.forgotPass = !this.forgotPass;
    this.loginScreen = !this.loginScreen;
  }
  
  submitReset() {
    if (this.forgotForm.valid) {
      this.auth.forgotPassword(this.forgotForm.value).subscribe({
        next: (res) => {
          if (res) {
            this.alertService.success(res.msg, { autoClose: true });
            this.forgotPass = !this.forgotPass;
            this.loginScreen = !this.loginScreen;
          }
        },
        error: (err) => {
          this.alertService.error(err.error.error, { autoClose: true });
        },
      });
      this.forgotForm.reset();
    } else {
      // this.errorMessage = 'Invalid inputs';
      // this.errorAlert = true;
    }
  }

  submitResetTemporary() {
    if (this.temporaryForm.valid) {
      this.auth
        .changePassword(this.temporaryForm.value, this.token.token)
        .subscribe({
          next: (res) => {
            if (res) {
              this.forgotPass = false;
              this.newUserLogin = false;
              this.loginScreen = true;
            }
          },
          error: (err) => {
            this.alertService.error(err.error.error, { autoClose: true });
          },
        });
      this.forgotForm.reset();
    } else {
      this.errorMessage = 'Ungültige Eingaben';
      this.errorAlert = true;
    }
  }

  handlecloseTerms(isTermsOpen: boolean) {
    this.isTermsOpen = isTermsOpen
    this.termsAccepted = false
  }

  handleTermsAccepted(accepted: boolean) {
    this.termsAccepted = accepted
  }

  handlePostRequest(post: boolean) {
    if(this.termsAccepted) {
      console.log(post)
      this.postRequest = post
      if(this.postRequest) {
        let termsObj = {
          email: this.loginForm.get('email')?.value,
          acceptedTermsAndConditions: this.termsAccepted
        }
        this.auth.termsAndConditions(termsObj).subscribe({
          next: (res) => {
            if (res) {
              console.log(res);
              this.login()
            }
          },
          error: (err) => {
            if (err) {
              console.log(err);
            }
          }
        })
      }
    }
  }

  toggleVisibility(action: string): void {
    if(action === 'password'){
      this.hide = !this.hide
      if(this.hide) {
        this.password = 'password'
      } else {
        this.password = 'text'
      }
    } else if(action === 'oldpass') {
      this.hideOldPAss = !this.hideOldPAss
      if(this.hideOldPAss) {
        this.oldpass = 'password'
      } else {
        this.oldpass = 'text'
      }
    } else if (action === 'newpass') {
      this.hideNewPass = !this.hideNewPass
      if(this.hideNewPass) {
        this.newpass = 'password'
      } else {
        this.newpass = 'text'
      }
    } else if (action === 'newpassSecond') {
      this.hideNewPassSecond = !this.hideNewPassSecond
      if(this.hideNewPassSecond) {
        this.newpassSecond = 'password'
      } else {
        this.newpassSecond = 'text'
      }
    }
  }
}
