import { ChangeDetectorRef, Component, LOCALE_ID, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { StepperComponent } from '../components/stepper/stepper.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import { CustomSelectComponent } from '../components/custom-select/custom-select.component';
import * as stepContent from './stepContent.json';
import { CompanyContactFormComponent } from './components/company-contact-form/company-contact-form.component';
import { CalendarModule } from 'angular-calendar';
import { CalendarComponent } from '../components/calendar/calendar.component';
import { TranslateModule } from '@ngx-translate/core';
import localeCh from '@angular/common/locales/de-CH';
import { MomentModule } from 'ngx-moment';
import { BookingService } from '../services/core/booking.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { FilterPipe } from '../pipes/filter.pipe';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from '../services/auth/auth.service';
import moment from 'moment';
import { PricingService } from '../services/core/pricing.service';
import { AlertService } from '../services/core/alert.service';
import { ModalComponent } from './components/modal/modal.component';
import { TermsComponent } from "../components/terms/terms.component";
import { GoogleAnalyticsService } from '../services/core/analytics.service';
import { Subscription } from 'rxjs';

registerLocaleData(localeCh);

interface CleaningDay {
  day: string;
  timeFrame: string;
}
@Component({
    selector: 'app-company-cleaning',
    standalone: true,
    templateUrl: './company-cleaning.component.html',
    styleUrl: './company-cleaning.component.scss',
    providers: [{ provide: LOCALE_ID, useValue: 'de-CH' }],
    imports: [
        StepperComponent,
        CommonModule,
        CustomSelectComponent,
        CompanyContactFormComponent,
        CalendarModule,
        CalendarComponent,
        TranslateModule,
        MomentModule,
        FilterPipe,
        ReactiveFormsModule,
        FormsModule,
        ModalComponent,
        TermsComponent
    ]
})
export class CompanyCleaningComponent implements OnInit{
  @ViewChild('standartStep1') standartStep1!: TemplateRef<any>;
  @ViewChild('standartStep2') standartStep2!: TemplateRef<any>;
  @ViewChild('standartStep3') standartStep3!: TemplateRef<any>;
  @ViewChild('standartStep4') standartStep4!: TemplateRef<any>;

  @ViewChild('individualStep1') individualStep1!: TemplateRef<any>;
  @ViewChild('individualStep2') individualStep2!: TemplateRef<any>;
  @ViewChild('individualStep3') individualStep3!: TemplateRef<any>;
  @ViewChild('individualStep4') individualStep4!: TemplateRef<any>;
  @ViewChild('individualStep5') individualStep5!: TemplateRef<any>;

  @ViewChild('expressStep1') expressStep1!: TemplateRef<any>;
  @ViewChild('expressStep2') expressStep2!: TemplateRef<any>;
  @ViewChild('expressStep3') expressStep3!: TemplateRef<any>;
  @ViewChild('expressStep4') expressStep4!: TemplateRef<any>;
  @ViewChild('expressStep5') expressStep5!: TemplateRef<any>;
  @ViewChild('expressStep6') expressStep6!: TemplateRef<any>;

  step: number = 1
  public token = localStorage.getItem('token');
  private userId = localStorage.getItem('user');

  public modalOpen: boolean = false;
  public hasToken:boolean = false;
  locale = 'de-CH';

  public cleaningDays = 3;
  public currentStep = 1;
  public currentSubStep = 1;
  public selectedSecond: Number = 1;
  public selectedType = '';
  public selectedRecurringType = 'REGULAR';
  public selectedCleaningSteps: any[] = stepContent.standartSteps;
  public selectedCleaningType = 2;
  public stepTemplates: TemplateRef<any>[] = [];
  public expressTimeSlots: any;
  public filteredExpressTimeSlots: any;
  public loadingSchedule: boolean = false
  public user: any
  public address:any
  public isCompany: boolean = true
  public cabinetHours = 0;
  
  public individualCleaningDays: CleaningDay[] = [];
  public viewDate = new Date();
  public serviceExtras: any[] = [];

  searchText:any = ''
  userAddresses:any
  redirecting: boolean = false;
  hours = 2
  openIroningModal = false;
  openCommentModal = false;
  openCabinetsModal = false;
  commentValue = ''
  meterSquaresChecked:boolean = true
  meterSquares = 0
  private routerSubscription: Subscription

  recycling: boolean = true
  selectedExpressDate:any
  selectedExpressHour:number = 0
  loadingTimeSlots: boolean = false;
  displayWarning: boolean = false
  suppliesState = 'default'
  suppliesChecked = false
  displayPrice = false
  confirmPass = ''
  regLoading: boolean = false
  confirm: boolean = true;
  isEditActivated: boolean = false;
  termsAccepted: boolean = true
  displayButtons: boolean = true
  isAddressSelection: boolean = false;

  zip:any
  hasPets: Boolean = true;
  extraHours = 0;
  login: boolean = false
  zipFromQuery:any
  cityFromZip:string
  selectZipFromStart: boolean
  zipcodes:any[] = []
  zipForChild: any
  defaultAddress: any
  selectedAddress = ''
  zipFromQueryParam: any
  zipCode: any
  materialModal: boolean = false
  checkDate: boolean = false
  price: any
  changeButton: boolean
  changeToLogin: boolean
  changeToRegister: boolean
  loading:boolean = false
  selectedRecurrence = 'WEEKLY'
  hasAcceptedTerms: boolean = false 

  hasSupplies: boolean = false;
  isLastStep: boolean = false

  correctAnswer: boolean

  public days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
  public individualHours = [
    'Before noon (08:00 Uhr - 12:00 Uhr)',
    'Afternoon (12:00 Uhr - 18:00 Uhr)',
  ];

  //forms
  registerForm!: FormGroup;
  loginForm!: FormGroup;
  expressForm!: FormGroup;
  bookingForm!: FormGroup;
  expressDays!: FormGroup;
  addressForm!: FormGroup;
  individualDays!: FormGroup;

  public isTermsOpen: boolean = false
  public postRequest: boolean = false
  public acceptedTerms: boolean = false

  public cards = [
    { title: 'Express', description: '(Innerhalb 48 Stunden Werktage)', id: 1 },
    { title: 'Angebot Anfordern', description: 'Regelmässige Reinigungen im Rhythmus Ihrer Wahl', id: 2 },
  ];

  public secondCards = [
    { id: 1, title: 'Wöchentlich', value:'WEEKLY' },
    { id: 2, title: 'Alle zwei Wochen', value:'BI_WEEKLY' },
    { id: 3, title: 'Monatlich', value:'MONTHLY' },
    { id: 4, title: 'Individuell', value:'MORE_OFTEN' },
    { id: 5, title: 'Fensterreinigung', value:'WINDOW_CLEANING' },
    { id: 6, title: 'Umzugsreinigung', value:'END_OF_TENANCY' },

  ];

  public additionalServices = [
    {
      name: 'Backofen',
      id: 1,
      duration: 0.5,
      iconActive: '../assets/icons/oven-white.svg',
      icon: '../assets/icons/oven.svg',
      value: 'oven',
      description: 'Backofen von innen'
    },
    {
      name: 'Schränke',
      id: 2,
      duration: 0.5,
      iconActive: '../assets/icons/cabinets-white.svg',
      icon: '../assets/icons/cabinets.svg',
      value: 'cabinetDuration',
      description: 'Schränke von innen'
    },
    {
      name: 'Kühlschrank',
      id: 3,
      duration: 0.5,
      iconActive: '../assets/icons/fridge-white.svg',
      icon: '../assets/icons/fridge.svg',
      value: 'fridge',
      description: 'Kühlschrank von innen'
    },
  ];

  public typeOfCompany = [
    'Büro',
    'Laden',
    'Fitness / Yoga Zentrum',
    'Schule, Kindergarten oder Kita',
    'Andere'
  ];

    // Discounts
    discountForm = new FormGroup({
      cleaningType: new FormControl(null, [Validators.required]),
      couponCode: new FormControl(null || '', [Validators.required])
    })

  constructor(
    private booking:BookingService, 
    private activatedRoute: ActivatedRoute, 
    private fb:FormBuilder,
    private auth:AuthService,
    private pricing:PricingService,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef,
    private route: Router,
    private gaService: GoogleAnalyticsService
  ) {
    for (let i = 0; i < this.cleaningDays; i++) {
      this.individualCleaningDays[i] = {
        day: '',
        timeFrame: '',
      };
      this.addIndividualDays(this.individualCleaningDays);
    }
  }
  
  swissPhoneNumberValidator(): ValidatorFn {
    const swissPhoneNumberPattern = /(\b(0041|0)|\B\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}\b/;
  
      return (control: AbstractControl): { [key: string]: any } | null => {
        const isValid = swissPhoneNumberPattern.test(control.value);
        return isValid ? null : { 'invalidSwissPhoneNumber': { value: control.value } };
      };
    }

  ngOnInit(): void {
    const zipFromLocal = localStorage.getItem('selectedZip')
    if(zipFromLocal) {
      this.route.navigate([], {
        queryParams: {
            zip: zipFromLocal
        },
      });
    }

    this.routerSubscription = this.route.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const url = new URL(event.url, window.location.origin);
        const zipParam = url.searchParams.get('zip');

        if (!zipParam) {
          localStorage.removeItem('selectedZip');
        }
      }
    });
    this.activatedRoute.queryParams.subscribe(params => {
      this.zipFromQueryParam = params['zip']
      this.zipCode = parseInt(this.zipFromQueryParam, 10);
    })
    if(!this.token){
      let city
      if (this.zipFromQueryParam) {
        localStorage.setItem('selectedZip', this.zipFromQueryParam.toString());
        this.zipFromQuery = this.zipFromQueryParam
        this.zip = this.zipFromQueryParam
        this.booking.getZipCode().subscribe({
          next: (res:any) => {
            city = res.data.filter((e:any) => e.zip === this.zipCode )
            if(city.length > 0 && city[0].city){
              this.cityFromZip = city[0].city
              this.selectZipFromStart = false
            } else {
              this.selectZipFromStart = true
            }
            let mappedzips = res.data.map((zipData: any) => {
              return { ...zipData, cityZip: `${zipData.zip} ${zipData.city}` };
            });
            this.zipcodes.push(mappedzips)
          },
          error: (error:any) => {
            console.error(error)
          }
        })
      } else {
        this.selectZipFromStart = true
        this.booking.getZipCode().subscribe({
          next: (res:any) => {
            let mappedzips = res.data.map((zipData: any) => {
              return { ...zipData, cityZip: `${zipData.zip} ${zipData.city}` };
            });
            // const allData = mappedzips.filter((el: any) => el.erpRegion !== null);
            this.zipcodes.push(mappedzips)
          }
        })
      }
    }
    if(this.token){
      this.hasToken = true
      this.auth.getUser(this.userId,this.token).subscribe({
        next: (res:any) => {
          if (res) {
            this.user = res.data
            if(this.user.clientType === 'PRIVATE'){
              this.route.navigate(['/private'])
            }
          }
        }
      })
      let city
      this.booking.getZipCode().subscribe({
        next: (res:any) => {
          city = res.data.filter((e:any) => e.zip === this.zipCode )
          if(city.length > 0 && city[0].city){
            this.cityFromZip = city[0].city
            this.selectZipFromStart = false
          } else {
            this.selectZipFromStart = true
          }
          let mappedzips = res.data.map((zipData: any) => {
            return { ...zipData, cityZip: `${zipData.zip} ${zipData.city}` };
          });
          this.zipcodes.push(mappedzips)
        },
        error: (error:any) => {
          console.error(error)
        }
      })
      if(this.zipCode){
        this.selectZipFromStart = false
        this.auth.getUserAdresses().subscribe({
          next: (res: any) => {
            if (res) {
              const token = localStorage.getItem('token')
              this.userAddresses = res.data;
              const defaultAddress = res.data.filter((e:any) => e.isDefaultAddress)
              this.address = defaultAddress
              this.defaultAddress = defaultAddress
              if(this.defaultAddress[0].zipCode === this.zip){
                this.selectZipFromStart = false
                this.selectedAddress = this.defaultAddress[0].id
              }
              this.expressForm.get('addressId')?.setValue(this.selectedAddress);
              this.bookingForm.get('addressId')?.setValue(this.selectedAddress);
              this.auth.getAddresById(this.selectedAddress,token).subscribe({
                next: (res:any) => {
                  if (res) {
                    this.address = res.data
                  }
                },
                error: (error:any) => {
                  console.error(error)
                }
              })
              if(this.zip === defaultAddress[0].zipCode){
                this.zipFromQuery = defaultAddress[0].zipCode
              }
            }
          },
          error: (err) => {
            console.log(err);
          },
        });
        this.activatedRoute.queryParams.subscribe(params => {
          const zipFromQueryParam = params['zip'];
          this.zipFromQuery = parseInt(zipFromQueryParam, 10);
        })
        if(!this.zipFromQuery){
          this.booking.getZipCode().subscribe({
            next: (res:any) => {
              let mappedzips = res.data.map((zipData: any) => {
                return { ...zipData, cityZip: `${zipData.zip} ${zipData.city}` };
              });
              // const allData = mappedzips.filter((el: any) => el.erpRegion !== null);
              this.zipcodes.push(mappedzips)
            }
          })
        } 

        this.activatedRoute.queryParams.subscribe(params => {
          if(params['zip']){
            this.zip = params['zip']
          }
        })
      } else {
        this.selectZipFromStart = true
        this.booking.getZipCode().subscribe({
          next: (res:any) => {
            let mappedzips = res.data.map((zipData: any) => {
              return { ...zipData, cityZip: `${zipData.zip} ${zipData.city}` };
            });
            // const allData = mappedzips.filter((el: any) => el.erpRegion !== null);
            this.zipcodes.push(mappedzips)
          }
        })
      }

    }

    this.registerForm = this.fb.group({
      clientType: ['COMPANY'],
      firstName: ['', Validators.required],
      companyName: ['',Validators.required],
      lastName: ['', Validators.required],
      birthDate: [null,Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, this.swissPhoneNumberValidator()]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      newsletter: [false],
      language: ['German'],
      sex: ['', [Validators.required]],
      role: ['USER'],
      acceptedTermsAndConditions: [false, [Validators.required]],
      stv: [false],
      referralSource: ['', [Validators.required]],
      otherReferralSource: ['' || null],
      address: this.fb.group({
        street: ['',Validators.required],
        streetNr: ['',Validators.required],
        city: [this.cityFromZip, Validators.required],
        zipCode: [String(this.zip),Validators.required],
        entryCode: [null],
        entranceInstructions: [null],
        additionalInformation: [null],
        isDefaultAddress: [true],
        dog: null,
        cat: null,
        otherAnimal: null,
        catText: null,
        dogText: null,
        otherAnimalText: null
      })
    });
    this.loginForm = this.fb.group({
      email: ['',[Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    })

    this.expressForm = new FormGroup({
      serviceType: new FormControl('BUSINESS', [Validators.required]),
      recurrence: new FormControl('ONCE'),
      startDate: new FormControl('', [Validators.required]),
      cleaningType: new FormControl('EXPRESS'),
      propertyType: new FormControl(''),
      specialInstruction: new FormControl(null),
      addressId: new FormControl(null, [Validators.required]),
      recycling: new FormControl(this.recycling, [Validators.required]),
      materialsIncluded: new FormControl(this.hasSupplies),
      cleaningDays: new FormArray([]),
      additionalServices: new FormGroup({
        cabinetDuration: new FormControl(0),
        cabinetRemark: new FormControl(null),
        fridge: new FormControl(false),
        oven: new FormControl(false),
      }),
    })

    this.bookingForm = new FormGroup({
      serviceType: new FormControl('BUSINESS', [Validators.required]),
      type: new FormControl('COMPANY_CLEANING', [Validators.required]),
      addressId: new FormControl(null, [Validators.required]),
      officeSize: new FormControl(null, [Validators.required]),
      recycling: new FormControl(this.recycling,[Validators.required]),
      materialsIncluded: new FormControl(true,[Validators.required]),
      recurrence: new FormControl(this.selectedRecurrence,[Validators.required]),
      propertyType: new FormControl('',[Validators.required])
    });

    this.expressDays = new FormGroup({
      day: new FormControl(null),
      date: new FormControl(null),
      duration: new FormControl(3),
    })

    this.individualDays = new FormGroup({
      day: new FormControl(null),
      timeFrame: new FormControl(null)
    })

    this.bookingForm.valueChanges.subscribe(() => {
      this.meterSquares = this.bookingForm.get('officeSize')?.value
    })
  }

  /**
   * sets the zip for the cleaning
   * @param selected 
   * @param city 
   */

  selectZipCode(selected:any, city:any){
    this.zip = selected
    this.zipForChild = selected
    this.cityFromZip = city
    let form = this.registerForm.get('address') as FormGroup
    form.get('city')?.setValue(city)
    form.get('zipCode')?.setValue(selected.toString())
    this.zipFromQuery = selected
    const storedZip = localStorage.getItem('selectedZip');
    if (!storedZip) {
      localStorage.setItem('selectedZip', selected.toString());
    }
    this.route.navigate([], {
      queryParams: {
          zip: this.zipFromQuery
      },
      queryParamsHandling: 'merge',
  });
    // if(this.defaultAddress && parseInt(this.defaultAddress[0].zipCode) === this.zip){
    //   this.selectedAddress = this.defaultAddress[0].id
    // }
    this.selectZipFromStart = false
    if(this.token){
      this.auth.getUserAdresses().subscribe({
        next: (res: any) => {
          if (res) {
            const token = localStorage.getItem('token')
            this.userAddresses = res.data;
            const defaultAddress = res.data.filter((e:any) => e.isDefaultAddress)
            this.address = defaultAddress
            this.defaultAddress = defaultAddress
            if(this.defaultAddress[0].zipCode === this.zip){
              this.selectZipFromStart = false
              this.selectedAddress = this.defaultAddress[0].id
            }
            this.expressForm.get('addressId')?.setValue(this.selectedAddress);
            this.bookingForm.get('addressId')?.setValue(this.selectedAddress);
            // this.auth.getAddresById(this.selectedAddress,token).subscribe({
            //   next: (res:any) => {
            //     if (res) {
            //       this.address = res.data
            //     }
            //   },
            //   error: (error:any) => {
            //     console.error(error)
            //   }
            // })
            if(this.zip === defaultAddress[0].zipCode){
              this.zipFromQuery = defaultAddress[0].zipCode
            }
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
    }
  }
  
  getHours():number {
    return this.extraHours + this.hours + this.cabinetHours
  }

  setHours(action: string) {
    if (action === 'increase') {
      if (this.getHours() < 6) {
        this.hours += 0.5
        this.updatePrice()
      }
    }
    if (action === 'decrease') {
      if (this.getHours() > 2) {
        this.hours -= 0.5
        this.updatePrice()
      }
    }
  }

  openAddComment() {
    this.openCommentModal = !this.openCommentModal;
  }

  onCommentAdd(value: string) {
    this.commentValue = value
    if(this.selectedRecurringType.toLowerCase() === 'express'){
      this.expressForm.get('specialInstruction')?.setValue(value)
    } else{
      this.bookingForm.get('specialInstruction')?.setValue(value);
    }

    this.closeModal();
  }

  closeModal() {
    this.openIroningModal = false;
    this.openCommentModal = false;
    this.openCabinetsModal = false;
  }

  updatePrice(){
    this.pricing.fetchPrice(this.getHours(), 'ONCE', false, 'BUSINESS');
    this.pricing.price$.subscribe((price:any) => {
      this.price = price;
    });
  }

// additional services functions

  onCabinetsAddedChange(value: any) {
    const recurrence = this.expressForm.get('recurrence')?.value
    this.pricing.fetchPrice(this.getHours(), recurrence, false, 'BUSINESS');
    const service = this.additionalServices.find((n) => n.value === 'cabinetDuration');
    if (value) {
      this.serviceExtras.push(service);
      this.closeModal();
    } else {
      const index = this.serviceExtras.findIndex((n) => n.value === 'cabinetDuration');
      if (index !== -1) {
        this.serviceExtras.splice(index, 1);
      }
      this.closeModal();
    }
  }

  cabinetDurationHandler(value: any) {
    this.cabinetHours = value;
    let additionalServices
    if(this.selectedRecurringType.toLowerCase() === 'express'){
      additionalServices = this.expressForm.get('additionalServices') as FormGroup;
    } else {
      additionalServices = this.bookingForm.get('additionalServices') as FormGroup;
    }
    additionalServices.get('cabinetDuration')?.setValue(value * 60);
    this.updatePrice()
  }

  cabinetRemarks(value: string) {
    let additionalServices
    if(this.selectedRecurringType.toLowerCase() === 'express'){
      additionalServices = this.expressForm.get('additionalServices') as FormGroup
    }else {
      additionalServices = this.bookingForm.get('additionalServices') as FormGroup
    }
    additionalServices.get('cabinetRemark')?.setValue(value);
    this.closeModal();
  }

  getRemark():string{
    let additionalServices
    if(this.selectedRecurringType.toLowerCase() === 'express'){
      additionalServices = this.expressForm.get('additionalServices') as FormGroup
    } else {
      additionalServices = this.bookingForm.get('additionalServices') as FormGroup
    }
    return additionalServices.get('cabinetRemark')?.value
  }

  deleteCabinets(){
    let type = this.selectedRecurringType.toLowerCase()
    let additionalServices
    switch (type) {
      case 'express':
      additionalServices = this.expressForm.get('additionalServices') as FormGroup
      break;
      break;
      case 'individual':
      additionalServices = this.bookingForm.get('additionalServices') as FormGroup
      break;
      case 'special_cleaning':
      additionalServices = this.bookingForm.get('additionalServices') as FormGroup
      break;
      default:
      break;
    }
    additionalServices?.get('cabinetDuration')?.setValue(0)
    additionalServices?.get('cabinetRemark')?.setValue(null)
  }

  /**
   * selected day from the calendar
   * @param day 
   */

  getSelectedDay(day:any){
    if(this.selectedRecurringType.toLowerCase() === 'express'){
      let selectedDay = this.expressTimeSlots.filter((d:any) => moment(d.date).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD'))
      this.selectedExpressDate = selectedDay
      this.expressDays.get('date')?.setValue(moment(day).format('YYYY-MM-DD'))
      this.expressDays.get('day')?.setValue(moment(day).format('dddd').toUpperCase())
      this.expressForm.get('startDate')?.setValue(moment(day).format('YYYY-MM-DD'))
      this.expressDays.get('duration')?.setValue(this.getHours());
      const cleaningDaysArray = this.expressForm.get(
        'cleaningDays'
      ) as FormArray;
      cleaningDaysArray.clear(); 
      cleaningDaysArray.push(this.expressDays);
    }
  }

  individualForm = this.fb.group({
    formArray: this.fb.array([]),
  });

  get formArray() {
    return this.individualForm.controls['formArray'] as FormArray;
  }
  get individualFormGroups(): FormGroup[] {
    return this.formArray.controls as FormGroup[];
  }

  /**
   * individual cleaning days
   * @param ctx 
   */

  addIndividualDays(ctx: any) {
    const newFormArray = this.fb.group({
      day: 'Wochentag',
      timeFrame: 'BEFORE_NOON',
      date: new Date().toISOString(),
      duration: 3,
    });
    const newBookingFormArray = this.fb.group({
      day: 'Wochentag',
      timeFrame: 'BEFORE_NOON',
    });
    if(this.selectedRecurringType !== 'EXPRESS'){ 
      this.formArray.push(newBookingFormArray);
    } else {
      this.formArray.push(newFormArray);
    }
  }

  getDays() {
    const filteredDays = this.days.filter((day) => {
      return !this.individualCleaningDays.map((d) => d.day).includes(day);
    });
    return filteredDays;
  }

  /**
   * @param index 
   * @param selectedDay 
   */

  updateSelectedDay(index: number, selectedDay: string) {
    this.individualCleaningDays[index].day = selectedDay;
    this.days.filter((e) => e === selectedDay);
    const formGroup = this.formArray.at(index) as FormGroup;
    formGroup.patchValue({ day: selectedDay });
    this.triggerIndividualDays()
  }

  getSubtitle(formGroup: FormGroup): string {
    if (formGroup.get('timeFrame')?.value === 'AFTER_NOON') {
      return '(12:00 Uhr - 18:00 Uhr)';
    } else {
      return '(08:00 Uhr - 12:00 Uhr)';
    }
  }

  /**
   * update hours selected from calendar
   * @param index 
   * @param selectedHour 
   */

  updateSelectedHour(index: number, selectedHour: string) {
    const formGroup = this.formArray.at(index) as FormGroup;
    if (selectedHour === 'Afternoon (12:00 Uhr - 18:00 Uhr)') {
      formGroup.patchValue({
        timeFrame: 'AFTER_NOON',
      });
    } else {
      formGroup.patchValue({
        timeFrame: 'BEFORE_NOON',
      });
    }
  }

  triggerIndividualDays(){
    let days:string[] = []
    this.individualCleaningDays.filter((d:any) => {
      days.push(d.day)
    })
    if(days.some((e:any) => e === '')){
      this.loading = true
    } else {
      this.loading = false
    }
  }

  /**
   * individual cleaning day setter function
   * @param action 
   */

  setDays(action: string) {
    if (action === 'increase' && this.cleaningDays < 6) {
      this.cleaningDays++;
      this.individualCleaningDays.push({
        day: '',
        timeFrame: '',
      });
      this.addIndividualDays(this.individualCleaningDays);
    }
    if (action === 'decrease' && this.cleaningDays > 1) {
      this.cleaningDays--;
      this.individualCleaningDays.splice(this.cleaningDays, 1);
      this.individualFormGroups.splice(this.cleaningDays, 1);
    }
  }

  /**
   * check afternoon exceed hours
   * @param time 
   * @returns 
   */

  checkExceedExpress(time:any):boolean{
    if(Number(time) + this.getHours() > 18){
      return false
    } else {
      return true
    }
  }

  selectCleaningHour(hour:any){
    this.selectedExpressHour = hour
    this.triggerSelectedSlot()
    let dateControl = this.expressDays.get('date');
    if (dateControl) {
      let currentDate = new Date(dateControl.value);
      
      let [hourString, minuteString] = hour.split(':');
      let hours = parseInt(hourString, 10);
      let minutes = parseInt(minuteString, 10);
      
      currentDate.setHours(hours);
      currentDate.setMinutes(minutes);
  
      dateControl.setValue(moment(currentDate).format());
    }

    this.loadingTimeSlots = false
  }

  triggerMeters(){
    if(this.meterSquares > 10 && this.meterSquares < 10000){
      this.meterSquaresChecked = true
    }
    else {
      this.meterSquaresChecked = false
    }
  }

  openMaterials(){
    this.materialModal = true
  }

  closeMaterials(){
    this.materialModal = false
  }

  openModal() {
    this.modalOpen = !this.modalOpen;
  }

  changeMonth() {
    this.viewDate = new Date(
      this.viewDate.getFullYear(),
      this.viewDate.getMonth() + 1,
      1
    );
  }

  fetchTimeSlots() {
    this.loadingSchedule = true
      this.booking.getTimeSlots(this.zipFromQuery,'express').subscribe({
        next: (res: any) => {
          const data = res.data;
          this.expressTimeSlots = data.filter((item: any) => {
            let schedule = item.schedule;
            return schedule.some((i: any) => i.availability === true);
          });
        },
        error: (error: any) => {
          console.error('Error fetching time slots:', error);
        },
        complete: () => {
          this.loadingSchedule = false
          if(this.selectedRecurringType.toLowerCase() === 'express'){
            this.filterExpressTimeSlots()
          } else {
            // this.filterTimeSlots()
          }
        }
      });
  }

  /**
   * confirm express cleaning
   */

  confirmCleaning(){
    let url = ''
    this.redirecting = true;
    let expressObj = {}
    if(this.correctAnswer) {
      expressObj = {
        ...this.expressForm.value,
        couponCode: this.discountForm.get('couponCode')?.value
      }
    } else {
      expressObj = {
        ...this.expressForm.value
      }
    }     
    this.booking.createCleaning(expressObj, this.token).subscribe({
      next: (res: any) => {
        url = res.data.url;
        this.loading = false;
      },
      error: (err) => {
        console.log(err);
        this.loading = true;
      },
      complete: () => {
        if (url) {
          window.location.href = url;
        } else {
          this.route.navigate(['success']);
        }
      },
    });
  }

  /**
   * confirm booking request
   */
  confirmBooking(){
    const token = localStorage.getItem('token')
    this.loading = true
    if(this.bookingForm.get('recurrence')?.value === 'WINDOW_CLEANING' || this.bookingForm.get('recurrence')?.value === 'END_OF_TENANCY' ) {
      let bookingObj = {}
      if(this.correctAnswer) {
        bookingObj = {
          serviceType: this.bookingForm.get('serviceType')?.value,
          type: this.bookingForm.get('recurrence')?.value,
          addressId: this.bookingForm.get('addressId')?.value,
          officeSize: this.bookingForm.get('officeSize')?.value,
          recycling: this.bookingForm.get('recycling')?.value,
          materialsIncluded: this.bookingForm.get('materialsIncluded')?.value,
          propertyType: this.bookingForm.get('propertyType')?.value,
          couponCode: this.discountForm.get('couponCode')?.value
        }
      } else {
        bookingObj = {
          serviceType: this.bookingForm.get('serviceType')?.value,
          type: this.bookingForm.get('recurrence')?.value,
          addressId: this.bookingForm.get('addressId')?.value,
          officeSize: this.bookingForm.get('officeSize')?.value,
          recycling: this.bookingForm.get('recycling')?.value,
          materialsIncluded: this.bookingForm.get('materialsIncluded')?.value,
          propertyType: this.bookingForm.get('propertyType')?.value
        }
      }
      this.booking.requestBooking(bookingObj, token).subscribe({
        next: (res:any) => {
          this.loading = false
        },
        error: (err) => {
          console.error(err)
          this.loading = true
          this.alertService.error('something went wrong', {autoClose:true})
        },
        complete: () => {
          this.route.navigate(['success'])
        }
      })
    } else {
      this.booking.requestBooking(this.bookingForm.value, token).subscribe({
        next: (res:any) => {
          this.loading = false
        },
        error: (err) => {
          console.error(err)
          this.loading = true
          this.alertService.error('something went wrong', {autoClose:true})
        },
        complete: () => {
          this.route.navigate(['success'])
        }
      })
    }
  }

  filterExpressTimeSlots() {
    const today = new Date().setHours(0, 0, 0, 0);
    const futureDays = this.expressTimeSlots
      .map((slot: any) => new Date(slot.date).setHours(0, 0, 0, 0))
      .filter((date: any) => date >= today)
      .sort((a: any, b: any) => a - b)
      .slice(0, 4);

    this.filteredExpressTimeSlots = this.expressTimeSlots.filter((slot: any) =>
      futureDays.includes(new Date(slot.date).setHours(0, 0, 0, 0))
    );
  }

  openFile(){
    window.open("assets/terms/AGB_Pebra_Reinigung_Schweiz_AG_2024.pdf")
  }

  prevMonth() {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;

    if (this.viewDate < today) {
      console.log('Past month. Cannot navigate.');
      return;
    }

    if (this.viewDate.getMonth() === currentMonth) {
      this.viewDate = today;
    } else {
      this.viewDate = new Date(
        this.viewDate.getFullYear(),
        this.viewDate.getMonth() - 1,
        1
      );
    }
  }

  updateViewDate(action: any) {
    if (action) {
      this.changeMonth()
    } 
  }

  triggerNewAddress() {
    console.log('executed from child')
  }

  handleFunction(emittedFunction: () => void) {
    const token = localStorage.getItem('token')
    this.auth.getUserAdressesForStandart(token).subscribe({
      next:(res:any)=>{
        this.userAddresses = res.data
      }
    })
    this.triggerNewAddress = emittedFunction;
  }

  triggerConfirm() {
    this.confirm = false;
  }

  getAddressForm(value:any){
    this.addressForm = value
  }

  triggerOfficeType():boolean {
    return false
  }

  triggerSelectedSlot(): boolean {
    if(this.selectedExpressDate && this.selectedExpressHour){
      this.checkDate = false
    } else {
      this.checkDate = true
    }
    return this.checkDate
  }

  triggerSupplies():boolean{
    if(this.suppliesState === 'default'){
      this.suppliesChecked = true
    } else {
      this.suppliesChecked = false
    }
    return this.suppliesChecked
  }

  updateCleaningDays() {
    if (!this.bookingForm.get('cleaningDays')) {
      this.bookingForm.addControl('cleaningDays', this.fb.array([]));
    }
    const cleaningDaysControl = this.bookingForm.get('cleaningDays') as FormArray;
    cleaningDaysControl.clear();
    this.formArray.controls.forEach((control: AbstractControl) => {
      const formGroup = control as FormGroup;
      const clonedFormGroup = this.fb.group(formGroup.value);
      cleaningDaysControl.push(clonedFormGroup);
    });
  }

  /**
   * controls stepper interaction
   * @param name : action next/back
   */
  stepButton(name: string) {
    const currentStepObject = this.selectedCleaningSteps.find(
      (step) => step.id === this.currentStep
    );

    this.gaService.event('stepper_interaction', {
      event_category: 'navigation',
      event_label: `Company CleaningType ${this.selectedRecurringType} Step ${this.currentStep}, Sub-step ${this.currentSubStep}, Action: ${name}`,
    });

    this.route.navigate([], {
      queryParams: {
          type: this.selectedRecurringType.toLocaleLowerCase(),
          step: this.currentStep,
      },
      queryParamsHandling: 'merge',
  });

    if (name === 'next') {
      if((this.getHours() <= 6 && this.selectedRecurringType === 'EXPRESS') || this.selectedRecurringType !== 'EXPRESS'){
        if(this.currentStep === 2 && this.selectedRecurringType === 'EXPRESS'){
          this.fetchTimeSlots()
          this.triggerSelectedSlot()
        }
        if(this.currentStep === 1 && this.currentSubStep === 2 && this.selectedRecurringType === 'EXPRESS'){
          this.pricing.fetchPrice(this.getHours(), 'ONCE', this.hasSupplies, 'BUSINESS');
          this.pricing.price$.subscribe((price:any) => {
            this.price = price;
          });
        }
        if(this.currentStep === 3 && this.selectedRecurringType === 'EXPRESS'){
          this.triggerSupplies()
        }
        if(this.currentStep === 5 && this.selectedRecurringType === 'EXPRESS'){
          if(!this.token){
            this.changeButton = true
          }
          this.isAddressSelection = true;
          this.triggerSelection()
        }
        if(this.currentStep === 5 && this.currentSubStep === 2 && this.selectedRecurringType === 'EXPRESS'){
          this.triggerTerms()
        }
        if(this.currentStep === 6 && this.currentSubStep === 1 && this.selectedRecurringType === 'EXPRESS'){
          this.triggerConfirm()
          this.triggerTerms()
          this.isLastStep = true
        }
        if(this.currentStep === 6 && this.currentSubStep === 2 && this.selectedRecurringType === 'EXPRESS'){
          this.confirmCleaning()
        }
        if(this.currentStep === 1 && this.currentSubStep === 2 && this.selectedRecurringType !== 'EXPRESS'){
          this.triggerMeters()
        }
        if(this.selectedRecurringType === 'REGULAR'){
          if(this.currentStep === this.selectedCleaningSteps.length && this.currentSubStep === 2){
            this.confirmBooking()
          }
        }
        if(this.currentStep === 1 && this.currentSubStep === 4 && this.selectedRecurringType === 'REGULAR' && this.selectedCleaningSteps !== stepContent.individualSteps){
          this.triggerSupplies()
        }
        if(this.currentStep === 2 && this.selectedRecurringType === 'REGULAR' && this.selectedCleaningSteps === stepContent.individualSteps){
          this.triggerSupplies()
        }
        if(this.selectedRecurringType === 'REGULAR' && this.selectedCleaningSteps === stepContent.individualSteps){
          if(this.currentStep === 2){
            this.updateCleaningDays()
          } else if(this.currentStep === 1 && this.currentSubStep === 4){
            this.triggerIndividualDays()
          } else if(this.currentStep === 4){
            if(!this.token){
              this.changeButton = true
            }
            this.isAddressSelection = true;
            this.triggerSelection()
          } else if(this.currentStep === 5 && this.currentSubStep === 1){
            this.triggerTerms()
            this.isLastStep = true
          }
        }
        if(this.selectedRecurringType === 'REGULAR' && this.selectedCleaningSteps !== stepContent.individualSteps){
          if(this.currentStep === 3){
            if(!this.token){
              this.changeButton = true
            }
            this.isAddressSelection = true;
            this.triggerSelection()
          } if(this.currentStep === 4 && this.currentSubStep === 1){
            this.triggerTerms()
            this.isLastStep = true
          }
        }
        if(this.isAddressSelection && !this.token && this.checkLastStep()){
          this.loading = true
        }
        if (
          currentStepObject?.subSteps &&
          this.currentSubStep < currentStepObject.subSteps.length
        ) {
          this.currentSubStep += 1;
        } else {
          if (this.currentStep < this.selectedCleaningSteps.length) {
            this.currentStep += 1;
            if(this.step < this.currentStep){
              this.step += 1;
            }
            this.currentSubStep = 1;
          }
        }
      } else {
        this.alertService.error('Die Reinigung darf nicht länger als 6 Stunden dauern.',{ autoClose: true })
      }

    } else if (name === 'back') {
      this.checkDate = false
      this.suppliesChecked = false
      this.loading = false
      this.termsAccepted = true
      this.changeButton = false
      this.changeToLogin = false
      this.changeToRegister = false
      this.isAddressSelection = false
      this.meterSquaresChecked = true
      this.isLastStep = false

      if (this.currentSubStep > 1) {
        this.currentSubStep -= 1;
      } else {
        if (this.currentStep > 1) {
          this.currentStep -= 1;
          // this.step -= 1
          const previousStep = this.selectedCleaningSteps.find(
            (step) => step.id === this.currentStep
          );
          if (previousStep?.subSteps) {
            this.currentSubStep = previousStep.subSteps.length;
          }
        }
      }
    }
  }

  toggleLogin() {
    this.login = !this.login;
    if(this.login){
      this.changeToLogin = true
      this.changeToRegister = false
      this.changeButton = false
    } else {
      this.changeToRegister = true
      this.changeToLogin = false
      this.changeButton = false
    }
  }

  toggleTerms(){
    this.termsAccepted = !this.termsAccepted
    this.cdr.detectChanges()
  }

  triggerTerms(){
    this.termsAccepted = false
  }

  /**
   * checks if next is disabled before filling the required forms
   * @returns 
   */
  isNextDisabled(): boolean {
    const currentStepObject = this.selectedCleaningSteps.find(
      (step) => step.id === this.currentStep
    );
    if(
      this.selectZipFromStart ||
      this.currentSubStep === 2 && (!this.bookingForm.get('propertyType')?.value || this.bookingForm.get('propertyType')?.value === 'Andere') ||
      this.checkDate ||
      this.suppliesChecked ||
      this.loading ||
      !this.termsAccepted || 
      !this.meterSquaresChecked
    ){
        return true
    } else {
      if (
        currentStepObject?.subSteps &&
        currentStepObject?.id === this.selectedCleaningSteps.length
      ) {
        return this.currentSubStep === currentStepObject.subSteps.length;
      } else {
        return this.currentStep === this.selectedCleaningSteps.length;
      }
    }
  }

  summaryText(service:any):string{
    switch (service) {
      case "cabinetDuration":
        return "Schränke"
      case "fridge":
        return "Kühlschrank"
      case "ironingDuration":
        return "Bügeln"
      case "oven":
        return "Backofen"
      default:
        return ""
    }
  }

  getOptions() {
    const filteredTypes = this.typeOfCompany.filter((type) => {
      return !this.selectedType.includes(type);
    });
    return filteredTypes;
  }

  checkSelectedType(){
    this.selectedType = this.bookingForm.get('propertyType')?.value
  }

  updateSelectedType(selectedDay: string) {
    this.selectedType = selectedDay;
    this.expressForm.get('propertyType')?.setValue(this.selectedType)
    this.bookingForm.get('propertyType')?.setValue(this.selectedType)
    this.typeOfCompany.filter((e) => e === selectedDay);
  }

  checkLastStep():boolean{
    return this.isLastStep
  }
  

  selectCleaningType(selected: number) {
    this.selectedCleaningType = selected;
    this.discountForm.get('couponCode')?.setValue('')
    switch (selected) {
      case 1:
        this.selectedCleaningSteps = stepContent.expressSteps;
        this.selectedRecurringType = 'EXPRESS'
        this.displayPrice = true
        this.step = 1
        break;
      case 2:
        this.selectedCleaningSteps = stepContent.standartSteps;
        this.selectedRecurringType = 'REGULAR'
        this.displayPrice = false
        this.step = 1
        break;
      default:
        this.selectedCleaningSteps = stepContent.standartSteps;
        this.selectedRecurringType = 'REGULAR'
        this.displayPrice = false
        this.step = 1
    }
    this.populateSteps();
  }

  populateSteps() {
    switch (this.selectedCleaningType) {
      case 1:
        this.stepTemplates = [
          this.expressStep1,
          this.expressStep2,
          this.expressStep3,
          this.expressStep4,
          this.expressStep5,
          this.expressStep6,
        ];
        break;
      case 2:
        this.stepTemplates = [this.standartStep1, this.standartStep2, this.standartStep3, this.standartStep4];
        break;
      default:
        this.stepTemplates = [this.standartStep1, this.standartStep2, this.standartStep3, this.standartStep4];
    }
  }

  selectSecond(second: number,selected:string) {
    this.selectedRecurrence = selected
    this.bookingForm.get('recurrence')?.setValue(selected)
    this.selectedSecond = second;
    if (second === 4) {
      this.selectedCleaningSteps = stepContent.individualSteps;
      this.stepTemplates = [
        this.individualStep1,
        this.individualStep2,
        this.individualStep3,
        this.individualStep4,
        this.individualStep5
      ];
      this.step = 2
    } else {
      this.selectedCleaningSteps = stepContent.standartSteps;
      this.stepTemplates = [
        this.standartStep1,
        this.standartStep2,
        this.standartStep3,
        this.standartStep4
      ];
    }
  }

  /**
   * checks materials included
   * @param selectedValue 
   */
  onSupplyChange(selectedValue: any) {
    this.hasSupplies = selectedValue;
    if(selectedValue){
      this.suppliesState = 'true'
      this.displayWarning = false
    } else {
      this.suppliesState = 'false'
      this.displayWarning = true
    }
    this.triggerSupplies()

    if(this.selectedRecurringType === 'EXPRESS'){
      this.expressForm.get('materialsIncluded')?.setValue(!this.hasSupplies)
      this.pricing.fetchPrice(this.getHours(), 'ONCE', !selectedValue, 'BUSINESS');
      this.pricing.price$.subscribe((price:any) => {
        this.price = price;
      });
    } else {
      this.bookingForm.get('materialsIncluded')?.setValue(!this.hasSupplies)
    }
  }
  
  recyclingChane(selectedValue:any){
    this.recycling = selectedValue
    if(this.selectedRecurringType === 'EXPRESS'){
      this.expressForm.get('recycling')?.setValue(this.recycling)
    } else {
      this.bookingForm.get('recycling')?.setValue(this.recycling)
    }
  }

  /**
   * additional services
   * @param item 
   */

  selectExtraService(item: any) {
    const index = this.serviceExtras.indexOf(item);
    if (index != -1) {
      if (item.value === 'cabinetDuration'){
        this.openCabinetsModal = true;
      } else {
        const removedItem = this.serviceExtras.splice(index, 1)[0];
        this.extraHours -= removedItem.duration;
        const additionalServicesFormGroup = this.expressForm.get('additionalServices') as FormGroup
        additionalServicesFormGroup.get(item.value)?.setValue(false)
      }
    } else {
      if (item.value === 'cabinetDuration'){
        this.openCabinetsModal = true;
      } else {
        this.serviceExtras.push(item);
        this.extraHours += item.duration;
        const additionalServicesFormGroup = this.expressForm.get('additionalServices') as FormGroup
        additionalServicesFormGroup.get(item.value)?.setValue(true);
      }
    }
    this.updatePrice()
  }

  checkSelectedAddOn(id: number): boolean {
    return this.serviceExtras.some((i: any) => i.id === id);
  }

  triggerSelection() {
    const test = localStorage.getItem('token')

    if(this.defaultAddress){
      this.address = this.defaultAddress
    }
    if(this.selectedAddress){
      this.expressForm.get('addressId')?.setValue(this.selectedAddress);
      this.bookingForm.get('addressId')?.setValue(this.selectedAddress)
      this.auth.getAddresById(this.selectedAddress,test).subscribe({
        next: (res) => {
          if (res) {
            this.address = res.data
          }
        },
        error: (error) => {
          console.error(error)
        }
      })
    }
    if (!this.selectedAddress) {
      this.loading = true;
    } else {
      this.loading = false;
    }
  }

  selectExpressAddress(id:string){
    const test = localStorage.getItem('token')

    this.expressForm.get('addressId')?.setValue(id);
    this.bookingForm.get('addressId')?.setValue(id);
    this.auth.getAddresById(id,test).subscribe({
      next: (res) => {
        if (res) {
          this.address = res.data
        }
      },
      error: (error) => {
        console.error(error)
      }
    })
    this.selectedAddress = id;
    // console.log(this.expressForm.value);
    this.triggerSelection();
  }

  convertInt(value:any):number{
    return parseInt(value)
  }

  alertAddress(){
    this.alertService.info('Adresse mit ausgewählter Póstleitzahl erlaubt',{ autoClose: true })
  }

  //Auth functions
  loginFunction() {
    if(this.loginForm && this.loginForm.valid){

      this.auth.login(this.loginForm).subscribe({
        next: (res: any) => {
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('user', res.data.id);
            this.user = res.data  
        },
        error: (err: any) => {
          this.loading = true;
          if(err.error.error === "You must accept the terms and conditions to proceed.") {
            this.handlecloseTerms(true)
          }
          this.alertService.error(err.error.error,{autoClose: true})
        },
        complete: () => {
          // console.log(this.user.clientType);
          if(this.user.clientType === 'PRIVATE'){
            // this.login = !this.login
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.alertService.error('Sie sollten ein Firmenkonto erstellen',{ autoClose: true})
          }else {
            const token = localStorage.getItem('token');
            this.changeButton = false
            this.changeToLogin = false
            this.changeToRegister = false
            this.hasToken = true
            this.token = token;
            this.auth.getUserAdressesForStandart(token).subscribe({
              next: (res: any) => {
                if (res) {
                  this.userAddresses = res.data;
                  const defaultAddress = res.data.filter((e:any) => e.isDefaultAddress)
                  this.address = defaultAddress
                  this.defaultAddress = defaultAddress
                  this.selectedAddress = this.defaultAddress[0].id
                  this.bookingForm.get('addressId')?.setValue(res.data.address.id);
                  this.expressForm.get('addressId')?.setValue(res.data.address.id);
                }
              },
              error: (err) => {
                console.log(err);
              },
              complete: () => {
                this.triggerSelection()
              }
            });
          }
        },
      });
    } else {
      this.alertService.error('Bitte füllen Sie die Felder aus', { autoClose: true } )
    }
  }

  checkPass(pass:any, confirmation:string):boolean{
    return pass === confirmation
  }

  onConfirmPasswordChange(event:any){
    this.confirmPass = event
  }
  
  onConfirmationChange(confirmation: any) {
    this.cdr.detectChanges();
    this.confirmPass = confirmation
  }

  registerFunction() {
    let form = this.registerForm.get('address') as FormGroup
    form.get('city')?.setValue(this.cityFromZip)
    if(this.registerForm && this.registerForm.valid){
      const pass = this.registerForm.get('password')?.value
      if(this.checkPass(pass, this.confirmPass)){
        this.regLoading = true
        this.auth.register(this.registerForm).subscribe({
          next: (res: any) => {
            this.user = res.data
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('user', res.data.id);
          },
          error: (err: any) => {
            console.log(err);
            this.alertService.error(err.error.error,{autoClose: true})
            this.loading = true;
            this.regLoading = false
          },
          complete: () => {
            const token = localStorage.getItem('token');
            this.token = token;
            this.changeButton = false
            this.changeToLogin = false
            this.changeToRegister = false
            this.regLoading = false
            
  
            this.auth.getUserAdressesForStandart(token).subscribe({
              next: (res: any) => {
                if (res) {
                  this.userAddresses = res.data;
                  const defaultAddress = res.data.filter((e:any) => e.isDefaultAddress)
                  this.address = defaultAddress
                  this.defaultAddress = defaultAddress
                  this.selectedAddress = this.defaultAddress[0].id
                  this.bookingForm.get('addressId')?.setValue(res.data.address.id);
                  this.expressForm.get('addressId')?.setValue(res.data.address.id);
                }
              },
              error: (err) => {
                console.log(err);
              },
              complete: () => {
                this.triggerSelection()
              }
            });
          },
        });
      } else {
        this.alertService.error('Passwörter stimmen nicht überein', { autoClose:true })
        this.registerForm.get('password')?.setValue('')
      }

    } else {
      this.registerForm.markAllAsTouched()
      this.alertService.error('Bitte füllen Sie die Pflichtfelder aus', {autoClose: true})
    }
  }

  toggleAcceptedTerms(accepted: boolean) {
    this.hasAcceptedTerms = accepted
    this.registerForm.get('acceptedTermsAndConditions')?.setValue(accepted)
  }

  markAllControlsAsTouched() {
    this.registerForm.markAllAsTouched();
  }

  handlecloseTerms(isTermsOpen: boolean) {
    this.isTermsOpen = isTermsOpen
    this.acceptedTerms = false
  }

  handleTermsAccepted(accepted: boolean) {
    this.acceptedTerms = accepted
  }

  handlePostRequest(post: boolean) {
    if(this.acceptedTerms) {
      this.postRequest = post
      if(this.postRequest) {
        let termsObj = {
          email: this.loginForm.get('email')?.value,
          acceptedTermsAndConditions: this.acceptedTerms
        }
        this.auth.termsAndConditions(termsObj).subscribe({
          next: (res) => {
            if (res) {
              console.log(res);
              this.loginFunction()
            }
          },
          error: (err) => {
            if (err) {
              console.log(err);
            }
          }
        })
      }
    }
  }

  handleSteps(step: any) {
    if(step < this.step){
      this.currentStep = step
      this.currentSubStep = 1
      this.checkDate = false
      this.suppliesChecked = false
      this.loading = false
      this.termsAccepted = true
      this.changeButton = false
      this.changeToLogin = false
      this.changeToRegister = false
      this.isAddressSelection = false
      this.meterSquaresChecked = true
      this.discountForm.get('couponCode')?.setValue('')
    }
    }

  /**
   * post request for coupon validation
   * @param cleaningType 
   */
  checkDiscountCode(cleaningType?: any) {
      this.discountForm.get('cleaningType')?.setValue(cleaningType)
      this.booking.discountValidation(this.discountForm.value, this.token).subscribe({
        next: (res) => {
          if(res) {
            this.correctAnswer = true
          }
        },
        error: (err) => {
          if (err) {
            this.correctAnswer = false
          }
        }
      })
    }
}
