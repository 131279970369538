<button (click)="toggleLogin()" class="self-start">
  {{ login ? ('PRIVATE.Jetzt registrieren' | translate) : ('PRIVATE.Sie sind bereits Kunde? Jetzt anmelden' | translate) }}
</button>
@if(petsModal){
  <div class='absolute top-2 left-2 w-[95%] overflow-y-scroll md:overflow-y-hidden h-[95%] md:h-auto md:w-auto md:top-[10%] md:left-[30%] flex flex-col bg-[#E9F3F4] shadow-lg p-2 md:p-6 z-10'>

    <div class="flex justify-between mt-0 md:mt-4 flex-col md:flex-row">
      <div class="flex w-full md:w-[32%] my-5 md:my-0 flex-col p-2 md:p-6 gap-8 rounded-tl-lg rounded-br-lg">
        <span class="flex items-center gap-2">
          <p class="text-[16px]">{{ "AUTH.dog" | translate }}</p>
        </span>
        <textarea
          [value]="dogText ? dogText : ''"
          [(ngModel)]="dogText"
          name=""
          id=""
          cols="25"
          rows="2"
          placeholder="{{ 'AUTH.remark' | translate }}"
          class="bg-gray-200 rounded-3xl p-4"
        ></textarea>
        <div class="flex items-center gap-8 text-center justify-between">
          <button
            (click)="setPets('decrease', 'dog')"
            class="w-12 h-12 bg-[#61BFD1] rounded-lg px-2 flex items-center justify-center"
          >
            <img src="../assets/icons/minus.svg" alt="minus" />
          </button>
          <div>
            <p class="text-[24px] font-bold">
              {{ dogCount }}
            </p>
          </div>
          <button
            (click)="setPets('increase', 'dog')"
            class="w-12 h-12 bg-[#61BFD1] rounded-lg px-2 flex items-center justify-center"
          >
            <img src="../assets/icons/plus.svg" alt="plus" />
          </button>
        </div>
      </div>
      <div class="flex w-full md:w-[32%] flex-col p-6 gap-8 bg-[#61BFD1] rounded-tl-lg rounded-br-lg">
        <span class="flex items-center gap-2">
          <p class="text-[16px] text-white">
            {{ 'AUTH.cat' | translate }}
          </p>
        </span>
        <textarea
          [value]="catText ? catText : ''"
          [(ngModel)]="catText"
          name=""
          id=""
          cols="25"
          rows="2"
          placeholder="{{ 'AUTH.remark' | translate }}"
          class="bg-gray-200 rounded-3xl p-4"
        ></textarea>
        <div class="flex items-center gap-8 text-center justify-between">
          <button
            (click)="setPets('decrease', 'cat')"
            class="w-12 h-12 bg-white rounded-lg flex items-center justify-center"
          >
            <img src="../assets/icons/minus-trq.svg" alt="minus" />
          </button>
          <div>
            <p class="text-[24px] text-white font-bold">{{ catCount }}</p>
          </div>
          <button
            (click)="setPets('increase', 'cat')"
            class="w-12 h-12 bg-white rounded-lg flex items-center justify-center"
          >
            <img src="../assets/icons/plus-trq.svg" alt="plus" />
          </button>
        </div>
      </div>
      <div class="flex w-full md:w-[32%] my-5 md:my-0 flex-col p-6 gap-8 rounded-tl-lg rounded-br-lg">
        <span class="flex items-center gap-2">
          <p class="text-[16px]">{{ 'AUTH.other' | translate }}</p>
        </span>
        <textarea
        [value]="otherAnimalText ? otherAnimalText : ''"
        [(ngModel)]="otherAnimalText"
          name=""
          id=""
          cols="25"
          rows="2"
          placeholder="{{ 'AUTH.remark' | translate }}"
          class="bg-gray-200 rounded-3xl p-4"
        ></textarea>
        <div class="flex items-center gap-8 text-center justify-between">
          <button
            (click)="setPets('decrease', 'other')"
            class="w-12 h-12 bg-[#61BFD1] rounded-lg px-2 flex items-center justify-center"
          >
            <img src="../assets/icons/minus.svg" alt="minus" />
          </button>
          <div>
            <p class="text-[24px] text-[#61BFD1] font-bold">
              {{ otherPetCount }}
            </p>
          </div>
          <button
            (click)="setPets('increase', 'other')"
            class="w-12 h-12 bg-[#61BFD1] rounded-lg px-2 flex items-center justify-center"
          >
            <img src="../assets/icons/plus.svg" alt="plus" />
          </button>
        </div>
      </div>
    </div>
    <div class="bg-transparent flex gap-5 w-full justify-end p-4">
      <button class="btn-primary-outlined" (click)="cancelPets()">{{ 'AUTH.cancel' | translate }}</button>
      <button class="btn-primary" (click)="confirmPets()">{{ 'AUTH.confirm' | translate }}</button>
    </div>
</div>
}
@if(login){
  <form [formGroup]="loginForm" (keyup.enter)="onLoginSubmit()">
    <div class="flex flex-col items-center justify-center gap-6 text-[#346973]">
      <p class="text-[28px] font-bold">Login</p>
        <input
          formControlName="email"
          type="email"
          placeholder="E-Mail"
          class="md:w-[48%] lg:w-[48%] w-[100%]"
        />
        <div class="relative md:w-[48%] lg:w-[48%] w-[100%]">
          <input
          formControlName="password"
          [type]="password"
          placeholder="{{ 'AUTH.password' | translate }}"
          class="w-full"
        />
          <img *ngIf="!hide" class="absolute cursor-pointer w-6 right-2 translateY" src="../../assets/icons/profile/hidepass.svg" (click)="toggleVisibility('pass')" alt="hidden" />
          <img *ngIf="hide" class="absolute cursor-pointer w-[27px] right-[6.5px] translateY" src="../../assets/icons/profile/showpass.svg" (click)="toggleVisibility('pass')" alt="show" />
        </div>
    </div>
</form>
<div class="flex w-full justify-center items-center py-4">
  <button (click)="loginWithGoogle()" 
    class="relative flex justify-center border-[1px] box-border disabled:opacity-25 xl:w-[40%] border-[#61BFD1] font-bold rounded-[6px] px-8 py-2 hover:border-opacity-25">
      <img class="left-2 absolute" src="../../assets/icons/googleicon.svg" alt="googleicon">
      {{ 'AUTH.signingoogle' | translate}}
  </button>
</div>
} @else {
  <div class="overflow-y-auto flex flex-col gap-4 max-h-[320px]" (keyup.enter)="onRegisterSubmit()">
    <div class="flex flex-col gap-2 w-full h-full">
      <p class="text-[28px] font-bold">{{'PRIVATE.Ihre einmalige Registrierung' | translate}}</p>
      <form [formGroup]="registerForm">
        <div class="flex flex-wrap gap-6 text-[#346973]">
          <input
            formControlName="firstName"
            type="text"
            placeholder="{{ 'AUTH.firstname' | translate }}"
            class="w-[98%] md:w-[48%]"
          />
          <input
            formControlName="lastName"
            type="text"
            placeholder="{{ 'AUTH.lastname' | translate }}"
            class="w-[98%] md:w-[48%]"
          />
          <select name="gender" formControlName="sex" placeholder="Anrede" class="w-[48%]">
            <option value="" selected disabled hidden>{{ 'AUTH.gender' | translate }}</option>
            <option value="MALE">{{ 'AUTH.male' | translate }}</option>
            <option value="FEMALE">{{ 'AUTH.female' | translate }}</option>
            <option value="FEMALE">{{ 'AUTH.diverse' | translate }}</option>
        </select>
        <input type="text" formControlName="birthDate" placeholder="{{ 'AUTH.birthdate' | translate }}" class="w-[98%] md:w-[48%]" onfocus="(this.type='date')">
        <input
          type="text"
          placeholder="{{ 'AUTH.companyname' | translate }}"
          formControlName="companyName"
          class="w-[98%] md:w-[48%]"
        />
  
          <input
            formControlName="email"
            type="text"
            placeholder="E-Mail"
            class="w-[98%] md:w-[48%]"
          />
          <input
            formControlName="phoneNumber"
            type="text"
            placeholder="{{ 'AUTH.phone' | translate }}"
            class="w-[98%] md:w-[48%]"
          />
          <select formControlName="referralSource" class="w-[98%] md:w-[48%]">
            <option value="" selected disabled hidden>{{'PRIVATE.referraltext' | translate }}</option>
            <option value="GOOGLE">Google</option>
            <option value="FACEBOOK">Facebook</option>
            <option value="FLYER">Flyer</option>
            <option value="INSTAGRAM">Instagram</option>
            <option value="LINKEDIN">LinkedIn</option>
            <option value="PASCALZUBERBUHLER">Pascal Zuberbühler</option>
            <option value="EMPFEHLUNG">Empfehlung</option>
            <option value="OTHER">{{'AUTH.other' | translate}}</option>
          </select>
          <input
          formControlName="otherReferralSource"
          *ngIf="this.registerForm.get('referralSource')?.value === 'OTHER' || this.registerForm.get('referralSource')?.value === 'EMPFEHLUNG'"
          type="text" [placeholder]="this.registerForm.get('referralSource')?.value === 'OTHER' ? ('AUTH.other' | translate) : ('AUTH.recommended' | translate)" class="w-[98%] md:w-[48%]">
          <div class="relative w-[98%] md:w-[48%]">
            <input
            formControlName="password"
            [type]="pass"
            placeholder="{{ 'AUTH.password' | translate }}"
            class="w-full"
          />
            <img *ngIf="!hidePass" class="absolute cursor-pointer w-6 right-2 top-[35%]" src="../../assets/icons/profile/hidepass.svg" (click)="toggleVisibility('password')" alt="hidden" />
            <img *ngIf="hidePass" class="absolute cursor-pointer w-[27px] right-[6.5px] top-[34%]" src="../../assets/icons/profile/showpass.svg" (click)="toggleVisibility('password')" alt="show" />
          </div>
          <div class="relative w-[98%] md:w-[48%]">
            <input
            [type]="repeatPass"
            [(ngModel)]="confirmation"
            [ngModelOptions]="{standalone: true}"
            (ngModelChange)="onConfirmationChange($event)"
            placeholder="{{ 'AUTH.repeatpass' | translate }}"
            class="w-full"
          />
            <img *ngIf="!hideRepeatPass" class="absolute cursor-pointer w-6 right-2 top-[35%]" src="../../assets/icons/profile/hidepass.svg" (click)="toggleVisibility('repeatpass')" alt="hidden" />
            <img *ngIf="hideRepeatPass" class="absolute cursor-pointer w-[27px] right-[6.5px] top-[34%]" src="../../assets/icons/profile/showpass.svg" (click)="toggleVisibility('repeatpass')" alt="show" />
          </div>
        </div>
      </form>
    </div>
    <div class="flex flex-col gap-2 w-full h-full relative">
      <p class="text-[28px] font-bold">{{ 'AUTH.address' | translate }}</p>
  
      <form [formGroup]="registerForm">
        <div formGroupName="address" class="flex flex-wrap gap-6 text-[#346973]">
            <input formControlName="street" type="text" placeholder="{{ 'AUTH.street' | translate }}" class="w-[89%]">
            <input formControlName="streetNr" type="text" placeholder="Nr" class="md:w-[7%] w-[100%]">
            <input [disabled]="true"  type="text" placeholder="{{ 'AUTH.zip' | translate }}" [value]="zip" class="md:w-[48%] w-[100%]">
            <input [disabled]="true" type="text" placeholder="{{ 'AUTH.city' | translate }}" [value]="city" class="md:w-[48%] w-[100%]">
            <span class="flex items-center gap-6 w-[97%]">
              <button (click)="toggleModal()" class="bg-[#E9F3F4] rounded-lg p-4 outline-none border-[1px] border-transparent">{{ 'AUTH.animals' | translate }}</button>
              @if(dogCount){
                <span class="relative">
                  <span class="absolute cursor-pointer rounded-full bg-red-200 text-red-500 flex items-center justify-center w-[24px] h-[24px] -top-2 -right-2" (click)="removeDogs()">x</span>
                  <button disabled class="bg-[#E9F3F4] rounded-lg p-4 outline-none border-[1px] text-[#a5a5a5] border-transparent">{{ 'AUTH.dog' | translate }}</button>
                </span>
              }
              @if(catCount){
                <span class="relative">
                  <span class="absolute cursor-pointer rounded-full bg-red-200 text-red-500 flex items-center justify-center w-[24px] h-[24px] -top-2 -right-2" (click)="removeCats()">x</span>
                  <button disabled class="bg-[#E9F3F4] rounded-lg p-4 outline-none border-[1px] text-[#a5a5a5] border-transparent">{{ 'AUTH.cat' | translate }}</button>
                </span>
              }
              @if(otherPetCount){
                <span class="relative">
                  <span class="absolute cursor-pointer rounded-full bg-red-200 text-red-500 flex items-center justify-center w-[24px] h-[24px] -top-2 -right-2" (click)="removeOthers()">x</span>
                  <button disabled class="bg-[#E9F3F4] rounded-lg p-4 outline-none border-[1px] text-[#a5a5a5] border-transparent">{{ 'AUTH.other' | translate }}</button>
                </span>
              }
            </span>
            <textarea formControlName="additionalInformation" name="" id="" cols="35" rows="5" placeholder="{{ 'AUTH.remark' | translate }}" class="bg-[#E9F3F4] rounded-3xl p-4 w-[98%]"></textarea>
            </div>
            <span class="flex gap-3 my-2">
              <input type="checkbox" (click)="toggleAcceptedTerms()">
              <p class="text-violet-400">
                {{ 'AUTH.termstext' | translate }} <a class="underline cursor-pointer text-blue-500" (click)="openFile()">{{ 'AUTH.terms' | translate }}</a>
              </p>
            </span>
        </form>
    </div>
  </div>
}
