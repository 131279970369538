<div class="flex justify-between items-center pt-3 md:pt-4">
  <button (click)="goToWebsite()">
    <img src="../../assets/Logo.svg" alt="Logo" class="md:w-[140px]"/>
  </button>
  <span class="flex justify-between gap-4">
    <button
      class="border-[1px] hover:bg-[#ededed3a] hover:duration-100 flex justify-center gap-2 items-center border-white text-white rounded-[6px] px-8 py-2"
      (click)="handleLanguage()"
    >
      {{ getSelectedLang() }}
      <img src="../../assets/icons/captions.bubble.svg" alt="icon" />
    </button>
    <button
      class="border-[1px] hover:bg-[#ededed3a] hover:duration-100 flex flex-col justify-center items-center gap-1 border-white text-white rounded-[6px] px-4 py-2"
      (click)="handleMenu()"
    >
      <span
        [ngClass]="{
          'w-[20px] h-[2px] bg-white transition ease transform duration-300': true,
          'rotate-45 translate-y-2 group-hover:opacity-100 duration-100': isOpen
        }"
      ></span>
      <span
        [ngClass]="{
          'w-[20px] h-[2px] bg-white transition ease transform duration-300': true,
          'opacity-0 duration-100': isOpen
        }"
      ></span>
      <span
        [ngClass]="{
          'w-[20px] h-[2px] bg-white transition ease transform duration-300': true,
          '-rotate-45 -translate-y-1 group-hover:opacity-100 duration-100':
            isOpen
        }"
      ></span>
    </button>
  </span>
</div>
