<div
class="p-2 py-6 md:p-6 bg[#61bfd1] z-20 bg-white shadow-lg"
>
<div class="w-full flex justify-end">
  <span
    class="self-end text-[22px] cursor-pointer"
    (click)="closeTerms()"
    ><img src="../../assets/icons/exit.svg" alt=""
  /></span>
</div>
<div class="w-full overflow-y-auto gap-6 flex flex-col">
  <p class="text-black text-[22px] font-bold">{{'AUTH.termsandconditions' | translate}}</p>
  <span class="flex gap-3 flex-col">
    <div class="flex gap-3">
      <input type="checkbox" (change)="toggleTerms()">
      <p class="text-violet-400">
        {{'AUTH.termstext' | translate}} <a class="underline cursor-pointer text-blue-500" (click)="openFile()">{{'AUTH.terms' | translate}}</a>
      </p>
    </div>
    <button 
    (click)="handleAcceptedTerms()"
    [disabled]="!termsAccepted"
    [ngClass]="{'disabled opacity-50': !termsAccepted}"
    class="btn-primary w-fit">
    {{'AUTH.accept' | translate}}
  </button>
  </span>
</div>

