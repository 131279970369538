import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { AlertService } from '../../services/core/alert.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-success-card',
  standalone: true,
  imports: [TranslateModule, CommonModule],
  templateUrl: './success-card.component.html',
  styleUrl: './success-card.component.scss'
})
export class SuccessCardComponent implements OnInit {
  public user: any
  constructor(private auth: AuthService, private alertService: AlertService) {}

  ngOnInit(): void {
    let token = localStorage.getItem('token')
    let userId = localStorage.getItem("user")
    this.getUser(userId, token)
    
  }

  /**
   * updates user if card is succesfully added
   * @param user 
   * @param token 
   */
  
  getUser(user: any, token: any) {
    this.auth.getUser(user, token).subscribe({
      next: (res) => {
        if (res) {
          this.user = res.data;
          console.log(this.user)
        }
      },
      error: (err) => {
        if (err) {
          this.alertService.error(err.error.error, { autoClose: true });
        }
      },
      complete:() => {
        let cardObj: any = {}
        if(this.user.clientType === 'PRIVATE') {
          cardObj = {
            clientType: this.user.clientType,
            paymentType: 'CARD'
          }
        } else {
          cardObj = {
            clientType: this.user.clientType,
            paymentType: 'CARD',
            companyName: this.user.companyName
          }
        }
    
        this.auth.updateUser(cardObj ,this.user.id, token).subscribe({
          next: (res) => {
            if(res) {
              this.alertService.success(res.msg, { autoClose: true });
            }
          },
          error: (err) => {
            if(err){
              this.alertService.error(err.error.error, {autoClose: true})
            }
          }
        })
      },
    })
  }
}
