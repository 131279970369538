import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
declare const FB: any;
@Injectable({
  providedIn: 'root'
})
export class FacebookAuthService {

  private userProfile: any;

  constructor(private http: HttpClient, private router: Router) {
    this.loadFBSDK();
  }

  public loadFBSDK() {
    return new Promise(resolve => {
      window.fbAsyncInit = function() {
        FB.init({
          appId: `${environment.fbclientId}`, 
          cookie: true,
          xfbml: true,
          version: 'v13.0'
        });
        resolve(true);
      };

      ((d, s, id) => {
        let js: any,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode?.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    });
  }

  public testAPI() {
    return new Promise((resolve) => {
      FB.api('/me?fields=name,email', (response: any) => {
        this.userProfile = response;
        resolve(response);
      });
    });
  }

  public login(): Promise<any> {
        return new Promise((resolve, reject) => {
          FB.login((response: any) => {
            if (response.authResponse) {
              this.testAPI().then((userData: any) => {
                resolve({ ...userData, token: response.authResponse.accessToken });
              });
            } else {
              reject('User cancelled login or did not fully authorize.');
            }
          }, { scope: 'public_profile,email' });
        });
      }

  getToken(): string | null {
    const token = FB.getAuthResponse()?.accessToken;
    return token;
  }

  getProfile(): string | null {
    const profile = this.userProfile;
    return profile
  }

  public async loginToBackend(): Promise<any> {
    const token = this.getToken();

    if (token) {
      try {
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': '123'
        });

        const response: any = await this.http.post(
          `${environment.apiUrl}/auth/login/facebook`,
          { token },
          { headers }
        ).toPromise();
        if (response && response.data) {
          const backendToken = response.data.token;
          const userId = response.data.id;
          localStorage.setItem('token', backendToken);
          localStorage.setItem('user', userId);
          this.router.navigate(['/profile']);
        }

        return response;
      } catch (error: any) {
        console.error('Error logging in to backend:', error);
        if (error.status === 404) {
          console.log('User not found, navigating to socialregister.');
          this.router.navigate(['/socialregister']);
        } else {
          console.error('Unexpected error status:', error.status);
          this.router.navigate(['/socialregister']);
        }
        throw error;
      }
    } else {
      console.error('Token is not available');
      throw new Error('Token is not available');
    }
  }

  public logout(): void {
    FB.logout((response: any) => {});
  }
}