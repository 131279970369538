import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})

export class GoogleAnalyticsService {
  constructor() {}

  public event(action: string, params: { event_category: string; event_label?: string; value?: number }): void {
    gtag('event', action, params);
  }
  
}