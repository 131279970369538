import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertService } from '../../services/core/alert.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-passwordreset',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, CommonModule],
  templateUrl: './passwordreset.component.html',
  styleUrl: './passwordreset.component.scss',
})
export class PasswordresetComponent implements OnInit {
  resetPasswordForm!: FormGroup;
  param: any;

  hideNewPass = true
  hideNewPassSecond = true
  newpass = 'password'
  newpassSecond = 'password'

  constructor(
    private route: Router,
    private fb: FormBuilder,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private alertServices: AlertService
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.param = params?.['token'];
    });
  }

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group({
      password: ['', [Validators.required]],
      secondPassword: ['', [Validators.required]],
    });
  }

  submitReset() {
    if (this.resetPasswordForm.valid) {
      if(this.resetPasswordForm.get('password')?.value ===
      this.resetPasswordForm.get('secondPassword')?.value) {
      const pass = this.resetPasswordForm.get('secondPassword')?.value;
      const body = {
        password: pass,
        token: this.param,
      };
      this.auth.resetPassword(body).subscribe({
        next: (res) => {
          if (res) {
            //  console.log(res);
            this.alertServices.success(res.msg, { autoClose: true });
            this.resetPasswordForm.reset();
            this.route.navigate(['login']);
          }
        },
        error: (err) => {
          if (err) {
            this.alertServices.error(err.error.error, { autoClose: true });
          }
        },
      });
      } else {
        this.alertServices.error("Passwort stimmt nicht überein", {autoClose: true})
      }
    }
  }
  
  toggleVisibility(action: string): void {
     if (action === 'newpass') {
      this.hideNewPass = !this.hideNewPass
      if(this.hideNewPass) {
        this.newpass = 'password'
      } else {
        this.newpass = 'text'
      }
    } else if (action === 'newpassSecond') {
      this.hideNewPassSecond = !this.hideNewPassSecond
      if(this.hideNewPassSecond) {
        this.newpassSecond = 'password'
      } else {
        this.newpassSecond = 'text'
      }
    }
  }
}
