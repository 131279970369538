import { CommonModule } from '@angular/common';
import { compileNgModule } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

interface Content {
  name: string;
  description: string;
  attributes: { label: string; value: number }[];
  title: string;
}
@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
})
export class ModalComponent implements OnInit{
  @Input() closeModal!: () => void;
  @Input() content!: any;
  @Input() name!: string;
  @Input() confRoomsChange!: () => void;
  @Input() cabinetRemarks!: any;
  @Input() resetCabinets!: () => void;
  @Input() commentVal!: string

  @Output() cabinetsAddedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cabinetsDuration: EventEmitter<number> = new EventEmitter<number>();
  @Output() commentAdd: EventEmitter<string> = new EventEmitter<string>();
  @Output() cabinetRemark: EventEmitter<string> = new EventEmitter<string>();

  cabinetsAdded: boolean = false;
  cabinetsRemark: any = '';
  cabinetDurationByHours: number = 0.5;
  addComment: any;

  public cabinets = [
    {
      name: 'Schränke von innen',
      description: '',
      attributes: [
        { label: 'Dauer', value: 30 }
      ],
      remark: '',
      title:
        '',
    }
  ]

  public comment = [
    {
      name: 'Kommentar',
      description: 'Vielen Dank im Voraus für Ihren Kommentar',
      attributes: [{ label: '', value: 0 }],
      title: 'Bitte hinterlassen Sie weitere Kommentare',
    },
  ];

  ngOnInit(): void {
    if(this.cabinetRemarks){
      this.cabinetsRemark = this.cabinetRemarks
    }
  }

  getObject(type: string): Content[] | undefined {
    if (type === 'comment') {
      return [this.comment[0]];
    }  else if(type === 'cabinets'){
      return [this.cabinets[0]]
    } 
    else {
      return undefined;
    }
  }

  emitValuesToParent() {
    this.cabinetsAddedChange.emit(this.cabinetsAdded);
    this.cabinetRemark.emit(this.cabinetsRemark)
    this.cabinetsDuration.emit(this.cabinetDurationByHours);
    this.commentAdd.emit(this.addComment);
  }

  confirmCabinets(action:string){
    if (action === 'confirm') {
      this.cabinetsAdded = true;
    } else if (action === 'cancel') {
      this.cabinetsAdded = false;
      this.cabinetDurationByHours = 0;
      this.cabinetsRemark = null
      this.resetCabinets()
    }
    this.cabinetsAddedChange.emit(this.cabinetsAdded);
    this.cabinetsDuration.emit(this.cabinetDurationByHours);
    this.cabinetRemark.emit(this.cabinetsRemark)
  }

  hasExactLength(str: string, length: number): boolean {
    return str.length > length;
  }

  confirmComment() {
    this.commentAdd.emit(this.addComment);
  }
  cancelComment() {
    this.closeModal()
  }

  handleChangeVal(event: any) {
    this.addComment = event.target.value;
    console.log(this.addComment);
  }

  increaseCabinetsValue() {
    if (this.cabinets[0].attributes[0].value < 120) {
      this.cabinets[0].attributes[0].value += 30;
      this.cabinetDurationByHours += 0.5;
    }
  }
  decreaseCabinetsValue() {
    if (this.cabinets[0].attributes[0].value > 30) {
      this.cabinets[0].attributes[0].value -= 30;
      this.cabinetDurationByHours -= 0.5;
    }
  }

}
