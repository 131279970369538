import { ChangeDetectionStrategy, Component, EventEmitter, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { CalendarEvent, CalendarModule, CalendarView } from 'angular-calendar';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeCh from '@angular/common/locales/de-CH';
import { MomentModule } from 'ngx-moment';
import moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';


registerLocaleData(localeCh)

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [CalendarModule, CommonModule, MomentModule, TranslateModule],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss',
  providers: [{ provide: LOCALE_ID, useValue: 'de-CH' }],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CalendarComponent implements OnInit{
  @Input() viewDate!: Date
  @Input() disabledDays!: any
  @Input() realDisabledDays!: any
  @Output() selectedDate: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewDateUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();

  locale = 'de-CH';
  selected: any
  events: CalendarEvent[] = [];


  ngOnInit(): void {
    const currentMonth = this.viewDate.getMonth()
    
    const hasCurrentMonth = this.disabledDays.every((date:any) => { return new Date(date.date).getMonth() !== currentMonth})
    
    if (hasCurrentMonth){
      this.viewDateUpdate.emit(hasCurrentMonth)
      }
    }

  isWeekend(day:any): boolean{
    let test = new Date(day)
    if (test.getDay() === 0 ) {
      return true
    } else if(day < this.viewDate){
      return true
    } else {
      return false
    }
  }

  clickedDate(day:any){
    this.selected = day;
    this.selectedDate.emit(this.selected)
  }

  /**
   * checks disabled days
   * @param day 
   * @returns 
   */
  checkDisabled(day:any):boolean{
    if(this.disabledDays && this.disabledDays.length > 0){
      let date = new Date(day)
      let dayIso = moment(date).format("YYYY-MM-DD")
      let disabled = this.disabledDays.some((d:any) => d.date.split('T')[0] === dayIso.split('T')[0]) && !this.isWeekend(day)
      return disabled
    } else {
      return true
    }
  }
}
