<div class="custom-select" (click)="selectElement($event)" style="width: 100%">
  <div [ngClass]="{ 'select-selected ': true, 'flex-wrap': subTitle, 'font-bold' : title === 'Auswählen' }">
    {{ 'PRIVATE.' + title | translate }}
    @if(subTitle !== ''){
    <p class="text-[16px] xl:text-[20px]">{{  'PRIVATE.' + subTitle | translate }}</p>
    }
    <img src="../../../assets/icons/Vector.svg" alt="vector">
  </div>
  @if(selectedOpen){
  <div class="select-items text-[16px] xl:text-[20px]">
    @for(option of options; track $index){
    <div (click)="selectOption(option)">{{"PRIVATE." + option | translate}}</div>
    }
  </div>
  }
</div>
