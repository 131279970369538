import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthConfig, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { environment} from '../../environments/environment';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleAuthService {

  private authSubscription: Subscription | undefined;

  constructor(
    private googleService: OAuthService,
    private http: HttpClient,
    private router: Router
  ) {
    this.initConfiguration();
    this.setupAuthMonitoring();
  }

  initConfiguration() {
    const authConfig: AuthConfig = {
      issuer: 'https://accounts.google.com',
      strictDiscoveryDocumentValidation: false,
      clientId: `${environment.googleclientId}`,
      redirectUri: window.location.origin + '/callback',
      scope: 'openid profile email',
      responseType: 'id_token token',
    };

    this.googleService.configure(authConfig);
    this.googleService.setupAutomaticSilentRefresh();
    this.googleService.loadDiscoveryDocumentAndTryLogin();
  }

  setupAuthMonitoring() {
    this.authSubscription = this.googleService.events.subscribe((event: OAuthEvent) => {
      if (event.type === 'token_error') {
        console.error('Access denied by user, redirecting to login.');
        this.router.navigate(['/login']);
      }
    });
  }
  
  login() {
    this.googleService.initImplicitFlow();
  }

async loginToBackend() {
  const token = this.getToken();

  if (token) {
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': '123'
      });

      const response = await this.http.post(
        `${environment.apiUrl}/auth/login/google`,
        {token},
        { headers }
      ).toPromise();

      return response;
    } catch (error: any) {
      console.error('Error logging in to backend:', error);
      if (error.status === 404) {
        console.log('User not found, navigating to socialregister.');
        this.router.navigate(['/socialregister']);
      } else {
        console.error('Unexpected error status:', error.status);
        this.router.navigate(['/socialregister']);
      }
      throw error;
    }
  } else {
    console.error('Token is not available');
    throw new Error('Token is not available');
  }
}

private async waitForToken(): Promise<string> {
  const maxAttempts = 10;
  let attempts = 0;
  while (attempts < maxAttempts) {
    const token = this.getToken();
    if (token) {
      return token;
    }
    await new Promise(resolve => setTimeout(resolve, 100));
    attempts++;
  }
  throw new Error('Token not available after waiting');
}

async loginToBackendWithRetry() {
  try {
    const token = await this.waitForToken();
    return await this.loginToBackend();
  } catch (error) {
    console.error('Error logging in to backend after retry:', error);
    this.cancelLogin()
    throw error;
  }
}


  logout() {
    this.googleService.revokeTokenAndLogout();
    this.googleService.logOut();
  }

  getProfile() {
    const profile = this.googleService.getIdentityClaims();
    return profile;
  }

  getToken() {
    const token = this.googleService.getIdToken(); 
    return token;
  }
  
  cancelLogin() {
    this.router.navigate(['/login']);
  }
}
