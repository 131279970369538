import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { HttpClient, provideHttpClient } from '@angular/common/http';

import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { provideOAuthClient } from 'angular-oauth2-oidc';

export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http)
}

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideHttpClient(),provideOAuthClient(),
  TranslateModule.forRoot({
    defaultLanguage: localStorage.getItem('selectedLanguage')?.toLocaleLowerCase() || 'de',
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps:[HttpClient]
    }
  }).providers!,
  importProvidersFrom(
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  )]
};
