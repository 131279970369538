@if(createNewAddress){
  <div class="h-full w-[100%] md:w-[75%] absolute right-0 top-[5%] md:right-0 md:top-0 flex justify-center items-center z-10">
    <div class="bg-white p-4 md:p-8 w-[700px] h-[500px] rounded-br-lg rounded-tl-lg shadow-2xl flex flex-col  overflow-y-auto">
      <button class="text-black text-[20px] self-start" (click)="closeModal()">
        <img src="../assets/icons/exit.svg" alt="exit" />
      </button>
      <form [formGroup]="addressForm">
      <span class="flex flex-col p-8 gap-4">
        <input formControlName="street" placeholder="{{ 'AUTH.address' | translate }}" />
        <input [disabled]="true" type="text" formControlName="zipCode" [value]="zip" placeholder="{{ 'AUTH.zip' | translate }}" />
        <input [disabled]="true" type="text" formControlName="city" placeholder="{{ 'AUTH.city' | translate }}" [value]="city"/>
        <input formControlName="streetNr" placeholder="Nr" />
        <span class="w-full flex justify-between">
          <input formControlName="street" class="w-[40%]" placeholder="{{ 'AUTH.dog' | translate }}" />
          <div class="flex justify-between w-[40%] items-center">
            <button class="w-8 h-8 bg-[#61BFD1] rounded-lg flex items-center justify-center" (click)="countPets('decrease', 'dog')">
              <img src="../assets/icons/minus.svg" class="w-4" alt="plus" />
            </button>
            {{dogsCount}}
            <button class="w-8 h-8 bg-[#61BFD1] rounded-lg flex items-center justify-center" (click)="countPets('increase', 'dog')">
              <img src="../assets/icons/plus.svg" class="w-4" alt="plus" />
            </button>
          </div>
        </span>
        <span class="w-full flex justify-between">
          <input class="w-[40%]" placeholder="{{ 'AUTH.cat' | translate }}" />
          <div class="flex justify-between w-[40%] items-center">
            <button class="w-8 h-8 bg-[#61BFD1] rounded-lg flex items-center justify-center" (click)="countPets('decrease', 'cat')">
              <img src="../assets/icons/minus.svg" class="w-4" alt="plus" />
            </button>
            {{catsCount}}
            <button class="w-8 h-8 bg-[#61BFD1] rounded-lg flex items-center justify-center" (click)="countPets('increase', 'cat')">
              <img src="../assets/icons/plus.svg" class="w-4" alt="plus" />
            </button>
          </div>
        </span>
        <span class="w-full flex justify-between">
          <input class="w-[40%]" placeholder="{{ 'AUTH.other' | translate }}" />
          <div class="flex justify-between w-[40%] items-center">
            <button class="w-8 h-8 bg-[#61BFD1] rounded-lg flex items-center justify-center" (click)="countPets('decrease', 'other')">
              <img src="../assets/icons/minus.svg" class="w-4" alt="plus" />
            </button>
            {{othersCount}}
            <button class="w-8 h-8 bg-[#61BFD1] rounded-lg flex items-center justify-center" (click)="countPets('increase', 'other')">
              <img src="../assets/icons/plus.svg" class="w-4" alt="plus" />
            </button>
          </div>
        </span>
        <!-- <textarea class="bg-gray-200 rounded-3xl p-4" name="" id="" cols="30" rows="3" placeholder="Eintrittsanweisungen"></textarea> -->
        <textarea
        name=""
        id=""
        cols="25"
        rows="3"
        placeholder="{{ 'AUTH.remark' | translate }}"
        class="bg-gray-200 rounded-3xl p-4"
      ></textarea>
        <button (click)="submitForm()" class="btn-primary w-[50%] self-center">{{'PRIVATE.Adresse erstellen' | translate}}</button> 
      </span>
    </form>
    </div>
  </div>
}

<div class="flex flex-col md:flex-row md:justify-between w-full py-0 md:h-[600px] overflow-y-auto h-[550px] 2xl:h-[620px] overflow-x-hidden">
  <div class="md:h-[90%] 2xl:h-[100%] self-center w-[92%] mx-3 md:mx-0 rounded-tl-[18px] rounded-br-[18px] bg-[#EFF8F6] pl-4 2xl:p-6 pt-3 mb-5 md:mb-0 md:pt-8 md:w-[22%] md:hidden flex flex-col justify-between">
    <div (click)="openStepper()" class="flex justify-between md:hidden">
      <div class="mb-3">
        @if(displayPrice) {
          <h4 class="font-bold text-black text-[24px]">CHF {{price ? price : '- -'}}</h4>
          <p class="font-black text-[24px]">{{ "PRIVATE.Gesamtbetrag" | translate }}</p>
        } @else {
          @for (item of steps; track $index) {
            <p *ngIf="currentStep === item.id" class="text-[24px] text-[#00829A]">
              {{ "PRIVATE." + item.name | translate }}
            </p>
          }
        }
      </div>
      <img
        [ngClass]="{ '-rotate-90': !stepperDisplay }"
        class="mb-5"
        src="../../assets/icons/chevron-down-stepper.svg"
        alt="chevron down"
      />
    </div>
    @if(stepperDisplay) {
    <div>
      <span
        class="text-[20px] flex gap-2 overflow-y-auto items-center font-bold text-[#00829A]"
      >
        <span
          class="h-[20px] w-[20px] rounded-full border-2 border-[#00829A] flex items-center justify-center"
        >
          <img src="../../assets/icons/tick.svg" alt="tick" /> </span
        >{{ "PRIVATE.zipcity" | translate }}</span
      >
      <div class="w-[2px] h-[50px] bg-[#3C7366] ml-[3%]"></div>
      <ul class="text-[20px] flex flex-col font-bold gap-5">
        <!-- Side bar steps -->
        @for(item of steps; track $index){
        <li
          [ngClass]="{
            'flex items-center gap-2 cursor-default': true,
            'text-[#00829A]': currentStep === item.id,
            'text-[#B4B4B4]': currentStep < item.id
          }"
          (click)="handleSteps(item.id)"
          class="cursor-pointer"
        >
          <span
            [ngClass]="{
              'h-[20px] w-[20px] rounded-full  border-2 flex items-center justify-center' : true,
              'border-[#00829A]' : currentStep === item.id, 
              'border-[#B4B4B4]' : currentStep < item.id,
              'border-[#61BFD1]' : currentStep > item.id,
              }"
          >
            @if(currentStep > item.id){
            <img src="../../assets/icons/tick-blue.svg" alt="tick" />
            }
          </span>
          {{ "PRIVATE." + item.name | translate }}
        </li>
        }
      </ul>
    </div>
    <div
      (displayPriceChange)="handlePriceDisplay($event)"
      class="flex flex-col py-8 text-[#5F5F5F]"
    >
      @if (displayPrice) {
      <p class="font-black text-[24px]">CHF {{price ? price : '- -'}}</p>
      <p *ngIf="selectedCleaningType === 2">{{'PRIVATE.pro Reinigung' | translate}}</p>
      <p *ngIf="selectedCleaningType === 2 && hasSupplies">+ CHF 50 {{'PRIVATE.pro Monat für Reinigungsmaterial' | translate}}</p>
      <p *ngIf="selectedCleaningType !== 2" class="text-[16px]">{{ "PRIVATE.Gesamtbetrag" | translate }}</p>
      <p class="text-[12px] py-2">
        {{ "PROFILE_MODALS.vat" | translate }}
      </p>
      }
    </div>
    }
  </div>
  <div class="md:h-[90%] 2xl:h-[100%] self-center w-[92%] mx-3 md:mx-0 rounded-tl-[18px] rounded-br-[18px] bg-[#EFF8F6] pl-4 2xl:p-6 pt-3 mb-5 md:mb-0 md:pt-8 md:w-[22%] md:overflow-y-auto hidden md:flex flex-col justify-between">
    <div>
      <span
        class="text-[20px] flex gap-2 overflow-y-auto items-center font-bold text-[#00829A]"
      >
        <span
          class="h-[20px] w-[20px] rounded-full border-2 border-[#00829A] flex items-center justify-center"
        >
          <img src="../../assets/icons/tick.svg" alt="tick" /> </span
        >{{ "PRIVATE.zipcity" | translate }}</span
      >
      <div class="w-[2px] h-[50px] bg-[#3C7366] ml-[3%]"></div>
      <ul class="text-[20px] flex flex-col font-bold gap-5">
        <!-- Side bar steps -->
        @for(item of steps; track $index; let i = $index){
        <li
          [ngClass]="{
            'flex items-center gap-2 cursor-default': true,
            'text-[#00829A]': currentStep === item.id,
            'text-[#B4B4B4]': currentStep < item.id
          }"
          (click)="handleSteps(item.id)"
          class="cursor-pointer"
        >
          <span
            [ngClass]="{
              'h-[20px] w-[20px] rounded-full  border-2 flex items-center justify-center' : true,
              'border-[#00829A]' : currentStep === item.id, 
              'border-[#B4B4B4]' : currentStep < item.id,
              'border-[#61BFD1]' : currentStep > item.id,
              }"
          >
            @if(currentStep > item.id){
            <img src="../../assets/icons/tick-blue.svg" alt="tick" />
            }
          </span>
          {{ "PRIVATE." + item.name | translate }}
        </li>
        }
      </ul>
    </div>

    <div
      (displayPriceChange)="handlePriceDisplay($event)"
      class="flex flex-col py-4 text-[#5F5F5F]"
    >
      @if (displayPrice) {
      <p class="font-black text-[24px]">CHF {{price ? price : '- -'}}</p>
      <p *ngIf="selectedCleaningType === 2">{{'PRIVATE.pro Reinigung' | translate}}</p>
      <p *ngIf="selectedCleaningType === 2 && hasSupplies">+ CHF 50 {{'PRIVATE.pro Monat für Reinigungsmaterial' | translate}}</p>
      <p *ngIf="selectedCleaningType !== 2" class="text-[16px]">{{ "PRIVATE.Gesamtbetrag" | translate }}</p>
      <p class="text-[12px] py-2">
        {{ 'PROFILE_MODALS.vat' | translate}}
      </p>
      }

    </div>
  </div>
  <div class="stepper-container md:overflow-y-auto w-[92%] md:min-w-[70%] mx-3 md:mx-0 rounded-tl-[18px] px-2 2xl:px-10 2xl:py-8 md:px-8 py-4 rounded-br-[18px]">
    <div class="flex flex-col gap-4">
      <!-- Steps -->
      @for(item of steps; track $index){ @if(currentStep === item.id){
      <div class="flex gap-4 items-center">
        <span
          class="bg-violet-100 text-violet-400 rounded-md text-[20px] 2xl:text-[22px] font-bold px-5 py-2 uppercase"
          >{{ "PRIVATE." + item.name | translate }}</span
        >
      </div>
      <!-- Sub-steps -->
      @for(subStep of item.subSteps; track $index){ @if(currentSubStep ===
      subStep.id){
      <div>
        @if(subStep.name === 'Explanation'){
          @switch (specialTitle) {
            @case ('WINDOW_CLEANING'){
              <div class="text-[28px] font-bold text-[#00829A]">{{'SERVICE_TYPE.Fensterreinigung' | translate}}</div>
            } @case ("END_OF_TENANCY"){
              <div class="text-[28px] font-bold text-[#00829A]">{{'SERVICE_TYPE.Umzugsreinigung' | translate}}</div>
            } @case ("BASIC_CLEANING"){
              <div class="text-[28px] font-bold text-[#00829A]">{{'SERVICE_TYPE.Grundreinigung' | translate}}</div>
            } @case ("CLEAN_OUT"){
              <div class="text-[28px] font-bold text-[#00829A]">{{'SERVICE_TYPE.Ausräumen / Ordnung schaffen' | translate}}</div>
            } @case ("BUY_CLEANING"){
              <div class="text-[28px] font-bold text-[#00829A]">{{'SERVICE_TYPE.Einkaufen' | translate}}</div>
            } @case ("GARDEN_MAINTENANCE"){
              <div class="text-[28px] font-bold text-[#00829A]">{{'SERVICE_TYPE.Gartenpflege' | translate}}</div>
            } @case ("ILLNESS_CLEANING" ){
              <div class="text-[28px] font-bold text-[#00829A]">{{'PRIVATE.Welche Art von Spezialreinigung wünschen Sie?' | translate}}</div>
            } @case ('ACCIDENT_CLEANING'){
              <div class="text-[28px] font-bold text-[#00829A]">{{'PRIVATE.Welche Art von Spezialreinigung wünschen Sie?' | translate}}</div>
            } @case ('PREGNANCY_CLEANING'){
              <div class="text-[28px] font-bold text-[#00829A]">{{'PRIVATE.Welche Art von Spezialreinigung wünschen Sie?' | translate}}</div>
            } @case ('WEEKEND_CLEANING'){
              <div class="text-[28px] font-bold text-[#00829A]">{{'PRIVATE.Welche Art von Spezialreinigung wünschen Sie?' | translate}}</div>
            }
          }
        } @else if(subStep.name === 'Welche Art von Spezialreinigung wünschen Sie?' && selectedCleaningType === 5) {
          <div class="text-[28px] font-bold text-[#00829A]">{{'PRIVATE.Haushaltshilfe - Krankenkasse' | translate}}</div>
        } 
        @else {
          <div class="text-[28px] font-bold flex justify-between text-[#00829A] flex-col md:flex-row lg:flex-row">
            {{ 'PRIVATE.' + subStep.name | translate }}
            @if(subStep.name === "Kontaktangaben" && token){
              <button (click)="openModal()" class="btn-primary md:text-[20px] lg:text-[20px] text-[14px]">{{'PRIVATE.Neue Adresse Hinzufügen' | translate}}</button>
            }
            @if(subStep.name === "Was dürfen wir noch für Sie tun?"){
              <span class="bg-violet-100 text-violet-400 rounded-md text-[16px] w-[100%] md:w-auto lg:w-auto self-end font-bold px-5 py-2 uppercase">{{hours}} {{ 'PROFILE.hours' | translate}} (max 6 {{ 'PROFILE.hours' | translate}})</span>
            }
          </div>
        }
      </div>
      } }
      <!-- Content -->
      <ng-container *ngFor="let stepTemplate of stepTemplates; let i = index">
        <ng-container *ngIf="currentStep === i + 1">
          <ng-container *ngTemplateOutlet="stepTemplate"></ng-container>
        </ng-container>
      </ng-container>
      } }
    </div>
    @if(displayButtons){
    <div class="w-full flex justify-end mt-5">
      <span class="flex gap-2">
        <button
          class="border-[1px] box-border disabled:opacity-25 border-[#61BFD1] font-bold rounded-[6px] px-8 py-2 hover:bg-[#61bfd13a] hover:duration-100"
          [disabled]="currentStep === 1 && currentSubStep === 1 "
          (click)="stepButton('back')"
        >
          {{ "PRIVATE.Zurück" | translate }}
        </button>
        @if(isLoginButton){
          <button class="btn-primary" (click)="loginFromStepper()">{{ 'AUTH.login' | translate }}</button>
        }
        @else if(isRegisterButton || changeButton){
          <button class="btn-primary" [disabled]="loading || !hasAcceptedTerms" (click)="registerFromStepper()">{{ 'AUTH.register' | translate }}</button>
        } 
        @else {
          @if(isCompany) {
            <button
            class="border-[1px] box-border disabled:bg-[#61bfd185] bg-[#61BFD1] text-white font-bold rounded-[6px] px-8 py-2 hover:bg-[#3b7a86]"
              [disabled]="disableNext()"
              (click)="stepButton('next')"
            >
              {{ isLastStep() ? ('STEPS.Unverbindlich anfragen' | translate) : ("PRIVATE.Weiter" | translate) }}
            </button>
          } @else {
            <button
              class="border-[1px] box-border disabled:bg-[#61bfd185] bg-[#61BFD1] text-white font-bold rounded-[6px] px-8 py-2 hover:bg-[#3b7a86]"
              [disabled]="disableNext()"
              (click)="stepButton('next')"
            >
              {{ isLastStep() ? ('STEPS.Angebot anfordern' | translate) : ("PRIVATE.Weiter" | translate) }}
    
            </button>
          }
        }
      </span>
    </div>
    }
  </div>
</div>
