<div class="absolute custom-modal z-50 p-4">
  <app-alert></app-alert>
</div>
<div class="md:h-screen lg:h-screen h-[calc(100dvh)] flex flex-col md:pb-4 justify-between overflow-x-hidden">
  <div class="w-full flex justify-between px-3 md:px-12">
    <app-header
      class="w-full z-50"
      (isOpenChange)="handleIsOpenChange($event)"
      [selectedLang]="selectedLanguage ? selectedLanguage : 'DE'"
      [handleLanguage]="handleOpenLanguage.bind(this)"
    ></app-header>
  </div>
  <div class="flex justify-center items-center py-2 h-[calc(100dvh)] overflow-y-hidden">
    <div class="container p-0">
      <div
        [ngClass]="{
          'flex md:pt-0 transition ease transform duration-300 md:py-0 h-full': true,
          'opacity-0 duration-150': isOpen
        }"
      >
        <div class="w-full">
          <router-outlet></router-outlet>
        </div>
      </div>
      <!-- Menu Open -->
      <div
        [ngClass]="{
          'w-[100%] header-container-open p-20 ': isOpen,
          'header-container-closed ': !isOpen,
          'fixed top-0 bottom-0 min-h-screen max-w-[100%]': true
        }"
      >
        <div class="flex justify-between relative w-[90%]">
          <div class="w-full hidden md:block text-[77px]">
            <img
              src="../assets/icons/cleaning-lady-home.svg"
              alt="janies"
              class="absolute w-64 top-[35%] left-0"
            />
            <img
              src="../assets/icons/cleaning-boy-home.svg"
              alt="janies"
              class="absolute w-64 top-[35%] left-[20%]"
            />
          </div>
          <div class="flex mx-auto flex-col pt-16 md:items-end mr-10 w-full gap-4">
            <a
              href="{{website}}/"
              class="md:text-[48px] lg:text-[48px] text-[36px] text-[#cfeef4] hover:text-white hover:duration-100 font-bold"
            >
            {{ 'MENU.home' | translate }}
            </a>
            <a
              href="/"
              class="md:text-[48px] lg:text-[48px] text-[36px] text-[#cfeef4] hover:text-white hover:duration-100 font-bold"
            >
            {{ 'MENU.cleaning' | translate }}
            </a>
            <a
              href="{{website}}/FAQ"
              class="md:text-[48px] lg:text-[48px] text-[36px] text-[#cfeef4] hover:text-white hover:duration-100 font-bold"
            >
              FAQ
            </a>
            <a
              href="{{website}}/about"
              class="md:text-[48px] lg:text-[48px] text-[36px] text-[#cfeef4] hover:text-white hover:duration-100 font-bold"
            >
            {{ 'MENU.aboutus' | translate }}
            </a>
          </div>
        </div>
      </div>
      <!-- Lang open -->
      <div
        [ngClass]="{
          'md:w-[20%] header-container-open ml-40 md:ml-0': isLanguageOpen,
          'header-container-closed ': !isLanguageOpen,
          'bg-[#61BFD8] text-white absolute rounded-lg top-20 right-28 max-w-[160px]': true
        }"
      >
          <button
          class="w-full text-start cursor-pointer hover:bg-[#134379] hover:duration-300 p-4 rounded-t-lg"
          (click)="selectLang('DE')"
        >
        Deutsch
        </button>
        <button
          class="w-full text-start cursor-pointer hover:bg-[#134379] hover:duration-300 p-4 rounded-b-lg"
          (click)="selectLang('EN')"
        >
          English
        </button>
      </div>
    </div>
  </div>
  <div class="w-full flex justify-between px-4 z-50 md:px-12 pb-3 md:pb-2">
    <a
      href="{{website}}/#review"
      class="hover:bg-[#ededed3a] flex gap-1 items-center hover:duration-100 border-[1px] border-white text-white font-bold rounded-[6px] px-8 py-2"
    >
      {{ 'MENU.reviews' | translate }}
      <img src="../assets/icons/star.svg" alt="star" />
    </a>
    <button
      (click)="login()"
      class="hover:bg-[#ededed3a] flex gap-1 items-center hover:duration-100 border-[1px] border-white text-white font-bold rounded-[6px] px-8 py-2"
    >
      {{ token ? ('PROFILE.profile' | translate) : ('AUTH.login' | translate) }}
      <img src="../assets/icons/Human.svg" alt="human" />
    </button>
  </div>
</div>
