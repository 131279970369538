import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAuthService } from '../../services/OAuth/googleauth.service';

@Component({
  selector: 'app-callback',
  standalone: true,
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(
    private googleAuthService: GoogleAuthService,
    private router: Router
  ) {}

  async ngOnInit() {
    try {
      const response: any = await this.googleAuthService.loginToBackendWithRetry();
      if (response && response.data && typeof response.data === 'object' && 'token' in response.data && 'id' in response.data) {
        const token = response.data.token;
        const id = response.data.id;
        localStorage.setItem('token', token);
        localStorage.setItem('user', id);
        this.router.navigate(['/profile']);
      } else {
        this.router.navigate(['/socialregister']);
        throw new Error('Invalid response structure');
      }
    } catch (error: any) {
      this.router.navigate(['/socialregister']);
      console.error('Error during backend login:', error);
    }
  }
}
