<mwl-calendar-month-view
[viewDate]="viewDate"
[locale]="locale"
[weekStartsOn]="1"
[headerTemplate]="headerTemplate"
[cellTemplate]="cellTemplate"
tooltipPlacement="top"
view-title="calendarTitle"
slide-box-disabled="true">
</mwl-calendar-month-view>

<ng-template #headerTemplate 
        let-months="months"
        let-days="days" 
        let-locale="locale"
        let-dayClicked="dayClicked"
        let-eventDropped="eventDropped">
    <div class="cal-cell-row cal-header">
      <div class="cal-cell"
        *ngFor="let day of days"
        [class.cal-past]="day.isPast "
        [class.cal-today]="day.isToday"
        [class.cal-future]="day.isFuture"
        [class.cal-weekend]="day.isWeekend">
      {{ 'DATE.' + (day.date | date: 'EE') | translate }}
      </div>
    </div>
</ng-template>

<ng-template #cellTemplate 
      let-day="day"
      let-tooltipTemplate="tooltipTemplate"
      let-tooltipPlacement="tooltipPlacement">
    <div [ngClass]="{'cal-cell-top' : true}" (click)="isWeekend(day.date) || !checkDisabled(day.date) ? null : clickedDate(day.date)">
        <span [ngClass]="{
          'cal-day-number hover:bg-[#aaf1ff] cursor-pointer' : true, 
          'bg-[#61BFD1] text-white' : day.date === selected,
          'text-red-600 hover:bg-transparent' : !checkDisabled(day.date)
          }"
          >
          {{ day.date | calendarDate:'monthViewDayNumber':locale }}
        </span>
    </div>
</ng-template>
