import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-custom-select',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './custom-select.component.html',
  styleUrl: './custom-select.component.scss'
})
export class CustomSelectComponent {
  @Input() options: string[] = [];
  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Output() selected = new EventEmitter<string>();

  public selectedOption:string = ''
  public selectedOpen:boolean = false

  constructor() { }

  selectElement(event: Event) {
    event.stopPropagation();
    this.selectedOpen = !this.selectedOpen
  }

  selectOption(option: string) {
    this.selected.emit(option)
    this.selectedOption = option
    this.closeAllSelect();
  }

  closeAllSelect() {
    let selectItems = document.getElementsByClassName("select-items");
    let selectSelected = document.getElementsByClassName("select-selected");
    for (let i = 0; i < selectSelected.length; i++) {
      selectSelected[i].classList.remove("select-arrow-active");
    }
    for (let i = 0; i < selectItems.length; i++) {
      selectItems[i].classList.add("select-hide");
    }
  }
}
