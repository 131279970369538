import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  public website = environment.websiteUrl
  isOpen:boolean = false
  isLanguageOpen:boolean = false
  selectedLanguage:string = 'German'
  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isLanguageChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() test:boolean = false
  @Input() handleLanguage!: () => void 
  @Input() selectedLang: string = 'DE'
  
  handleMenu(){
    this.isOpen = !this.isOpen
    this.isOpenChange.emit(this.isOpen);
  }
  getSelectedLang(): string{
    if(this.selectedLang === 'EN'){
      return 'English'
    } else if(this.selectedLang === 'DE'){
      return 'Deutsch'
    } else {
      return 'Deutsch'
    }
  }

  goToWebsite() {
    window.location.href = this.website
    localStorage.removeItem('selectedZip')
  }
}
