import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, LOCALE_ID, ChangeDetectorRef } from '@angular/core';
import { StepperComponent } from '../components/stepper/stepper.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators} from '@angular/forms';
import { CustomSelectComponent } from '../components/custom-select/custom-select.component';
import * as stepContent from './stepContent.json';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ModalComponent } from './components/modal/modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarComponent } from '../components/calendar/calendar.component';
import { AuthService } from '../services/auth/auth.service';
import { BookingService } from '../services/core/booking.service';
import { PricingService } from '../services/core/pricing.service';
import localeCh from '@angular/common/locales/de-CH';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import moment from 'moment';
import { FilterPipe } from '../pipes/filter.pipe';
import { AlertService } from '../services/core/alert.service';
import { TermsComponent } from "../components/terms/terms.component";
import { GoogleAnalyticsService } from '../services/core/analytics.service';
import { Subscription } from 'rxjs';

registerLocaleData(localeCh);

interface CleaningDay {
  day: string;
  timeFrame: string;
  duration: number;
}

@Component({
    selector: 'app-private-cleaning',
    standalone: true,
    templateUrl: './private-cleaning.component.html',
    styleUrl: './private-cleaning.component.scss',
    providers: [{ provide: LOCALE_ID, useValue: 'de-CH' }],
    imports: [
        StepperComponent,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CustomSelectComponent,
        ContactFormComponent,
        ModalComponent,
        TranslateModule,
        CalendarComponent,
        FilterPipe,
        TermsComponent
    ]
})
export class PrivateCleaningComponent implements OnInit {
  @ViewChild('standartCleaning1') standartCleaning1!: TemplateRef<any>;
  @ViewChild('standartCleaning2') standartCleaning2!: TemplateRef<any>;
  @ViewChild('standartCleaning3') standartCleaning3!: TemplateRef<any>;
  @ViewChild('standartCleaning4') standartCleaning4!: TemplateRef<any>;
  @ViewChild('standartCleaning5') standartCleaning5!: TemplateRef<any>;
  @ViewChild('standartCleaning6') standartCleaning6!: TemplateRef<any>;

  @ViewChild('expressCleaning1') expressCleaning1!: TemplateRef<any>;
  @ViewChild('expressCleaning2') expressCleaning2!: TemplateRef<any>;
  @ViewChild('expressCleaning3') expressCleaning3!: TemplateRef<any>;
  @ViewChild('expressCleaning4') expressCleaning4!: TemplateRef<any>;
  @ViewChild('expressCleaning5') expressCleaning5!: TemplateRef<any>;
  @ViewChild('expressCleaning6') expressCleaning6!: TemplateRef<any>;

  @ViewChild('individualCleaning1') individualCleaning1!: TemplateRef<any>;
  @ViewChild('individualCleaning2') individualCleaning2!: TemplateRef<any>;
  @ViewChild('individualCleaning3') individualCleaning3!: TemplateRef<any>;
  @ViewChild('individualCleaning4') individualCleaning4!: TemplateRef<any>;
  @ViewChild('individualCleaning5') individualCleaning5!: TemplateRef<any>;
  @ViewChild('individualCleaning6') individualCleaning6!: TemplateRef<any>;

  @ViewChild('specialCleaning1') specialCleaning1!: TemplateRef<any>;
  @ViewChild('specialCleaning2') specialCleaning2!: TemplateRef<any>;
  @ViewChild('specialCleaning3') specialCleaning3!: TemplateRef<any>;

  // @ViewChild('insurenceCleaning1') insurenceCleaning1!: TemplateRef<any>;
  // @ViewChild('insurenceCleaning2') insurenceCleaning2!: TemplateRef<any>;
  // @ViewChild('insurenceCleaning3') insurenceCleaning3!: TemplateRef<any>;

  private userId = localStorage.getItem('user');
  public token = localStorage.getItem('token');
  public hasToken:boolean = false;
  public selectedAddress = ''
  private routerSubscription: Subscription
  selectZipFromStart:boolean = true
  searchText:any = ''
  zipcodes:any[] = []
  addressForm!: FormGroup
  zipForChild: any
  changeButton: boolean
  changeToLogin: boolean
  changeToRegister: boolean
  materialModal: boolean = false
  suppliesState = 'default'
  confirmPass = ''
  commentValue = ''
  termsAccepted: boolean = true
  displayWarning: boolean = false
  specialValue:string = 'END_OF_TENANCY'
  regLoading: boolean = false
  loadingSchedule: boolean = false
  displayButtons: boolean = true
  public isCompany: boolean = false
  public displaySelectedDay: any
  public displayShift: any
  public viewDate = new Date();
  public modalOpen: boolean = false;
  public bedrooms: number = 2;
  public bathrooms: number = 1;
  public userAddresses: any;
  public selectedType: any[] = stepContent.standartSteps;
  public zip = 0;
  public stepTemplates: TemplateRef<any>[] = [];

  public currentStep = 1;
  public currentSubStep = 1;

  public selectedCleaningType: Number = 2;
  public selectedRecurrence: Number = 1;
  public freeTimeSlots: any;
  public expressTimeSlots: any;
  public filteredTimeSlots: any;
  public filteredExpressTimeSlots: any;
  public extraIndividualHours = 2;
  public hours = 2;
  public extraHours = 0;
  public ironingHours = 0;
  public cabinetHours = 0;
  public extraRooms: number = 2;
  public selectedAddOn: any[] = [];
  public serviceExtras: any[] = [];
  public hasPets = true;
  public doesntHavePets = false;
  public hasSupplies:boolean;
  public recycling = true;
  public totalHours = this.extraHours + this.hours + this.ironingHours;
  public hasAcceptedTerms: boolean = false

  public user: any
  public address:any

  public formType = 'REGULAR';
  
  public cleaningDays = 3;
  public testingson: CleaningDay[] = [];
  public days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
  public individualHours = [
    'Before noon (08:00 Uhr - 12:00 Uhr)',
    'Afternoon (12:00 Uhr - 18:00 Uhr)',
  ];
  public selectedHours: string[] = [];
  
  public isTermsOpen: boolean = false
  public postRequest: boolean = false
  public acceptedTerms: boolean = false
  
  // pets
  petsVal: string = '';
  public dogCount = 0;
  public catCount = 0;
  public otherPetCount = 0;
  
  
  step: number = 1
  login: boolean = false;
  isLogged: boolean = false;
  isAddressSelection: boolean = false;
  confirm: boolean = true;
  selectedExpressHour:number = 0
  selectedDay: any;
  loadingTimeSlots: boolean = false;
  isShiftSelected: boolean = true;
  selectedShift: string = '';
  shiftModal: boolean = false;
  selectedFastestTime: any;
  price: any;
  selectedClosestShift: string = '';
  isLastStep: boolean = false;
  redirecting: boolean = false;
  isEditActivated: boolean = false;
  selectedExpressDate:any
  individualCalendar: boolean = false;
  dateForCalendar:any
  zipFromQuery:any
  cityFromZip:any
  defaultAddress: any

  individualCabinetDuration = 0
  individualIroningDuration = 0

  cleaningForm!: FormGroup;
  expressForm!: FormGroup;
  loginForm!: FormGroup;
  registerForm!: FormGroup;
  bookingForm!: FormGroup;
  testingDays!: FormGroup;
  expressDays!: FormGroup;
  
  correctAnswer: boolean
  // discountCode: string = ''
  // correctExpressAnswer: boolean
  // correctServicesAnswer: boolean

  enableConfirmButton: boolean = true

  // individual forom
  individualForm = this.fb.group({
    formArray: this.fb.array([]),
  });

  // Discounts
  discountForm = new FormGroup({
    cleaningType: new FormControl(null, [Validators.required]),
    couponCode: new FormControl(null || '', [Validators.required])
  })

  paymentMethod = new FormControl('',[Validators.required])

  get formArray() {
    return this.individualForm.controls['formArray'] as FormArray;
  }
  get individualFormGroups(): FormGroup[] {
    return this.formArray.controls as FormGroup[];
  }


  addIndividualDays(ctx: any) {
    const newFormArray = this.fb.group({
      day: 'Wochentag',
      timeFrame: 'BEFORE_NOON',
      duration: 3,
    });
    this.formArray.push(newFormArray);
  }

  formatShift(shift:any):string{
    if(shift === 'BEFORE_NOON'){
      return 'Vormittag'
    } 
    else if(shift === 'AFTER_NOON'){
      return 'Nachmittag'
    }
    else {
      return ''
    }
  }

  updateExtraHours(index: number, amount: number) {
    const formGroup = this.formArray.at(index) as FormGroup;
    const currentExtraHours = +formGroup.get('duration')?.value;
    const newExtraHours = currentExtraHours + amount;
    formGroup.patchValue({ duration: newExtraHours });
  }

  getSubtitle(formGroup: FormGroup): string {
    if (formGroup.get('timeFrame')?.value === 'AFTER_NOON') {
      return '(12:00 Uhr - 18:00 Uhr)';
    } else {
      return '(08:00 Uhr - 12:00 Uhr)';
    }
  }

  //special cleaning
  public selectedSpecialCleaning = 2;

  public cards = [
    { title: 'Express', description: '(Innerhalb 48 Stunden Werktage)', id: 1, },
    { title: 'Regelmässig', description: 'Unsere Empfehlung', id: 2 },
    {
      title: 'Spezialreinigung',
      list: ['Fensterreinigung', 'Umzugsreinigung', 'Grundreinigung'],
      id: 3,
    },
    {
      title: 'Spezialdienste',
      list: [
        'Ausräumen',
        'Einkaufen',
        'Gartenpflege',
        'Link zum Recycling',
      ],
      id: 4,
    },
    {
      title: 'Haushaltshilfe - Krankenkasse',
      list: ['Krankheit', 'Unfall', 'Schwangerschaft', 'Wochenende'],
      id: 5,
    },
  ];

  public secondCards = [
    { id: 1, title: 'Wöchentlich', value: 'WEEKLY' },
    { id: 2, title: 'Alle zwei Wochen', value: 'BI_WEEKLY' },
    { id: 3, title: 'Monatlich', value: 'MONTHLY' },
    { id: 4, title: 'Individuell', value: 'MORE_OFTEN' },
  ];

  public secondStepCards = [
    {
      name: 'Bügeln',
      id: 1,
      duration: 0.5,
      iconActive: '../assets/icons/ironing-white.svg',
      icon: '../assets/icons/ironing.svg',
      value: 'ironingDuration',
      description: 'Wäsche bügeln und zusammenlegen'
    },
    {
      name: 'Backofen',
      id: 2,
      duration: 0.5,
      iconActive: '../assets/icons/oven-white.svg',
      icon: '../assets/icons/oven.svg',
      value: 'oven',
      description: 'Backofen von innen'
    },
    {
      name: 'Schränke',
      id: 3,
      duration: 0.5,
      iconActive: '../assets/icons/cabinets-white.svg',
      icon: '../assets/icons/cabinets.svg',
      value: 'cabinetDuration',
      description: 'Schränke von innen'
    },
    {
      name: 'Kühlschrank',
      id: 4,
      duration: 0.5,
      iconActive: '../assets/icons/fridge-white.svg',
      icon: '../assets/icons/fridge.svg',
      value: 'fridge',
      description: 'Kühlschrank von innen'
    },
    {
      name: 'Pflanzen',
      id: 5,
      duration: 0.5,
      iconActive: '../assets/icons/water-white.svg',
      icon: '../assets/icons/water.svg',
      value: 'wateringPlants',
      description: 'Pflanzen giessen'
    },
  ];

  public specialCleaningCards = [
    { title: 'Fensterreinigung', id: 1, value:'WINDOW_CLEANING'},
    { title: 'Umzugsreinigung', id: 2, value: 'END_OF_TENANCY' },
    { title: 'Grundreinigung', id: 3, value: 'BASIC_CLEANING' },
  ];

  public specialServicesCards = [
    { title: 'Ausräumen', id: 1, value: 'CLEAN_OUT' },
    { title: 'Einkaufen', id: 2, value: 'BUY_CLEANING' },
    { title: 'Gartenpflege', id: 3, value: 'GARDEN_MAINTENANCE' }
  ];

  public insuranceCleaningCards = [
    { title: 'Krankheit', id: 1, value: 'ILLNESS_CLEANING' },
    { title: 'Unfall', id: 2, value: 'ACCIDENT_CLEANING' },
    { title: 'Schwangerschaft', id: 3, value: 'PREGNANCY_CLEANING' },
    { title: 'Wochenende', id:4, value: 'WEEKEND_CLEANING' }
  ];

  // individual cleaning
  public selectedSchedule = 4;
  openWindowModal: boolean = false;
  openCalculatorModal: boolean = false;
  openCommentModal: boolean = false;
  openIroningModal: boolean = false;
  openCabinetsModal: boolean = false;
  public selectedExtrasIndividual: any[] = [];
  public loading: boolean = false;

  public calendarCards = [
    { name: '2 Monate', value: '2 months', id: 1 },
    { name: '3 Monate', value: '3 months', id: 2 },
    { name: '1 Jahr', value: '1 year', id: 3 },
    { name: 'Nicht terminieren', value: 'no schedule', id: 4 },
  ];

  // public cleaningTypes = [
  //   { name:'Basic', description: 'Lorem ipsum dolor sit amet', price: '49.-', id:1, },
  //   { name:'Pro', description: 'Lorem ipsum dolor sit amet', price: '69.-', id:2, },
  //   { name:'Ultra', description: 'Lorem ipsum dolor sit amet', price: '89.-', id:3, },
  // ]

  @Input() displayPrice: boolean = true;
  @Output() displayPriceChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
   
  constructor(
    private auth: AuthService,
    private booking: BookingService,
    private pricing: PricingService,
    private route: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef,
    private gaService: GoogleAnalyticsService,
  ) {
    for (let i = 0; i < this.cleaningDays; i++) {
      this.testingson[i] = {
        day: '',
        timeFrame: '',
        duration: 2,
      };
      this.addIndividualDays(this.testingson);
    }
  }

  swissPhoneNumberValidator(): ValidatorFn {
    const swissPhoneNumberPattern = /(\b(0041|0)|\B\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}\b/;
  
      return (control: AbstractControl): { [key: string]: any } | null => {
        const isValid = swissPhoneNumberPattern.test(control.value);
        return isValid ? null : { 'invalidSwissPhoneNumber': { value: control.value } };
      };
    }

  ngOnInit(): void {
    const zipFromLocal = localStorage.getItem('selectedZip')
    if(zipFromLocal) {
      this.route.navigate([], {
        queryParams: {
            zip: zipFromLocal
        },
      });
    }

    this.routerSubscription = this.route.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const url = new URL(event.url, window.location.origin);
        const zipParam = url.searchParams.get('zip');

        if (!zipParam) {
          localStorage.removeItem('selectedZip');
        }
      }
    });

    this.paymentMethod.valueChanges.subscribe(val => {
      if(val === '') {
        this.enableConfirmButton = true
        // console.log(this.enableConfirmButton);
      } else {
        this.enableConfirmButton = false
        // console.log(this.enableConfirmButton);
      }
      
    })
    if(!this.token){
      this.activatedRoute.queryParams.subscribe(params => {
        const zipFromQueryParam = params['zip'];
        const zipCode = parseInt(zipFromQueryParam, 10);
        let city
        if (zipFromQueryParam) {
          localStorage.setItem('selectedZip', zipFromQueryParam.toString());
          this.zipFromQuery = zipFromQueryParam
          this.zip = zipFromQueryParam
          this.booking.getZipCode().subscribe({
            next: (res:any) => {
              city = res.data.filter((e:any) => e.zip === zipCode )
              if(city.length > 0 && city[0].city){
                this.cityFromZip = city[0].city
                this.selectZipFromStart = false
              } else {
                this.selectZipFromStart = true
                // this.alertService.error('Ungültige Póstleitzahl oder Ihr Gebiet ist noch nicht abgedeckt',{ autoClose: true })
                // window.location.href = 'http://d3n7uw896ei18k.cloudfront.net/';
                // alert('Invalid zip code or your area is not covered yet')
              }
              let mappedzips = res.data.map((zipData: any) => {
                return { ...zipData, cityZip: `${zipData.zip} ${zipData.city}` };
              });
              // const allData = mappedzips.filter((el: any) => el.erpRegion !== null);
              this.zipcodes.push(mappedzips)
            },
            error: (error:any) => {
              console.error(error)
            },
            complete: () => {
              // this.booking.getTimeSlots(zipCode, this.formType.toLowerCase()).subscribe({
              //   next: (res:any) => {
              //     const data = res.data;
              //     if(this.formType.toLowerCase() !== 'express'){
              //       this.freeTimeSlots = data.filter((item: any) => {
              //         return item.schedule.morning || item.schedule.afternoon;
              //       });
                    
              //     } else {
              //       this.expressTimeSlots = data.filter((item: any) => {
              //         let schedule = item.schedule;
              //         return schedule.some((i: any) => i.availability === true);
              //       });
              //     }
              //     this.filterTimeSlots();
              //   }
              // })
            }
          })
        } else {
          this.selectZipFromStart = true
          this.booking.getZipCode().subscribe({
            next: (res:any) => {
              let mappedzips = res.data.map((zipData: any) => {
                return { ...zipData, cityZip: `${zipData.zip} ${zipData.city}` };
              });
              // const allData = mappedzips.filter((el: any) => el.erpRegion !== null);
              this.zipcodes.push(mappedzips)
            }
          })
        }
      });
    }
    if(this.token){
      this.auth.getUserAdresses().subscribe({
        next: (res: any) => {
          if (res) {
            const token = localStorage.getItem('token')
            this.userAddresses = res.data;
            const defaultAddress = res.data.filter((e:any) => e.isDefaultAddress)
            this.address = defaultAddress
            this.defaultAddress = defaultAddress
            if(this.defaultAddress[0].zipCode === this.zip){
              this.selectZipFromStart = false
              this.selectedAddress = this.defaultAddress[0].id
            }
            this.cleaningForm.get('addressId')?.setValue(this.selectedAddress);
            this.expressForm.get('addressId')?.setValue(this.selectedAddress);
            this.bookingForm.get('addressId')?.setValue(this.selectedAddress);
            this.auth.getAddresById(this.selectedAddress,token).subscribe({
              next: (res) => {
                if (res) {
                  this.address = res.data
                }
              },
              error: (error) => {
                console.error(error)
              }
            })
            if(this.zip === defaultAddress[0].zipCode){
              this.zipFromQuery = defaultAddress[0].zipCode
            }
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
      this.activatedRoute.queryParams.subscribe(params => {
        const zipFromQueryParam = params['zip'];
        this.zipFromQuery = parseInt(zipFromQueryParam, 10);
      })
      if(!this.zipFromQuery){
        this.booking.getZipCode().subscribe({
          next: (res:any) => {
            let mappedzips = res.data.map((zipData: any) => {
              return { ...zipData, cityZip: `${zipData.zip} ${zipData.city}` };
            });
            // const allData = mappedzips.filter((el: any) => el.erpRegion !== null);
            this.zipcodes.push(mappedzips)
          }
        })
      } 
      this.hasToken = true
      this.activatedRoute.queryParams.subscribe(params => {
        if(params['zip']){
          this.zip = params['zip']
        }
      })
      this.auth.getUser(this.userId,this.token).subscribe({
        next: (res:any) => {
          if (res) {
            this.user = res.data 
            if(this.user.clientType === 'COMPANY'){
              this.route.navigate(['/company'])
            }
          }
        }
      })
    }
    this.cleaningForm = new FormGroup({
      serviceType: new FormControl('PRIVATE', [Validators.required]),
      recurrence: new FormControl(
        this.handleReccurence(this.selectedRecurrence)
      ),
      startDate: new FormControl('', [Validators.required]),
      cleaningType: new FormControl('REGULAR'),
      propertyType: new FormControl('Apartment'),
      specialInstruction: new FormControl(null),
      addressId: new FormControl(null, [Validators.required]),
      recycling: new FormControl(this.recycling, [Validators.required]),
      materialsIncluded: new FormControl(!this.hasSupplies),
      cleaningDays: new FormArray([]),
      additionalServices: new FormGroup({
        cabinetDuration: new FormControl(0),
        cabinetRemark: new FormControl(null),
        fridge: new FormControl(false),
        oven: new FormControl(false),
        ironingDuration: new FormControl(0),
        wateringPlants: new FormControl(false)
      }),
    });

    this.expressForm = new FormGroup({
      serviceType: new FormControl('PRIVATE', [Validators.required]),
      recurrence: new FormControl('ONCE'),
      startDate: new FormControl('', [Validators.required]),
      cleaningType: new FormControl('EXPRESS'),
      propertyType: new FormControl('Apartment'),
      specialInstruction: new FormControl(null),
      addressId: new FormControl(null, [Validators.required]),
      recycling: new FormControl(this.recycling, [Validators.required]),
      materialsIncluded: new FormControl(this.hasSupplies),
      cleaningDays: new FormArray([]),
      additionalServices: new FormGroup({
        cabinetDuration: new FormControl(0),
        cabinetRemark: new FormControl(null),
        fridge: new FormControl(false),
        oven: new FormControl(false),
        ironingDuration: new FormControl(0),
        wateringPlants: new FormControl(false)
      }),
    })

    this.bookingForm = new FormGroup({
      serviceType: new FormControl('PRIVATE'),
      type: new FormControl(this.specialValue, [Validators.required]),
      appointmentDate: new FormControl('', [
        Validators.required,
      ]),
      addressId: new FormControl(null, [Validators.required]),
      timeFrame: new FormControl(null)
    });
    this.testingDays = new FormGroup({
      day: new FormControl(null),
      date: new FormControl(null),
      duration: new FormControl(3),
      timeFrame: new FormControl(null),
    });
    this.expressDays = new FormGroup({
      day: new FormControl(null),
      date: new FormControl(null),
      duration: new FormControl(3),
    })
    this.registerForm = this.fb.group({
      clientType: ['PRIVATE'],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      birthDate: [null,Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, this.swissPhoneNumberValidator()]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      newsletter: [false],
      language: ['German'],
      sex: ['', [Validators.required]],
      acceptedTermsAndConditions: [false, [Validators.required]],
      role: ['USER'],
      stv: [false],
      referralSource: ['', [Validators.required]],
      otherReferralSource: ['' || null],
      address: this.fb.group({
        street: ['',Validators.required],
        streetNr: ['',Validators.required],
        city: [this.cityFromZip, Validators.required],
        zipCode: [String(this.zip),Validators.required],
        entryCode: [null],
        entranceInstructions: [null],
        additionalInformation: [null],
        isDefaultAddress: [true],
        dog: null,
        cat: null,
        otherAnimal: null,
        catText: null,
        dogText: null,
        otherAnimalText: null
      })
    });
    this.loginForm = this.fb.group({
      email: ['',[Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    })

    if(this.selectZipFromStart && this.zipFromQuery){
      this.activatedRoute.queryParams.subscribe(params => {
        const zipFromQueryParam = params['zip'];
        const zipCode = parseInt(zipFromQueryParam, 10);
        let city
        if (zipFromQueryParam) {
          this.zipFromQuery = zipFromQueryParam
          this.zip = zipFromQueryParam
          this.booking.getZipCode().subscribe({
            next: (res:any) => {
              city = res.data.filter((e:any) => e.zip === zipCode )
              if(city.length > 0 && city[0].city){
                this.cityFromZip = city[0].city
                this.selectZipFromStart = false
              } else {
                this.selectZipFromStart = true
                this.alertService.error('Ungültige Póstleitzahl oder Ihr Gebiet ist noch nicht abgedeckt', {autoClose:true})
              }
              let mappedzips = res.data.map((zipData: any) => {
                return { ...zipData, cityZip: `${zipData.zip} ${zipData.city}` };
              });
              // const allData = mappedzips.filter((el: any) => el.erpRegion !== null);
              this.zipcodes.push(mappedzips)
            },
            error: (error:any) => {
              console.error(error)
            }
          })
        } else {
          this.selectZipFromStart = true
        }
      });
    }
  }

  /**
   * time slots for all the cleaning types
   */

  fetchTimeSlots() {
    if(this.formType.toLowerCase() === 'special_cleaning' || this.formType.toLowerCase() === 'special_services' || this.formType.toLowerCase() === 'illness_cleaning' || this.formType.toLowerCase() === 'regular'){
      this.loadingSchedule = true
      this.booking.getTimeSlots(this.zipFromQuery,'regular').subscribe({
        next: (res: any) => {  
          const data = res.data;
          this.freeTimeSlots = data.filter((item: any) => {
            return item.schedule.morning || item.schedule.afternoon;
          });
          this.filterTimeSlots();
        },
        error: (error: any) => {
          console.error('Error fetching time slots:', error);
          this.loadingSchedule = false
        },
        complete: () => {
          this.loadingSchedule = false
          if(this.formType.toLowerCase() === 'express'){
            this.filterExpressTimeSlots()
          } else {
            this.filterTimeSlots()
          }
        }
      });
    } 
    else if(this.formType.toLowerCase() === 'express'){
      this.loadingSchedule = true
      this.booking.getTimeSlots(this.zipFromQuery,'express').subscribe({
        next: (res: any) => {
          const data = res.data;
          this.expressTimeSlots = data.filter((item: any) => {
            let schedule = item.schedule;
            return schedule.some((i: any) => i.availability === true);
          });
          this.filterTimeSlots();
        },
        error: (error: any) => {
          console.error('Error fetching time slots:', error);
        },
        complete: () => {
          this.loadingSchedule = false
          if(this.formType.toLowerCase() === 'express'){
            this.filterExpressTimeSlots()
          } else {
            this.filterTimeSlots()
          }
        }
      });
    }
    else {
      if(this.token){
      this.auth.getUserAdresses().subscribe({
        next: (res: any) => {
          if (res) {
            const token = localStorage.getItem('token')
            this.userAddresses = res.data;
            const defaultAddress = res.data.filter((e:any) => e.isDefaultAddress)
            this.address = defaultAddress
            this.defaultAddress = defaultAddress
            if(this.defaultAddress[0].zipCode === this.zip){
              this.selectZipFromStart = false
              this.selectedAddress = this.defaultAddress[0].id
            }
            this.cleaningForm.get('addressId')?.setValue(this.selectedAddress);
            this.expressForm.get('addressId')?.setValue(this.selectedAddress);
            this.bookingForm.get('addressId')?.setValue(this.selectedAddress);
            this.auth.getAddresById(this.selectedAddress,token).subscribe({
              next: (res) => {
                if (res) {
                  this.address = res.data
                }
              },
              error: (error) => {
                console.error(error)
              }
            })
            if(this.zip === defaultAddress[0].zipCode){
              this.zipFromQuery = defaultAddress[0].zipCode
            }
          }
        },
        error: (err) => {
          console.log(err);
        }
      });
    }

    }
  }

  /**
   * zip code selection for the cleaning
   * @param selected 
   * @param city 
   */

  selectZipCode(selected:any, city:any){
    this.zip = selected
    this.zipForChild = selected
    this.cityFromZip = city
    let form = this.registerForm.get('address') as FormGroup
    form.get('city')?.setValue(city)
    form.get('zipCode')?.setValue(selected.toString())
    this.zipFromQuery = selected

    const storedZip = localStorage.getItem('selectedZip');
    if (!storedZip) {
      localStorage.setItem('selectedZip', selected.toString());
    }

    this.route.navigate([], {
      queryParams: {
          zip: this.zipFromQuery
      },
      queryParamsHandling: 'merge',
  });
    if(this.defaultAddress && parseInt(this.defaultAddress[0].zipCode) === this.zip){
      this.selectedAddress = this.defaultAddress[0].id
    }
    this.selectZipFromStart = false
  }

  triggerNewAddress() {
    console.log('executed from child')
  }
  

  handleFunction(emittedFunction: () => void) {
    const token = localStorage.getItem('token')
    this.auth.getUserAdressesForStandart(token).subscribe({
      next:(res:any)=>{
        // console.log(res.data)
        this.userAddresses = res.data
      }
    })
    this.triggerNewAddress = emittedFunction;
  }

  triggerTimeSlots() {
    if(this.formType.toLowerCase() !== 'special_cleaning' && this.formType.toLowerCase() !== 'special_services' && this.formType.toLowerCase() !== 'illness_cleaning') {      
      const expressDayValue = this.expressDays.get('date')
      const daysArray = this.cleaningForm.get('cleaningDays') as FormArray
      if(this.formType.toLowerCase() !== 'express' && daysArray.value <= 0){
        this.loadingTimeSlots = true;
      }
      if(this.formType.toLowerCase() === 'express' && !expressDayValue?.value){
        this.loadingTimeSlots = true;
      }
      for (const group of daysArray.controls) {
        if (group.get('timeFrame')?.value) {
          this.isShiftSelected = true;
          break;
        } else {
          this.isShiftSelected = false;
          break;
        }
      }
    } else if (this.selectedType === stepContent.individualSteps){
      this.isShiftSelected = true;
    } else if (this.formType.toLowerCase() === 'special_cleaning' && this.formType.toLowerCase() === 'special_services' && this.formType.toLowerCase() === 'illness_cleaning'){
      const date = this.bookingForm.get('appointmentDate')?.value
      const shift = this.bookingForm.get('timeFrame')?.value
      if(date && shift){
        this.isShiftSelected = true
        this.loading = false
      } else {
        this.loading = true
      }
    }
  }

  filterTimeSlots() {
    const today = new Date().setHours(0, 0, 0, 0);
    if(this.freeTimeSlots && this.freeTimeSlots.length > 0){
      const futureDays = this.freeTimeSlots
        .map((slot: any) => new Date(slot.date).setHours(0, 0, 0, 0))
        .filter((date: any) => date >= today)
        .sort((a: any, b: any) => a - b)
        .slice(0, 4);
  
      this.filteredTimeSlots = this.freeTimeSlots.filter((slot: any) =>
        futureDays.includes(new Date(slot.date).setHours(0, 0, 0, 0))
      );
    }
  }

  filterExpressTimeSlots() {
    const today = new Date().setHours(0, 0, 0, 0);
    const futureDays = this.expressTimeSlots
      .map((slot: any) => new Date(slot.date).setHours(0, 0, 0, 0))
      .filter((date: any) => date >= today)
      .sort((a: any, b: any) => a - b)
      .slice(0, 4);

    this.filteredExpressTimeSlots = this.expressTimeSlots.filter((slot: any) =>
      futureDays.includes(new Date(slot.date).setHours(0, 0, 0, 0))
    );
  }

  updateViewDate(action: any) {
    if (action) {
      this.changeMonth()
    } 
  }

  getSelectedDay(day: any) {
    
    if(this.formType.toLowerCase() === 'express'){
      let selectedDay = this.expressTimeSlots.filter((d:any) => moment(d.date).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD'))
      this.selectedExpressDate = selectedDay
      this.triggerDayLoader();
      this.expressDays.get('date')?.setValue(moment(day).format('YYYY-MM-DD'))
      this.expressDays.get('day')?.setValue(moment(day).format('dddd').toUpperCase())
      this.expressForm.get('startDate')?.setValue(moment(day).format('YYYY-MM-DD'))
      this.expressDays.get('duration')?.setValue(this.getHours());
      const cleaningDaysArray = this.expressForm.get(
        'cleaningDays'
      ) as FormArray;
      cleaningDaysArray.clear(); 
      cleaningDaysArray.push(this.expressDays);
    } 
    else if (this.selectedType === stepContent.individualSteps) {
      if(this.currentSubStep === 1) {
      this.cleaningForm.get('startDate')?.setValue(moment(day).format());
      this.loading = false
      this.changeButton = false
      this.changeToLogin = false
      this.changeToRegister = false
      this.loadingTimeSlots = false;
      this.isShiftSelected = true;
      this.isLastStep = false
      this.termsAccepted = true
      this.isAddressSelection = false
      this.triggerIndividualDays()
      }
    }
    else if(this.formType.toLowerCase() === 'special_cleaning' && this.formType.toLowerCase() === 'special_services' && this.formType.toLowerCase() === 'illness_cleaning'){
      let test = this.freeTimeSlots.filter(
        (d: any) =>
          moment(d.date).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD')
      );
      this.selectedDay = test;
      this.loadingTimeSlots = false;
      this.triggerDayLoader();
      this.displaySelectedDay = moment(day).format('ll')
      this.bookingForm.get('appointmentDate')?.setValue(moment(day).format())
    }
    else {
      let test = this.freeTimeSlots.filter(
        (d: any) =>
          moment(d.date).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD')
      );
      this.selectedDay = test;
      this.displaySelectedDay = moment(day).format('ll')
      this.loadingTimeSlots = false;
      this.triggerDayLoader();
      this.testingDays.get('date')?.setValue(moment(day).format('YYYY-MM-DD'));
      this.cleaningForm.get('startDate')?.setValue(moment(day).format('YYYY-MM-DD'))
      this.testingDays.get('day')?.setValue(moment(day).format('dddd').toUpperCase());
      this.testingDays.get('duration')?.setValue(this.getHours());
    }
  }

  selectCleaningHour(hour:any){
    this.selectedExpressHour = hour
    let dateControl = this.expressDays.get('date');
    if (dateControl) {
      let currentDate = new Date(dateControl.value);
      
      let [hourString, minuteString] = hour.split(':');
      let hours = parseInt(hourString, 10);
      let minutes = parseInt(minuteString, 10);
      
      currentDate.setHours(hours);
      currentDate.setMinutes(minutes);
  
      dateControl.setValue(moment(currentDate).format());
    }

    this.loadingTimeSlots = false
  }

  selectShift(selected: any) {
    this.selectedShift = selected;
    this.displayShift = selected
    this.testingDays.get('timeFrame')?.setValue(selected);
    this.isShiftSelected = true;
  }

  selectShiftSpecial(selected: any) {
    this.selectedShift = selected;
    this.displayShift = selected
    this.bookingForm.get('timeFrame')?.setValue(selected);
    this.isShiftSelected = true;
    this.triggerTimeSlots()
    // console.log('date', this.bookingForm.get('appointmentDate')?.value);
    
  }

  triggerDayLoader(): boolean {
    return this.loadingTimeSlots;
  }

  getHours(): number {
    let sum;
  
    if (this.extraRooms < 2) {
      sum = this.hours + this.extraHours + this.ironingHours + this.cabinetHours;
    } else {
      sum = this.extraRooms + this.extraHours + this.ironingHours + this.cabinetHours;
    }
  
    return sum;
  }

  getIndividualHours(): number {
    let sum = 0
    let sumOfServices = (this.ironingHours + this.cabinetHours) * this.cleaningDays
    const cleaningDaysControl = this.individualFormGroups;
    
    for (let i = 0; i < cleaningDaysControl.length; i++) {
      const dayFormGroup = cleaningDaysControl[i] as FormGroup;
    
      // Check if the FormGroup has the 'duration' control and add its value to the sum
      if (dayFormGroup && dayFormGroup.get('duration')) {
        sum += dayFormGroup.get('duration')?.value || 0; // Handle null or undefined values
      }
    }
    return sum + sumOfServices
  }

  handleTotalHours(): number {
    if (this.totalHours > 6) {
      alert('Total hours cannot be more than 6');
      return 6;
    } else if (this.totalHours < 2) {
      return 2;
    } else {
      return this.totalHours;
    }
  }

  /**
   * address selection
   * @param id 
   */

  selectAddress(id: string) {
    const token = localStorage.getItem('token')
    const userId = localStorage.getItem('user')
    this.bookingForm.get('addressId')?.setValue(id);
    this.auth.getAddresById(id, token).subscribe({
      next: (res) => {
        if (res) {
          this.address = res.data
        }
      },
      error: (error) => {
        console.error(error)
      }
    })
    this.selectedAddress = id;
    this.triggerSelection();
  }

  alertAddress(){
    this.alertService.info('Adresse mit ausgewählter Póstleitzahl erlaubt',{ autoClose: true })
  }

  convertInt(value:any):number{
    return parseInt(value)
  }

  selectPrivAddress(id: string) {
    const token = localStorage.getItem('token')
    const userId = localStorage.getItem('user')

    this.cleaningForm.get('addressId')?.setValue(id);
    this.auth.getAddresById(id,token).subscribe({
      next: (res) => {
        if (res) {
          this.address = res.data
        }
      },
      error: (error) => {
        console.error(error)
      }
    })
    this.selectedAddress = id;
    // console.log(this.cleaningForm.value);
    this.triggerSelection();
  }

  selectExpressAddress(id:string){
    const token = localStorage.getItem('token')
    const userId = localStorage.getItem('user')

    this.expressForm.get('addressId')?.setValue(id);
    this.auth.getAddresById(id,token).subscribe({
      next: (res) => {
        if (res) {
          this.address = res.data
        }
      },
      error: (error) => {
        console.error(error)
      }
    })
    this.selectedAddress = id;
    // console.log(this.expressForm.value);
    this.triggerSelection();
  }

  confirmModal() {
    this.displayButtons = true
    this.modalOpen = false;
    this.selectedFastestTime = '';
    if (this.formType.toLowerCase() === 'regular'){
      const cleaningDaysArray = this.cleaningForm.get(
        'cleaningDays'
      ) as FormArray;
      cleaningDaysArray.clear();
      cleaningDaysArray.push(this.testingDays);
    } else if(this.formType.toLowerCase() === 'special_cleaning' || this.formType.toLowerCase() === 'special_services' || this.formType.toLowerCase() === 'illness_cleaning'){
      let selectedDate = new Date(this.selectedDay[0].date)
      this.bookingForm.get('appointmentDate')?.setValue(selectedDate)
      this.bookingForm.get('timeFrame')?.setValue(this.selectedShift)
    }  
    this.individualCalendar = false
    this.selectedSchedule = 0
  }

  openIdividualCalendar() {
    this.individualCalendar = true
  }

  changeMonth() {
    this.viewDate = new Date(
      this.viewDate.getFullYear(),
      this.viewDate.getMonth() + 1,
      1
    );
  }

  prevMonth() {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;

    if (this.viewDate < today) {
      // console.log('Past month. Cannot navigate.');
      return;
    }

    if (this.viewDate.getMonth() === currentMonth) {
      this.viewDate = today;
    } else {
      this.viewDate = new Date(
        this.viewDate.getFullYear(),
        this.viewDate.getMonth() - 1,
        1
      );
    }
  }

  /**
   * checks if month is fully booked
   */

  checkAndUpdateViewDate() {
    const currentMonth = this.viewDate.getMonth();
    const currentYear = this.viewDate.getFullYear();
    
    const hasCurrentMonth = this.freeTimeSlots.every((date: any) => {
        const disabledDate = new Date(date.date);
        return (
            disabledDate.getMonth() !== currentMonth ||
            disabledDate.getFullYear() !== currentYear
        );
    });

    if (hasCurrentMonth && (this.formType === 'REGULAR' || this.formType.toLowerCase() === 'special_cleaning' || this.formType.toLowerCase() === 'special_services' || this.formType.toLowerCase() === 'illness_cleaning')) {
        this.changeMonth();
    }
  }

  openModal() {
    this.checkAndUpdateViewDate()
    this.displayButtons = !this.displayButtons
    this.selectedShift = ''
    this.modalOpen = !this.modalOpen;
    this.individualCalendar = false
}

  getCleaningTypes(id: number) {
    switch (id) {
      case 1:
        return (this.formType = 'EXPRESS');
      case 2:
        this.filterTimeSlots()
        return (this.formType = 'REGULAR');
      case 3:
        return (this.formType = 'SPECIAL_CLEANING');
      case 4:
        return (this.formType = 'SPECIAL_SERVICES');
      case 5:
        return (this.formType = 'ILLNESS_CLEANING');
      default:
        return (this.formType = 'SPECIAL_CLEANING');
    }
  }

  handleSpecialType(type: string): string {
    switch (type) {
      case 'WINDOW_CLEANING':
        return 'Fensterreinigung';
      case 'CLEAN_OUT':
        return 'Ausräumen';
      case 'BUY_CLEANING':
        return 'Einkaufen';
      case 'GARDEN_MAINTENANCE':
        return 'Gartenpflege';
      case 'ILLNESS_CLEANING':
        return 'Krankheit';
      case 'ACCIDENT_CLEANING':
        return 'Unfall';
      case 'PREGNANCY_CLEANING':
        return 'Schwangerschaft';
      case 'MATERNITY_CLEANING':
        return 'MUTTERSCHAFTEN';
      case 'COMPANY_CLEANING':
        return 'Firmenreinigung';
      case 'LINK_TO_RECYCLING':
        return 'Link zum Recycling';
      case 'WEEKEND_CLEANING':
        return 'Wochenende';
      case 'END_OF_TENANCY':
        return 'Umzugsreinigung';
      case 'BASIC_CLEANING':
        return 'Grundreinigung';
      default:
        return "Fensterreinigung"
  }
  }

  toggleLogin() {
    this.login = !this.login;
    if(this.login){
      this.changeToLogin = true
      this.changeToRegister = false
      this.changeButton = false
    } else {
      this.changeToRegister = true
      this.changeToLogin = false
      this.changeButton = false
    }
  }

  /**
   * time selection from calendar
   * @param selected 
   * @returns 
   */

  selectTime(selected: any) {
    this.selectedFastestTime = selected;
    this.shiftModal = true;
    this.displaySelectedDay = selected?.date
    const days = new FormGroup({
      date: new FormControl(moment(selected.date).format('YYYY-MM-DD')),
      day: new FormControl(moment(selected.date).format('dddd').toUpperCase()),
      duration: new FormControl(this.getHours()),
      timeFrame: new FormControl(''),
    });

    let start = new Date(selected.date);
    this.bookingForm.get('appointmentDate')?.setValue(moment(start).format());
    this.cleaningForm.get('startDate')?.setValue(moment(start).format());

    const cleaningDaysArray = this.cleaningForm.get(
      'cleaningDays'
    ) as FormArray;
    return days;
  }

  /**
   * shift selection 
   * @param shift 
   */

  selectClosestShift(shift: string) {
    this.selectedClosestShift = shift;
    this.displayShift = shift
    const cleaningDaysArray = this.cleaningForm.get(
      'cleaningDays'
    ) as FormArray;

    cleaningDaysArray.clear();

    const days = this.selectTime(this.selectedFastestTime);
    days.get('timeFrame')?.setValue(shift);
    cleaningDaysArray.push(days);

    this.isShiftSelected = true;
    this.loadingTimeSlots = false;
  }
  
  closeShiftModal() {
    this.shiftModal = false;
  }

  openCalc() {
    this.openCalculatorModal = !this.openCalculatorModal;
  }

  populateSteps() {
    switch (this.selectedCleaningType) {
      case 1:
        this.stepTemplates = [
          this.expressCleaning1,
          this.expressCleaning2,
          this.expressCleaning3,
          this.expressCleaning4,
          this.expressCleaning5,
          this.expressCleaning6,
        ];
        break;
      case 2:
        this.stepTemplates = [
          this.standartCleaning1,
          this.standartCleaning2,
          this.standartCleaning3,
          this.standartCleaning4,
          this.standartCleaning5,
          this.standartCleaning6,
        ];
        break;
      case 3:
        this.stepTemplates = [
          this.specialCleaning1,
          this.specialCleaning2,
          this.specialCleaning3,
        ];
        break;
      case 4:
        this.stepTemplates = [
          this.specialCleaning1,
          this.specialCleaning2,
          this.specialCleaning3,
        ];
        break;
      case 5:
        this.stepTemplates = [
          this.specialCleaning1,
          this.specialCleaning2,
          this.specialCleaning3,
        ];
        break;
      default:
        this.stepTemplates = [
          this.standartCleaning1,
          this.standartCleaning2,
          this.standartCleaning3,
          this.standartCleaning4,
          this.standartCleaning5,
          this.standartCleaning6,
        ];
    }
  }

  checkLastStep(): boolean {
    return this.isLastStep;
  }

  /**
   * confirms regular/individual/express cleanings
   * @param form : express of regular
   */
  confirmCleaning(form:any) {
    this.confirm = true;
    let url = '';
    if (this.confirm) {
      this.redirecting = true;
      if(this.selectedType === stepContent.standartSteps || this.selectedType === stepContent.individualSteps){
        // this.enableConfirmButton = true
        // TODO test with Meka
        if(this.user.paymentType === null) {
          if(this.paymentMethod.valid) {
            let newUserCleaningFormObj = {
              ...this.cleaningForm?.value,
              paymentMethod: this.paymentMethod.value,
            }
            this.booking.createCleaning(newUserCleaningFormObj, this.token).subscribe({
              next: (res: any) => {
                url = res.data.url;
                this.loading = false;
              },
              error: (err) => {
                this.alertService.success('Etwas ist schief gelaufen',{ autoClose: true })
                console.log(err);
                this.loading = true;
              },
              complete: () => {
                this.alertService.success('Sie haben erfolgreich eine Reinigung erstellt',{ autoClose: true })
                if (url) {
                  window.location.href = url;
                } else {
                  this.route.navigate(['success']);
                }
              },
            });
          } else {
            // console.log('please select payment method');
            this.enableConfirmButton = true
          }
        } else {
          this.booking.createCleaning(this.cleaningForm?.value, this.token).subscribe({
            next: (res: any) => {
              url = res.data.url;
              this.loading = false;
            },
            error: (err) => {
              this.alertService.success('Etwas ist schief gelaufen',{ autoClose: true })
              console.log(err);
              this.loading = true;
            },
            complete: () => {
              this.alertService.success('Sie haben erfolgreich eine Reinigung erstellt',{ autoClose: true })
              if (url) {
                window.location.href = url;
              } else {
                this.route.navigate(['success']);
              }
            },
          });
        }
      } 
      else if(this.selectedType === stepContent.expressSteps){
        this.booking.createCleaning(form, this.token).subscribe({
          next: (res: any) => {
            url = res.data.url;
            this.loading = false;
          },
          error: (err) => {
            console.log(err);
            this.loading = true;
          },
          complete: () => {
            if (url) {
              window.location.href = url;
            } else {
              this.route.navigate(['success']);
            }
          },
        });
      }
    }
  }

  triggerConfirm() {
    this.confirm = false;
  }

  getAddressForm(value:any){
    this.addressForm = value
  }

  //Event Handlers from modal
  onIroningAddedChange(value: any) {
    const service = this.secondStepCards.find((n) => n.value === 'ironingDuration');
    if(this.formType.toLowerCase() === 'express'){
      const recurrence = this.expressForm.get('recurrence')?.value
      this.pricing.fetchPrice(this.getHours(), recurrence, false, 'PRIVATE');
    } else if(this.selectedType === stepContent.individualSteps) {
      const recurrence = this.cleaningForm.get('recurrence')?.value
      this.pricing.fetchPrice(this.getIndividualHours(), recurrence, false, 'PRIVATE');
    } 
    else {
      const recurrence = this.cleaningForm.get('recurrence')?.value
      this.pricing.fetchPrice(this.getHours(), recurrence, false, 'PRIVATE');
    }
    if (value) {
      this.serviceExtras.push(service);
      this.closeModal();
    } else {
      const index = this.serviceExtras.findIndex((n) => n.value === 'ironingDuration');
      if (index !== -1) {
        this.serviceExtras.splice(index, 1);
      }
      this.closeModal();
    }
  }

  onCabinetsAddedChange(value: any) {
    if(this.formType.toLowerCase() === 'express'){
      const recurrence = this.expressForm.get('recurrence')?.value
      this.pricing.fetchPrice(this.getHours(), recurrence, false, 'PRIVATE');
    } 
    else if(this.selectedType === stepContent.individualSteps) {
      const recurrence = this.cleaningForm.get('recurrence')?.value
      this.pricing.fetchPrice(this.getIndividualHours(), recurrence, false, 'PRIVATE');
    }  
    else {
      const recurrence = this.cleaningForm.get('recurrence')?.value
      this.pricing.fetchPrice(this.getHours(), recurrence, false, 'PRIVATE');
    }
    const service = this.secondStepCards.find((n) => n.value === 'cabinetDuration');
    if (value) {
      this.serviceExtras.push(service);
      this.closeModal();
    } else {
      const index = this.serviceExtras.findIndex((n) => n.value === 'cabinetDuration');
      if (index !== -1) {
        this.serviceExtras.splice(index, 1);
      }
      // if(this.selectedType === stepContent.individualSteps){
      //   let individual = this.cleaningForm.get('additionalServices') as FormGroup
      //   individual.push(this.serviceExtras)
      // }
      this.closeModal();
    }
  }

  ironingDurationHandler(value: any) {
    this.ironingHours = value;
    let additionalServices
    if(this.formType.toLowerCase() === 'express'){
      additionalServices = this.expressForm.get('additionalServices') as FormGroup;
    } else {
      additionalServices = this.cleaningForm.get('additionalServices') as FormGroup;
    }
    additionalServices.get('ironingDuration')?.setValue(value * 60);
    this.updatePrice()

  }

  cabinetDurationHandler(value: any) {
    this.cabinetHours = value;
    let additionalServices
    if(this.formType.toLowerCase() === 'express'){
      additionalServices = this.expressForm.get('additionalServices') as FormGroup;
    } else {
      additionalServices = this.cleaningForm.get('additionalServices') as FormGroup;
    }
    additionalServices.get('cabinetDuration')?.setValue(value * 60);
    this.updatePrice()
    // console.log(this.cleaningForm.value)
  }

  onWindowsAddedChange(value: any) {
    const service = this.secondStepCards.find((n) => n.value === 'windows');
    if (value) {
      this.serviceExtras.push(service);
      this.closeModal();
    } else {
      const index = this.serviceExtras.findIndex((n) => n.value === 'windows');
      if (index !== -1) {
        this.serviceExtras.splice(index, 1);
      }
      this.closeModal();
    }
  }

  onBedRoomsChange(value: any) {
    this.bedrooms = value;
  }

  onBathRoomsChange(value: any) {
    this.bathrooms = value;
  }

  onCommentAdd(value: string) {
    this.commentValue = value
    if(this.formType.toLowerCase() === 'express'){
      this.expressForm.get('specialInstruction')?.setValue(value)
    } else{
      this.cleaningForm.get('specialInstruction')?.setValue(value);
    }

    this.closeModal();
  }
  
  cabinetRemarks(value: string) {
    let additionalServices
    if(this.formType.toLowerCase() === 'express'){
      additionalServices = this.expressForm.get('additionalServices') as FormGroup
    } else if(this.formType.toLowerCase() === 'regular') {
      additionalServices = this.cleaningForm.get('additionalServices') as FormGroup
    } else {
      additionalServices = this.bookingForm.get('additionalServices') as FormGroup
    }
    additionalServices.get('cabinetRemark')?.setValue(value);
    this.closeModal();
  }

  getRemark():string{
    let additionalServices
    if(this.formType.toLowerCase() === 'express'){
      additionalServices = this.expressForm.get('additionalServices') as FormGroup
    } else if(this.formType.toLowerCase() === 'regular') {
      additionalServices = this.cleaningForm.get('additionalServices') as FormGroup
    } else {
      additionalServices = this.bookingForm.get('additionalServices') as FormGroup
    }
    return additionalServices.get('cabinetRemark')?.value
  }

  confirmRooms() {
    this.extraRooms = this.bedrooms + this.bathrooms / 2;
    this.hours = 0
    // console.log('extrarooms',this.extraRooms)
    // console.log('hours',this.hours)
    this.closeModal();
  }

  /**
   * fetch pricing for all cleanings
   */

  updatePrice() {
    if(this.formType.toLowerCase() === 'express'){
      const recurrence = this.expressForm.get('recurrence')?.value
      this.pricing.fetchPrice(this.getHours(), recurrence, false, 'PRIVATE');
    }
    else if(this.selectedType === stepContent.individualSteps){
      this.pricing.fetchPrice(this.getIndividualHours(), 'MORE_OFTEN', false, 'PRIVATE');
    } 
    else {
      const recurrence = this.cleaningForm.get('recurrence')?.value
      this.pricing.fetchPrice(this.getHours(), recurrence, false, 'PRIVATE');
    }

    this.pricing.price$.subscribe((price) => {
      this.price = price;
    });
  }

  checkExceedExpress(time:any):boolean{
    if(Number(time) + this.getHours() > 18){
      return false
    } else {
      return true
    }
  }

  triggerTerms(){
    this.termsAccepted = false
    this.enableConfirmButton = false
  }

  toggleTerms(){
    this.termsAccepted = !this.termsAccepted
    this.cdr.detectChanges()
  }

  openFile() {
    window.open("assets/terms/AGB_Pebra_Reinigung_Schweiz_AG_2024.pdf")
  }

  /**
   * checks materials
   * @returns 
   */

  checkSuppliesStatus():boolean{
    if(this.currentStep === 4){
      if(this.suppliesState !== 'default'){
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  /**
   * stepper interaction handler
   * @param name : next/back
   */

  stepButton(name: string) {
    const currentStepObject = this.selectedType.find(
      (step) => step.id === this.currentStep
    );

    this.gaService.event('stepper_interaction', {
      event_category: 'navigation',
      event_label: `CleaningType ${this.formType} Step ${this.currentStep}, Sub-step ${this.currentSubStep}, Action: ${name}`,
    });

    this.route.navigate([], {
      queryParams: {
          type: this.formType.toLocaleLowerCase(),
          step: this.currentStep,
      },
      queryParamsHandling: 'merge',
    });

    if (name === 'next') {
      if(this.currentStep === 1 && this.currentSubStep === 3){
        this.updatePrice()
      }
      if(this.formType.toLocaleLowerCase() === 'express' && this.currentStep === 2 && this.currentSubStep ===1) {
          this.loadingTimeSlots = true
          this.selectedExpressDate = undefined
      }
      if(this.getHours() <= 6){
        if (this.currentStep === 2 && this.selectedType === stepContent.standartSteps) {
          this.isShiftSelected = false
          this.fetchTimeSlots()
          this.triggerTimeSlots();
        }
        if (this.currentStep === 2 && this.selectedType === stepContent.expressSteps) {
          this.fetchTimeSlots()
          this.triggerTimeSlots();
        }
        if (this.currentStep === 1 && this.currentSubStep === 4 && this.selectedType === stepContent.individualSteps){
          this.isShiftSelected = false
          this.fetchTimeSlots()
        }
        if (this.currentStep === 1 && this.currentSubStep === 3 && this.selectedType === stepContent.specialCleaningSteps){
          this.isShiftSelected = false
          this.fetchTimeSlots()
          this.triggerTimeSlots();
        }
        if (this.selectedType === stepContent.specialCleaningSteps) {
          if (
            this.currentStep === this.selectedType.length - 3 &&
            this.currentSubStep === 3
          ) {
            this.triggerTimeSlots();
            this.fetchTimeSlots()
          }
        }
        else if (this.selectedType === stepContent.individualSteps) {
          if (
            this.currentStep === this.selectedType.length - 1 &&
            this.selectedType === stepContent.individualSteps
          ) {
            const cleaningDaysControl = this.cleaningForm.get(
              'cleaningDays'
            ) as FormArray;
            cleaningDaysControl.clear();
            this.formArray.controls.forEach((formGroup: any) => {
              const clonedFormGroup = this.fb.group(formGroup.value);
              cleaningDaysControl.push(clonedFormGroup);
            });
            this.triggerSelection();
          }
          else if (this.currentStep === 1 && this.currentSubStep === 4 && this.selectedType === stepContent.individualSteps){
            const startDate = this.cleaningForm.get('startDate')?.value
            if(!startDate){
              this.loading = true
            }
          }
        } 
        else {
          if (
            this.currentStep === this.selectedType.length - 1 &&
            this.selectedType === stepContent.standartSteps
          ) {
            this.triggerSelection();
          }
        }
  
        if (this.currentStep === this.selectedType.length - 1 && this.currentSubStep === 2 && this.token && this.selectedType === stepContent.expressSteps) {
          this.isAddressSelection = true;
  
          if (!this.selectedAddress) {
            this.triggerSelection();
          } else {
            this.triggerSelection();
          }
        }
        if (this.isAddressSelection && this.selectedAddress && this.token) {
          this.loading = true;
          if (this.selectedType === stepContent.specialCleaningSteps) {
              this.triggerConfirm();
              this.triggerTerms()
              if (this.currentStep === this.selectedType.length && this.currentSubStep === 1) {
                this.isLastStep = true;
              }
              if (this.currentStep === this.selectedType.length && this.currentSubStep === 2 && this.token) {
                const token = localStorage.getItem('token')
                
                let bookingObj = {}
                if(this.correctAnswer) {
                  bookingObj = {
                    ...this.bookingForm.value,
                    couponCode: this.discountForm.get('couponCode')?.value
                  }
                }else {
                  bookingObj = {
                    ...this.bookingForm.value
                  }
                }
                this.booking.requestBooking(bookingObj,token).subscribe({
                  next: (res: any) => {
                    this.loading = false;
                  },
                  error: (err) => {
                    this.alertService.error(err, { autoClose: true })
                    this.loading = true;
                  },
                  complete: () => {
                    this.alertService.success('Buchungsreinigung erfolgreich', { autoClose: true })
                    this.route.navigate(['success'])
                  },
            });
              } 
              else {
                this.triggerSelection();
                if (currentStepObject?.subSteps && this.currentSubStep < currentStepObject.subSteps.length) {
                  this.currentSubStep += 1;
                } 
                else {
                  if (this.currentStep < this.selectedType.length) {
                    this.currentStep += 1;
                    if(this.step < this.currentStep){
                      this.step += 1;
                    }
                    this.currentSubStep = 1;
  
                    if (this.currentStep === this.selectedType.length - 1 &&this.currentSubStep === 1 &&this.token
                    ) {
                      this.isAddressSelection = true;
    
                      if (!this.selectedAddress) {
                        this.triggerSelection();
                      } else {
                        this.triggerSelection();
                      }
                    }
                  }
                }
              }
            
          } 
          else if (this.selectedType === stepContent.standartSteps || this.selectedType === stepContent.individualSteps) {
            this.triggerConfirm();
            this.triggerTerms()
            let url: string = '';
            if (
              this.currentStep === this.selectedType.length &&
              (this.currentSubStep === 1)
            ) {
              this.isLastStep = true;
            }
            if (
              this.currentStep === this.selectedType.length &&
              this.currentSubStep === 2 &&
              this.token
            ) {
              this.confirmCleaning(this.cleaningForm);
            } else {
              this.triggerSelection();
              if (
                currentStepObject?.subSteps &&
                this.currentSubStep < currentStepObject.subSteps.length
              ) {
                this.currentSubStep += 1;
              } else {
                if (this.currentStep < this.selectedType.length) {
                  this.currentStep += 1;
                  if(this.step < this.currentStep){
                    this.step += 1;
                  }
                  this.currentSubStep = 1;
  
                  if (
                    this.currentStep === this.selectedType.length - 1 &&
                    this.currentSubStep === 1 &&
                    this.token
                  ) {
                    this.isAddressSelection = true;
  
                    if (!this.selectedAddress) {
                      this.triggerSelection();
                    } else {
                      this.triggerSelection();
                    }
                  }
                }
              }
            }
          }
          // TODO: HERE FOR EXPRESS
          else if (this.selectedType === stepContent.expressSteps) {
            this.triggerConfirm();
            this.triggerTerms()
            let url: string = '';
            if (
              this.currentStep === this.selectedType.length &&
              this.currentSubStep === 1
            ) {
              this.isLastStep = true;
            }
            if (
              this.currentStep === this.selectedType.length &&
              this.currentSubStep === 2 &&
              this.token
            ) {
              let expressObj = {}
              if(this.correctAnswer) {
                expressObj = {
                  ...this.expressForm.value,
                  couponCode: this.discountForm.get('couponCode')?.value
                }
              }else {
                expressObj = {
                  ...this.expressForm.value
                }
              }
              this.confirmCleaning(expressObj);
            } 
            else {
              this.triggerSelection();
              if (currentStepObject?.subSteps && this.currentSubStep < currentStepObject.subSteps.length) {
                this.currentSubStep += 1;
              } 
              else {
                if (this.currentStep < this.selectedType.length) {
                  this.currentStep += 1;
                  if(this.step < this.currentStep){
                    this.step += 1;
                  }
                  this.currentSubStep = 1;
  
                  if (
                    this.currentStep === this.selectedType.length - 1 &&
                    this.currentSubStep === 1 &&
                    this.token
                  ) {
                    this.isAddressSelection = true;
  
                    if (!this.selectedAddress) {
                      this.triggerSelection();
                    } else {
                      this.triggerSelection();
                    }
                  }
                }
              }
            }
          }
        } 
        else if (this.isAddressSelection && !this.token && this.checkLastStep()) {
          this.loading = true;
        } 
        else {
          if (
            currentStepObject?.subSteps &&
            this.currentSubStep < currentStepObject.subSteps.length
          ) {
            this.currentSubStep += 1;
          } else {
            if (this.currentStep < this.selectedType.length) {
              this.currentStep += 1;
              if(this.step < this.currentStep){
                this.step += 1;
              }
              this.currentSubStep = 1;
  
              if (this.selectedType === stepContent.specialCleaningSteps) {
                if (
                  this.currentStep === this.selectedType.length &&
                  this.currentSubStep === 1
                ) {
                  this.isAddressSelection = true;
                  if(!this.token){
                    this.changeButton = true
                  }
                  this.triggerSelection()
                }
              } 
              else if (this.selectedType === stepContent.standartSteps || this.selectedType === stepContent.individualSteps) {
                if (
                  this.currentStep === this.selectedType.length &&
                  this.currentSubStep === 1
                ) {
                  this.isAddressSelection = true;
                  if(!this.token){
                    this.changeButton = true
                    this.triggerSelection()
                  }
  
                }
              }
              else if (this.selectedType === stepContent.expressSteps) {
                if (
                  this.currentStep === this.selectedType.length &&
                  this.currentSubStep === 1
                ) {
                  if(!this.token){
                    this.changeButton = true
                  }
                  this.isAddressSelection = true;
                  this.triggerSelection()
  
                }
              }
            }
          }
        }
      }
      else {
        this.alertService.error('Die Reinigung darf nicht länger als 6 Stunden dauern.',{ autoClose: true })
      }
      
    }
    else if (name === 'back') {
      this.changeButton = false
      this.changeToLogin = false
      this.changeToRegister = false
      this.loading = false;
      this.loadingTimeSlots = false;
      this.isShiftSelected = true;
      this.isLastStep = false
      this.termsAccepted = true
  
      if(this.currentStep === 6 && this.currentSubStep === 2) {
        this.isAddressSelection = true
      } else if (this.selectedType === stepContent.specialCleaningSteps && this.currentStep === 3 && this.currentSubStep === 2) {
        this.isAddressSelection = true
      } else {
        this.isAddressSelection = false
      }

      if(this.currentStep === 1 && this.currentSubStep === 3){
        if(this.getHours() > 6){
          this.serviceExtras = []
          this.ironingHours = 0 
          this.cabinetHours = 0
          this.extraRooms = 2
          this.hours = 0
          this.extraHours = 0
        }
      }
      if (this.currentSubStep > 1) {
        this.currentSubStep -= 1;
        this.step -= 1      } else {
        if (this.currentStep > 1) {
          this.currentStep -= 1;
          const previousStep = this.selectedType.find(
            (step) => step.id === this.currentStep
          );
          if (previousStep?.subSteps) {
            this.currentSubStep = previousStep.subSteps.length;
          }
        }
      }
    }
  }

  loginFunction() {
    if(this.loginForm && this.loginForm.valid){

      this.auth.login(this.loginForm).subscribe({
        next: (res: any) => {
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('user', res.data.id);
          this.user = res.data
        },
        error: (err: any) => {
          this.loading = true;
          if(err.error.error === "You must accept the terms and conditions to proceed.") {
            this.handlecloseTerms(true)
          }
          this.alertService.error(err.error.error,{autoClose: true})
        },
        complete: () => {
          if(this.user.clientType === 'COMPANY'){
            // this.login = !this.login
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.alertService.error('Sie sollten ein Privat erstellen',{ autoClose: true})
          } else {
            const token = localStorage.getItem('token');
            this.changeButton = false
            this.changeToLogin = false
            this.changeToRegister = false
            this.hasToken = true
            this.token = token;
            this.auth.getUserAdressesForStandart(token).subscribe({
              next: (res: any) => {
                if (res) {
                  this.userAddresses = res.data;
                  const defaultAddress = res.data.filter((e:any) => e.isDefaultAddress)
                  this.address = defaultAddress
                  this.defaultAddress = defaultAddress
                  this.selectedAddress = this.defaultAddress[0].id
                  this.bookingForm.get('addressId')?.setValue(res.data.address.id);
                  this.cleaningForm.get('addressId')?.setValue(res.data.address.id);
                  this.expressForm.get('addressId')?.setValue(res.data.address.id);
                }
              },
              error: (err) => {
                console.log(err);
              },
              complete: () => {
                this.triggerSelection()
              }
            });
          }
        },
        
      });
    } else {
      this.alertService.error('Bitte füllen Sie die Felder aus', { autoClose: true } )
     
    }
  }

  checkPass(pass:any, confirmation:string):boolean{
    return pass === confirmation
  }

  onConfirmPasswordChange(event:any){
    this.confirmPass = event
  }
  
  onConfirmationChange(confirmation: any) {
    this.cdr.detectChanges();
    this.confirmPass = confirmation
  }

  registerFunction() {
    let form = this.registerForm.get('address') as FormGroup
    form.get('city')?.setValue(this.cityFromZip)
    if(this.registerForm && this.registerForm.valid){
      const pass = this.registerForm.get('password')?.value
      if(this.checkPass(pass, this.confirmPass)){
        this.regLoading = true
        this.auth.register(this.registerForm).subscribe({
          next: (res: any) => {
            this.user = res.data
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('user', res.data.id);
          },
          error: (err: any) => {
            console.log(err);
            this.alertService.error(err.error.error,{autoClose: true})
            this.loading = true;
            this.regLoading = false
          },
          complete: () => {
            const token = localStorage.getItem('token');
            this.token = token;
            this.changeButton = false
            this.changeToLogin = false
            this.changeToRegister = false
            this.regLoading = false
            
  
            this.auth.getUserAdressesForStandart(token).subscribe({
              next: (res: any) => {
                if (res) {
                  this.userAddresses = res.data;
                  const defaultAddress = res.data.filter((e:any) => e.isDefaultAddress)
                  this.address = defaultAddress
                  this.defaultAddress = defaultAddress
                  this.selectedAddress = this.defaultAddress[0].id
                  this.bookingForm.get('addressId')?.setValue(res.data.address.id);
                  this.cleaningForm.get('addressId')?.setValue(res.data.address.id);
                  this.expressForm.get('addressId')?.setValue(res.data.address.id);
                }
              },
              error: (err) => {
                console.log(err);
              },
              complete: () => {
                this.triggerSelection()
              }
            });
          },
        });
      } else {
        this.alertService.error('Passwörter stimmen nicht überein', { autoClose:true })
        this.registerForm.get('password')?.setValue('')
      }

    } else {
      this.registerForm.markAllAsTouched()
      // console.log(this.registerForm.value);
      
      this.alertService.error('Bitte füllen Sie die Pflichtfelder aus', {autoClose: true})
    }
  }

  toggleAcceptedTerms(accepted: boolean) {
    this.hasAcceptedTerms = accepted
    this.registerForm.get('acceptedTermsAndConditions')?.setValue(accepted)
  }

  markAllControlsAsTouched() {
    this.registerForm.markAllAsTouched();
  }

  triggerSelection() {
    const token = localStorage.getItem('token')

    if(this.defaultAddress){
      this.address = this.defaultAddress
    }
    if(this.selectedAddress){
      this.cleaningForm.get('addressId')?.setValue(this.selectedAddress);
      this.expressForm.get('addressId')?.setValue(this.selectedAddress);
      this.bookingForm.get('addressId')?.setValue(this.selectedAddress)
      this.auth.getAddresById(this.selectedAddress, token).subscribe({
        next: (res) => {
          if (res) {
            this.address = res.data
          }
        },
        error: (error) => {
          console.error(error)
        }
      })
    }
    if (!this.selectedAddress && !this.selectedShift) {
      this.loading = true;
    } else {
      this.loading = false;
    }
  }

  /**
   * check if next is disabled without filling required forms
   * @returns 
   */

  isNextDisabled(): boolean {
    const currentStepObject = this.selectedType.find(
      (step) => step.id === this.currentStep
    );
    if (
      this.loading ||
      this.triggerDayLoader() ||
      (!this.isShiftSelected && this.formType.toLowerCase() === 'regular' ) ||
      (!this.isShiftSelected && this.formType.toLowerCase() === 'special_cleaning' ) ||
      (!this.isShiftSelected && this.formType.toLowerCase() === 'special_services' ) ||
      (!this.isShiftSelected && this.formType.toLowerCase() === 'illness_cleaning' ) ||
      this.redirecting || 
      this.selectZipFromStart ||
      (!this.triggerIndividualDays() && this.selectedType === stepContent.individualSteps && this.currentSubStep === 4) ||
      this.checkSuppliesStatus() ||
      (!this.termsAccepted && !this.enableConfirmButton)
    ) {
      return true;
    } else {
      if (
        currentStepObject?.id === this.selectedType.length &&
        this.currentSubStep === 2 &&
        !this.token
      ) {
        return true;
      } else {
        if (
          currentStepObject?.subSteps &&
          currentStepObject?.id === this.selectedType.length
        ) {
          return this.currentSubStep === currentStepObject.subSteps.length;
        } else {
          return this.currentStep === this.selectedType.length;
        }
      }
    }
  }

  handleReccurence(recurrence: any) {
    switch (recurrence) {
      case 1:
        return 'WEEKLY';
      case 2:
        return 'BI_WEEKLY';
      case 3:
        return 'MONTHLY';
      case 4:
        return 'MORE_OFTEN';
      default:
        return 'WEEKLY';
    }
  }

/**
 * handles recurrence selection
 * @param second 
 * @param value 
 */

  selectRecurrence(second: number, value: any) {
    this.selectedRecurrence = second;
    if (second === 4) {
      this.extraHours = 0
      this.serviceExtras = []
      this.selectedType = stepContent.individualSteps;
      this.stepTemplates = [
        this.individualCleaning1,
        this.individualCleaning2,
        this.individualCleaning3,
        this.individualCleaning4,
        this.individualCleaning5,
        this.individualCleaning6,
      ];
      this.step = 2
    } else {
      this.selectedType = stepContent.standartSteps;
      this.stepTemplates = [
        this.standartCleaning1,
        this.standartCleaning2,
        this.standartCleaning3,
        this.standartCleaning4,
        this.standartCleaning5,
        this.standartCleaning6,
      ];
    }
    this.cleaningForm.get('recurrence')?.setValue(value);
  }

  summaryText(service:any):string{
    switch (service) {
      case "cabinetDuration":
        return "Schränke"
      case "fridge":
        return "Kühlschrank"
      case "ironingDuration":
        return "Bügeln"
      case "oven":
        return "Backofen"
      case "wateringPlants":
        return "Pflanzen giessen"
      default:
        return ""
    }
  }

  /**
   * cleaning type handler
   * @param selected 
   * @param name 
   */

  selectCleaningType(selected: number, name: string) {
    let pending = true
    this.selectedCleaningType = selected;
    this.discountForm.get('couponCode')?.setValue('')
    switch (selected) {
      case 1:
        this.selectedType = stepContent.expressSteps;
        this.displayPrice = true;
        this.step = 1
        this.displayPriceChange.emit(this.displayPrice);
        break;
      case 2:
        this.selectedType = stepContent.standartSteps;
        this.displayPrice = true;
        this.step = 1
        this.displayPriceChange.emit(this.displayPrice);
        break;
      case 3:
        this.selectedType = stepContent.specialCleaningSteps;
        this.displayPrice = false;
        this.step = 1
        this.selectSpecialType(2, 'END_OF_TENANCY')
        this.displayPriceChange.emit(this.displayPrice);
    break;
    case 4:
        this.selectedType = stepContent.specialCleaningSteps;
        this.displayPrice = false;
        this.step = 1
        this.selectSpecialType(2, 'BUY_CLEANING')
        this.displayPriceChange.emit(this.displayPrice);
      break;
      case 5:
        this.selectedType = stepContent.specialCleaningSteps;
        this.selectSpecialType(2, 'ACCIDENT_CLEANING')
        this.displayPrice = false;
        this.step = 1
        this.displayPriceChange.emit(this.displayPrice);
        break;
      default:
        this.selectedType = stepContent.standartSteps;
        this.step = 1
    }
    this.populateSteps();
    this.cleaningForm.get('cleaningType')?.setValue(this.getCleaningTypes(selected));
    this.getCleaningTypes(selected)
  }

  closeModal() {
    this.openWindowModal = false;
    this.openCalculatorModal = false;
    this.openIroningModal = false;
    this.openCommentModal = false;
    this.openCabinetsModal = false;
  }

  openAddComment() {
    this.openCommentModal = !this.openCommentModal;
  }

  // private cleaning functions
  updateSelectedDay(index: number, selectedDay: string) {
    this.testingson[index].day = selectedDay;
    this.days.filter((e) => e === selectedDay);
    const formGroup = this.formArray.at(index) as FormGroup;
    formGroup.patchValue({ day: selectedDay });
    this.triggerIndividualDays()
  }

  triggerIndividualDays():boolean{
    let days:string[] = []
    this.testingson.filter((d:any) => {
      days.push(d.day)
    })
    if(days.some((e:any) => e === '')){
      return false
    } else {
      return true
    }
  } 

  openMaterials(){
    this.materialModal = true
  }

  closeMaterials(){
    this.materialModal = false
  }

  /**
   * handles pricing based on supplies
   * @param selectedValue 
   * @returns 
   */

  onSupplyChange(selectedValue: any) {
    this.hasSupplies = selectedValue;
    if(selectedValue){
      this.suppliesState = 'true'
      this.displayWarning = false
    } else {
      this.suppliesState = 'false'
      this.displayWarning = true
    }
    if(this.selectedType === stepContent.individualSteps){
      this.cleaningForm.get('materialsIncluded')?.setValue(!this.hasSupplies);
      this.pricing.fetchPrice(this.getIndividualHours(), 'MORE_OFTEN', false, 'PRIVATE');
    } else {
      if(this.formType.toLowerCase() === 'express'){
        this.expressForm.get('materialsIncluded')?.setValue(!this.hasSupplies);
        this.pricing.fetchPrice(this.getHours(), 'ONCE', !this.hasSupplies, 'PRIVATE');
      } else {
        this.cleaningForm.get('materialsIncluded')?.setValue(!this.hasSupplies);
        if(this.formType.toLowerCase() === 'express'){
          const recurrence = this.expressForm.get('recurrence')?.value
          this.pricing.fetchPrice(this.getHours(), recurrence, !this.hasSupplies, 'PRIVATE');
        } else {
          const recurrence = this.cleaningForm.get('recurrence')?.value
          this.pricing.fetchPrice(this.getHours(), recurrence, false, 'PRIVATE');
        }
      }
    }
    return this.hasSupplies;
  }

  handleRecycling(selectedValue: any) {
    this.recycling = selectedValue;
    if(this.formType.toLowerCase() === 'express'){
    this.expressForm.get('recycling')?.setValue(this.recycling);
    } else {
      this.cleaningForm.get('recycling')?.setValue(this.recycling);
    }
    return this.recycling;
  }

  setHours(action: string) {
    if (action === 'increase') {
      if (this.getHours() < 6) {  
        this.extraRooms += 0.5;
        this.updatePrice()
      }
    }
    if (action === 'decrease') {
      if (this.getHours() > 2 && this.extraRooms > 2) {
        this.extraRooms -= 0.5;
        this.updatePrice()
      }
    }
  }

  checkSelectedExtra(service: string): boolean {
    const isSelected = this.serviceExtras.some((sc) => sc.value === service);
    let additionalServices
    if(this.formType.toLowerCase() === 'express'){
      additionalServices = this.expressForm.get('additionalServices') as FormGroup;
    } else {
      additionalServices = this.cleaningForm.get('additionalServices') as FormGroup;
    }
    additionalServices.get(service)?.setValue(isSelected);
    return isSelected;
  }

  deleteIroning(){
    let type = this.formType.toLowerCase()
    let additionalServices
    switch (type) {
      case 'express':
      additionalServices = this.expressForm.get('additionalServices') as FormGroup
      break;
      case 'regular':
      additionalServices = this.cleaningForm.get('additionalServices') as FormGroup
      break;
      case 'individual':
      additionalServices = this.cleaningForm.get('additionalServices') as FormGroup
      break;
      case 'special_cleaning':
      additionalServices = this.bookingForm.get('additionalServices') as FormGroup
      break;
      case 'special_services':
      additionalServices = this.bookingForm.get('additionalServices') as FormGroup
      break;
      case 'illness_cleaning':
      additionalServices = this.bookingForm.get('additionalServices') as FormGroup
      break;
      default:
      break;
    }
    additionalServices?.get('ironingDuration')?.setValue(0)
  }

  deleteCabinets(){
    let type = this.formType.toLowerCase()
    let additionalServices
    switch (type) {
      case 'express':
      additionalServices = this.expressForm.get('additionalServices') as FormGroup
      break;
      case 'regular':
      additionalServices = this.cleaningForm.get('additionalServices') as FormGroup

      break;
      case 'individual':
      additionalServices = this.cleaningForm.get('additionalServices') as FormGroup
      break;
      case 'special_cleaning':
      additionalServices = this.bookingForm.get('additionalServices') as FormGroup
      break;
      case 'special_services':
      additionalServices = this.bookingForm.get('additionalServices') as FormGroup
      break;
      case 'illness_cleaning':
      additionalServices = this.bookingForm.get('additionalServices') as FormGroup
      break;
      default:
      break;
    }
    additionalServices?.get('cabinetDuration')?.setValue(0)
    additionalServices?.get('cabinetRemark')?.setValue(null)
  }

  selectExtraService(item: any) {
    const index = this.serviceExtras.indexOf(item);
    if (index != -1) {
      if (item.value === 'ironingDuration') {
        this.openIroningModal = true;
      } 
      else if (item.value === 'cabinetDuration'){
        this.openCabinetsModal = true;
      }
      else {
        const removedItem = this.serviceExtras.splice(index, 1)[0];
        this.extraHours -= removedItem.duration;
        if(this.formType.toLowerCase() === 'express'){
          this.expressForm.get(item.value)?.setValue(false)
        } else {
          this.cleaningForm.get(item.value)?.setValue(false);
          if(this.selectedType === stepContent.individualSteps){          
            const cleaningDaysArray = this.formArray;
            cleaningDaysArray.controls.forEach((dayGroup: any) => {
              let durations = dayGroup.get('duration')
              dayGroup.get('duration').setValue(durations.value -= 0.5);
            });
          }
        }
      }
    } 
    else {
      if (item.value === 'ironingDuration') {
        this.openIroningModal = true;
      } else if (item.value === 'cabinetDuration'){
        this.openCabinetsModal = true;
      }
       else {
        this.serviceExtras.push(item);
        this.extraHours += item.duration;
        if(this.formType.toLowerCase() === 'express'){
          this.expressForm.get(item.value)?.setValue(true);
        } else {
          this.cleaningForm.get(item.value)?.setValue(true);
          if(this.selectedType === stepContent.individualSteps){          
            const cleaningDaysArray = this.formArray;        
            cleaningDaysArray.controls.forEach((dayGroup: any) => {
              let durations = dayGroup.get('duration')
              dayGroup.get('duration').setValue(durations.value += 0.5);
            });
          }
        }
      }
    }
    if(item.value === 'ironingDuration' || item.value === 'cabinetDuration') {
      this.checkSelectedExtra(item.duration);
      this.updatePrice()
    } else {
      this.checkSelectedExtra(item.value);
      this.updatePrice()
    }
  }

  getDays() {
    const filteredDays = this.days.filter((day) => {
      return !this.testingson.map((d) => d.day).includes(day);
    });
    return filteredDays;
  }

  setDays(action: string) {
    if (action === 'increase' && this.cleaningDays < 6) {
      this.cleaningDays++;
      this.testingson.push({
        day: '',
        timeFrame: '',
        duration: 2,
      });
      this.addIndividualDays(this.testingson);
    }
    if (action === 'decrease' && this.cleaningDays > 1) {
      this.cleaningDays--;
      this.testingson.splice(this.cleaningDays, 1);
      this.individualFormGroups.splice(this.cleaningDays, 1);
    }
    this.updatePrice()
  }

  getCleaningDays(): any[] {
    return Array(this.cleaningDays).fill(0);
  }

  updateSelectedHour(index: number, selectedHour: string) {
    const formGroup = this.formArray.at(index) as FormGroup;

    if (selectedHour === 'Afternoon (12:00 Uhr - 18:00 Uhr)') {
      const durationControl = this.individualFormGroups[index].get('duration');
      durationControl?.setValue(3)
      this.updatePrice()
      formGroup.patchValue({
        timeFrame: 'AFTER_NOON',
      });
    } else {
      formGroup.patchValue({
        timeFrame: 'BEFORE_NOON',
      });
    }
  }

  checkSelectedAddOn(id: number): boolean {
    return this.serviceExtras.some((i: any) => i.id === id);
  }

  // individual cleaning functions

  selectSchedule(selected: number) {
    this.selectedSchedule = selected;
  }
  
  setIndividualHours(index: number, action: string) {
    const timeFrameControl = this.individualFormGroups[index].get('timeFrame');
    const durationControl = this.individualFormGroups[index].get('duration');

    if (!timeFrameControl || !durationControl) {
      return;
    }

    const timeFrameValue = timeFrameControl.value;
    const currentDuration = durationControl.value;

    if (timeFrameValue === 'AFTER_NOON') {
      if (action === 'increase' && currentDuration < 3) {
        this.updateExtraHours(index, 1);
      } else if (action === 'decrease' && currentDuration > 2) {
        this.updateExtraHours(index, -1);
      }
    } else {
      if (action === 'increase' && currentDuration < 6) {
        this.updateExtraHours(index, 1);
      } else if (action === 'decrease' && currentDuration > 2) {
        this.updateExtraHours(index, -1);
      }
    }
    this.updatePrice()
  }

  selectCleaningTypeForIndividual(selectedType: number) {
    this.selectedCleaningType = selectedType;
  }

  // special cleaning functions
  selectSpecialType(selected: number, val: string) {
    this.specialValue = val
    this.selectedSpecialCleaning = selected;
    this.bookingForm.get('type')?.setValue(val);
  }

  handlecloseTerms(isTermsOpen: boolean) {
    this.isTermsOpen = isTermsOpen
    this.acceptedTerms = false
  }

  handleTermsAccepted(accepted: boolean) {
    this.acceptedTerms = accepted
  }

  handlePostRequest(post: boolean) {
    if(this.acceptedTerms) {
      this.postRequest = post
      if(this.postRequest) {
        let termsObj = {
          email: this.loginForm.get('email')?.value,
          acceptedTermsAndConditions: this.acceptedTerms
        }
        this.auth.termsAndConditions(termsObj).subscribe({
          next: (res) => {
            if (res) {
              this.loginFunction()
            }
          },
          error: (err) => {
            if (err) {
              console.log(err);
            }
          }
        })
      }
    }
  }

  handlePaymentMethod(event: any) {
    const value = event.target?.value;
      this.paymentMethod.setValue(value);
      this.enableConfirmButton = false

  }
  
  /**
   * handles steps
   * @param step 
   */

  handleSteps(step: any) {
    if(step < this.step){
      this.currentStep = step
      this.currentSubStep = 1
      this.changeButton = false
      this.changeToLogin = false
      this.changeToRegister = false
      this.loading = false;
      this.loadingTimeSlots = false;
      this.isShiftSelected = true;
      this.isLastStep = false
      this.termsAccepted = true
      this.isAddressSelection = false
      this.correctAnswer = false
      this.discountForm.get('couponCode')?.setValue('')
    }
  }

  /**
   * post request for coupon validation
   * @param cleaningType 
   */
  
  checkDiscountCode(cleaningType?: any) {
    this.discountForm.get('cleaningType')?.setValue(cleaningType)
    this.booking.discountValidation(this.discountForm.value, this.token).subscribe({
      next: (res) => {
        if(res) {
          this.correctAnswer = true
        }
      },
      error: (err) => {
        if (err) {
          this.correctAnswer = false
        }
      }
    })
  }

}
