<div class="container" (keyup.enter)="login()">
  @if(errorAlert){
  <div
    class="absolute md:-top-10 lg:-top-10 top-0 custom-modal text-red-700 p-4 rounded-lg text-center bg-red-200 flex justify-between"
  >
    <p>{{ errorMessage }}</p>
    <button
      (click)="closeAlert()"
      class="px-2 rounded-full bg-red-500 ml-4 text-white font-bold"
    >
      x
    </button>
  </div>
  }
  @if(isTermsOpen) {
    <div class="absolute terms-modal z-50">
      <app-terms 
        (isTermsChange)="handlecloseTerms($event)"
        [isTermsOpen]="isTermsOpen"
        (isTermsAccepted)="handleTermsAccepted($event)"
        [termsAccepted]="termsAccepted"
        [postRequest]="postRequest"
        (isPostEnabled)="handlePostRequest($event)"
      ></app-terms>
    </div>
  }
  <div
    class="mx-3 bg-white rounded-tl-lg my-3 rounded-br-lg  h-[70vh] overflow-y-auto items-center justify-center"
  >
    <div
      class="flex flex-col items-center py-3 justify-center text-start "
    >
      <span class="flex flex-col items-start gap-4 md:w-[25%]">
        @if(loginScreen) {
        <h1 class="text-[28px] font-bold">{{ 'AUTH.login' | translate}}</h1>
        <form [formGroup]="loginForm" class="w-full">
          @if(loginForm.errors && (loginForm.get('email')?.touched ||
          loginForm.get('email')?.dirty)){
          <span class="text-red-500">{{ 'AUTH.emailrequired' | translate}}</span>
          }
          <input
            formControlName="email"
            type="email"
            class="bg-[#e8eaf3] w-full p-4 rounded-lg text-black"
            placeholder="E-Mail"
          />
          @if(loginForm.errors && (loginForm.get('password')?.touched ||
          loginForm.get('password')?.dirty)){
          <span class="text-red-500">{{ 'AUTH.passrequired' | translate}}</span>
          }
          <div class="relative">
            <input
              formControlName="password"
              [type]="password"
              class="bg-[#e8eaf3] w-full p-4 rounded-lg text-black mt-2"
              placeholder="{{ 'AUTH.password' | translate}}"
              />
            <img *ngIf="!hide" class="absolute cursor-pointer w-6 right-2 translateY" src="../../assets/icons/profile/hidepass.svg" (click)="toggleVisibility('password')" alt="hidden" />
            <img *ngIf="hide" class="absolute cursor-pointer w-[27px] right-[6.5px] translateY" src="../../assets/icons/profile/showpass.svg" (click)="toggleVisibility('password')" alt="show" />
          </div>
        </form>
        <button
          (click)="login()"
          class="border-[1px] box-border disabled:bg-[#61bfd185] w-full bg-[#61BFD1] text-white font-bold rounded-[6px] px-8 py-2 hover:bg-[#61bfd185]"
        >
        {{ 'AUTH.login' | translate}}
        </button>
        <div class="relative w-full text-center mx-auto">
          <hr class="border-1 border-[#61BFD1]  w-[43%] absolute top-3 left-0">
          <span class="font-inter italic">{{ 'AUTH.or' | translate}}</span>
          <hr class="border-1 border-[#61BFD1]  w-[43%] absolute top-3 right-0">
        </div>
        <button (click)="loginWithGoogle()" 
        class="relative flex justify-center border-[1px] box-border disabled:opacity-25 w-full border-[#61BFD1] font-bold rounded-[6px] px-8 py-2 hover:border-opacity-25">
          <img class="left-2 absolute" src="../../assets/icons/googleicon.svg" alt="googleicon">
          {{ 'AUTH.signingoogle' | translate}}
        </button>
        <!-- <button disabled (click)="onFacebookLogin()" 
        class="relative flex justify-center border-[1px] box-border disabled:opacity-25 w-full border-[#61BFD1] font-bold rounded-[6px] px-8 py-2 hover:border-opacity-25">
          <img class="left-2 absolute" src="../../assets/icons/facebookicon.svg" alt="googleicon">
          {{ 'AUTH.signinfb' | translate}}
        </button> -->
        <button
          (click)="register()"
          class="border-[1px] box-border disabled:opacity-25 w-full border-[#61BFD1] font-bold rounded-[6px] px-8 py-2 hover:border-opacity-25"
        >
        {{ 'AUTH.register' | translate }}
        </button>
        <h1
          (click)="resetPassword()"
          class="self-center font-bold cursor-pointer"
        >
        {{ 'AUTH.forgotpass' | translate }}?
        </h1>
        } @if(forgotPass) {
        <h1 class="text-[28px] px-2 md:px-0 font-bold">{{ 'AUTH.forgotpass' | translate }}</h1>
        <form
          [formGroup]="forgotForm"
          (submit)="submitReset()"
          class="w-full px-2 md:px-0 relative"
        >
          <input
            formControlName="email"
            type="text"
            class="bg-[#e8eaf3] w-full p-4 rounded-lg text-black"
            placeholder="E-Mail"
          />
          @if(forgotForm.get('email')?.hasError('email') ||
          (forgotForm.get('email')?.hasError('required') &&
          forgotForm.get('email')?.touched)){
          <span class="text-red-500 absolute left-0 bottom-24"
            >{{ 'AUTH.emailrequired' | translate}}</span
          >
          }
          <button
            type="submit"
            class="border-[1px] box-border disabled:bg-[#61bfd185] w-full bg-[#61BFD1] text-white font-bold rounded-[6px] px-8 mt-5 py-2 hover:bg-[#61bfd185]"
          >
          {{ 'AUTH.send' | translate }}
          </button>
          <button
          (click)="backToLogin()"
          class="border-[1px] box-border disabled:opacity-25 w-full border-[#61BFD1] font-bold rounded-[6px] px-8 py-2 hover:border-opacity-25 mt-3"
        >
        {{ 'AUTH.login' | translate }}
        </button>
        </form>
        } 
      </span>
    </div>
  </div>
</div>
