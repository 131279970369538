{
    "expressSteps": [
        {
            "name": "Reinigung",
            "id": 1,
            "subSteps": [
              { "name": "Welche Art von Reinigung wünschen Sie?", "id": 1 },
              { "name": "Wie viel Zeit wird die Reinigung in Anspruch nehmen?", "id": 2 },
              { "name": "Wie gross ist die Fläche Ihrer Räumlichkeit", "id": 3 }
            ]
          },
          {
            "name": "Zusatzleistungen",
            "id": 2,
            "subSteps": [
              { "name": "Welche Zusatzleistungen wünschen Sie?", "id": 1 }
            ]
          },
          {
            "name": "Kalender",
            "id": 3,
            "subSteps": [
              { "name": "Bitte legen Sie einen Termin für Ihre erste Reinigung fest.", "id": 1 }
            ]
          },
          {
            "name": "Reinigungsmaterial",
            "id": 4,
            "subSteps": [
              { "name": "Besitzen Sie das erforderliche Reinigungsmaterial?", "id":1 }
            ]
          },
          {
            "name": "360 Recycling & More",
            "id": 5,
            "subSteps": [
              { "name": "Wir bieten Ihnen die passende Recycling-Lösung. Interessiert?", "id":1 }
            ]
          },
          {
            "name": "Kasse",
            "id": 6,
            "subSteps": [
              { "name": "Kontaktangaben", "id": 1 },
              { "name": "Zahlung", "id": 2 },
              { "name" : "Vielen dank für Ihre Buchung!", "id" : 3}
            ]
          }
    ],
    "standartSteps": [
        {
            "name": "Reinigung",
            "id": 1,
            "subSteps": [
                { "name": "Welche Art von Reinigung wünschen Sie?", "id": 1 },
                { "name": "Welche Art von Räumlichkeit haben Sie?", "id": 2 },
                { "name": "Wie gross ist die Fläche Ihrer Räumlichkeit", "id": 3 },
                { "name": "Wie oft soll gereinigt werden?", "id": 4 }
            ]
        },
        {
          "name": "Reinigungsmaterial",
          "id": 2,
          "subSteps": [
            { "name": "Besitzen Sie das erforderliche Reinigungsmaterial?", "id":1 }
          ]
        },
        {
          "name": "360 Recycling & More",
          "id": 3,
          "subSteps": [
            { "name": "Wir bieten Ihnen die passende Recycling-Lösung. Interessiert?", "id":1 }
          ]
        },
        {
            "name": "Kasse",
            "id": 4,
            "subSteps": [
                { "name": "Kontaktangaben", "id": 1 },
                { "name": "Zahlung", "id": 2 },
                { "name": "Vielen dank für Ihre Buchung!", "id": 3 }
            ]
        }
    ],
    "individualSteps": [
        {
          "name": "Reinigung",
          "id": 1,
          "subSteps": [
              { "name": "Wie möchten Sie starten?", "id": 1 },
              { "name": "Welche Art von Räumlichkeit haben Sie?", "id": 2 },
              { "name": "Wie gross ist die Fläche Ihrer Räumlichkeit", "id": 3 },
              { "name": "Wie oft soll gereinigt werden?", "id": 4 }
          ]
      },
      {
        "name": "Planen Sie Ihre Reinigung",
        "id": 2,
        "subSteps": [
          {"name": "Planen Sie Ihre Reinigung", "id":1 }
        ]
      },
      {
        "name": "Reinigungsmaterial",
        "id": 3,
        "subSteps": [
          { "name": "Besitzen Sie das erforderliche Reinigungsmaterial?", "id":1 }
        ]
      },
      {
        "name": "360 Recycling & More",
        "id": 4,
        "subSteps": [
          { "name": "Wir bieten Ihnen die passende Recycling-Lösung. Interessiert?", "id":1 }
        ]
      },
      {
          "name": "Kasse",
          "id": 5,
          "subSteps": [
              { "name": "Kontaktangaben", "id": 1 },
              { "name": "Zahlung", "id": 2 },
              { "name": "Vielen dank für Ihre Buchung!", "id": 3 }
          ]
      }
    ]
  }