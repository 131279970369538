import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleAuthService } from '../../../services/OAuth/googleauth.service';
import moment from 'moment';

@Component({
  selector: 'app-contact-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, CommonModule],
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.scss'
})
export class ContactFormComponent implements OnInit {
  @Input() login: boolean = false
  @Input() toggleLogin!: () => void
  @Input() loginFunction!: () => void
  @Input() registerFunction!: () => void
  @Input() markTouched!: () => void
  @Input() zip: any
  @Input() city: any
  @Input() loginForm!: FormGroup;
  @Input() hasAcceptedTerms: boolean = false
  @Input() registerForm!: FormGroup;
  @Output() confirmPassword: EventEmitter<string> = new EventEmitter<string>()
  @Output() formSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() hasAcceptedTermsChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  public dogCount = 0;
  public catCount = 0;
  public otherPetCount = 0;

  dogText!: any
  catText!: any
  otherAnimalText!:any

  addressForm!: FormGroup;
  petsModal: boolean = false
  confirmation = ""
  birthDate:any
  
  hide: boolean = true
  password = 'password'
  hidePass = true
  hideRepeatPass = true
  pass = 'password'
  repeatPass = 'password'
  
  otherReferralSource = new FormControl('', [Validators.required])
  
  constructor(private fb: FormBuilder, private googleAuth: GoogleAuthService) { }

  ngOnInit(): void {
    this.registerForm.valueChanges.subscribe(() => {
      this.formSubmit.emit(this.registerForm)
      this.confirmPassword.emit(this.confirmation)      
    })
    this.loginForm.valueChanges.subscribe(() => {
      this.formSubmit.emit(this.loginForm)
    })
  }

  setPets(action: string, petType: string) {
    if (action === 'increase') {
      if (petType === 'dog') {
        this.dogCount = this.dogCount + 1;
      } else if (petType === 'cat') {
        this.catCount = this.catCount + 1;
      } else if (petType === 'other') {
        this.otherPetCount = this.otherPetCount + 1;
      }
    } else if (action === 'decrease') {
      if (petType === 'dog') {
        if (this.dogCount !== 0) {
          this.dogCount = this.dogCount - 1;
        }
      } else if (petType === 'cat') {
        if (this.catCount !== 0) {
          this.catCount = this.catCount - 1;
        }
      } else if (petType === 'other') {
        if (this.otherPetCount !== 0) {
          this.otherPetCount = this.otherPetCount - 1;
        }
      }
    }
  }

  removeDogs(){
    this.dogCount = 0
    this.dogText = null
    this.registerForm.get('address.dog')?.patchValue(this.dogCount)
    this.registerForm.get('address.dogText')?.patchValue(this.dogText)
  }
  removeCats(){
    this.catCount = 0
    this.catText = null
    this.registerForm.get('address.cat')?.patchValue(this.catCount)
    this.registerForm.get('address.catText')?.patchValue(this.catText)
  }
  removeOthers(){
    this.otherPetCount = 0
    this.otherAnimalText = null
    this.registerForm.get('address.otherAnimal')?.patchValue(this.otherPetCount)
    this.registerForm.get('address.otherAnimalText')?.patchValue(this.otherAnimalText)
  }

  toggleModal(){
    this.petsModal = !this.petsModal
  }

  cancelPets() {
    this.removeCats()
    this.removeDogs()
    this.removeOthers()
    this.petsModal = !this.petsModal
  }

  confirmPets() {
    this.registerForm.get('address.dog')?.patchValue(this.dogCount)
    this.registerForm.get('address.cat')?.patchValue(this.catCount)
    this.registerForm.get('address.otherAnimal')?.patchValue(this.otherPetCount)
    this.registerForm.get('address.dogText')?.patchValue(this.dogText)
    this.registerForm.get('address.catText')?.patchValue(this.catText)
    this.registerForm.get('address.otherAnimalText')?.patchValue(this.otherAnimalText)
    this.petsModal = !this.petsModal
  }

  onLoginSubmit(){
    if (this.loginForm.invalid) {
      this.markTouched();
    }
    this.loginFunction()
  }

  loginWithGoogle() {
    this.googleAuth.login();
  }

  onConfirmationChange(value: string) {
    this.confirmation = value;
    this.confirmPassword.emit(value);
  }

  onRegisterSubmit(){
    this.confirmPassword.emit(this.confirmation)
    if (this.registerForm.invalid) {
      this.markTouched();
    }
    this.registerFunction()
  }

  toggleAcceptedTerms() { 
    this.hasAcceptedTerms = !this.hasAcceptedTerms
    this.hasAcceptedTermsChange.emit(this.hasAcceptedTerms)
  }
  openFile(){
    window.open("assets/terms/AGB_Pebra_Reinigung_Schweiz_AG_2024.pdf")
  }
  
  handleOtherReferralSource(event: any) {
    const value = event.target?.value
    if(value === 'other') {
      this.registerForm.get('referralSource')?.setValue(value)
    } else {
      this.registerForm.get('referralSource')?.setValue(value)
    }
  }
  
  toggleVisibility(action: string): void {
    if (action === 'password') {
     this.hidePass = !this.hidePass
     if(this.hidePass) {
       this.pass = 'password'
     } else {
       this.pass = 'text'
     }
   } else if (action === 'repeatpass') {
     this.hideRepeatPass = !this.hideRepeatPass
     if(this.hideRepeatPass) {
       this.repeatPass = 'password'
     } else {
       this.repeatPass = 'text'
     }
   } else if(action === 'pass') {
    this.hide = !this.hide
    if(this.hide){
      this.password = 'password'
    } else {
      this.password = 'text'
    }
   }
 }
}
