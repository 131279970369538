import { CommonModule } from '@angular/common';
import { compileNgModule } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

interface Content {
  name: string;
  description: string;
  attributes: { label: string; value: number }[];
  title: string;
}
@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
})
export class ModalComponent implements OnInit{
  @Input() closeModal!: () => void;
  @Input() content!: any;
  @Input() name!: string;
  @Input() confRoomsChange!: () => void;

  @Output() ironingAddedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cabinetsAddedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() windowsAddedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() bedRoomsChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() bathRoomsChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() ironingDuration: EventEmitter<number> = new EventEmitter<number>();
  @Output() cabinetsDuration: EventEmitter<number> = new EventEmitter<number>();
  @Output() windowsDuration: EventEmitter<number> = new EventEmitter<number>();
  @Output() commentAdd: EventEmitter<string> = new EventEmitter<string>();
  @Output() cabinetRemark: EventEmitter<string> = new EventEmitter<string>();
  @Input() bedRooms!: any;
  @Input() bathRooms!: any;
  @Input() cabinetRemarks!: any;
  @Input() resetCabinets!: () => void;
  @Input() resetIroning!: () => void;
  @Input() commentVal!: string

  ironingAdded: boolean = false;
  cabinetsAdded: boolean = false;
  cabinetsRemark: any = '';
  windowsAdded: boolean = false;
  // bedRooms: number = 3;
  // bathRooms: number = 1;
  ironingDurationByHours: number = 0.5;
  cabinetDurationByHours: number = 0.5;
  addedLargeWindows: number = 0;
  addedStandartWindows: number = 0;
  addComment: any;

  public windows = [
    {
      name: 'Fenster',
      description:
        'Wir empfehlen Ihnen 3 Reinigungsstunden Wohnzimmer, Küche und alle Gemeinschaftsbereiche sind inbegriffen!',
      attributes: [
        { label: 'Standard Fenster', value: 0 },
        { label: 'Große Fenster', value: 0 },
      ],
      title:
        'Wir empfehlen Ihnen 3 Reinigungsstunden Wohnzimmer, Küche und alle Gemeinschaftsbereiche sind inbegriffen!',
    },
  ];

  public calculator = [
    {
      name: 'Reinigungsdauer-Rechner',
      description:
        'Berechnen Sie die Reinigungszeit, indem Sie die Anzahl der Schlafzimmer und Badezimmer hinzufügen.',
      attributes: [
        { label: 'Zimmer', value: 0 },
        { label: 'Badezimmer', value: 0 },
      ],
      title:
        'Wir empfehlen Ihnen 3 Reinigungsstunden Wohnzimmer, Küche und alle Gemeinschaftsbereiche sind inbegriffen!',
    },
  ];

  public ironing = [
    {
      name: 'Bügeln',
      description: '',
      attributes: [{ label: 'Dauer', value: 30 }],
      title:
        'Bitte achten Sie darauf, dass Ihr Bügeleisen und Ihr Bügelbrett leicht erreichbar sind.',
    },
  ];

  public cabinets = [
    {
      name: 'Schränke von innen',
      description: '',
      attributes: [
        { label: 'Dauer', value: 30 }
      ],
      remark: '',
      title:
        '',
    }
  ]

  public comment = [
    {
      name: 'Kommentar',
      description: 'Vielen Dank im Voraus für Ihren Kommentar',
      attributes: [{ label: '', value: 0 }],
      title: 'Bitte hinterlassen Sie weitere Kommentare',
    },
  ];

  ngOnInit(): void {
    if(this.cabinetRemarks){
      this.cabinetsRemark = this.cabinetRemarks
    }
  }

  getObject(type: string): Content[] | undefined {
    if (type === 'calculator') {
      return [this.calculator[0]];
    } else if (type === 'windows') {
      return [this.windows[0]];
    } else if (type === 'ironing') {
      return [this.ironing[0]];
    } else if (type === 'comment') {
      return [this.comment[0]];
    } else if(type === 'cabinets'){
      return [this.cabinets[0]]
    } 
    else {
      return undefined;
    }
  }

  emitValuesToParent() {
    this.ironingAddedChange.emit(this.ironingAdded);
    this.cabinetsAddedChange.emit(this.cabinetsAdded);
    this.cabinetRemark.emit(this.cabinetsRemark)
    this.windowsAddedChange.emit(this.windowsAdded);
    this.bedRoomsChange.emit(this.bedRooms);
    this.bathRoomsChange.emit(this.bathRooms);
    this.ironingDuration.emit(this.ironingDurationByHours);
    this.cabinetsDuration.emit(this.cabinetDurationByHours);
    this.windowsDuration.emit((this.addedLargeWindows + this.addedStandartWindows) / 2);
    this.commentAdd.emit(this.addComment);
  }

  confirm(action: string) {
    if (action === 'confirm') {
      this.ironingAdded = true;
    } else if (action === 'cancel') {
      this.ironingAdded = false;
      this.ironingDurationByHours = 0;
      this.resetIroning()
    }
    this.ironingAddedChange.emit(this.ironingAdded);
    this.ironingDuration.emit(this.ironingDurationByHours);
  }

  confirmCabinets(action:string){
    if (action === 'confirm') {
      this.cabinetsAdded = true;
    } else if (action === 'cancel') {
      this.cabinetsAdded = false;
      this.cabinetDurationByHours = 0;
      this.cabinetsRemark = null
      this.resetCabinets()
    }
    this.cabinetsAddedChange.emit(this.cabinetsAdded);
    this.cabinetsDuration.emit(this.cabinetDurationByHours);
    this.cabinetRemark.emit(this.cabinetsRemark)
  }

  hasExactLength(str: string, length: number): boolean {
    return str.length > length;
  }

  confirmComment() {
    this.commentAdd.emit(this.addComment);
  }

  cancelComment() {
    this.closeModal()
  }

  handleChangeVal(event: any) {
    this.addComment = event.target.value;
    console.log(this.addComment);
  }

  confirmWindows(action: string) {
    if (action === 'confirm') {
      this.windowsAdded = true;
    } else if (action === 'cancel') {
      this.windowsAdded = false;
    }
    this.windowsAddedChange.emit(this.windowsAdded);
    this.windowsDuration.emit(
      (this.addedLargeWindows + this.addedStandartWindows) / 2
    );
  }

  increaseValue() {
    if (this.ironing[0].attributes[0].value < 120) {
      this.ironing[0].attributes[0].value += 30;
      this.ironingDurationByHours += 0.5;
    }
  }

  decreaseValue() {
    if (this.ironing[0].attributes[0].value > 30) {
      this.ironing[0].attributes[0].value -= 30;
      this.ironingDurationByHours -= 0.5;
    }
  }

  increaseCabinetsValue() {
    if (this.cabinets[0].attributes[0].value < 120) {
      this.cabinets[0].attributes[0].value += 30;
      this.cabinetDurationByHours += 0.5;
    }
  }

  decreaseCabinetsValue() {
    if (this.cabinets[0].attributes[0].value > 30) {
      this.cabinets[0].attributes[0].value -= 30;
      this.cabinetDurationByHours -= 0.5;
    }
  }

  handleLargeWindows(action: string, windowType: string) {
    if (action === 'add') {
      if (windowType === 'Standard Fenster') {
        this.addedStandartWindows += 1;
      } else {
        this.addedLargeWindows += 1;
      }
    } else {
      if (windowType === 'Standard Fenster') {
        this.addedStandartWindows > 0
          ? (this.addedStandartWindows -= 1)
          : this.addedStandartWindows;
      } else {
        this.addedLargeWindows > 0
          ? (this.addedLargeWindows -= 1)
          : this.addedLargeWindows;
      }
    }
  }

  handleRooms(action: string, room: string) {
    if (action === 'add') {
      if (room === 'Badezimmer') {
        if(this.bathRooms < 4){
          this.bathRooms += 1;
          this.bathRoomsChange.emit(this.bathRooms);
        }
      } else {
        if(this.bedRooms < 4){
          this.bedRooms += 1;
          this.bedRoomsChange.emit(this.bedRooms);
        }
      }
    } else {
      if (room === 'Badezimmer') {
        if(this.bathRooms > 1){
          this.bathRooms -= 1;
          this.bathRoomsChange.emit(this.bathRooms);
        }
      } else {
        if(this.bedRooms > 2){
          this.bedRooms -= 1;
          this.bedRoomsChange.emit(this.bedRooms);
        }
      }
    }
  }

  handleIncrease(type: string, item: any) {
    switch (type) {
      case 'ironing':
        return this.increaseValue();
      case 'windows':
        return this.handleLargeWindows('add', item.label);
      case 'calculator':
        return this.handleRooms('add', item.label);
      case 'cabinets':
        console.log(this.cabinets[0].attributes[0].value)
        return this.increaseCabinetsValue()
      default:
        break;
    }
  }

  handleDecrease(type: string, item: any) {
    switch (type) {
      case 'ironing':
        return this.decreaseValue();
      case 'windows':
        return this.handleLargeWindows('remove', item.label);
      case 'calculator':
        return this.handleRooms('remove', item.label);
      case 'cabinets':
        return this.decreaseCabinetsValue()
    default:
        break;
    }
  }

  handleConfirmation(type: string) {
    switch (type) {
      case 'ironing':
        return this.confirm('confirm');
      case 'windows':
        return this.confirmWindows('confirm');
      case 'calculator':
        return this.confRoomsChange()
      case 'comment':
        return this.confirmComment();
      case 'cabinets':
        return this.confirmCabinets('confirm');
      default:
        break;
    }
  }
  
  handleCancellation(type: string) {
    switch (type) {
      case 'ironing':
        return this.confirm('cancel');
      case 'windows':
        return this.confirmWindows('cancel');
      case 'calculator':
        return this.confRoomsChange()
      case 'comment':
        return this.cancelComment();
        case 'cabinets':
          return this.confirmCabinets('cancel');
      default:
        break;
    }
  }
}
