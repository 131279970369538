import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PricingService } from '../../services/core/pricing.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { AlertService } from '../../services/core/alert.service';
@Component({
  selector: 'app-stepper',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
})
export class StepperComponent {
  @Input() steps: any[] = [];
  @Input() stepButton!: (args: any) => void;
  @Input() parentDisableFunciton!: (args: any) => void;
  @Input() disableNext!: () => void;
  @Input() stepTemplates: TemplateRef<any>[] = [];
  @Input() currentSubStep!: Number;
  @Input() currentStep!: Number;
  @Input() displayPrice: boolean = false;
  @Input() price: any = '';
  @Input() token: any = '';
  @Input() isLastStep!: () => boolean;
  @Input() submit!: () => void;
  @Input() displayButtons: boolean = true;
  @Input() city:any = ''
  @Input() zip:any = ''
  @Output() addressFormEmitter: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() functionEmitter = new EventEmitter<() => void>();
  @Input() isLoginButton: boolean = false
  @Input() isRegisterButton: boolean = false
  @Input() changeButton: boolean = false
  @Input() loginFromStepper!: () => void
  @Input() registerFromStepper!: () => void
  @Input() specialTitle: string = 'END_OF_TENANCY'
  @Input() hours: any
  @Input() selectedCleaningType: any
  @Input() loading: boolean = false
  @Input() isCompany: boolean = false;
  @Input() hasAcceptedTerms: boolean = false
  @Input() handleSteps: (step:any) => void
  @Input() hasSupplies: boolean
  
  public addressForm!: FormGroup
  
  createNewAddress:boolean = false
  stepperDisplay: boolean = false;
  pricingTest:any

  dogsCount:number = 0
  catsCount:number = 0
  othersCount:number = 0

 


  constructor(private pricing: PricingService, private auth:AuthService, private alertService: AlertService){
    this.pricing.price$.subscribe(price => {
      this.pricingTest = price;
    });
  }
  
  ngOnInit() {
    this.addressForm = new FormGroup({
      street: new FormControl(null,[Validators.required]),
      streetNr: new FormControl(null,[Validators.required]),
      city: new FormControl(this.city,[Validators.required]),
      zipCode: new FormControl(this.zip,[Validators.required]),
      entryCode: new FormControl('785'),
      entranceInstructions: new FormControl(null),
      additionalInformation: new FormControl(null),
      dog: new FormControl(null),
      cat: new FormControl(null),
    })

    this.addressForm.valueChanges.subscribe(() => {
      this.addressFormEmitter.emit(this.addressForm)
    })
  }

  submitForm(){
    if(this.addressForm.valid){
      const token = localStorage.getItem('token')
      this.auth.createAddress(this.addressForm.getRawValue(),token).subscribe({
        next: (res) => {
          console.log(res)
        },
        error: (err) => {
          this.alertService.error(err.error.error,{ autoClose: true })
        },
        complete: () => {
          this.alertService.success('Adresse erfolgreich erstellt', { autoClose: true })
          const token = localStorage.getItem('token')
          this.auth.getUserAdressesForStandart(token)
          const functionToEmit = () => {
            console.log('Function emitted from child');
          };
          this.functionEmitter.emit(functionToEmit);
        }
      })
    }
    this.closeModal()
  }

  openStepper() {
    this.stepperDisplay = !this.stepperDisplay;
  }

  countPets(action:string, pet:string){
    if(action === 'increase'){
      switch (pet) {
        case 'dog':
        this.dogsCount += 1          
        break;
        case 'cat':
        this.catsCount += 1
        break;
        case 'other':
        this.othersCount += 1
        break;
        default:
          break;
      }
    } else {
      switch (pet) {
        case 'dog':
        this.dogsCount > 0 ? this.dogsCount -= 1 : null          
        break;
        case 'cat':
        this.catsCount ? this.catsCount -= 1 : null
        break;
        case 'other':
        this.othersCount ? this.othersCount -= 1 : null
        break;
        default:
          break;
      }
    }
    if(pet === 'cat'){
      this.addressForm.get('cat')?.setValue(this.catsCount)
    } else if(pet === 'dog'){
      this.addressForm.get('dog')?.setValue(this.dogsCount)
    }
  }

  closeModal(){
    this.createNewAddress = false
  }

  openModal(){
    this.addressForm.get('city')?.setValue(this.city)
    this.addressForm.get('city')?.disable()
    this.addressForm.get('zipCode')?.setValue(String(this.zip))
    this.addressForm.get('zipCode')?.disable()
    this.createNewAddress = true
  }

  getSubStepsLength(stepIndex: any):number {
    const step = this.steps[stepIndex - 1];
    return step ? step.subSteps.length : 0;
  }

  handlePriceDisplay(event: any) {
    this.displayPrice = event;
  }

}
