<div class="my-3 md:w-[100%] z-50"
  [ngClass]="{'fixed top-0 right-3 w-11/12 md:w-96 z-50' : absolute, 'w-80' : !absolute}">
  <div *ngFor="let alert of alerts" class="flex flex-nowrap items-center {{cssClass(alert)}}"
    [ngClass]="transparent ? 'alert-transparent' : 'alert-filled'">
    <img src="../../assets/icons/alerts/alert-{{ imgPath }}.svg" alt="Alert Icon">
    <div *ngIf="alert.type != 4" class="px-4 alert-text">{{ alert.message }}</div>
    <div class="alert-text" *ngIf="alert.type == 4">{{ alert.message }} - <a class="underline text-link-hover" href="/individual/user-profile">Subscribe now</a></div>
    <button (click)="removeAlert(alert)" class="min-w-4 ml-auto z-50">
      <img class="w-6" src="../../assets/icons/alerts/close-{{ imgPath }}.svg" alt="Close Alert">
    </button>
  </div>
</div>