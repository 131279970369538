<app-stepper
  class="w-[100%]"
  [stepTemplates]="
    stepTemplates.length > 0
      ? stepTemplates
      : [
          standartCleaning1,
          standartCleaning2,
          standartCleaning3,
          standartCleaning4,
          standartCleaning5,
          standartCleaning6
        ]
  "
  [currentSubStep]="currentSubStep"
  [steps]="selectedType"
  [currentStep]="currentStep"
  [stepButton]="stepButton.bind(this)"
  [disableNext]="isNextDisabled.bind(this)"
  [displayPrice]="displayPrice"
  [price]="price"
  [isLastStep]="checkLastStep.bind(this)"
  [token]="hasToken"
  (addressFormEmitter)="getAddressForm($event)"
  [zip]="zipFromQuery"
  [city]="cityFromZip"
  [isLoginButton]="changeToLogin"
  [isRegisterButton]="changeToRegister"
  [changeButton]="changeButton"
  [loginFromStepper]="loginFunction.bind(this)"
  [registerFromStepper]="registerFunction.bind(this)"
  (functionEmitter)="handleFunction($event)"
  [specialTitle]="specialValue"
  [selectedCleaningType]="selectedCleaningType"
  [hours]="getHours()"
  [loading]="regLoading"
  [displayButtons]="displayButtons"
  [isCompany]="isCompany"
  [hasAcceptedTerms]="hasAcceptedTerms"
  [handleSteps]="handleSteps.bind(this)"
  [hasSupplies]="displayWarning"
  [region]="region"
  >
</app-stepper>

@if(openCabinetsModal){
  <app-modal
  [closeModal]="closeModal.bind(this)"
  name="cabinets"
  (cabinetsAddedChange)="onCabinetsAddedChange($event)"
  (cabinetsDuration)="cabinetDurationHandler($event)"
  (cabinetRemark)="cabinetRemarks($event)"
  [cabinetRemarks]="getRemark()"
  [resetCabinets]="deleteCabinets.bind(this)"
  >
  </app-modal>
}

@if(isTermsOpen) {
  <div class="absolute terms-modal-private">
    <app-terms 
      (isTermsChange)="handlecloseTerms($event)"
      [isTermsOpen]="isTermsOpen"
      (isTermsAccepted)="handleTermsAccepted($event)"
      [termsAccepted]="acceptedTerms"
      [postRequest]="postRequest"
      (isPostEnabled)="handlePostRequest($event)"
    ></app-terms>
  </div>
}

@if(openIroningModal){
<app-modal
  [closeModal]="closeModal.bind(this)"
  name="ironing"
  (ironingAddedChange)="onIroningAddedChange($event)"
  (ironingDuration)="ironingDurationHandler($event)"
  [resetIroning]="deleteIroning.bind(this)"
>
</app-modal>
} 

@if(openCalculatorModal){
<app-modal
  [closeModal]="closeModal.bind(this)"
  name="calculator"
  (bathRoomsChange)="onBathRoomsChange($event)"
  (bedRoomsChange)="onBedRoomsChange($event)"
  [confRoomsChange]="confirmRooms.bind(this)"
  [bedRooms]="bedrooms"
  [bathRooms]="bathrooms"
>
</app-modal>
} 

@if (openCommentModal){
<app-modal
  [closeModal]="closeModal.bind(this)"
  name="comment"
  (commentAdd)="onCommentAdd($event)"
  [commentVal]="commentValue"
>
</app-modal>
} 

@if(redirecting){
<div
  class="absolute w-fit h-fit bg-[#EEE0F1] rounded-lg text-violet-400 font-bold text-[20px] p-4 -top-20 right-[30%]"
>
  <p class="animate-pulse">{{ 'PRIVATE.Bitte haben Sie etwas Geduld bei der Weiterleitung zur Zahlung...' | translate }}</p>
</div>
}

<!-- Regularly Cleaning -->
<ng-template #standartCleaning1>
  @if(currentSubStep === 1){
    @if(selectZipFromStart){
      <h1 class="text-[20px] font-bold">{{ 'PRIVATE.Bitte wählen Sie Ihre Postleitzahl, um fortzufahren!' | translate }}</h1>

      <form class="md:py-5 md:block flex flex-col relative">
        <input
          class="md:inline-block block md:w-[50%] border-0 py-3 mt-5 pl-7 pr-20 bg-blue-secondary text-gray-ducktertiary placeholder:text-gray-ducktertiary md:rounded-tl-xl md:rounded-bl-xl rounded-xl md:rounded-none"
          type="text"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="searchText"
          placeholder="{{ 'PRIVATE.Ihre Postleitzahl' | translate }}"
        />
        @if(searchText.length > 0) {
        <div class="z-50 drop my-2 rounded-2xl bg-white max-h-[200px] h-fit md:w-[50%] overflow-y-scroll absolute border-[1px] border-[#E9F3F4]">
          <ul class="p-2">
            <li class="hover:bg-[#E9F3F4] rounded-lg"
              *ngFor="let zip of zipcodes[0] | filter : searchText : ['zip', 'city', 'cityZip']" (click)="selectZipCode(zip.zip, zip.city)">
              <p class="p-2 inline-block cursor-pointer">
                {{ zip.zip }} {{ zip.city }}
              </p>
            </li>
          </ul>
        </div>
        }
      </form>
    }
    @else {
      <ng-container *ngIf="currentSubStep === 1">
        <div class="grid md:grid-cols-3 grid-rows-2 gap-4">
          @for(item of cards; track $index){
          <div
            (click)="selectCleaningType(item.id, item.title)"
            [ngClass]="{
              'rounded-tl-md p-2 shadow-none md:shadow-xl border-[#61BFD19f] md:border-transparent lg:border-transparent border-[1px] rounded-br-md flex flex-col gap-2 justify-center items-center w-full cursor-pointer hover:border-[#61BFD19f]': true,
              'bg-[#61BFD1]': item.id === selectedCleaningType
            }"
          >
            <p
              [ngClass]="{
                'text-[24px] font-bold text-center': true,
                'text-white': item.id === selectedCleaningType
              }"
            >
              {{ "PRIVATE." + item.title | translate }}
            </p>
            @if(!item.list){
            <p
              [ngClass]="{
                'text-[16px] px-2 p-1 bg-white': true,
                'text-violet-400 font-bold rounded-md':
                  item.id === selectedCleaningType
              }"
            >
              {{ "PRIVATE." + item.description | translate }}
            </p>
            } @else if(item.list){
            <ul
              [ngClass]="{
                'list-disc': true,
                'text-white': item.id === selectedCleaningType
              }"
            >
              <li *ngFor="let listItem of item.list">
                {{ "PRIVATE." + listItem | translate }}
              </li>
            </ul>
            }
          </div>
          }
        </div>
      </ng-container>
    }
  } 
  @if(currentSubStep === 2) {
    <ng-container>
      <div class="text-black py-2">
        <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Regelmässige Reinigung (Abo) – Sauberkeit, auf die Sie sich verlassen können' | translate}}</h4>
        <p>{{'EXPLAINATION_PAGE.Eine saubere Umgebung trägt wesentlich zum Wohlbefinden bei. Mit unserem Service der regelmässigen Reinigung bieten wir Ihnen die Möglichkeit, stets ein gepflegtes Zuhause oder Büro zu geniessen, ohne sich um die Reinigungsarbeiten kümmern zu müssen. Geniessen Sie ein dauerhaftes Wohlfühlgefühl in Ihren Räumlichkeiten.'| translate}}</p>
      </div>
      <div class="text-black py-2">
        <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Was ist die regelmässige Reinigung?'| translate}}</h4>
        <p>{{'EXPLAINATION_PAGE.Unsere regelmässige Reinigung ist der perfekte Service für alle, die eine kontinuierliche Sauberkeit und Hygiene in ihren Räumlichkeiten sicherstellen möchten. Fast schon wie ein Abo. Ob wöchentlich, zweiwöchentlich oder monatlich – wir passen uns Ihren Bedürfnissen an.' | translate}}</p>
      </div>
      <div class="text-black py-2">
        <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Was muss ich zur Verfügung stellen?' | translate}}</h4>
        <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Zugang:' | translate}}</span> {{'EXPLAINATION_PAGE.Für einen reibungslosen Zugang zu Ihren Räumlichkeiten haben Sie beim Vertragsabschluss die folgenden Optionen: Schlüsselübergabe oder Schlüsselhinterlegung. Gerne können Sie das Reinigungspersonal jeweils auch persönlich Empfangen.' | translate}}</p>
        <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Reinigungsmaterial:' | translate}}</span> {{'EXPLAINATION_PAGE.Damit unser Personal eine einwandfreie Reinigung durchführen kann, bitten wir Sie die geeigneten Reinigungsmaterialen zu Verfügung zu stellen. Eine genaue Auflistung der Materialien finden Sie hier. Sollten Sie das passende Reinigungsmaterial nicht zur Hand haben, stellen wir dies gerne gegen einen Aufpreis zur Verfügung.'| translate}}</p>
      </div>
      <div class="text-black py-2">
        <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Ihre Vorteile auf einen Blick:'| translate}}</h4>
        <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Kontinuität:'| translate}}</span> {{'EXPLAINATION_PAGE.Geplante Reinigungstermine im Voraus, geniessen Sie konstante Sauberkeit ohne Unterbrechungen.'| translate}}</p>
        <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Qualität:' | translate}}</span> {{'EXPLAINATION_PAGE.Unser geschultes Team sorgt dafür, dass jede Reinigung gründlich und mit höchster Sorgfalt durchgeführt wird.'| translate}}</p>
        <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Bequemlichkeit:'| translate}}</span> {{'EXPLAINATION_PAGE.Kein Stress mehr mit Last-Minute-Reinigungen – unsere regelmässigen Besuche garantieren immer eine saubere Umgebung.' | translate}}</p>
        <h4 class="text-[20px] mt-3">{{'EXPLAINATION_PAGE.Kontaktieren Sie uns noch heute und vereinbaren Sie Ihre regelmässige Reinigung!'| translate}}</h4>
      </div>
    </ng-container>
  }
  @if(currentSubStep === 3){
  <ng-container>
    <div class="flex flex-wrap justify-between gap-4 py-2">
      @for(item of secondCards; track $index){
      <div
        (click)="selectRecurrence(item.id, item.value)"
        [ngClass]="{
          'bg-[#61BFD1] border-2 text-white border-transparent':
            item.id === selectedRecurrence,
          'rounded-tl-md shadow-none md:shadow-xl cursor-pointer md:border-transparent border-[#61BFD19f] border-[1px] rounded-br-md flex flex-col gap-2 justify-center items-center min-h-24 w-full md:w-[48%] hover:border-[#61BFD19f] hover:border-2': true
        }"
      >
        <p class="text-[24px] font-bold">
          {{ "PRIVATE." + item.title | translate }}
        </p>
        @if(item.title === 'Wöchentlich'){
        <p
          [ngClass]="{
            'font-bold px-2 p-1 text-[16px] rounded-md': true,
            'bg-green-400 text-[#ffffff]': item.id != selectedRecurrence,
            'text-green-500 bg-white': item.id === selectedRecurrence
          }"
        >
          {{ "PRIVATE.Unsere Empfehlung" | translate }}
        </p>
        }
      </div>
      }
    </div>
  </ng-container>
  } @if(currentSubStep === 4){
  <ng-container>
    <div class="flex flex-col gap-8 py-4 items-center">
      <div
        class="flex items-center gap-8 text-center justify-between  p-4 md:p-14 lg:p-14 rounded-tl-lg rounded-br-lg shadow-xl"
      >
        <button
          class="w-12 h-12 px-2 bg-[#61BFD1] rounded-lg flex items-center justify-center"
          (click)="setHours('decrease')"
        >
          <img src="../assets/icons/minus.svg" alt="minus" />
        </button>
        <div>
          <p class="text-[24px] text-[#61BFD1] font-bold">
            {{ getHours() }}
            {{ "PRIVATE.Stunden" | translate }}
          </p>
          <p class="text-[16px] text-[#61BFD1]">
            {{ bedrooms }} {{ "PRIVATE.Zimmer" | translate }}
            {{ "PRIVATE.und" | translate }} {{ bathrooms }}
            {{ "PRIVATE.Badezimmer" | translate }}
          </p>
        </div>
        <button
          class="w-12 h-12 bg-[#61BFD1] px-2 rounded-lg flex items-center justify-center"
          (click)="setHours('increase')"
        >
          <img src="../assets/icons/plus.svg" alt="plus" />
        </button>
      </div>
      <div class="flex flex-col md:flex-row lg:flex-row gap-6">
        <button
          (click)="openCalc()"
          class="border-[1px] disabled:bg-[#6c91d785] bg-[#61BFD1] text-white font-bold rounded-[6px] px-12 py-4 hover:bg-[#61BFD1cb]"
        >
          {{ 'PRIVATE.Zeit berechnen' | translate }}
        </button>
        <button
          (click)="openAddComment()"
          class="border-[1px] disabled:opacity-25 border-[#61BFD1] font-bold rounded-[6px] px-12 py-4 hover:bg-[#61bfd13a]"
        >
          {{ "PRIVATE.Bemerkung hinzufügen" | translate }}
        </button>
      </div>
    </div>
  </ng-container>
  }
</ng-template>
<ng-template #standartCleaning2>
  @if(currentSubStep === 1){
  <div class="flex flex-wrap gap-4">
    <ng-container>
      @for(item of secondStepCards; track $index){
      <div
        (click)="selectExtraService(item)"
        [ngClass]="{
          'flex p-6 flex-col w-full 2xl:max-w-[31%] md:w-[47%] shadow-none md:shadow-xl rounded-tl-lg rounded-br-lg cursor-pointer border-[1px] border-[#61BFD1] md:border-transparent hover:border-[#61BFD1]': true,
          'bg-[#61BFD1] text-white': checkSelectedAddOn(item.id)
        }"
      >
        <div class="flex items-center gap-4 capitalize">
          <img
            [src]="checkSelectedAddOn(item.id) ? item.iconActive : item.icon"
            alt="window"
            class="max-w-[28px]"
          />
          {{ "PRIVATE." + item.name | translate }}
        </div>
        <div class="text-[24px] font-bold pt-3">{{'PRIVATE.' + item.description | translate}}</div>
      </div>
      }
    </ng-container>
  </div>
  }
</ng-template>
<ng-template #standartCleaning3>
  @if(modalOpen){
  <div class="flex flex-col gap-2">
    <div class="flex w-[100%] md:w-[73%] lg:w-[73%] justify-between">
      <div class="flex p-2 bg-[#E9EBF4] rounded-lg">{{getHours()}} {{'PROFILE.hours' | translate}}</div>
      <div class="flex w-[60%] md:w-[30%] lg:w-[30%] justify-between items-center">
        <button (click)="prevMonth()">
          <img src="../assets/icons/Vector-left.svg" alt="" />
        </button>
        <p>{{ 'DATE.' + (viewDate | date : "MMMM") | translate }} {{ viewDate | date : "y" }}</p>
        <button (click)="changeMonth()">
          <img src="../assets/icons/Vector-right.svg" alt="" />
        </button>
      </div>
    </div>
    <div class="flex flex-col md:flex-row lg:flex-row w-full justify-between">
      <app-calendar
        [disabledDays]="freeTimeSlots"
        [viewDate]="viewDate"
        (selectedDate)="getSelectedDay($event)"
        (viewDateUpdate)="updateViewDate($event)"
        class="w-[100%] md:w-[74%] lg:w-[74%] shadow-lg md:h-[240px]"
      ></app-calendar>
      <div class="w-[100%] md:w-[26%] lg:w-[26%] items-start justify-between flex flex-col gap-4 overflow-auto md:min-h-[370px]">
        <div class="flex flex-col gap-2 w-full">
          <button
            (click)="selectShift('BEFORE_NOON')"
            [disabled]="
              (selectedDay && !selectedDay[0].schedule.morning) || !selectedDay
            "
            [ngClass]="{
              'md:w-[80%] lg:w-[80%] w-[100%] md:mt-0 lg:mt-0 mt-4 rounded-br-lg self-end rounded-tl-lg shadow-lg p-4 disabled:text-gray-500 disabled:bg-gray-50': true,
              'bg-[#61BFD1] text-white': selectedShift === 'BEFORE_NOON'
            }"
          >
            {{'PRIVATE.Before noon (08:00 Uhr - 12:00 Uhr)' | translate}}
          </button>
          <button
          (click)="selectShift('AFTER_NOON')"
          [disabled]="
              (selectedDay && !selectedDay[0].schedule.afternoon) || !selectedDay || getHours() >= 6
              "
            [ngClass]="{
              'md:w-[80%] lg:w-[80%] w-[100%] md:mt-0 lg:mt-0 mt-4 rounded-br-lg self-end rounded-tl-lg shadow-lg p-4 disabled:text-gray-500 disabled:bg-gray-50': true,
              'bg-[#61BFD1] text-white': selectedShift === 'AFTER_NOON'
            }"
          >
            {{'PRIVATE.Afternoon (12:00 Uhr - 18:00 Uhr)' | translate}}
          </button>
        </div>
        <div class="flex flex-col justify-end self-end w-[80%] gap-4 pt-2">
          <button
            class="border-[1px] box-border disabled:opacity-25 border-[#61BFD1] font-bold rounded-[6px] px-8 py-2 hover:bg-[#61bfd13a] hover:duration-100"
            (click)="openModal()"
          >
            {{'PRIVATE.Schliessen' | translate}}
          </button>
          <button
            [disabled]="!selectedShift"
            class="border-[1px] box-border disabled:bg-[#61bfd185] bg-[#61BFD1] text-white font-bold rounded-[6px] px-8 py-2 hover:bg-[#3b7a86]"
            (click)="confirmModal()"
          >
            {{'PRIVATE.Bestätigen' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
  } @if(!modalOpen){
  <div class="flex flex-col gap-2">
    <div class="flex flex-col md:flex-row lg:flex-row w-full justify-between text-[18px] text-[#7E7E7E]">
      <p>
        {{
          "PRIVATE.Hier finden Sie die nächsten verfügbaren Termine."
            | translate
        }}
      </p>
      <span class="rounded-lg bg-[#E9EBF4] px-4 py-2 2xl:py-2 md:py-0 lg:py-0 font-semibold">
        {{'PRIVATE.Heute ist der' | translate}} {{ viewDate | date : "dd" }}, {{ 'DATE.' + (viewDate | date : "MMMM") | translate }} {{ viewDate | date : "y" }}
      </span>
    </div>
    @if(freeTimeSlots && freeTimeSlots.length > 0){
    <div class="flex flex-wrap gap-2 justify-between text-blue-duckFortiary">
      @for(day of filteredTimeSlots; track $index){
      <div
        [ngClass]="{
          'rounded-lg md:p-4 lg:p-4 p-2 w-full md:w-[48%] lg:w-[48%] shadow-lg text-[18px] 2xl:text-[22px] border-[1px] border-transparent cursor-pointer hover:border-[#61BFD1]': true,
          'bg-[#61BFD1] text-white': selectedFastestTime?.date === day?.date
        }"
        (click)="selectTime(day)"
      >
        <div class="flex justify-between">
          <p class="font-bold">{{ "PRIVATE." + day.day | translate}}</p>
          <span
            class="text-[14px] rounded-lg bg-[#E9EBF4] p-2 font-semibold text-blue-duckFortiary"
          >
            {{ day.date | date : "dd" }}, {{ 'DATE.' + (day.date | date : "MMMM") | translate }} {{ day.date | date : "y" }}
          </span>
        </div>
        <span *ngIf="day.schedule.morning" class="flex gap-2">
          <span
            *ngIf="selectedFastestTime === day"
            (click)="selectClosestShift('BEFORE_NOON')"
            class="w-[20px] h-[20px] flex rounded-full bg-gray-200 items-center justify-center"
            ><span
              *ngIf="selectedClosestShift === 'BEFORE_NOON'"
              class="w-[12px] h-[12px] bg-[#2b5a68] flex rounded-full"
            ></span
          ></span>
          <p class="text-[18px]">{{'PRIVATE.Before noon (08:00 Uhr - 12:00 Uhr)' | translate}}</p>
        </span>
        <span *ngIf="day.schedule.afternoon" class="flex gap-2">
          <span
            *ngIf="selectedFastestTime === day"
            (click)="selectClosestShift('AFTER_NOON')"
            class="w-[20px] h-[20px] flex rounded-full bg-gray-200 items-center justify-center"
            ><span
              *ngIf="selectedClosestShift === 'AFTER_NOON'"
              class="w-[12px] h-[12px] bg-[#2b5a68] flex rounded-full"
            ></span
          ></span>
          <p class="text-[18px]">{{'PRIVATE.Afternoon (12:00 Uhr - 18:00 Uhr)' | translate}}</p>
        </span>
      </div>
      }
    </div>
    } @else if(loadingSchedule){
    <div class="w-full flex flex-wrap h-[320px] gap-2">
      <div class="md:w-[49%] md:h-[47%] lg:w-[49%] lg:h-[47%] w-[100%] h-[24%] rounded-lg bg-slate-300 animate-pulse"></div>
      <div class="md:w-[49%] md:h-[47%] lg:w-[49%] lg:h-[47%] w-[100%] h-[24%] rounded-lg bg-slate-300 animate-pulse"></div>
      <div class="md:w-[49%] md:h-[47%] lg:w-[49%] lg:h-[47%] w-[100%] h-[24%] rounded-lg bg-slate-300 animate-pulse"></div>
      <div class="md:w-[49%] md:h-[47%] lg:w-[49%] lg:h-[47%] w-[100%] h-[24%] rounded-lg bg-slate-300 animate-pulse"></div>
    </div>
    }
    @else {
      <div class="text-[20px] text-black font-bold">Zurzeit sind keine Termine für diese Region verfügbar.</div>
    }
    <div class="flex flex-col md:flex-row gap-2 md:gap-0 lg:flex-row items-center justify-between" *ngIf="freeTimeSlots && freeTimeSlots.length > 0">
      <button
        (click)="openModal()"
        [disabled]="freeTimeSlots === undefined || freeTimeSlots < 1"
        class="border-[1px] bg-[#61BFD1] text-white font-bold rounded-[6px] px-4 py-3 hover:bg-[#61BFD1cb] disabled:bg-[#61BFD1cb] w-full md:w-[40%]"
      >
        {{ "PRIVATE.Alle verfügbare Termine anzeigen" | translate }}
      </button>
      @if(displaySelectedDay){
        <span class="rounded-lg bg-[#E9EBF4] py-2 px-4 md:p-2 font-semibold">{{ displaySelectedDay | date : "dd" }} {{ 'DATE.' + (displaySelectedDay | date : "MMMM") | translate }} {{ displaySelectedDay | date : "yyyy" }}, <span *ngIf="formatShift(displayShift)">{{'PRIVATE.' + formatShift(displayShift) | translate}}</span></span>
      }
    </div>
  </div>
  }
</ng-template>
<ng-template #standartCleaning4>
  <div class="flex flex-col">
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [checked]="suppliesState === 'true'"
        class="regular-checkbox"
        (change)="onSupplyChange(true)"
      />
      <span class="flex flex-col md:flex-row gap-0 md:gap-2">
        <p>{{"PRIVATE.Ja, ich besitze das erforderliche" | translate}}</p>
        <p (click)="openMaterials()" class="font-bold z-10 cursor-pointer text-blue-500 underline">{{'PRIVATE.Reinigunsmaterial' | translate}}.</p>
        </span>
    </label>
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [checked]="suppliesState === 'false'"
        class="regular-checkbox"
        (change)="onSupplyChange(false)"
      />
      {{ "PRIVATE.Nein - Ich bin mit dem Aufpreis von CHF 10.00 pro Reinigungsstunde einverstanden." | translate }}
    </label>
    @if(displayWarning){
      <p>{{ "PRIVATE.nomaterials" | translate }}</p>
    }
    @if(materialModal){
      <div class="absolute md:w-[640px] lg:w-[640px] h-[520px] md:h-[580px] lg:h-[580px] px-6 md:top-5 lg:top-5 top-0 md:right-[20%] lg:right-[20%] right-0 overflow-y-auto border-[1px] border-gray-700 bg-white shadow-lg p-4 z-50">
        <div class="flex flex-col ">
          <span class="flex justify-between w-full items-center py-2">
            <p class="font-bold text-[24px]">{{'MATERIALS.Benötigte Reingungsmaterialien' | translate}}</p>
            <img src="../assets/icons/exit.svg" alt="quit" class="w-6 h-6 cursor-pointer" (click)="closeMaterials()">
          </span>
          <div class="flex flex-col justify-between gap-2">
            <ul class="list-disc list-inside my-2 font-bold">
              <li>{{'MATERIALS.Staubsauger' | translate}}</li>
              <li>{{'MATERIALS.Mikrofasertücher (aus hygienischen Gründen mehrere Tücher)' | translate}}</li>
              <li>{{'MATERIALS.Putzschwamm Kratzfrei' | translate}}</li>
              <li>{{'MATERIALS.Mop-Set mit zwei Tüchern plus Eimer' | translate}}</li>
              <li>{{'MATERIALS.Swiffer Staubmagnet' | translate}}</li>
              <li>{{'MATERIALS.Entkalker' | translate}}</li>
              <li>{{'MATERIALS.Badreiniger' | translate}}</li>
              <li>{{'MATERIALS.Fettlöser' | translate}}</li>
              <li>{{'MATERIALS.Glasreiniger' | translate}}</li>
              <li>{{'MATERIALS.Herdreiniger' | translate}}</li>
              <li>{{'MATERIALS.Handschuhe' | translate}}</li>
              <li>{{'MATERIALS.WC Reiniger' | translate}}</li>
              <li>{{'MATERIALS.Allzweckreiniger' | translate}}</li>
            </ul>
    
            <p class="mt-4 text-black">{{'MATERIALS.cleaninginfo' | translate}}</p>
  
               <button class="btn-primary w-[50%] self-center" (click)="closeMaterials()">{{'MATERIALS.understood' | translate}}</button>
          </div>
        </div>
      </div>
    }
  </div>
</ng-template>
<ng-template #standartCleaning5>
  <div class="flex flex-col">
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [checked]="recycleState === 'true'"
        class="regular-checkbox"
        (change)="handleRecycling(true)"
      />
        {{ "PRIVATE.Ja, ich bin daran interessiert." | translate }}
    </label>
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [checked]="recycleState === 'false'"
        class="regular-checkbox"
        (change)="handleRecycling(false)"
      />
      {{ "PRIVATE.Nein, aktuell habe ich kein Interesse." | translate }}
    </label>
    @if(recycleState === 'true'){
      <div class="flex border bg-[#61bfd13a] rounded-lg p-2 md:w-[80%] border-[#00819A]">
        <img
        class="w-[38px] h-[38px] mt-1 mr-4"
        src="../assets/icons/mrGreen.png"
        alt="mrGreen"
      />
        <p>{{ "PRIVATE.recycleinfo" | translate }}- <a href="https://mr-green.ch/pages/ueber-uns" target="_blank" class="text-[#00819A]">{{ "PRIVATE.textlink" | translate }}</a></p>
      </div>
    }
  </div>
</ng-template>
<ng-template #standartCleaning6>
  @if(currentSubStep === 1){
    @if(token){ 
    <div class="max-h-[370px] gap-2 flex flex-col overflow-y-auto">
    @for(address of userAddresses; track $index){
        <div
          [ngClass]="{
            'w-full rounded-lg p-4 flex justify-between border-[1px] capitalize cursor-pointer hover:border-[#95e2e9]': true,
            'bg-[#61BFD1] text-white': selectedAddress === address.id   ,
            'opacity-55 border-[#61bfd185] hover:border-[#61bfd185] cursor-not-allowed' : convertInt(address.zipCode) !== convertInt(this.zip)
          }"
          (click)="convertInt(address.zipCode) === convertInt(this.zip) ? selectPrivAddress(address.id) : alertAddress()"
        >
          <!-- <pre>{{convertInt(address.zipCode) === convertInt(this.zip)}}</pre> -->
          <span class="w-[25%]">
            <p class="font-bold">Name:</p>
            {{ user.firstName }} {{ " " }} {{ user.lastName }}
          </span>
          <span class="w-[25%]">
            <p class="font-bold">{{ "AUTH.address" | translate }}:</p>
            {{ address.street }} {{ " " }} {{ address.streetNr }}
          </span>
          <span class="w-[25%]">
            <p class="font-bold">{{ "AUTH.zip" | translate }}:</p>
            {{ address.zipCode }}
          </span>
          <span class="w-[25%]">
            <p class="font-bold">{{ "AUTH.city" | translate }}:</p>
            {{ address.country === 'Switzerland' ? 'Schweiz' : '' }}, {{ " " }} {{ address.city }}
          </span>
        </div>
      }
    </div>
      <!-- COME HERE -->
  } @else { @if(confirm){
  <app-contact-form
    [login]="login"
    [toggleLogin]="toggleLogin.bind(this)"
    [loginForm]="loginForm"
    [registerForm]="registerForm"
    [loginFunction]="loginFunction.bind(this)"
    [registerFunction]="registerFunction.bind(this)"
    [zip]="zipFromQuery"
    [city]="cityFromZip"
    [markTouched]="markAllControlsAsTouched"
    (confirmPassword)="onConfirmPasswordChange($event)"
    (confirmationChange)="onConfirmationChange($event)"
    [hasAcceptedTerms]="hasAcceptedTerms"
    (hasAcceptedTermsChange)="toggleAcceptedTerms($event)"
  ></app-contact-form>
  } } }
  <!-- COME HERE -->
  @if(currentSubStep === 2) { 
    @if(userAddresses.length > 0){
  <!-- @if(!confirm){ -->
  <form [formGroup]="cleaningForm">
  <div class="max-h-[380px] w-full overflow-y-auto gap-6 flex flex-col">
    <p class="text-black text-[22px] font-bold">{{ "PROFILE.details" | translate }}</p>
    <span
      class="flex flex-col max-h-[300px] gap-4 px-10 overflow-y-auto w-full"
    >
    <form *ngIf="this.user.paymentType === null" class="flex justify-between items-center flex-col md:flex-row w-full py-2">
      <p class="w-full md:w-[50%] text-black">{{'PRIVATE.Bitte wählen Sie Ihre Zahlungsart aus' | translate}}</p>
      <select (change)="handlePaymentMethod($event)" class="text-black">
        <option value="" selected disabled hidden>{{'PRIVATE.Zahlungsart' | translate}}</option>
        <option value="CARD">{{'PROFILE.creditcard' | translate}}</option>
        <option value="INVOICE">{{'PROFILE.invoice' | translate}}</option>
      </select>
    </form>
    <span
    class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
  >
    <p class="w-[50%]">{{'PRIVATE.Vollständiger Name' | translate}}</p>
    <p class="text-black capitalize">{{user.firstName}} {{user.lastName}}</p>
  </span>
  <span
  class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
  >
    <p class="w-[50%]">E-Mail</p>
    <p class="text-black">{{user.email}}</p>
  </span>
  <span
  class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
  >
    <p class="w-[50%]">{{ "AUTH.phone" | translate }}</p>
    <p class="text-black">{{user.phoneNumber}}</p>
  </span>
  <span
    class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
  >
    <p class="w-[50%]">{{ "AUTH.address" | translate }}</p>
    <p class="text-black">{{address.street}} {{address.streetNr}}</p>
  </span>
  <span
    class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
  >
    <p class="w-[50%]">{{ "PRIVATE.zipcity" | translate }}</p>
    <p class="text-black">{{address.city}} {{address.zipCode}}</p>
  </span>
  <span
  *ngIf="this.user.paymentType === null && address.additionalInformation !== null"
  class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
  >
    <p class="w-[50%]">{{ 'PRIVATE.addressremark' | translate }}</p>
    <p class="text-black">{{address.additionalInformation}}</p>
  </span>
      <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
      >
        <p class="w-[50%]">{{ "PROFILE_MODALS.cleaningtype" | translate }}</p>
        <p class="text-black">{{ cleaningForm.get("serviceType")?.value.toLowerCase() === 'private' ? ("AUTH.private" | translate) : 'Business' }}</p>
      </span>
      <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
      >
        <p class="w-[50%]">{{ "PROFILE_MODALS.oftencleaning" | translate }}</p>
        <p class="text-black">{{'PRIVATE.' + cleaningForm.get("recurrence")?.value | translate}}</p>
      </span>
      <span *ngIf="cleaningForm.get('specialInstruction')?.value !== null"
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
      >
        <p class="w-[50%]">{{ 'PRIVATE.Zusätzliche Angaben' | translate }}</p>
        <p class="text-black">{{ cleaningForm.get('specialInstruction')?.value }}</p>
      </span>
      <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
      >
        <p class="w-[50%]">{{ "PROFILE_MODALS.startdate" | translate }}</p>
        <p class="text-black">
          {{ 'DATE.' + (cleaningForm.get("startDate")?.value | date : "EEEE") | translate }}, {{cleaningForm.get("startDate")?.value | date : "d"}} {{  'DATE.' + (cleaningForm.get("startDate")?.value | date : "MMMM") | translate }} {{cleaningForm.get("startDate")?.value | date : "y"}}
        </p>
      </span>
      <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
      >
        <p class="w-[50%]">{{'PRIVATE.Reinigungsmaterial vorhanden?' | translate}}</p>
        <p class="text-black">
          {{ cleaningForm.get("materialsIncluded")?.value ? ('PROFILE_MODALS.no'| translate) : ('PROFILE_MODALS.yes'| translate) }}
        </p>
      </span>
      <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
      >
        <p class="w-[50%]">{{ "PRIVATE.Gesamtbetrag" | translate }}</p>
        <span>
          <p class="text-black text-right">{{ price }} CHF</p>
          @if(!hasSupplies){
            <p class="text-black text-right">+ CHF 50</p>
            <p class="text-black text-right">{{'PRIVATE.pro Monat für Reinigungsmaterial' | translate}}</p>
          }
        </span>
      </span>
      <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
      >
        <p class="w-[50%]">{{'PROFILE.duration' | translate}}</p>
        <p class="text-black">{{ getHours() }} {{'PROFILE.hours' | translate}}</p>
      </span>
      <h1 class="font-bolD text-[20px]">{{'PRIVATE.Zusatzleistungen' | translate}}</h1>
      @for(service of cleaningForm.get('additionalServices')?.value | keyvalue;
      track service){
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
          *ngIf="service.value && service.key !== 'cabinetRemark'"
        >
        <p class="w-[50%]">{{'PRIVATE.' + summaryText(service.key) | translate}}</p>
        <p *ngIf="service.key === 'ironingDuration' || service.key === 'cabinetDuration'" class="text-black">{{ service.value }}min</p>
        <p *ngIf="summaryText(service.key) === 'Kühlschrank' || summaryText(service.key) === 'Backofen' || summaryText(service.key) === 'Pflanzen giessen'" class="text-black">30 min</p>

        </span>

        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
          *ngIf="service.value && service.key === 'cabinetRemark'"
        >
        <p class="w-[50%]">{{'PRIVATE.Bemerkung zu den Schränken' | translate}}</p>
        <p class="text-black">{{ service.value }}</p>
        </span>
      }
    </span>
    
    <span class="flex gap-3">
      <input [disabled]="this.paymentMethod.value === '' && this.user.paymentType === null" type="checkbox" (change)="toggleTerms()">
      <p class="text-violet-400">
        {{'AUTH.termstext' | translate}} <a class="underline cursor-pointer text-blue-500" (click)="openFile()">{{'AUTH.terms' | translate}}.</a>
      </p>
    </span>
  </div>
</form>
  } @else {
  <p class="text-[20p] font-bold">Redirecting to payment page...</p>
  } } @if(currentSubStep === 3){
  <div class="flex flex-col gap-4">
    <p class="text-[#7E7E7E] text-[20px]">
      {{
        "PRIVATE.Sie werden in wenigen Minuten eine Bestätigung per Mail erhalten."
          | translate
      }}
    </p>
    <p class="text-[12px]">
      {{
        "PRIVATE.Sie haben keine Bestätigung erhalten? Bitte setzten Sie sich direkt mit uns in Verbindung."
          | translate
      }}
    </p>
  </div>
  }
</ng-template>

<!-- Express Cleaning -->
<ng-template #expressCleaning1>
  @if(currentSubStep === 1){
  <ng-container *ngIf="currentSubStep === 1">
    <div class="grid md:grid-cols-3 grid-rows-2 gap-4">
      @for(item of cards; track item){
      <div
        (click)="selectCleaningType(item.id, item.title)"
        [ngClass]="{
          'rounded-tl-md p-2 shadow-none md:shadow-xl border-[#61BFD19f] md:border-transparent lg:border-transparent border-[1px] rounded-br-md flex flex-col gap-2 justify-center items-center w-full cursor-pointer hover:border-[#61BFD19f]': true,
          'bg-[#61BFD1]': item.id === selectedCleaningType
        }"
      >
        <p
          [ngClass]="{
            'text-[24px] font-bold text-center': true,
            'text-white': item.id === selectedCleaningType
          }"
        >
          {{ "PRIVATE." + item.title | translate }}
        </p>
        @if(!item.list){
        <p
          [ngClass]="{
            'text-[16px] px-2 p-1 bg-white': true,
            'text-violet-400 rounded-md':
              item.id === selectedCleaningType
          }"
        >
          {{ "PRIVATE." + item.description | translate }}
        </p>
        } @else if(item.list){
        <ul
          [ngClass]="{
            'list-disc': true,
            'text-white': item.id === selectedCleaningType
          }"
        >
          <li *ngFor="let listItem of item.list">
            {{ "PRIVATE." + listItem | translate }}
          </li>
        </ul>
        }
      </div>
      }
    </div>
  </ng-container>
  } 
  @if(currentSubStep === 2) {
    <ng-container>
      <div class="text-black py-2">
        <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Expressreinigung – Sauberkeit zum Wunschtermin'| translate}}</h4>
        <p>{{'EXPLAINATION_PAGE.In unserer hektischen Welt bleibt oft wenig Zeit für Reinigungen. Und dann muss es schnell geheb. Genau hier kommt unsere Expressreinigung ins Spiel. Mit unserem erstklassigen Service bieten wir Ihnen die Möglichkeit, Ihre Reinigungsbedürfnisse spontan und unkompliziert zu erfüllen – ganz nach Ihrem Zeitplan. Machen Sie es sich einfach und konzentrieren sich auf Wichtigeres.'| translate}}</p>
      </div>
      <div class="text-black py-2">
        <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Was ist die Expressreinigung?'| translate}}</h4>
        <p>{{'EXPLAINATION_PAGE.Unsere Expressreinigung ist der ideale Service für alle, die eine sofortige und effiziente Reinigung benötigen. Egal, ob Sie unerwartet Gäste erwarten, eine Veranstaltung vorbereiten oder einfach spontan eine gründliche Reinigung wünschen – wir sind zur Stelle.' | translate}}</p>
      </div>
      <div class="text-black py-2">
        <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Was muss ich zur Verfügung stellen?' | translate}}</h4>
        <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Zugang:' | translate}}</span> {{'EXPLAINATION_PAGE.Damit unser Personal Zutritt zu Ihren Räumlichkeiten hat, hinterlegen Sie bitte einen Schlüssel oder empfangen Sie das Personal persönlich.' | translate}}</p>
        <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Reinigungsmaterial:' | translate}}</span> {{'EXPLAINATION_PAGE.Damit unser Personal eine einwandfreie Reinigung durchführen kann, bitten wir Sie die geeigneten Reinigungsmaterialen zu Verfügung zu stellen. Eine genaue Auflistung der Materialien finden Sie hier. Sollten Sie das passende Reinigungsmaterial nicht zur Hand haben, stellen wir dies gerne gegen einen Aufpreis zur Verfügung.' | translate}}</p>
      </div>
      <div class="text-black py-2">
        <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Ihre Vorteile auf einen Blick:' | translate}}</h4>
        <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Flexibilität:' | translate}}</span> {{'EXPLAINATION_PAGE.Buchen Sie Ihre Reinigung ganz einfach online. Geben Sie Ihren gewünschten Termin und die Uhrzeit, an zahlen Sie Ihre Reinigung online - wir kümmern uns um den Rest.' | translate}}</p>
        <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Extras:' | translate}}</span> {{'EXPLAINATION_PAGE.Wählen Sie Zusatzleistungen nach Bedarf!'| translate}}</p>
        <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Schnelligkeit:' | translate}}</span> {{'EXPLAINATION_PAGE.Unser Team ist darauf spezialisiert, schnell und effizient zu arbeiten, ohne dabei Abstriche bei der Qualität zu machen.' | translate}}</p>
        <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Zuverlässigkeit:' | translate}}</span> {{'EXPLAINATION_PAGE.Sie können sich auf uns verlassen. Pünktlichkeit und Verlässlichkeit sind für uns selbstverständlich.' | translate}}</p>
        <p>{{'EXPLAINATION_PAGE.Falls sich doch was ändern sollte, können Sie jederzeit Änderungen Ihrer Buchung in unserem System selbst vornehmen.' | translate}}</p>
        <h4 class="text-[20px] mt-3">{{'EXPLAINATION_PAGE.Buchen Sie noch heute Ihre Expressreinigung!' | translate}}</h4>
      </div>
    </ng-container>
  }
  @if(currentSubStep === 3){
      <ng-container>
        <div class="flex flex-col gap-8 py-4 items-center">
          <div
            class="flex items-center gap-8 text-center justify-between md:p-14 lg:p-14 p-2 rounded-tl-lg rounded-br-lg shadow-xl"
          >
            <button
              class="w-12 h-12 px-2 bg-[#61BFD1] rounded-lg flex items-center justify-center"
              (click)="setHours('decrease')"
            >
              <img src="../assets/icons/minus.svg" alt="minus" />
            </button>
            <div>
              <p class="text-[24px] text-[#61BFD1] font-bold">
                {{ getHours() }}
                {{ "PRIVATE.Stunden" | translate }}
              </p>
              <p class="text-[16px] text-[#61BFD1]">
                {{ bedrooms }} {{ "PRIVATE.Zimmer" | translate }}
                {{ "PRIVATE.und" | translate }} {{ bathrooms }}
                {{ "PRIVATE.Badezimmer" | translate }}
              </p>
            </div>
            <button
              class="w-12 h-12 bg-[#61BFD1] px-2 rounded-lg flex items-center justify-center"
              (click)="setHours('increase')"
            >
              <img src="../assets/icons/plus.svg" alt="plus" />
            </button>
          </div>
          <div class="flex flex-col md:flex-row lg:flex-row gap-6">
            <button
              (click)="openCalc()"
              class="border-[1px] disabled:bg-[#6c91d785] bg-[#61BFD1] text-white font-bold rounded-[6px] px-12 py-4 hover:bg-[#61BFD1cb]"
            >
              {{ "PRIVATE.Zeit berechnen" | translate }}
            </button>
            <button
              (click)="openAddComment()"
              class="border-[1px] disabled:opacity-25 border-[#61BFD1] font-bold rounded-[6px] px-12 py-4 hover:bg-[#61bfd13a]"
            >
              {{ "PRIVATE.Bemerkung hinzufügen" | translate }}
            </button>
          </div>
        </div>
      </ng-container>
  }
</ng-template>
<ng-template #expressCleaning2>
  @if(currentSubStep === 1){
    <div class="flex flex-wrap gap-4">
      <ng-container>
        @for(item of secondStepCards; track $index){
        <div
          (click)="selectExtraService(item)"
          [ngClass]="{
            'flex p-6 flex-col w-full 2xl:max-w-[31%] md:w-[47%] shadow-none md:shadow-xl rounded-tl-lg rounded-br-lg cursor-pointer border-[1px] border-[#61BFD1] md:border-transparent hover:border-[#61BFD1]': true,
            'bg-[#61BFD1] text-white': checkSelectedAddOn(item.id)
          }"
        >
          <div class="flex items-center gap-4 capitalize">
            <img
              [src]="checkSelectedAddOn(item.id) ? item.iconActive : item.icon"
              alt="window"
              class="max-w-[28px]"
            />
            {{ "PRIVATE." + item.name | translate }}
          </div>
          <div class="text-[24px] font-bold pt-3">{{'PRIVATE.' + item.description | translate }}</div>
        </div>
        }
      </ng-container>
    </div>
    }
</ng-template>
<ng-template #expressCleaning3>
  @if(expressTimeSlots && expressTimeSlots.length > 0){
    <div class="flex flex-col gap-2 md:max-h-[280px] lg:max-h-[280px]">
      <div class="flex w-full md:w-[73%] justify-between">
        <div class="flex p-2 bg-[#E9EBF4] rounded-lg">{{getHours()}} {{'PROFILE.hours' | translate}}</div>
        <div class="flex md:w-[30%] lg:w-[30%] w-[50%] justify-between items-center">
          <button (click)="prevMonth()">
            <img src="../assets/icons/Vector-left.svg" alt="" />
          </button>
          <p>{{ 'DATE.' + (viewDate | date : "MMMM") | translate }} {{ viewDate | date : "y" }}</p>
          <button (click)="changeMonth()">
            <img src="../assets/icons/Vector-right.svg" alt="" />
          </button>
        </div>
      </div>
      <div class="flex w-full flex-col md:flex-row lg:flex-row justify-between">
        <app-calendar
          [disabledDays]="expressTimeSlots"
          class="md:w-[72%] lg:w-[72%] w-[100%] shadow-lg"
          [viewDate]="viewDate"
          (selectedDate)="getSelectedDay($event)"
          (viewDateUpdate)="updateViewDate($event)"
        ></app-calendar>
        <div class="w-[100%] md:w-[26%] mt-6 md:mt-0 items-center justify-center md:justify-normal md:items-start flex flex-wrap md:flex-nowrap md:flex-col gap-4 overflow-auto md:h-[290px]">
          @for(item of selectedExpressDate; track $index){
            @for(slot of item.schedule; track $index){
              <!-- @if(checkExceedExpress(slot.time.substring(0,2))){ -->
                <button
                [disabled]="!checkExceedExpress(slot.time.substring(0,2))"
                  (click)="selectCleaningHour(slot.time)"
                  [ngClass]="{
                    'md:w-[80%] w-auto md:mt-0 lg:mt-0 rounded-br-lg self-end rounded-tl-lg border-[1px] md:border-0 rounded-xl md:rounded-none md:shadow-lg p-4 disabled:text-gray-500 disabled:bg-gray-50': true,
                    'bg-[#61BFD1] text-white': selectedExpressHour === slot.time
                  }"
                >
                  {{slot.time}}
                </button>
              <!-- } -->
            }
          }
        </div>
      </div>
    </div>
  }
  @else if(loadingSchedule){
    <div class="flex w-full h-[370px] bg-slate-300 rounded-lg animate-pulse"></div>
  }@else {
    <div class="text-[20px] font-bold text-black">Zurzeit sind keine Termine für diese Region verfügbar.</div>
  }
</ng-template>
<ng-template #expressCleaning4>
  <div class="flex flex-col">
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [checked]="suppliesState === 'true'"
        class="regular-checkbox"
        (change)="onSupplyChange(true)"
      />
      <span class="flex flex-col md:flex-row gap-0 md:gap-2">
        <p>{{"PRIVATE.Ja, ich besitze das erforderliche" | translate}}</p>
        <p (click)="openMaterials()" class="font-bold z-10 cursor-pointer text-blue-500 underline">{{'PRIVATE.Reinigunsmaterial' | translate}}.</p>
        </span>
    </label>
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [checked]="suppliesState === 'false'"
        class="regular-checkbox"
        (change)="onSupplyChange(false)"
      />
      {{ "PRIVATE.Nein - Ich bin mit dem Aufpreis von CHF 50.00 einverstanden." | translate }}
    </label>
    @if(displayWarning){
      <p>{{ "PRIVATE.nomaterials" | translate }}</p>
    }
    @if(materialModal){
      <div class="absolute md:w-[640px] lg:w-[640px] h-[520px] md:h-[580px] lg:h-[580px] px-6 md:top-5 lg:top-5 top-0 md:right-[20%] lg:right-[20%] right-0 overflow-y-auto border-[1px] border-gray-700 bg-white shadow-lg p-4 z-50">
        <div class="flex flex-col ">
          <span class="flex justify-between w-full items-center py-2">
            <p class="font-bold text-[24px]">{{'MATERIALS.Benötigte Reingungsmaterialien' | translate}}</p>
            <img src="../assets/icons/exit.svg" alt="quit" class="w-6 h-6 cursor-pointer" (click)="closeMaterials()">
          </span>
          <div class="flex flex-col justify-between gap-2">
            <ul class="list-disc list-inside my-2 font-bold">
              <li>{{'MATERIALS.Staubsauger' | translate}}</li>
              <li>{{'MATERIALS.Mikrofasertücher (aus hygienischen Gründen mehrere Tücher)' | translate}}</li>
              <li>{{'MATERIALS.Putzschwamm Kratzfrei' | translate}}</li>
              <li>{{'MATERIALS.Mop-Set mit zwei Tüchern plus Eimer' | translate}}</li>
              <li>{{'MATERIALS.Swiffer Staubmagnet' | translate}}</li>
              <li>{{'MATERIALS.Entkalker' | translate}}</li>
              <li>{{'MATERIALS.Badreiniger' | translate}}</li>
              <li>{{'MATERIALS.Fettlöser' | translate}}</li>
              <li>{{'MATERIALS.Glasreiniger' | translate}}</li>
              <li>{{'MATERIALS.Herdreiniger' | translate}}</li>
              <li>{{'MATERIALS.Handschuhe' | translate}}</li>
              <li>{{'MATERIALS.WC Reiniger' | translate}}</li>
              <li>{{'MATERIALS.Allzweckreiniger' | translate}}</li>
            </ul>
    
            <p class="mt-4 text-black">{{'MATERIALS.cleaninginfo' | translate}}</p>
  
               <button class="btn-primary w-[50%] self-center" (click)="closeMaterials()">{{'MATERIALS.understood' | translate}}</button>
          </div>
        </div>
      </div>
    }
  </div>
</ng-template>
<ng-template #expressCleaning5>
  <div class="flex flex-col">
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [checked]="recycleState === 'true'"
        class="regular-checkbox"
        (change)="handleRecycling(true)"
      />
        {{ "PRIVATE.Ja, ich bin daran interessiert." | translate }}
    </label>
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [checked]="recycleState === 'false'"
        class="regular-checkbox"
        (change)="handleRecycling(false)"
      />
      {{ "PRIVATE.Nein, aktuell habe ich kein Interesse." | translate }}
    </label>
    @if(recycleState === 'true'){
      <div class="flex border bg-[#61bfd13a] rounded-lg p-2 md:w-[80%] border-[#00819A]">
        <img
        class="w-[38px] h-[38px] mt-1 mr-4"
        src="../assets/icons/mrGreen.png"
        alt="mrGreen"
      />
        <p>{{ "PRIVATE.recycleinfo" | translate }}- <a href="https://mr-green.ch/pages/ueber-uns" target="_blank" class="text-[#00819A]">{{ "PRIVATE.textlink" | translate }}</a></p>
      </div>
    }
  </div>
</ng-template>
<ng-template #expressCleaning6>
  @if(currentSubStep === 1){ 
    @if(token){
       <div class="max-h-[370px] gap-2 flex flex-col overflow-y-auto">
      @for(address of userAddresses; track $index){
        <div
        [ngClass]="{
          'w-full rounded-lg p-4 flex justify-between border-[1px] capitalize cursor-pointer hover:border-[#95e2e9]': true,
          'bg-[#61BFD1] text-white': selectedAddress === address.id   ,
          'opacity-55 border-[#61bfd185] hover:border-[#61bfd185] cursor-not-allowed' : convertInt(address.zipCode) !== convertInt(this.zip)
        }"
        (click)="convertInt(address.zipCode) === convertInt(this.zip) ? selectExpressAddress(address.id) : alertAddress()"
      >
        <span class="w-[25%]">
          <p class="font-bold">Name:</p>
          {{ user.firstName }} {{ " " }} {{ user.lastName }}
        </span>
        <span class="w-[25%]">
          <p class="font-bold">{{ "AUTH.address" | translate }}:</p>
          {{ address.street }} {{ " " }} {{ address.streetNr }}
        </span>
        <span class="w-[25%]">
          <p class="font-bold">{{ "AUTH.zip" | translate }}:</p>
          {{ address.zipCode }}
        </span>
        <span class="w-[25%]">
          <p class="font-bold">{{ "AUTH.city" | translate }}:</p>
          {{ address.country === 'Switzerland' ? 'Schweiz' : '' }}, {{ " " }} {{ address.city }}
        </span>
      </div>
    }
    </div>
    <!-- COME HERE -->
    } @else { @if(confirm){
    <app-contact-form
      [login]="login"
      [toggleLogin]="toggleLogin.bind(this)"
      [loginForm]="loginForm"
      [registerForm]="registerForm"
      [loginFunction]="loginFunction.bind(this)"
      [registerFunction]="registerFunction.bind(this)"
      [zip]="zipFromQuery"
      [city]="cityFromZip"
      [markTouched]="markAllControlsAsTouched"
      (confirmPassword)="onConfirmPasswordChange($event)"
      (confirmationChange)="onConfirmationChange($event)"
      [hasAcceptedTerms]="hasAcceptedTerms"
      (hasAcceptedTermsChange)="toggleAcceptedTerms($event)"
    ></app-contact-form>
    } } }
    <!-- COME HERE -->
    @if(currentSubStep === 2) { @if(userAddresses.length > 0){
    <!-- @if(!confirm){ -->
      <form [formGroup]="expressForm">
    <div class="max-h-[380px] w-full overflow-y-auto gap-6 flex flex-col">
      <p class="text-black text-[22px] font-bold">{{ "PROFILE.details" | translate }}</p>
      <span
        class="flex flex-col max-h-[300px] gap-4 px-10 overflow-y-auto w-full"
      >
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PRIVATE.Vollständiger Name' | translate}}</p>
          <p class="text-black">{{user.firstName}} {{user.lastName}}</p>
        </span>
        <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">E-Mail</p>
          <p class="text-black">{{user.email}}</p>
        </span>
        <span
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ "AUTH.phone" | translate }}</p>
          <p class="text-black">{{user.phoneNumber}}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ "AUTH.address" | translate }}</p>
          <p class="text-black">{{address.street}} {{address.streetNr}}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ "PRIVATE.zipcity" | translate }}</p>
          <p class="text-black">{{address.city}} {{address.zipCode}}</p>
        </span>
        <span
        *ngIf="this.user.paymentType === null && address.additionalInformation !== null"
        class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ 'PRIVATE.addressremark' | translate }}</p>
          <p class="text-black">{{address.additionalInformation}}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ "PROFILE_MODALS.cleaningtype" | translate }}</p>
          <p class="text-black capitalize">{{ expressForm.get("serviceType")?.value.toLowerCase() === 'private' ?  ("AUTH.private" | translate) : 'Business'}}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ "PROFILE_MODALS.oftencleaning" | translate }}</p>
          <p class="text-black">{{ 'PRIVATE.' + expressForm.get("recurrence")?.value | translate }}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ 'PRIVATE.Zusätzliche Angaben' | translate }}</p>
          <p class="text-black">{{ expressForm.get('specialInstruction')?.value }}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ "PROFILE_MODALS.startdate" | translate }}</p>
          <p class="text-black">
            {{ 'DATE.' + (expressForm.get("startDate")?.value | date : "EEEE") | translate }}, {{expressForm.get("startDate")?.value | date : "d"}} {{  'DATE.' + (expressForm.get("startDate")?.value | date : "MMMM") | translate }} {{expressForm.get("startDate")?.value | date : "y"}}
          </p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PRIVATE.Reinigungsmaterial vorhanden?' | translate}}</p>
          <p class="text-black">
            {{ expressForm.get("materialsIncluded")?.value ? ('PROFILE_MODALS.no'| translate) : ('PROFILE_MODALS.yes'| translate) }}
          </p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ "PRIVATE.Gesamtbetrag" | translate }}</p>
          <p class="text-black">{{ price }} CHF</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ "PROFILE.duration" | translate }}</p>
          <p class="text-black">{{ getHours() }} {{ "PROFILE.hours" | translate }}</p>
        </span>
        <h1 class="font-bolD text-[20px]">{{'PRIVATE.Zusatzleistungen' | translate}}</h1>
        @for(service of expressForm.get('additionalServices')?.value | keyvalue; track $index){
          <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
          *ngIf="service.value && service.key !== 'cabinetRemark'"
        >
          <p class="w-[50%]">{{'PRIVATE.' + summaryText(service.key) | translate}}</p>
          <p *ngIf="service.key === 'ironingDuration' || service.key === 'cabinetDuration'" class="text-black">{{ service.value }}min</p>
          <p *ngIf="summaryText(service.key) === 'Kühlschrank' || summaryText(service.key) === 'Backofen' || summaryText(service.key) === 'Pflanzen giessen'" class="text-black">30 min</p>

          </span>

          <span
            class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
            *ngIf="service.value && service.key === 'cabinetRemark'"
          >
          <p class="w-[50%]">{{'PRIVATE.Bemerkung zu den Schränken' | translate}}</p>
          <p class="text-black">{{ service.value }}</p>
        </span>
        }

        
        <!-- <span
          class="flex justify-between items-center w-full py-2 border-b-2 border-[#acacac]"
         >
        <p class="w-[50%]">{{'PRIVATE.Rabattcode' | translate}}</p>
        <div class="flex flex-col">
          <input 
          [ngClass]="{
            'custom-green border-green-600': correctExpressAnswer === true,
            'custom-red border-red-500': correctExpressAnswer === false
          }" 
          (change)="handleExpressCoupon($event)" 
          class="border outline-none text-black" 
          type="text" 
          placeholder="{{'PRIVATE.Rabattcode' | translate}}">
          <span class="text-black">
            @if(correctExpressAnswer === true) {
              {{'PRIVATE.Rabattcode erfolgreich verwendet' | translate}}
            } @else if (correctExpressAnswer === false) {
              {{'PRIVATE.Rabattcode ungültig' | translate}}
            }
          </span>
        </div>
      </span> -->
      <span
      class="flex justify-between items-center w-full py-2 border-b-2 border-[#acacac]"
    >
    <p class="w-[50%]">{{'PRIVATE.Rabattcode' | translate}}</p>
    <form [formGroup]="discountForm" class="flex flex-col">
      <input 
      [ngClass]="{
        'custom-green border-green-600': correctAnswer === true,
        'custom-red border-red-500': correctAnswer === false
      }" 
      (blur)="checkDiscountCode(this.formType)"
      formControlName="couponCode" 
      class="border outline-none text-black" 
      type="text" 
      placeholder="{{'PRIVATE.Rabattcode' | translate}}">
        <span class="text-black">
          @if(correctAnswer === true) {
            {{'PRIVATE.Rabattcode erfolgreich verwendet' | translate}}
          } @else if (correctAnswer === false) {
            {{'PRIVATE.Rabattcode ungültig' | translate}}
          }
        </span>
    </form>
    </span>


      </span>
      <span class="flex gap-3">
        <input type="checkbox" (change)="toggleTerms()">
        <p class="text-violet-400">
          {{'AUTH.termstext' | translate}} <a class="underline cursor-pointer text-blue-500" (click)="openFile()">{{'AUTH.terms' | translate}}.</a>
        </p>
      </span>
    </div>
  </form>

    } @else {
    <p class="text-[20p] font-bold">Redirecting to payment page...</p>
    } } @if(currentSubStep === 3){
    <div class="flex flex-col gap-4">
      <p class="text-[#7E7E7E] text-[20px]">
        {{
          "PRIVATE.Sie werden in wenigen Minuten eine Bestätigung per Mail erhalten."
            | translate
        }}
      </p>
      <p class="text-[12px]">
        {{
          "PRIVATE.Sie haben keine Bestätigung erhalten? Bitte setzten Sie sich direkt mit uns in Verbindung."
            | translate
        }}
      </p>
    </div>
    }
</ng-template>

<!-- Special cleaning & services & insurance -->
<ng-template #specialCleaning1>
  @if(currentSubStep === 1){
  <ng-container *ngIf="currentSubStep === 1">
    <div class="grid md:grid-cols-3 grid-rows-2 gap-4">
      @for(item of cards; track $index){
      <div
        (click)="selectCleaningType(item.id, item.title)"
        [ngClass]="{
          'rounded-tl-md p-2 shadow-none md:shadow-xl border-[#61BFD19f] md:border-transparent lg:border-transparent border-[1px] rounded-br-md flex flex-col gap-2 justify-center items-center w-full cursor-pointer hover:border-[#61BFD19f]': true,
          'bg-[#61BFD1]': item.id === selectedCleaningType
        }"
      >
        <p
          [ngClass]="{
            'text-[24px] font-bold text-center': true,
            'text-white': item.id === selectedCleaningType
          }"
        >
          {{ "PRIVATE." + item.title | translate }}
        </p>
        @if(!item.list){
        <p
          [ngClass]="{
            'text-[16px] px-2 p-1 bg-white': true,
            'text-violet-400 font-bold rounded-md':
              item.id === selectedCleaningType
          }"
        >
          {{ "PRIVATE." + item.description | translate }}
        </p>
        } @else if(item.list){
        <ul
          [ngClass]="{
            'list-disc': true,
            'text-white': item.id === selectedCleaningType
          }"
        >
          <li *ngFor="let listItem of item.list">
            {{ "PRIVATE." + listItem | translate }}
          </li>
        </ul>
        }
      </div>
      }
    </div>
  </ng-container>
  } 
  @if(selectedCleaningType === 3 || selectedCleaningType === 4) {
    @if(currentSubStep === 2){
    <ng-container>
      <div class="flex flex-wrap justify-between gap-4 py-2">
        @if(selectedCleaningType === 3){
          @for(item of specialCleaningCards; track $index){
          <div
            (click)="selectSpecialType(item.id, item.value)"
            [ngClass]="{
              'bg-[#61BFD1] border-2 text-white border-transparent':
                item.id === selectedSpecialCleaning,
              'rounded-tl-md min-h-[160px] cursor-pointer shadow-xl rounded-br-md flex flex-col gap-2 justify-center items-center w-full md:w-[31%] border-transparent hover:border-[#61BFD19f] border-[1px]': true
            }"
          >
            <p class="text-[24px] font-bold">
              {{ "PRIVATE." + item.title | translate }}
            </p>
            <span class="px-4 text-center">
              @switch (item.id) {
                @case(1){
                  <p>{{'SPECIAL.Erfrischende Klarheit für Ihre Fenster: Professionelle Reinigung für strahlenden Glanz und klare Sicht im Frühling!' | translate}}</p>
                }
                @case(2){
                  <p>{{'SPECIAL.Für ein makelloses Finish: Gründliche Reinigung, um Ihre Räumlichkeiten in einem Top-Zustand zu übergeben.'| translate}}</p>
                }
                @case(3){
                  <p>{{'SPECIAL.Die solide Basis für Ihre Sauberkeit im Büro, Gewerbe und Zuhause – zu jeder Zeit.' | translate}}</p>
                }
              }
            </span>
          </div>
          }
  
        } 
        @else if(selectedCleaningType === 4){
          @for(item of specialServicesCards; track $index){
            <div
              (click)="selectSpecialType(item.id, item.value)"
              [ngClass]="{
                'bg-[#61BFD1] border-2 text-white border-transparent':
                  item.id === selectedSpecialCleaning,
                'rounded-tl-md min-h-[120px] cursor-pointer shadow-xl rounded-br-md flex flex-col gap-2 justify-center items-center w-full md:w-[31%] hover:border-[#61BFD19f] hover:border-2': true
              }"
            >
              <p class="text-[24px] font-bold">
                {{ "PRIVATE." + item.title | translate }}
              </p>
            </div>
          }
          <div class="rounded-tl-md min-h-[120px] cursor-pointer shadow-xl rounded-br-md flex flex-col gap-2 justify-center items-center w-full md:w-[31%] hover:border-[#61BFD19f] hover:border-2">
            <a href="https://mr-green.ch/pages/ueber-uns" target="_blank" class="text-[24px] font-bold">
              {{'PRIVATE.Link zum Recycling' | translate}}
            </a>
          </div>
        }
        @else if(selectedCleaningType === 5){
          @for(item of insuranceCleaningCards; track $index){
            <div
              (click)="selectSpecialType(item.id, item.value)"
              [ngClass]="{
                'bg-[#61BFD1] border-2 text-white border-transparent':
                  item.id === selectedSpecialCleaning,
                'rounded-tl-md min-h-[120px] cursor-pointer shadow-xl rounded-br-md flex flex-col gap-2 justify-center items-center w-full md:w-[31%] hover:border-[#61BFD19f] hover:border-2': true
              }"
            >
              <p class="text-[24px] font-bold">
                {{ "PRIVATE." + item.title | translate }}
              </p>
            </div>
          }
        }
      </div>
    </ng-container>
    }
    @if(currentSubStep === 3) {
          @if(this.bookingForm.get('type')?.value === 'WINDOW_CLEANING') {
            <ng-container>
              <div class="text-black py-2">
                <h4 class="text-[24px] font-bold">{{'EXPLAINATION_PAGE.Fensterreinigung – Klare Sicht, strahlende Fenster' | translate}}</h4>
                <p>{{'EXPLAINATION_PAGE.Glasklare Fenster verleihen jedem Raum ein helles und freundliches Ambiente. Mit unserem professionellen Fensterreinigungsservice sorgen wir dafür, dass Ihre Fenster stets in bestem Licht erstrahlen und Ihnen eine ungetrübte Sicht bieten. Gönnen Sie sich eine Fensterreinigung für einen sauberen Weitblick.' | translate}}</p>
              </div>
              <div class="text-black py-2">
                <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Was ist die Fensterreinigung?' | translate}}</h4>
                <p>{{'EXPLAINATION_PAGE.Unsere Fensterreinigung umfasst die gründliche Reinigung aller Fensterflächen, Rahmen und Fensterbänke. Innen sowie aussen – egal in welcher Größe oder in welchem Stockwerk.' | translate}}</p>
              </div>
              <div class="text-black py-2">
                <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Ihre Vorteile auf einen Blick:' | translate}}</h4>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Perfekte Klarheit:' | translate}}</span> {{'EXPLAINATION_PAGE.Unsere Experten verwenden spezielle Techniken und Reinigungsmittel, um Ihre Fenster makellos sauber zu hinterlassen.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Schonende Reinigung:' | translate}}</span> {{'EXPLAINATION_PAGE.Wir achten darauf, Ihre Fenster und Rahmen materialschonend zu reinigen, um deren Lebensdauer zu verlängern.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Erfahrung:' | translate}}</span> {{'EXPLAINATION_PAGE.Mit jahrelanger Erfahrung im Bereich der Fensterreinigung kennen wir die besten Methoden, um auch hartnäckige Verschmutzungen zu beseitigen.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Sicherheit:' | translate}}</span> {{'EXPLAINATION_PAGE.Unser Team ist professionell geschult und arbeitet sicher, auch in hohen und schwer zugänglichen Bereichen.' | translate}}</p>
              </div>
            </ng-container>
          }
          @if(this.bookingForm.get('type')?.value === 'END_OF_TENANCY') {
            <ng-container>
              <div class="text-black py-2">
                <h4 class="text-[24px] font-bold">{{'EXPLAINATION_PAGE.Endreinigung mit Übergabegarantie – Sorglose Übergabe, perfekte Sauberkeit' | translate}}</h4>
                <p>{{'EXPLAINATION_PAGE.Der Umzug in eine neue Wohnung oder das Ende eines Mietverhältnisses bringt oft großen Aufwand mit sich. Unsere Endreinigung mit Übergabegarantie nimmt Ihnen diesen Stress ab und sorgt dafür, dass Ihre Räumlichkeiten in bestem Zustand übergeben werden können. Investieren Sie in unsere Qualitätsreinigung und Ihre Kaution ist gesichert.' | translate}}</p>
              </div>
              <div class="text-black py-2">
                <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Was ist die Endreinigung mit Übergabegarantie?'| translate}}</h4>
                <p>{{'EXPLAINATION_PAGE.Unsere Endreinigung mit Übergabegarantie umfasst eine gründliche und umfassende Reinigung aller Wohn- und Nutzflächen. Wir stellen sicher, dass alle Reinigungsarbeiten den Anforderungen der Abnahmeprotokolle entsprechen, sodass die Übergabe an den Vermieter reibungslos verläuft.' | translate}}</p>
              </div>
              <div class="text-black py-2">
                <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Ihre Vorteile auf einen Blick:' | translate}}</h4>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Rundum-Sorglos-Paket:' | translate}}</span> {{'EXPLAINATION_PAGE.Wir kümmern uns um alle Reinigungsarbeiten, von Böden und Fenstern bis hin zu Küchengeräten und Sanitärbereichen.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Garantierte Abnahme:' | translate}}</span> {{'EXPLAINATION_PAGE.Dank unserer Übergabegarantie können Sie sicher sein, dass die Reinigung den hohen Standards entspricht und die Übergabe problemlos abläuft.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Zeit-und Stressersparnis:' | translate}}</span> {{'EXPLAINATION_PAGE.Lehnen Sie sich zurück und überlassen Sie uns die Arbeit, während Sie sich auf Ihren Umzug oder andere wichtige Aufgaben konzentrieren.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Erfahrung und Professionalität:' | translate}}</span> {{'EXPLAINATION_PAGE.Unser Team verfügt über jahrelange Erfahrung und kennt die Anforderungen und Standards für eine erfolgreiche Wohnungsübergabe.' | translate}}</p>
              </div>
            </ng-container>
          }
          @if(this.bookingForm.get('type')?.value === 'BASIC_CLEANING') {
          <ng-container>
            <div class="text-black py-2">
              <h4 class="text-[24px] font-bold">{{'EXPLAINATION_PAGE.Grundreinigung – Tiefenreinigung für nachhaltige Sauberkeit' | translate}}</h4>
              <p>{{'EXPLAINATION_PAGE.Manchmal reicht eine normale Reinigung nicht aus, um hartnäckigen Schmutz und Ablagerungen zu beseitigen. Mit unserem Service der Grundreinigung sorgen wir für eine gründliche und tiefgehende Reinigung Ihrer Räumlichkeiten, damit alles wieder in neuem Glanz erstrahlt. Gönnen Sie sich einen reboot Ihrer Räumlichkeiten.' | translate}}</p>
            </div>
            <div class="text-black py-2">
              <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Was ist die Grundreinigung?' | translate}}</h4>
              <p>{{'EXPLAINATION_PAGE.Unsere Grundreinigung umfasst eine intensive Reinigung aller Oberflächen, Ecken und schwer zugänglichen Bereiche. Diese umfassende Reinigung geht über die normale Unterhaltsreinigung hinaus und eignet sich besonders für stark verschmutzte Räume oder nach besonderen Ereignissen.' | translate}}</p>
            </div>
            <div class="text-black py-2">
              <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Ihre Vorteile auf einen Blick:' | translate}}</h4>
              <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Tiefenreinigung:' | translate}}</span> {{'EXPLAINATION_PAGE.Wir entfernen gründlich auch hartnäckigen Schmutz und Ablagerungen, die sich im Laufe der Zeit angesammelt haben.' | translate}}</p>
              <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Spezialisierte Techniken:' | translate}}</span> {{'EXPLAINATION_PAGE.Unser Team verwendet spezielle Reinigungsmittel und -geräte, um auch die schwierigsten Verschmutzungen zu beseitigen.' | translate}}</p>
              <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Umfassende Reinigung:' | translate}}</span> {{'EXPLAINATION_PAGE.Von Böden und Wänden über Fenster bis hin zu Heizkörpern und schwer zugänglichen Stellen – wir reinigen alles gründlich und sorgfältig.' | translate}}</p>
              <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Gesundheitsfördernd:' | translate}}</span> {{'EXPLAINATION_PAGE.Eine gründliche Reinigung reduziert Allergene und verbessert die Raumluftqualität, was Ihrer Gesundheit zugutekommt.' | translate}}</p>
            </div>
          </ng-container>
          }
          @if(this.bookingForm.get('type')?.value === 'CLEAN_OUT') {
            <ng-container>
              <div class="text-black py-2">
                <h4 class="text-[24px] font-bold">{{'EXPLAINATION_PAGE.Ausmisten – Platz schaffen und Ordnung schaffen' | translate}}</h4>
                <p>{{'EXPLAINATION_PAGE.Ein aufgeräumter Raum fördert Wohlbefinden und Produktivität. Unser professioneller Ausmist- Service hilft Ihnen dabei, sich von überflüssigem Ballast zu befreien und wieder Platz und Ordnung in Ihrem Zuhause oder Büro zu schaffen. Wir machen es Ihnen leicht, angesammeltes loszuwerden.' | translate}}</p>
              </div>
              <div class="text-black py-2">
                <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Was ist der Ausmist-Service?' | translate}}</h4>
                <p>{{'EXPLAINATION_PAGE.Unser Ausmist-Service umfasst die gründliche Entrümpelung und Organisation Ihrer Räumlichkeiten. Ob Keller, Dachboden, Garage oder Wohnräume – wir unterstützen Sie dabei, überflüssige Gegenstände zu identifizieren und sinnvoll zu entsorgen oder zu recyceln.' | translate}}</p>
              </div>
              <div class="text-black py-2">
                <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Ihre Vorteile auf einen Blick:' | translate}}</h4>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Platz schaffen:' | translate}}</span> {{'EXPLAINATION_PAGE.Wir helfen Ihnen, ungenutzte und überflüssige Gegenstände zu identifizieren und zu beseitigen, um mehr Platz und Freiraum zu schaffen.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Ordnung und Struktur:' | translate}}</span> {{'EXPLAINATION_PAGE.Durch systematisches Ausmisten bringen wir wieder Ordnung in Ihre Räume und schaffen eine angenehme und funktionale Umgebung.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Umweltfreundlich:' | translate}}</span> {{'EXPLAINATION_PAGE.Wir entsorgen alle Gegenstände fachgerecht und achten dabei auf umweltfreundliche Methoden, Recycling und Wiederverwertung.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Zeitersparnis:' | translate}}</span> {{'EXPLAINATION_PAGE.Sparen Sie Zeit und Mühe, indem Sie die aufwendige Arbeit des Ausmistens unseren erfahrenen Profis überlassen.' | translate}}</p>
              </div>
              <div class="text-black py-2">
                <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Wie funktioniert die Buchung?' | translate}}</h4>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Bedarf analysieren:' | translate}}</span> {{'EXPLAINATION_PAGE.Kontaktieren Sie uns und besprechen Sie Ihre spezifischen Anforderungen mit uns. Wir erstellen einen individuellen Plan für das Ausmisten Ihrer Räumlichkeiten.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Termin vereinbaren:' | translate}}</span> {{'EXPLAINATION_PAGE.Wählen Sie einen passenden Termin, an dem wir die Entrümpelung und Organisation durchführen.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Aufgeräumt leben:' | translate}}</span> {{'EXPLAINATION_PAGE.Geniessen Sie den neu gewonnenen Platz und die Ordnung in Ihren Räumen.' | translate}}</p>
              </div>
            </ng-container>
          }
          @if(this.bookingForm.get('type')?.value === 'BUY_CLEANING') {
            <ng-container>
              <div class="text-black py-2">
                <h4 class="text-[24px] font-bold">{{'EXPLAINATION_PAGE.Einkaufsservice – Stressfreies Einkaufen, persönlich für Sie' | translate}}</h4>
                <p>{{'EXPLAINATION_PAGE.Manchmal fehlt einfach die Zeit oder die Möglichkeit, persönlich einzukaufen. Mit unserem Einkaufsservice bieten wir Ihnen eine bequeme Lösung, um Ihre Einkäufe professionell und zuverlässig zu erledigen, ganz nach Ihren Wünschen und Bedürfnissen. Unser Life-Hack für Sie: Senden sie uns einfach Ihre Thermomix Einkaufsliste.' | translate}}</p>
              </div>
              <div class="text-black py-2">
                <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Was ist der Einkaufsservice?' | translate}}</h4>
                <p>{{'EXPLAINATION_PAGE.Unser Einkaufsservice ermöglicht es Ihnen, sich zurückzulehnen, während wir Ihre Einkäufe erledigen. Wir besorgen die gewünschten Produkte und liefern sie direkt zu Ihnen nach Hause oder ins Büro – frisch, pünktlich und stressfrei.' | translate}}</p>
              </div>
              <div class="text-black py-2">
                <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Ihre Vorteile auf einen Blick:' | translate}}</h4>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Persönliche Betreuung:' | translate}}</span> {{'EXPLAINATION_PAGE.Wir gehen auf Ihre individuellen Einkaufswünsche ein und besorgen genau die Produkte, die Sie benötigen.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Zeitersparnis:' | translate}}</span> {{'EXPLAINATION_PAGE.Sparen Sie wertvolle Zeit, die Sie sonst mit Einkaufen verbringen müssten, und nutzen Sie sie für wichtigere Dinge.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Flexibilität:' | translate}}</span> {{'EXPLAINATION_PAGE.Ob Lebensmittel, Haushaltswaren oder spezielle Produkte – wir erledigen Ihre Einkäufe nach Ihren Vorstellungen.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Qualität und Frische:' | translate}}</span> {{'EXPLAINATION_PAGE.Wir achten darauf, nur hochwertige Produkte auszuwählen und diese in bestem Zustand zu liefern.' | translate}}</p>
              </div>
              <div class="text-black py-2">
                <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Wie funktioniert die Buchung?' | translate}}</h4>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Einkaufsliste erstellen:' | translate}}</span> Senden Sie uns Ihre detaillierte Einkaufsliste.</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Einkauf durchführen:' | translate}}</span> {{'EXPLAINATION_PAGE.Unser Team besorgt die gewünschten Produkte und achtet auf Qualität und Sorgfalt.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Lieferung:' | translate}}</span> {{'EXPLAINATION_PAGE.Ihre Einkäufe werden zum vereinbarten Zeitpunkt und Ort geliefert, damit Sie sich um nichts kümmern müssen.' | translate}}</p>
              </div>
            </ng-container>
          }
          @if(this.bookingForm.get('type')?.value === 'GARDEN_MAINTENANCE') {
            <ng-container>
              <div class="text-black py-2">
                <h4 class="text-[24px] font-bold">{{'EXPLAINATION_PAGE.Gartenpflege – Grüne Oasen zum Wohlfühlen' | translate}}</h4>
                <p>{{'EXPLAINATION_PAGE.Ein gepflegter Garten ist nicht nur eine Augenweide, sondern auch ein Ort der Entspannung und Erholung. Mit unserer professionellen Gartenpflege kümmern wir uns liebevoll um Ihre Grünflächen, damit Sie die Schönheit und Ruhe Ihres Gartens jederzeit geniessen können. Lassen Sie uns Ihren Sommer verschönern.' | translate}}</p>
              </div>
              <div class="text-black py-2">
                <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Was beinhaltet die Gartenpflege?' | translate}}</h4>
                <p>{{'EXPLAINATION_PAGE.Unsere Gartenpflege umfasst eine Vielzahl von Dienstleistungen, die darauf abzielen, Ihren Garten in bestem Zustand zu erhalten. Dazu gehören Rasenpflege, Heckenschnitt, Blumenbeetpflege, Unkrautentfernung, Bewässerung und vieles mehr. Wir passen unser Angebot Ihren individuellen Wünschen und den Bedürfnissen Ihres Gartens an.' | translate}}</p>
              </div>
              <div class="text-black py-2">
                <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Ihre Vorteile auf einen Blick:' | translate}}</h4>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Professionelle Pflege:' | translate}}</span> {{'EXPLAINATION_PAGE.Unser erfahrenes Team kennt sich mit den Bedürfnissen verschiedener Pflanzenarten aus und sorgt für eine fachgerechte Pflege.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Zeitersparnis:' | translate}}</span> {{'EXPLAINATION_PAGE.Überlassen Sie uns die mühsame Arbeit der Gartenpflege und nutzen Sie Ihre Zeit für andere Aktivitäten.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Schönheit und Ordnung:' | translate}}</span> {{'EXPLAINATION_PAGE.Wir halten Ihren Garten in einem gepflegten Zustand, der sowohl schön anzusehen als auch funktional ist.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Jahreszeitliche Anpassung:' | translate}}</span> {{'EXPLAINATION_PAGE.Wir passen unsere Pflege je nach Jahreszeit an, um sicherzustellen, dass Ihr Garten das ganze Jahr über in seiner besten Form bleibt.' | translate}}</p>
              </div>
              <div class="text-black py-2">
                <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Wie funktioniert die Buchung?' | translate}}</h4>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Beratung vor Ort:' | translate}}</span> {{'EXPLAINATION_PAGE.Kontaktieren Sie uns für eine kostenlose Beratung und eine Einschätzung Ihrer Gartenpflegebedürfnisse.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Individueller Pflegeplan:' | translate}}</span> {{'EXPLAINATION_PAGE.Gemeinsam entwickeln wir einen massgeschneiderten Pflegeplan, der Ihre Anforderungen und Ihr Budget berücksichtigt.' | translate}}</p>
                <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Professionelle Durchführung:' | translate}}</span> {{'EXPLAINATION_PAGE.Unser Team kümmert sich um alle Aspekte der Gartenpflege gemäß Ihrem vereinbarten Plan.' | translate}}</p>
              </div>
            </ng-container>
          }
    }
  } @else {
    @if(currentSubStep === 2) {
      <ng-container>
        <div class="text-black py-2">
          <h4 class="text-[24px] font-bold">{{'EXPLAINATION_PAGE.Haushaltshilfe im Krankheitsfall – Unterstützung in schweren Zeiten' | translate}}</h4>
          <p>{{'EXPLAINATION_PAGE.In Zeiten von Krankheit, Unfall, Schwangerschaft oder Wochenbett ist es oft schwer, den Haushalt allein zu bewältigen. Unsere Haushaltshilfe bietet Ihnen die notwendige Unterstützung, um Ihren Alltag zu erleichtern und Ihnen in solchen herausfordernden Situationen zur Seite zu stehen. Unser Tipp: viele Krankenversicherung subventionieren diesen Service.' | translate}}</p>
        </div>
        <div class="text-black py-2">
          <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Was umfasst unsere Haushaltshilfe?' | translate}}</h4>
          <p>{{'EXPLAINATION_PAGE.Unsere Haushaltshilfe deckt eine Vielzahl von Aufgaben im Haushalt ab, darunter Reinigung, Wäsche, Einkäufe, Zubereitung von Mahlzeiten und kleinere Besorgungen. Wir passen uns flexibel Ihren Bedürfnissen an und helfen Ihnen dabei, Ihren Alltag so normal wie möglich zu gestalten.' | translate}}</p>
        </div>
        <div class="text-black py-2">
          <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Unterstützung in schwierigen Lebensphasen:' | translate}}</h4>
          <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Krankheitsfall:' | translate}}</span> {{'EXPLAINATION_PAGE.Wenn Sie krankheitsbedingt, nicht in der Lage sind, den Haushalt zu führen, stehen wir Ihnen zur Seite.' | translate}}</p>
          <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Unfall:' | translate}}</span> {{'EXPLAINATION_PAGE.Nach einem Unfall können wir Ihnen helfen, den Alltag zu organisieren und Aufgaben zu übernehmen, die Ihnen derzeit schwerfallen.' | translate}}</p>
          <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Schwangerschaft und Wochenbett:' | translate}}</span> {{'EXPLAINATION_PAGE.Wir unterstützen Sie während der Schwangerschaft und im Wochenbett, damit Sie sich vollkommen auf Ihre Genesung und Ihr Baby konzentrieren können.' | translate}}</p>
        </div>
        <div class="text-black py-2">
          <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Von der Krankenkasse unterstützt:' | translate}}</h4>
          <p>{{'EXPLAINATION_PAGE.Diese wichtigen Dienstleistungen können von Ihrer Krankenkasse unterstützt werden. Kontaktieren Sie Ihre Krankenkasse direkt, um mehr über die Möglichkeiten der Kostenübernahme zu erfahren.' | translate}}</p>
        </div>
        <div class="text-black py-2">
          <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Wie funktioniert die Buchung nach Zuspruch der Krankenkasse?'| translate}}</h4>
          <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Kontaktieren Sie uns:' | translate}}</span> {{'EXPLAINATION_PAGE.Rufen Sie uns an oder schreiben Sie uns eine Nachricht, um Ihre Situation und Ihre Bedürfnisse zu besprechen.' | translate}}</p>
          <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Beratung und Planung:' | translate}}</span> {{'EXPLAINATION_PAGE.Wir erstellen gemeinsam einen individuellen Plan, der auf Ihre spezifischen Anforderungen zugeschnitten ist.' | translate}}</p>
          <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Professionelle Unterstützung:' | translate}}</span> {{'EXPLAINATION_PAGE.Unser geschultes Team steht bereit, um Ihnen die benötigte Haushaltshilfe zu bieten, wann immer Sie sie brauchen.' | translate}}</p>
        </div>
      </ng-container>
  }
    @if(currentSubStep === 3){
      <ng-container>
        <div class="flex flex-wrap justify-between gap-4 py-2">
          @if(selectedCleaningType === 3){
            @for(item of specialCleaningCards; track $index){
            <div
              (click)="selectSpecialType(item.id, item.value)"
              [ngClass]="{
                'bg-[#61BFD1] border-2 text-white border-transparent':
                  item.id === selectedSpecialCleaning,
                'rounded-tl-md min-h-[160px] cursor-pointer shadow-xl rounded-br-md flex flex-col gap-2 justify-center items-center w-full md:w-[31%] border-transparent hover:border-[#61BFD19f] border-[1px]': true
              }"
            >
              <p class="text-[24px] font-bold">
                {{ "PRIVATE." + item.title | translate }}
              </p>
              <span class="px-4 text-center">
                @switch (item.id) {
                  @case(1){
                    <p>{{'SPECIAL.Erfrischende Klarheit für Ihre Fenster: Professionelle Reinigung für strahlenden Glanz und klare Sicht im Frühling!' | translate}}</p>
                  }
                  @case(2){
                    <p>{{'SPECIAL.Für ein makelloses Finish: Gründliche Reinigung, um Ihre Räumlichkeiten in einem Top-Zustand zu übergeben.'| translate}}</p>
                  }
                  @case(3){
                    <p>{{'SPECIAL.Die solide Basis für Ihre Sauberkeit im Büro, Gewerbe und Zuhause – zu jeder Zeit.' | translate}}</p>
                  }
                }
              </span>
            </div>
            }
    
          } 
          @else if(selectedCleaningType === 4){
            @for(item of specialServicesCards; track $index){
              <div
                (click)="selectSpecialType(item.id, item.value)"
                [ngClass]="{
                  'bg-[#61BFD1] border-2 text-white border-transparent':
                    item.id === selectedSpecialCleaning,
                  'rounded-tl-md min-h-[120px] cursor-pointer shadow-xl rounded-br-md flex flex-col gap-2 justify-center items-center w-full md:w-[31%] hover:border-[#61BFD19f] hover:border-2': true
                }"
              >
                <p class="text-[24px] font-bold">
                  {{ "PRIVATE." + item.title | translate }}
                </p>
              </div>
            }
            <div class="rounded-tl-md min-h-[120px] cursor-pointer shadow-xl rounded-br-md flex flex-col gap-2 justify-center items-center w-full md:w-[31%] hover:border-[#61BFD19f] hover:border-2">
              <a href="https://mr-green.ch/pages/abholtermine" target="_blank" class="text-[24px] font-bold">
                {{'PRIVATE.Link zum Recycling' | translate}}
              </a>
            </div>
          }
          @else if(selectedCleaningType === 5){
            @for(item of insuranceCleaningCards; track $index){
              <div
                (click)="selectSpecialType(item.id, item.value)"
                [ngClass]="{
                  'bg-[#61BFD1] border-2 text-white border-transparent':
                  item.id === selectedSpecialCleaning,
                  'rounded-tl-md min-h-[120px] cursor-pointer shadow-xl rounded-br-md flex flex-col gap-2 justify-center items-center w-full md:w-[31%] hover:border-[#61BFD19f] hover:border-2': true
                }"
                >
                <p class="text-[24px] font-bold">
                  {{ "PRIVATE." + item.title | translate }}
                </p>
              </div>
            }
          }
        </div>
      </ng-container>
    }
  }


</ng-template>
<ng-template #specialCleaning2>
  @if(modalOpen){
  <div class="flex flex-col gap-2">
    <div class="flex w-[100%] md:w-[73%] lg:w-[73%] justify-between">
      <div class="flex p-2 bg-[#E9EBF4] rounded-lg">{{getHours()}} {{'PROFILE.hours' | translate}}</div>
      <div class="flex w-[60%] md:w-[30%] lg:w-[30%] justify-between items-center">
        <button (click)="prevMonth()">
          <img src="../assets/icons/Vector-left.svg" alt="" />
        </button>
        <p>{{ 'DATE.' + (viewDate | date : "MMMM") | translate }} {{ viewDate | date : "y" }}</p>
        <button (click)="changeMonth()">
          <img src="../assets/icons/Vector-right.svg" alt="" />
        </button>
      </div>
    </div>
    <div class="flex flex-col md:flex-row lg:flex-row w-full justify-between">
      <app-calendar
        [disabledDays]="freeTimeSlots"
        class="w-[100%] md:w-[74%] lg:w-[74%] shadow-lg md:h-[240px]"
        [viewDate]="viewDate"
        (selectedDate)="getSelectedDay($event)"
        (viewDateUpdate)="updateViewDate($event)"
      ></app-calendar>
      <div class="w-[100%] md:w-[26%] lg:w-[26%] items-start justify-between flex flex-col gap-4 overflow-auto md:min-h-[370px]">
        <div class="flex flex-col gap-2 w-full">
          <button
            (click)="selectShiftSpecial('BEFORE_NOON')"
            [disabled]="
              (selectedDay && !selectedDay[0].schedule.morning) || !selectedDay
            "
            [ngClass]="{
              'md:w-[80%] lg:w-[80%] w-[100%] md:mt-0 lg:mt-0 mt-4 rounded-br-lg self-end rounded-tl-lg shadow-lg p-4 disabled:text-gray-500 disabled:bg-gray-50': true,
              'bg-[#61BFD1] text-white': selectedShift === 'BEFORE_NOON'
            }"
          >
          {{'PRIVATE.Before noon (08:00 Uhr - 12:00 Uhr)' | translate}}
          </button>
          <button
            (click)="selectShiftSpecial('AFTER_NOON')"
            [disabled]="
              (selectedDay && !selectedDay[0].schedule.afternoon) || !selectedDay || getHours() >= 6
            "
            [ngClass]="{
              'md:w-[80%] lg:w-[80%] w-[100%] md:mt-0 lg:mt-0 mt-4 rounded-br-lg self-end rounded-tl-lg shadow-lg p-4 disabled:text-gray-500 disabled:bg-gray-50': true,
              'bg-[#61BFD1] text-white': selectedShift === 'AFTER_NOON'
            }"
          >
          {{'PRIVATE.Afternoon (12:00 Uhr - 18:00 Uhr)' | translate}}
          </button>
        </div>
        <div class="flex flex-col justify-end self-end w-[80%] gap-4 pt-2">
          <button
            class="border-[1px] box-border disabled:opacity-25 border-[#61BFD1] font-bold rounded-[6px] px-8 py-2 hover:bg-[#61bfd13a] hover:duration-100"
            (click)="openModal()"
          >
          {{'PRIVATE.Schliessen' | translate}}
          </button>
          <button
            [disabled]="!selectedShift"
            class="border-[1px] box-border disabled:bg-[#61bfd185] bg-[#61BFD1] text-white font-bold rounded-[6px] px-8 py-2 hover:bg-[#3b7a86]"
            (click)="confirmModal()"
          >
          {{'PRIVATE.Bestätigen' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
  } 
  @if(!modalOpen){
  <div class="flex flex-col gap-2">
    <div class="flex flex-col md:flex-row lg:flex-row w-full justify-between text-[18px] text-[#7E7E7E]">
      <p>
        {{
          "PRIVATE.Hier finden Sie die nächsten verfügbaren Termine."
            | translate
        }}
      </p>
      <span class="rounded-lg bg-[#E9EBF4] px-4 py-2 2xl:py-2 md:py-0 lg:py-0 font-semibold">
        {{'PRIVATE.Heute ist der' | translate}} {{ viewDate | date : "dd. MMMM y" }}</span
      >
    </div>
    @if(freeTimeSlots && freeTimeSlots.length > 0){
    <div class="flex flex-wrap gap-2 justify-between text-blue-duckFortiary">
      @for(day of filteredTimeSlots; track $index){
        <div
          [ngClass]="{
            'rounded-lg md:p-4 lg:p-4 p-2 w-full md:w-[48%] lg:w-[48%] shadow-lg text-[18px] 2xl:text-[22px] border-[1px] border-transparent cursor-pointer hover:border-[#61BFD1]': true,
            'bg-[#61BFD1] text-white': selectedFastestTime?.date === day?.date
          }"
          (click)="selectTime(day)"
        >
          <div class="flex justify-between">
            <p class="font-bold">{{ "PRIVATE." + day.day | translate }}</p>
            <span
              class="text-[14px] rounded-lg bg-[#E9EBF4] p-2 font-semibold text-blue-duckFortiary"
            >
            {{ day.date | date : "dd" }}, {{ 'DATE.' + (day.date | date : "MMMM") | translate }} {{ day.date | date : "y" }}
            </span>
          </div>
          <span *ngIf="day.schedule.morning" class="flex gap-2">
            <span
              *ngIf="selectedFastestTime === day"
              (click)="selectShiftSpecial('BEFORE_NOON')"
              class="w-[20px] h-[20px] flex rounded-full bg-gray-200 items-center justify-center"
              ><span
                *ngIf="selectedShift === 'BEFORE_NOON'"
                class="w-[12px] h-[12px] bg-[#2b5a68] flex rounded-full"
              ></span
            ></span>
            <p class="text-[18px]">{{'PRIVATE.Before noon (08:00 Uhr - 12:00 Uhr)' | translate}}</p>
          </span>
          <span *ngIf="day.schedule.afternoon" class="flex gap-2">
            <span
              *ngIf="selectedFastestTime === day"
              (click)="selectShiftSpecial('AFTER_NOON')"
              class="w-[20px] h-[20px] flex rounded-full bg-gray-200 items-center justify-center"
              ><span
                *ngIf="selectedShift === 'AFTER_NOON'"
                class="w-[12px] h-[12px] bg-[#2b5a68] flex rounded-full"
              ></span
            ></span>
            <p class="text-[18px]">{{'PRIVATE.Afternoon (12:00 Uhr - 18:00 Uhr)' | translate}}</p>
          </span>
        </div>
        }
    </div>
    }
    @else if(loadingSchedule){
      <div class="w-full flex flex-wrap h-[370px] gap-2">
        <div class="md:w-[49%] md:h-[47%] lg:w-[49%] lg:h-[47%] w-[100%] h-[24%] rounded-lg bg-slate-300 animate-pulse"></div>
        <div class="md:w-[49%] md:h-[47%] lg:w-[49%] lg:h-[47%] w-[100%] h-[24%] rounded-lg bg-slate-300 animate-pulse"></div>
        <div class="md:w-[49%] md:h-[47%] lg:w-[49%] lg:h-[47%] w-[100%] h-[24%] rounded-lg bg-slate-300 animate-pulse"></div>
        <div class="md:w-[49%] md:h-[47%] lg:w-[49%] lg:h-[47%] w-[100%] h-[24%] rounded-lg bg-slate-300 animate-pulse"></div>
      </div>
      }
    @else {
      <div>No slots</div>
    }
      <div class="flex items-center flex-col gap-2 md:gap-0 md:flex-row justify-between" *ngIf="freeTimeSlots && freeTimeSlots.length > 0">
      <button
        (click)="openModal()"
        class="border-[1px] bg-[#61BFD1] text-white font-bold rounded-[6px] px-4 py-3 hover:bg-[#61BFD1cb] disabled:bg-[#61BFD1cb] w-full md:w-[40%]"
      >
        {{ "PRIVATE.Alle verfügbare Termine anzeigen" | translate }}
      </button>
      @if(displaySelectedDay){
        <span class="rounded-lg bg-[#E9EBF4] py-2 px-4 md:p-2 font-semibold">{{ displaySelectedDay | date : "dd" }} {{ 'DATE.' + (displaySelectedDay | date : "MMMM") | translate }} {{ displaySelectedDay | date : "yyyy" }}, <span *ngIf="formatShift(displayShift)">{{'PRIVATE.' + formatShift(displayShift) | translate}}</span></span>
      }
    </div>
  </div>
}

</ng-template>
<ng-template #specialCleaning3>
  @if(currentSubStep === 1){ 
    @if(token){ 
      <div class="max-h-[370px] gap-2 flex flex-col overflow-y-auto">
        @for(address of userAddresses; track $index){
          <div
            [ngClass]="{
              'w-full rounded-lg p-4 flex justify-between border-[1px] capitalize cursor-pointer hover:border-[#95e2e9]': true,
              'bg-[#61BFD1] text-white': selectedAddress === address.id   ,
              'opacity-55 border-[#61bfd185] hover:border-[#61bfd185] cursor-not-allowed' : convertInt(address.zipCode) !== convertInt(this.zip)
            }"
            (click)="convertInt(address.zipCode) === convertInt(this.zip) ? selectPrivAddress(address.id) : alertAddress()"
          >
            <span class="w-[25%]">
              <p class="font-bold">Name:</p>
              {{ user.firstName }} {{ " " }} {{ user.lastName }}
            </span>
            <span class="w-[25%]">
              <p class="font-bold">{{'AUTH.address' | translate}}:</p>
              {{ address.street }} {{ " " }} {{ address.streetNr }}
            </span>
            <span class="w-[25%]">
              <p class="font-bold">{{'AUTH.zip' | translate}}:</p>
              {{ address.zipCode }}
            </span>
            <span class="w-[25%]">
              <p class="font-bold">{{'AUTH.city' | translate}}:</p>
              {{ address.country === 'Switzerland' ? 'Schweiz' : '' }}, {{ " " }} {{ address.city }}
            </span>
          </div>
        }
      </div>
  <!-- COME HERE -->
  } @else { @if(confirm){
  <app-contact-form
    [login]="login"
    [toggleLogin]="toggleLogin.bind(this)"
    [loginForm]="loginForm"
    [registerForm]="registerForm"
    [loginFunction]="loginFunction.bind(this)"
    [registerFunction]="registerFunction.bind(this)"
    [zip]="zipFromQuery"
    [city]="cityFromZip"
    [markTouched]="markAllControlsAsTouched"
    (confirmPassword)="onConfirmPasswordChange($event)"
    (confirmationChange)="onConfirmationChange($event)"
    [hasAcceptedTerms]="hasAcceptedTerms"
    (hasAcceptedTermsChange)="toggleAcceptedTerms($event)"
  ></app-contact-form>
  } } }
  <!-- COME HERE -->
  @if(currentSubStep === 2) { @if(userAddresses.length > 0){
  <!-- @if(!confirm){ -->
    <div class="max-h-[380px] w-full overflow-y-auto gap-6 flex flex-col">
      <p class="text-black text-[22px] font-bold">{{ "PROFILE.details" | translate }}</p>
      <span
        class="flex flex-col max-h-[300px] gap-4 px-10 overflow-y-auto w-full"
      >
      <span
      class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
    >
      <p class="w-[50%]">{{'PRIVATE.Vollständiger Name' | translate}}</p>
      <p class="text-black">{{user.firstName}} {{user.lastName}}</p>
    </span>
    <span
    class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
    >
      <p class="w-[50%]">Email</p>
      <p class="text-black">{{user.email}}</p>
    </span>
    <span
    class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
    >
      <p class="w-[50%]">{{'AUTH.phone' | translate}}</p>
      <p class="text-black">{{user.phoneNumber}}</p>
    </span>
    <span
    *ngIf="this.user.paymentType === null && address.additionalInformation !== null"
    class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
    >
      <p class="w-[50%]">{{ 'PRIVATE.addressremark' | translate }}</p>
      <p class="text-black">{{address.additionalInformation}}</p>
    </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PROFILE_MODALS.cleaningtype' | translate}}</p>
          <p class="text-black">{{'SERVICE_TYPE.' + handleSpecialType(bookingForm.get("type")?.value) | translate}}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ "PROFILE_MODALS.startdate" | translate }}</p>
          <p class="text-black">
            {{ 'DATE.' + (bookingForm.get("appointmentDate")?.value | date : "EEEE") | translate }}, {{bookingForm.get("appointmentDate")?.value | date : "d"}} {{  'DATE.' + (bookingForm.get("appointmentDate")?.value | date : "MMMM") | translate }} {{bookingForm.get("appointmentDate")?.value | date : "y"}}
          </p>
        </span>
        <!-- <span
          *ngIf="formType !== 'ILLNESS_CLEANING' && this.bookingForm.get('type')?.value !== 'BUY_CLEANING' "
          class="flex justify-between items-center w-full py-2 border-b-2 border-[#acacac]"
        >
        <p class="w-[50%]">{{'PRIVATE.Rabattcode' | translate}}</p>
        <div class="flex flex-col">
          <input 
          [ngClass]="{
            'custom-green border-green-600': correctAnswer === true,
            'custom-red border-red-500': correctAnswer === false
          }" 
          (change)="handleCoupon($event)" 
          class="border outline-none text-black" 
          type="text" 
          placeholder="{{'PRIVATE.Rabattcode' | translate}}">
            <span class="text-black">
              @if(correctAnswer === true) {
                {{'PRIVATE.Rabattcode erfolgreich verwendet' | translate}}
              } @else if (correctAnswer === false) {
                {{'PRIVATE.Rabattcode ungültig' | translate}}
              }
            </span>
        </div>
      </span> -->
<!--       
      <span
      *ngIf="this.bookingForm.get('type')?.value === 'BUY_CLEANING'"
      class="flex justify-between items-center w-full py-2 border-b-2 border-[#acacac]"
    >
    <p class="w-[50%]">{{'PRIVATE.Rabattcode' | translate}}</p>
    <div class="flex flex-col">
      <input 
      [ngClass]="{
        'custom-green border-green-600': correctServicesAnswer === true,
        'custom-red border-red-500': correctServicesAnswer === false
      }" 
      (change)="handleServicesCoupon($event)" 
      class="border outline-none text-black" 
      type="text" 
      placeholder="{{'PRIVATE.Rabattcode' | translate}}">
        <span class="text-black">
          @if(correctServicesAnswer === true) {
            {{'PRIVATE.Rabattcode erfolgreich verwendet' | translate}}
          } @else if (correctServicesAnswer === false) {
            {{'PRIVATE.Rabattcode ungültig' | translate}}
          }
        </span>
    </div>
  </span> -->
  <span
  *ngIf="formType !== 'ILLNESS_CLEANING' && this.bookingForm.get('type')?.value !== 'BUY_CLEANING'"
  class="flex justify-between items-center w-full py-2 border-b-2 border-[#acacac]"
>
<p class="w-[50%]">{{'PRIVATE.Rabattcode' | translate}}</p>
<form [formGroup]="discountForm" class="flex flex-col">
  <input 
  [ngClass]="{
    'custom-green border-green-600': correctAnswer === true,
    'custom-red border-red-500': correctAnswer === false
  }" 
  (blur)="checkDiscountCode(this.bookingForm.get('type')?.value)"
  formControlName="couponCode" 
  class="border outline-none text-black" 
  type="text" 
  placeholder="{{'PRIVATE.Rabattcode' | translate}}">
    <span class="text-black">
      @if(correctAnswer === true) {
        {{'PRIVATE.Rabattcode erfolgreich verwendet' | translate}}
      } @else if (correctAnswer === false) {
        {{'PRIVATE.Rabattcode ungültig' | translate}}
      }
    </span>
</form>
</span>
      </span>
      <span class="flex gap-3">
        <input type="checkbox" (change)="toggleTerms()">
        <p class="text-violet-400">
          {{'AUTH.termstext' | translate}} <a class="underline cursor-pointer text-blue-500" (click)="openFile()">{{'AUTH.terms' | translate}}</a>
        </p>
      </span>
    </div>
  <!-- } -->
  } @else {
  <p class="text-[20p] font-bold">Redirecting to payment page...</p>
  } } 
  @if(currentSubStep === 3){
  <div class="flex flex-col gap-4">
    <p class="text-[#7E7E7E] text-[20px]">
      {{
        "PRIVATE.Sie werden in wenigen Minuten eine Bestätigung per Mail erhalten."
          | translate
      }}
    </p>
    <p class="text-[12px]">
      {{
        "PRIVATE.Sie haben keine Bestätigung erhalten? Bitte setzten Sie sich direkt mit uns in Verbindung."
          | translate
      }}
    </p>
  </div>
  }
</ng-template>


<!-- Individual Cleaning -->
<ng-template #individualCleaning1>
  @if(currentSubStep === 1){
  <ng-container *ngIf="currentSubStep === 1">
    <div class="grid md:grid-cols-3 grid-rows-2 gap-4">
      @for(item of cards; track item){
      <div
        (click)="selectCleaningType(item.id, item.title)"
        [ngClass]="{
          'rounded-tl-md p-2 shadow-none md:shadow-xl border-[#61BFD19f] md:border-transparent lg:border-transparent border-[1px] rounded-br-md flex flex-col gap-2 justify-center items-center w-full cursor-pointer hover:border-[#61BFD19f]': true,
          'bg-[#61BFD1]': item.id === selectedCleaningType
        }"
      >
        <p
          [ngClass]="{
            'text-[24px] font-bold text-center': true,
            'text-white': item.id === selectedCleaningType
          }"
        >
          {{ "PRIVATE." + item.title | translate }}
        </p>
        @if(!item.list){
        <p
          [ngClass]="{
            'text-[16px] px-2 p-1 bg-white': true,
            'text-violet-400 font-bold rounded-md':
              item.id === selectedCleaningType
          }"
        >
          {{ "PRIVATE." + item.description | translate }}
        </p>
        } @else if(item.list){
        <ul
          [ngClass]="{
            'list-disc': true,
            'text-white': item.id === selectedCleaningType
          }"
        >
          <li *ngFor="let listItem of item.list">
            {{ "PRIVATE." + listItem | translate }}
          </li>
        </ul>
        }
      </div>
      }
    </div>
  </ng-container>
  } 
  @if(currentSubStep === 2) {
    <ng-container>
      <div class="text-black py-2">
        <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Regelmässige Reinigung (Abo) – Sauberkeit, auf die Sie sich verlassen können' | translate}}</h4>
        <p>{{'EXPLAINATION_PAGE.Eine saubere Umgebung trägt wesentlich zum Wohlbefinden bei. Mit unserem Service der regelmässigen Reinigung bieten wir Ihnen die Möglichkeit, stets ein gepflegtes Zuhause oder Büro zu geniessen, ohne sich um die Reinigungsarbeiten kümmern zu müssen. Geniessen Sie ein dauerhaftes Wohlfühlgefühl in Ihren Räumlichkeiten.'| translate}}</p>
      </div>
      <div class="text-black py-2">
        <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Was ist die regelmässige Reinigung?'| translate}}</h4>
        <p>{{'EXPLAINATION_PAGE.Unsere regelmässige Reinigung ist der perfekte Service für alle, die eine kontinuierliche Sauberkeit und Hygiene in ihren Räumlichkeiten sicherstellen möchten. Fast schon wie ein Abo. Ob wöchentlich, zweiwöchentlich oder monatlich – wir passen uns Ihren Bedürfnissen an.' | translate}}</p>
      </div>
      <div class="text-black py-2">
        <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Was muss ich zur Verfügung stellen?' | translate}}</h4>
        <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Zugang:' | translate}}</span> {{'EXPLAINATION_PAGE.Für einen reibungslosen Zugang zu Ihren Räumlichkeiten haben Sie beim Vertragsabschluss die folgenden Optionen: Schlüsselübergabe oder Schlüsselhinterlegung. Gerne können Sie das Reinigungspersonal jeweils auch persönlich Empfangen.' | translate}}</p>
        <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Reinigungsmaterial:' | translate}}</span> {{'EXPLAINATION_PAGE.Damit unser Personal eine einwandfreie Reinigung durchführen kann, bitten wir Sie die geeigneten Reinigungsmaterialen zu Verfügung zu stellen. Eine genaue Auflistung der Materialien finden Sie hier. Sollten Sie das passende Reinigungsmaterial nicht zur Hand haben, stellen wir dies gerne gegen einen Aufpreis zur Verfügung.'| translate}}</p>
      </div>
      <div class="text-black py-2">
        <h4 class="text-[20px]">{{'EXPLAINATION_PAGE.Ihre Vorteile auf einen Blick:'| translate}}</h4>
        <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Kontinuität:'| translate}}</span> {{'EXPLAINATION_PAGE.Geplante Reinigungstermine im Voraus, geniessen Sie konstante Sauberkeit ohne Unterbrechungen.'| translate}}</p>
        <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Qualität:' | translate}}</span> {{'EXPLAINATION_PAGE.Unser geschultes Team sorgt dafür, dass jede Reinigung gründlich und mit höchster Sorgfalt durchgeführt wird.'| translate}}</p>
        <p><span class="text-[20px]">{{'EXPLAINATION_PAGE.Bequemlichkeit:'| translate}}</span> {{'EXPLAINATION_PAGE.Kein Stress mehr mit Last-Minute-Reinigungen – unsere regelmässigen Besuche garantieren immer eine saubere Umgebung.' | translate}}</p>
        <h4 class="text-[20px] mt-3">{{'EXPLAINATION_PAGE.Kontaktieren Sie uns noch heute und vereinbaren Sie Ihre regelmässige Reinigung!'| translate}}</h4>
      </div>
    </ng-container>
  }
  @if(currentSubStep === 3){
  <ng-container>
    <div class="flex flex-wrap justify-between gap-4 py-2">
      @for(item of secondCards; track $index){
      <div
        (click)="selectRecurrence(item.id, item.value)"
        [ngClass]="{
          'bg-[#61BFD1] border-2 text-white border-transparent':
            item.id === selectedRecurrence,
          'rounded-tl-md shadow-none md:shadow-xl cursor-pointer md:border-transparent border-[#61BFD19f] border-[1px] rounded-br-md flex flex-col gap-2 justify-center items-center min-h-24 w-full md:w-[48%] hover:border-[#61BFD19f] hover:border-2': true
        }"
      >
        <p class="text-[24px] font-bold">
          {{ "PRIVATE." + item.title | translate }}
        </p>
        @if(item.title === 'Wöchentlich'){
        <p
          [ngClass]="{
            ' font-bold px-2 p-1 text-[16px] rounded-md': true,
            'bg-green-400 text-[#ffffff]': item.id != selectedRecurrence,
            'text-green-500': item.id === selectedRecurrence
          }"
        >
          {{ "PRIVATE.Unsere Empfehlung" | translate }}
        </p>
        }
      </div>
      }
    </div>
  </ng-container>
  } 
  @if(currentSubStep === 4){
  <ng-container>
    <div class="flex flex-col">
      <div class="shadow w-full bg-white rounded flex flex-col md:flex-row gap-2 md:gap-16 p-4 items-center">
        <div class="flex justify-between w-[80%] md:w-[29%] ">
          <button
            class="w-7 h-7 bg-[#61BFD1] rounded-lg flex items-center justify-center"
            (click)="setDays('decrease')"
          >
            <img src="../assets/icons/minus.svg" alt="plus" class="w-4" />
          </button>
          <p class="text-[20px]">{{ cleaningDays }}</p>
          <button
            class="w-7 h-7 bg-[#61BFD1] rounded-lg flex items-center justify-center"
            (click)="setDays('increase')"
          >
            <img src="../assets/icons/plus.svg" alt="plus" class="w-4" />
          </button>
        </div>
        {{'PRIVATE.Mal pro Woche' | translate}}
      </div>
      <form [formGroup]="individualForm">
        <div formArrayName="formArray">
          <div
            *ngFor="let formArrayGroup of individualFormGroups; let i = index"
          >
            <div
              [formGroup]="formArrayGroup"
              class="w-full flex flex-col md:flex-row justify-between rounded"
            >
              <div class="md:w-[30%] py-2">
                <app-custom-select
                  [options]="getDays()"
                  [title]="this.formArrayGroup.get('day')?.value"
                  (selected)="updateSelectedDay(i, $event)"
                ></app-custom-select>
              </div>

              <div class="md:w-[30%] py-2">
                <app-custom-select
                  [options]="individualHours"
                  [title]="
                    this.formArrayGroup.get('timeFrame')?.value ===
                    'AFTER_NOON'
                      ? 'Nachmittag'
                      : 'Vormittag'
                  "
                  [subTitle]="getSubtitle(formArrayGroup)"
                  (selected)="updateSelectedHour(i, $event)"
                ></app-custom-select>
              </div>

              <div class="md:w-[30%] py-2">
                <div class="flex border-b-[1px] md:border-b-0 border-gray-500 custom-shadow justify-between p-6">
                  <button
                    (click)="setIndividualHours(i, 'decrease')"
                    class="w-7 h-7 bg-[#61BFD1] rounded-lg flex items-center justify-center"
                  >
                    <img
                      src="../assets/icons/minus.svg"
                      alt="minus"
                      class="w-4"
                    />
                  </button>
                  <p class="text-[20px]">
                    {{ this.formArrayGroup.get("duration")?.value }}
                    {{ "PRIVATE.Stunden" | translate }}
                  </p>
                  <button
                    class="w-7 h-7 bg-[#61BFD1] rounded-lg flex items-center justify-center"
                    (click)="setIndividualHours(i, 'increase')"
                  >
                    <img
                      src="../assets/icons/plus.svg"
                      alt="plus"
                      class="w-4"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div
        class="overflow-y-scroll hide max-h-[320px] flex flex-col gap-5"
      ></div>
    </div>
  </ng-container>
  }
</ng-template>
<ng-template #individualCleaning2>
  <div class="flex flex-col gap-2">
    @if(freeTimeSlots && freeTimeSlots.length > 0){
    <div class="flex w-full justify-between">
      <div class="flex w-[20%] justify-between items-center">
        <button (click)="prevMonth()">
          <img src="../assets/icons/Vector-left.svg" alt="" />
        </button>
        <p>{{ 'DATE.' + (viewDate | date : "MMMM") | translate }} {{ viewDate | date : "y" }}</p>
        <button (click)="changeMonth()">
          <img src="../assets/icons/Vector-right.svg" alt="" />
        </button>
      </div>
    </div>
      <div class="flex w-full justify-between">
        <app-calendar
          [disabledDays]="freeTimeSlots"
          class="w-[100%] shadow-lg"
          [viewDate]="viewDate"
          (selectedDate)="getSelectedDay($event)"
          (viewDateUpdate)="updateViewDate($event)"
        ></app-calendar>
      </div>
    } @else if(loadingSchedule){
      <div class="flex w-full h-[370px] bg-slate-300 animate-pulse rounded-lg"></div>
    } @else {
      <div class="text-[20px] font-bold text-black">Zurzeit sind keine Termine für diese Region verfügbar.</div>
    }

  </div>
</ng-template>
<ng-template #individualCleaning3>
  @if(currentSubStep === 1){
  <div class="flex flex-wrap gap-4">
    <ng-container>
      @for(item of secondStepCards; track $index){
      <div
        (click)="selectExtraService(item)"
        [ngClass]="{
          'flex p-6 flex-col w-full 2xl:max-w-[31%] md:w-[47%] shadow-none md:shadow-xl rounded-tl-lg rounded-br-lg cursor-pointer border-[1px] border-[#61BFD1] md:border-transparent hover:border-[#61BFD1]': true,
          'bg-[#61BFD1] text-white': checkSelectedAddOn(item.id)
        }"
      >
        <div class="flex items-center gap-4 capitalize">
          <img
            [src]="checkSelectedAddOn(item.id) ? item.iconActive : item.icon"
            alt="window"
            class="max-w-[28px]"
          />
          {{ "PRIVATE." + item.name | translate }}
        </div>
        <div class="text-[24px] font-bold pt-3">{{'PRIVATE.' + item.description | translate}}</div>
      </div>
      }
    </ng-container>
  </div>
  }
</ng-template>
<ng-template #individualCleaning4>
  <div class="flex flex-col">
    <label class="md:text-[20px] text-[16px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [checked]="suppliesState === 'true'"
        class="regular-checkbox"
        (change)="onSupplyChange(true)"
      />
      <span class="flex flex-col md:flex-row gap-0 md:gap-2">
        <p>{{"PRIVATE.Ja, ich besitze das erforderliche" | translate}}</p>
        <p (click)="openMaterials()" class="font-bold z-10 cursor-pointer text-blue-500 underline">{{'PRIVATE.Reinigunsmaterial' | translate}}.</p>
      </span>
    </label>
    <label class="md:text-[20px] text-[16px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [checked]="suppliesState === 'false'"
        class="regular-checkbox"
        (change)="onSupplyChange(false)"
      />
      {{ "PRIVATE.Nein - Ich bin mit dem Aufpreis von CHF 10.00 pro Reinigungsstunde einverstanden." | translate }}
    </label>
    @if(displayWarning){
      <p>{{ "PRIVATE.nomaterials" | translate }}</p>
    }
    @if(materialModal){
      <div class="absolute md:w-[640px] lg:w-[640px] h-[520px] md:h-[580px] lg:h-[580px] px-6 md:top-5 lg:top-5 top-0 md:right-[20%] lg:right-[20%] right-0 overflow-y-auto border-[1px] border-gray-700 bg-white shadow-lg p-4 z-50">
        <div class="flex flex-col ">
          <span class="flex justify-between w-full items-center py-2">
            <p class="font-bold text-[24px]">{{'MATERIALS.Benötigte Reingungsmaterialien' | translate}}</p>
            <img src="../assets/icons/exit.svg" alt="quit" class="w-6 h-6 cursor-pointer" (click)="closeMaterials()">
          </span>
          <div class="flex flex-col justify-between gap-2">
            <ul class="list-disc list-inside my-2 font-bold">
              <li>{{'MATERIALS.Staubsauger' | translate}}</li>
              <li>{{'MATERIALS.Mikrofasertücher (aus hygienischen Gründen mehrere Tücher)' | translate}}</li>
              <li>{{'MATERIALS.Putzschwamm Kratzfrei' | translate}}</li>
              <li>{{'MATERIALS.Mop-Set mit zwei Tüchern plus Eimer' | translate}}</li>
              <li>{{'MATERIALS.Swiffer Staubmagnet' | translate}}</li>
              <li>{{'MATERIALS.Entkalker' | translate}}</li>
              <li>{{'MATERIALS.Badreiniger' | translate}}</li>
              <li>{{'MATERIALS.Fettlöser' | translate}}</li>
              <li>{{'MATERIALS.Glasreiniger' | translate}}</li>
              <li>{{'MATERIALS.Herdreiniger' | translate}}</li>
              <li>{{'MATERIALS.Handschuhe' | translate}}</li>
              <li>{{'MATERIALS.WC Reiniger' | translate}}</li>
              <li>{{'MATERIALS.Allzweckreiniger' | translate}}</li>
            </ul>
    
            <p class="mt-4 text-black">{{'MATERIALS.cleaninginfo' | translate}}</p>
  
               <button class="btn-primary w-[50%] self-center" (click)="closeMaterials()">{{'MATERIALS.understood' | translate}}</button>
          </div>
        </div>
      </div>
    }
  </div>
</ng-template>
<ng-template #individualCleaning5>
  <div class="flex flex-col">
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [checked]="recycleState === 'true'"
        class="regular-checkbox"
        (change)="handleRecycling(true)"
      />
        {{ "PRIVATE.Ja, ich bin daran interessiert." | translate }}
    </label>
    <label class="text-[20px] flex gap-3 items-center py-2">
      <input
        type="radio"
        [checked]="recycleState === 'false'"
        class="regular-checkbox"
        (change)="handleRecycling(false)"
      />
      {{ "PRIVATE.Nein, aktuell habe ich kein Interesse." | translate }}
    </label>
    @if(recycleState === 'true'){
      <div class="flex border bg-[#61bfd13a] rounded-lg p-2 md:w-[80%] border-[#00819A]">
        <img
        class="w-[38px] h-[38px] mt-1 mr-4"
        src="../assets/icons/mrGreen.png"
        alt="mrGreen"
      />
        <p>{{ "PRIVATE.recycleinfo" | translate }}- <a href="https://mr-green.ch/pages/ueber-uns" target="_blank" class="text-[#00819A]">{{ "PRIVATE.textlink" | translate }}</a></p>
      </div>
    }
  </div>
</ng-template>
<ng-template #individualCleaning6>
  @if(currentSubStep === 1){ 
    @if(token){ 
      <div class="max-h-[370px] gap-2 flex flex-col overflow-y-auto">
        @for(address of userAddresses; track $index){
          <div
            [ngClass]="{
              'w-full rounded-lg p-4 flex justify-between border-[1px] capitalize cursor-pointer hover:border-[#95e2e9]': true,
              'bg-[#61BFD1] text-white': selectedAddress === address.id   ,
              'opacity-55 border-[#61bfd185] hover:border-[#61bfd185] cursor-not-allowed' : convertInt(address.zipCode) !== convertInt(this.zip)
            }"
            (click)="convertInt(address.zipCode) === convertInt(this.zip) ? selectPrivAddress(address.id) : alertAddress()"
          >
            <span class="w-[25%]">
              <p class="font-bold">Name:</p>
              {{ user.firstName }} {{ " " }} {{ user.lastName }}
            </span>
            <span class="w-[25%]">
              <p class="font-bold">{{'AUTH.address' | translate}}:</p>
              {{ address.street }} {{ " " }} {{ address.streetNr }}
            </span>
            <span class="w-[25%]">
              <p class="font-bold">{{'AUTH.zip' | translate}}:</p>
              {{ address.zipCode }}
            </span>
            <span class="w-[25%]">
              <p class="font-bold">{{'AUTH.city' | translate}}:</p>
              {{ address.country === 'Switzerland' ? 'Schweiz' : '' }}, {{ " " }} {{ address.city }}
            </span>
          </div>
        }
      </div>
  <!-- COME HERE -->
  } @else { @if(confirm){
  <app-contact-form
    [login]="login"
    [toggleLogin]="toggleLogin.bind(this)"
    [loginForm]="loginForm"
    [registerForm]="registerForm"
    [loginFunction]="loginFunction.bind(this)"
    [registerFunction]="registerFunction.bind(this)"
    [zip]="zipFromQuery"
    [city]="cityFromZip"
    [markTouched]="markAllControlsAsTouched"
    (confirmPassword)="onConfirmPasswordChange($event)"
    (confirmationChange)="onConfirmationChange($event)"
    [hasAcceptedTerms]="hasAcceptedTerms"
    (hasAcceptedTermsChange)="toggleAcceptedTerms($event)"
  ></app-contact-form>
  } } }
  <!-- COME HERE -->
  @if(currentSubStep === 2) { @if(userAddresses.length > 0){
  <!-- @if(!confirm){ -->
    <div class="max-h-[380px] w-full overflow-y-auto gap-6 flex flex-col">
      <p class="text-black text-[22px] font-bold">{{ "PROFILE.details" | translate }}</p>
      <span
        class="flex flex-col max-h-[300px] gap-4 px-10 overflow-y-auto w-full"
      >
      <form *ngIf="this.user.paymentType === null" class="flex justify-between items-center flex-col md:flex-row w-full py-2">
        <p class="w-full md:w-[50%] text-black">{{'PRIVATE.Bitte wählen Sie Ihre Zahlungsart aus' | translate}}</p>
        <select (change)="handlePaymentMethod($event)" class="text-black">
          <option value="" selected disabled hidden>{{'PRIVATE.Zahlungsart' | translate}}</option>
          <option value="CARD">{{'PROFILE.creditcard' | translate}}</option>
          <option value="INVOICE">{{'PROFILE.invoice' | translate}}</option>
        </select>
      </form>
      <span
      class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
    >
      <p class="w-[50%]">{{'PRIVATE.Vollständiger Name' | translate}}</p>
      <p class="text-black">{{user.firstName}} {{user.lastName}}</p>
    </span>
    <span
    class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
    >
      <p class="w-[50%]">Email</p>
      <p class="text-black">{{user.email}}</p>
    </span>
    <span
    class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
    >
      <p class="w-[50%]">{{'AUTH.phone' | translate}}</p>
      <p class="text-black">{{user.phoneNumber}}</p>
    </span>
    <span
    *ngIf="this.user.paymentType === null && address.additionalInformation !== null"
    class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
    >
      <p class="w-[50%]">{{ 'PRIVATE.addressremark' | translate }}</p>
      <p class="text-black">{{address.additionalInformation}}</p>
    </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PROFILE_MODALS.cleaningtype' | translate}}</p>
          <p class="text-black">{{ cleaningForm.get("serviceType")?.value.toLowerCase() === 'private' ? ("AUTH.private" | translate) : 'Business' }}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ "PROFILE_MODALS.oftencleaning" | translate }}</p>
          <p class="text-black">{{'PRIVATE.' + cleaningForm.get("recurrence")?.value | translate}}</p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ "PROFILE_MODALS.startdate" | translate }}</p>
          <p class="text-black">
            {{ 'DATE.' + (cleaningForm.get("startDate")?.value | date : "EEEE") | translate }}, {{cleaningForm.get("startDate")?.value | date : "d"}} {{  'DATE.' + (cleaningForm.get("startDate")?.value | date : "MMMM") | translate }} {{cleaningForm.get("startDate")?.value | date : "y"}}
          </p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PRIVATE.Reinigungsmaterial vorhanden?' | translate}}</p>
          <p class="text-black">
            {{ cleaningForm.get("materialsIncluded")?.value  ? ('PROFILE_MODALS.no' | translate) : ('PROFILE_MODALS.yes' | translate)}}
          </p>
        </span>
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PRIVATE.Gesamtbetrag' | translate}}</p>
          <span>
            <p class="text-black text-right">{{ price }} CHF</p>
            @if(!hasSupplies){
              <p class="text-black text-right">+ CHF 50</p>
              <p class="text-black text-right">{{'PRIVATE.pro Monat für Reinigungsmaterial' | translate}}</p>
            }
          </span>
        </span>
        <h1 class="font-bold text-[20px]">{{'PRIVATE.Reinigungstage' | translate}}</h1>

        @for(days of cleaningForm.get('cleaningDays')?.value; track $index){
        <span
          class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ 'PRIVATE.' + days.day | translate }}</p>
          <p class="text-black">{{ days.duration }} {{'PROFILE.hours' | translate}}</p>
          <p class="text-black">{{ days.timeFrame === 'BEFORE_NOON' ? ('PRIVATE.Vormittag' | translate) : ('PRIVATE.Nachmittag' | translate) }}</p>
        </span>
        }
        <h1 class="font-bolD text-[20px]">{{'PRIVATE.Zusatzleistungen' | translate}}</h1>
        @for(service of cleaningForm.get('additionalServices')?.value | keyvalue;
        track service){
          <span
            class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
            *ngIf="service.value && service.key !== 'cabinetRemark'"
          >
          <p class="w-[50%]">{{"PRIVATE." + summaryText(service.key) | translate}}</p>
          <p *ngIf="service.key === 'ironingDuration' || service.key === 'cabinetDuration'" class="text-black">{{ service.value }}min</p>
          <p *ngIf="summaryText(service.key) === 'Kühlschrank' || summaryText(service.key) === 'Backofen' || summaryText(service.key) === 'Pflanzen giessen'" class="text-black">30 min</p>
          </span>
  
          <span
            class="flex justify-between w-full py-2 border-b-2 border-[#acacac]"
            *ngIf="service.value && service.key === 'cabinetRemark'"
          >
          <p class="w-[50%]">{{'PRIVATE.Bemerkung zu den Schränken' | translate}}</p>
          <p class="text-black">{{ service.value }}</p>
          </span>
        }
        <span class="flex justify-between">
          <p>{{'PRIVATE.Insgesamt mit zusätzlichen Dienstleistungen' | translate}}</p>
          <h1 class="font-bold text-[20px]">{{getIndividualHours()}} {{'PROFILE.hours' | translate}}</h1>
        </span>
      </span>
      <span class="flex gap-3">
        <input [disabled]="this.paymentMethod.value === '' && this.user.paymentType === null" type="checkbox" [checked]="this.registerForm.get('acceptedTermsAndConditions')?.value" (change)="toggleTerms()">
        <p class="text-violet-400">
          {{'AUTH.termstext' | translate}} <a class="underline cursor-pointer text-blue-500" (click)="openFile()">{{'AUTH.terms' | translate}}</a>
        </p>
      </span>
    </div>
  <!-- } -->
  } @else {
  <p class="text-[20p] font-bold">Redirecting to payment page...</p>
  } } @if(currentSubStep === 3){
  <div class="flex flex-col gap-4">
    <p class="text-[#7E7E7E] text-[20px]">
      {{
        "PRIVATE.Sie werden in wenigen Minuten eine Bestätigung per Mail erhalten."
          | translate
      }}
    </p>
    <p class="text-[12px]">
      {{
        "PRIVATE.Sie haben keine Bestätigung erhalten? Bitte setzten Sie sich direkt mit uns in Verbindung."
          | translate
      }}
    </p>
  </div>
  }
</ng-template>