{
    "standartSteps": [
        {
            "name": "Ihre Reinigung",
            "id": 1,
            "subSteps": [
              { "name": "Welche Art von Reinigung wünschen Sie?", "id": 1 },
              { "name": "Regelmässige Reinigung", "id": 2 },
              { "name": "Wie oft soll gereinigt werden?", "id": 3 },
              { "name": "Wie viel Zeit wird die Reinigung in Anspruch nehmen?", "id": 4 }
            ]
          },
          {
            "name": "Zusatzleistungen",
            "id": 2,
            "subSteps": [
              { "name": "Was dürfen wir noch für Sie tun?", "id": 1 }
            ]
          },
          {
            "name": "Ihr Wunschtermin",
            "id": 3,
            "subSteps": [
              { "name": "Bitte wählen Sie einen Termin für Ihre erste Reinigung", "id": 1 }
            ]
          },
          {
            "name": "Reinigungsmaterial",
            "id": 4,
            "subSteps": [
              { "name": "Besitzen Sie das erforderliche Reinigungsmaterial?", "id":1 }
            ]
          },
          {
            "name": "360 Recycling & More",
            "id": 5,
            "subSteps": [
              { "name": "Interesse an einer passenden Recycling-Lösung?", "id":1 }
            ]
          },
          {
            "name": "Kasse",
            "id": 6,
            "subSteps": [
              { "name": "Kontaktangaben", "id": 1 },
              { "name": "Zahlung", "id": 2 },
              { "name" : "Vielen dank für Ihre Buchung!", "id" : 3}
            ]
          }
    ],
    "expressSteps": [
        {
            "name": "Expressreinigung",
            "id": 1,
            "subSteps": [
              { "name": "Welche Art von Reinigung wünschen Sie?", "id": 1 },
              { "name": "Expressreinigung", "id": 2 },
              { "name": "Wie viel Zeit wird die Reinigung in Anspruch nehmen?", "id": 3 }
            ]
          },
          {
            "name": "Zusatzleistungen",
            "id": 2,
            "subSteps": [
              { "name": "Was dürfen wir noch für Sie tun?", "id": 1 }
            ]
          },
          {
            "name": "Ihr Wunschtermin",
            "id": 3,
            "subSteps": [
              { "name": "Bitte legen Sie einen Termin für Ihre einmalige Reinigung fest", "id": 1 }
            ]
          },
          {
            "name": "Reinigungsmaterial",
            "id": 4,
            "subSteps": [
              { "name": "Besitzen Sie das erforderliche Reinigungsmaterial?", "id":1 }
            ]
          },
          {
            "name": "360 Recycling & More",
            "id": 5,
            "subSteps": [
              { "name": "Interesse an einer passenden Recycling-Lösung?", "id":1 }
            ]
          },
          {
            "name": "Kasse",
            "id": 6,
            "subSteps": [
              { "name": "Kontaktangaben", "id": 1 },
              { "name": "Zahlung", "id": 2 },
              { "name" : "Vielen dank für Ihre Buchung!", "id" : 3}
            ]
          }
    ],
    "specialCleaningSteps": [
        {
            "name": "Ihre Reinigung",
            "id": 1,
            "subSteps": [
              {"name": "Welche Art von Reinigung wünschen Sie?", "id":1},
              {"name": "Welche Art von Spezialreinigung wünschen Sie?", "id":2},
              {"name": "Explanation", "id":3}
            ]
        },
        {
            "name": "Ihr Wunschtermin",
            "id": 2,
            "subSteps": [
                { "name": "Bitte wählen Sie einen passenden Zeitraum für Ihre Reinigung", "id": 1 }
            ]
        },

        {
            "name": "Kasse",
            "id":3,
            "subSteps": [
              { "name": "Kontaktangaben", "id": 1 },
              { "name": "Zahlung", "id": 2 },
              { "name" : "Vielen dank für Ihre Buchung!", "id" : 3}
            ]
        }
    ],
    "individualSteps": [
      {
        "name": "Regelmässige Reinigung",
        "id": 1,
        "subSteps": [
          { "name": "Welche Art von Reinigung wünschen Sie?", "id": 1 },
          { "name": "Regelmässige Reinigung", "id": 2 },
          { "name": "Wie oft soll gereinigt werden?", "id": 3 },
          { "name": "Planen Sie Ihre Reinigung", "id": 4 }
        ]
      },
      {
        "name": "Ihr Wunschtermin",
        "id": 2,
        "subSteps": [
          {
            "name": "Bitte wählen Sie einen Termin für Ihre erste Reinigung",
            "id": 1
          }
        ]
      },
      {
        "name": "Zusatzleistungen",
        "id": 3,
        "subSteps": [{ "name": "Was dürfen wir noch für Sie tun?", "id": 1 }]
      },
  
      {
        "name": "Reinigungsmaterial",
        "id": 4,
        "subSteps": [
          {
            "name": "Besitzen Sie das erforderliche Reinigungsmaterial?",
            "id": 1
          }
        ]
      },
      {
        "name": "360 Recycling & More",
        "id": 5,
        "subSteps": [
          {
            "name": "Interesse an einer passenden Recycling-Lösung?",
            "id": 1
          }
        ]
      },
      {
        "name": "Kasse",
        "id": 6,
        "subSteps": [
          { "name": "Kontaktangaben", "id": 1 },
          { "name": "Zahlung", "id": 2 },
          { "name": "Vielen dank für Ihre Buchung!", "id": 3 }
        ]
      }
    ]
}