@for(content of getObject(name); track content){
<div
  class="h-full w-[35%] md:w-[75%] absolute right-[32%] top-[2%] md:right-0 md:top-0 flex justify-center items-center "
>
  <div
    class="bg-white p-4 md:p-8 md:w-[700px] w-[350px] h-auto rounded-br-lg rounded-tl-lg shadow-2xl flex flex-col"
  >
    <button (click)="closeModal()" class="text-black text-[20px] self-end">
      <img src="../assets/icons/exit.svg" alt="exit" />
    </button>
    <span class="flex flex-col p-8 gap-4 overflow-y-auto">
      <h1 class="md:text-[28px] text-[20px] font-bold">{{ 'PRIVATE_MODALS.' + content.name | translate}}</h1>
      <p class="text-[#7E7E7E]">{{ 'PRIVATE_MODALS.' + content.title | translate}}</p>
      @if(name !== 'comment'){
      <div class="flex flex-col w-full py-6 gap-6">
        @for(item of content.attributes; track $index){
        <span class="w-full flex justify-between">
          <p class="text-black font-bold">{{ 'PRIVATE.' + item.label | translate }}</p>
          <span class="flex items-center gap-8 justify-between">
            <button
              class="w-8 h-8 bg-[#61BFD1] rounded-lg flex items-center justify-center"
              (click)="handleDecrease(name, item)"
            >
              <img src="../assets/icons/minus.svg" class="w-4" alt="minus" />
            </button>
            <div>
              @if(name === 'ironing'){
                <p class="md:text-[24px] text-[20px] font-bold">
                  {{ item.value }}{{ item.value > 15 ? "min" : "" }}
                </p>
              }
              @if(name === 'cabinets'){
                  <p class="md:text-[24px] text-[20px] font-bold">
                    {{ item.value }}{{ item.value > 15 ? "min" : "" }}
                  </p>
              }
              @if(name === 'windows'){ 
                @if(item.label === 'Standard Fenster'){
                  <p class="md:text-[24px] text-[20px] font-bold">{{ addedStandartWindows }}</p>
                }
                @else {
                  <p class="md:text-[24px] text-[20px] font-bold">{{ addedLargeWindows }}</p>
                }
             }
              @if(name === 'calculator'){ 
                @if(item.label === 'Zimmer'){
                  <p class="md:text-[24px] text-[20px] font-bold">{{ bedRooms }}</p>
                }
                @else {
                  <p class="md:text-[24px] text-[20px] font-bold">{{ bathRooms }}</p>
                } 
              }
            </div>
            <button
              class="w-8 h-8 bg-[#61BFD1] rounded-lg flex items-center justify-center"
              (click)="handleIncrease(name, item)"
            >
              <img src="../assets/icons/plus.svg" class="w-4" alt="plus" />
            </button>
          </span>
        </span>
        @if(name === 'cabinets'){
          <div class="flex w-full justify-between">
            <span class="flex flex-col">
              <p class="text-black font-bold">{{ 'AUTH.remark' | translate }}:</p>
              @if(!hasExactLength(this.cabinetsRemark,5)){
                <p class="text-red-500">{{ 'PROFILE_MODALS.minletters' | translate}}</p>
              }
            </span>
            <textarea [(ngModel)]="cabinetsRemark" name="remark" cols="30" rows="3" placeholder="{{ 'AUTH.remark' | translate }}" class="bg-[#E9F3F4] rounded-lg p-2"></textarea>
          </div>
        }
        }
      </div>
      } @else {
      <div class="flex">
        <textarea
          (change)="handleChangeVal($event)"
          [value]="commentVal"
          name=""
          id=""
          cols="35"
          rows="5"
          placeholder="{{'AUTH.remark' | translate }}"
          class="bg-[#E9F3F4] rounded-3xl p-4 w-[98%]"
        ></textarea>
      </div>
      }
      <p class="text-[#7E7E7E]">{{ 'PRIVATE_MODALS.' + content.description | translate }}</p>
      <span class="flex justify-center w-full gap-6">
        <button

          class="border-[1px] border-[#61BFD1] font-bold rounded-[6px] px-8 py-2 hover:bg-[#61bfd13a] hover:duration-100"
          (click)="handleCancellation(name)"
        >
          {{'AUTH.cancel' | translate}}
        </button>
        <button
          class="border-[1px] border-transparent bg-[#61BFD1] text-white font-bold rounded-[6px] px-8 py-2 hover:bg-[#3b7a86] disabled:bg-[#3b7a86]"
          [disabled]="name === 'cabinets' && !hasExactLength(this.cabinetsRemark, 5)"
          (click)="handleConfirmation(name)"
        >
        {{'AUTH.apply' | translate}}
        </button>
      </span>
    </span>
  </div>
</div>
}
