import { CommonModule } from '@angular/common';
import { Component, Input, } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PricingService } from '../../../services/core/pricing.service';
@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent {
  @Input() closeDetails: () => void;
  @Input() submitCancellation: () => void;
  @Input() handleIroningAndCabinets: (action: string, serviceType: string) => void;
  @Input() handleFridgeAndOven: (type: string) => void;
  @Input() cancelAdditionalServices: () => void;
  @Input() postAdditionalServices: () => void;
  @Input() submitReactivation: () => void
  @Input() formatCleaningDays: (startdate: string, enddate: string) => void
  @Input() handleCleaningStatus: (status: string) => void
  @Input() handleRecurrence: (recurrence: string) => void
  @Input() handleType: (type: string) => void
  @Input() transformDataAndCalculateDuration: (additionalServices: any, startdate: string, enddate: string) => void;
  @Input() detailsOpen: boolean = false
  @Input() selectedDetails: any
  @Input() cancelCleaningModal: boolean = false
  @Input() cancelCleaning: any
  @Input() displayCancellationMessage: boolean = false
  @Input() additionalServicesMoadl: boolean = false
  @Input() additionalServicesForm: any
  @Input() price: any
  @Input() requiredRemark: boolean = false
  @Input() reactivationModal: boolean = false
  @Input() cancellationLeft: number
  @Input() hasUnlimitedCancellations: boolean
  @Input() cleaningPrice: any

}
