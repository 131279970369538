import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'app-failed',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './failed.component.html',
  styleUrl: './failed.component.scss'
})
export class FailedComponent {
  public website = environment.websiteUrl
}
