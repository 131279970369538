import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslateModule } from '@ngx-translate/core';
import { AlertService } from '../../services/core/alert.service';
import { GoogleAuthService } from '../../services/OAuth/googleauth.service';



@Component({
    selector: 'app-register',
    standalone: true,
    templateUrl: './register.component.html',
    styleUrl: './register.component.scss',
    animations: [
        trigger('fadeInOut', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [
                style({ opacity: 0 }),
                animate('700ms ease-out', style({ opacity: 1 })),
            ]),
            transition(':leave', [
                animate('700ms ease-in', style({ opacity: 0 })),
            ]),
        ]),
    ],
    imports: [FormsModule, ReactiveFormsModule, CommonModule, TranslateModule,]
})
export class RegisterComponent implements OnInit {
  public dogCount = 0;
  public catCount = 0;
  public otherPetCount = 0;
  public clientType: string = "PRIVATE" 

  registerForm!: FormGroup;
  confirmPass: any
  petsModal: boolean = false
  termsAccepted: boolean = true

  hidePass = true
  hideRepeatPass = true
  pass = 'password'
  repeatPass = 'password'
  token: any
  
  constructor(private fb: FormBuilder, private auth: AuthService, private route: Router, private alertService: AlertService, private cdr: ChangeDetectorRef, private authService: GoogleAuthService){}



  ngOnInit(): void {
    this.registerForm = this.fb.group({
      clientType: ['PRIVATE'],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      birthDate: ['', Validators.required],
      companyName: ['' || null],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, this.swissPhoneNumberValidator()]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      newsletter: [false],
      language: ['German'],
      sex: ['', [Validators.required]],
      role: ['USER'],
      stv: [false],
      acceptedTermsAndConditions: [false, [Validators.required]],
      referralSource: ['', [Validators.required]],
      otherReferralSource: ['' || null],
      address: this.fb.group({
        street: ['',Validators.required],
        streetNr: ['',Validators.required],
        city: ['', Validators.required],
        zipCode: ['',Validators.required],
        entryCode: ['1234'],
        entranceInstructions: [null],
        additionalInformation: ['' || null],
        dog: [0],
        dogText: ['' || null],
        cat: [0],
        catText: ['' || null],
        otherAnimal: [0],
        otherAnimalText: ['' || null],
        isDefaultAddress: true
      })
    });
    
    this.registerForm.get('referralSource')?.valueChanges.subscribe({
      next: (res) => {
        if(res === 'OTHER' || res === 'EMPFEHLUNG'){
          this.registerForm.get('otherReferralSource')?.addValidators([Validators.required])
        } else {
          this.registerForm.get('otherReferralSource')?.removeValidators([Validators.required])
          this.registerForm.get('otherReferralSource')?.reset();
        }
      }
    })

  }

  swissPhoneNumberValidator(): ValidatorFn {
    const swissPhoneNumberPattern = /(\b(0041|0)|\B\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}\b/;
  
      return (control: AbstractControl): { [key: string]: any } | null => {
        const isValid = swissPhoneNumberPattern.test(control.value);
        return isValid ? null : { 'invalidSwissPhoneNumber': { value: control.value } };
      };
    }
  
  handleUserType(type: any) {
    if(type === 'PRIVATE') {
      this.clientType = type
      this.registerForm.get('companyName')?.setValue(null)
      this.registerForm.get('clientType')?.setValue(this.clientType)
    } else {
      this.clientType = type
      this.registerForm.get('clientType')?.setValue(this.clientType)
    }
  }


  setPets(action: string, petType: string) {
    if (action === 'increase') {
      if (petType === 'dog') {
        this.dogCount = this.dogCount + 1;    
        this.registerForm.get('address.dog')?.setValue(this.dogCount)  
      } else if (petType === 'cat') {
        this.catCount = this.catCount + 1;
        this.registerForm.get('address.cat')?.setValue(this.catCount)  

      } else if (petType === 'other') {
        this.otherPetCount = this.otherPetCount + 1;
        this.registerForm.get('address.otherAnimal')?.setValue(this.otherPetCount)  

      }
    } else if (action === 'decrease') {
      if (petType === 'dog') {
        if (this.dogCount !== 0) {
          this.dogCount = this.dogCount - 1;
        this.registerForm.get('address.dog')?.setValue(this.dogCount)           
        }
      } else if (petType === 'cat') {
        if (this.catCount !== 0) {
          this.catCount = this.catCount - 1;
        this.registerForm.get('address.cat')?.setValue(this.catCount)  

        }
      } else if (petType === 'other') {
        if (this.otherPetCount !== 0) {
          this.otherPetCount = this.otherPetCount - 1;
        this.registerForm.get('address.otherAnimal')?.setValue(this.otherPetCount)  

        }
      }
    }
  }

  removeDogs(){
    this.dogCount = 0
    this.registerForm.get('address.dog')?.setValue(this.dogCount)
    this.registerForm.get('address.dogText')?.setValue(null)       
  }

  removeCats(){
    this.catCount = 0
    this.registerForm.get('address.cat')?.setValue(this.catCount)
    this.registerForm.get('address.catText')?.setValue(null)       

  }

  removeOthers(){
    this.otherPetCount = 0
    this.registerForm.get('address.otherAnimal')?.setValue(this.otherPetCount)
    this.registerForm.get('address.otherAnimalText')?.setValue(null)       

  }

  cancelPets() {
    this.petsModal = !this.petsModal
  }

  toggleModal(){
    this.petsModal = !this.petsModal
  }

  handleOtherReferralSource(event: any) {
    const value = event.target?.value
    if(value === 'other') {
      this.registerForm.get('referralSource')?.setValue(value)
    } else {
      this.registerForm.get('referralSource')?.setValue(value)
    }
  }


/**
 * register function 
 */

  register(){
      if(this.registerForm.valid){
        const pass = this.registerForm.get('password')?.value
          if(this.checkPass(pass, this.confirmPass)){
            const value = this.registerForm.get('birthDate')?.value
            const dateValue = new Date(value)
            const isoString = dateValue.toISOString()
            this.registerForm.get('birthDate')?.setValue(isoString)
            this.auth.register(this.registerForm).subscribe(
              (res: any) => {
                console.log(res);
                if(res.success){
                  localStorage.setItem('token', res.data.token)
                  localStorage.setItem('user', res.data.id)
                  this.route.navigate(['profile'])
                }
              },
              (error: any) => {
                console.error(error);
                this.alertService.error(error.error.error,{autoClose: true})
              }
            );
            } else {
              this.alertService.error('Passwörter stimmen nicht überein',{ autoClose: true })
            }
          } else {
            this.alertService.error('Bitte füllen sie alle erforderlichen felder aus',{ autoClose: true })
            this.registerForm.markAllAsTouched()
          }
  }

  /**
   * 
   * @param pass 
   * @param confirmation 
   * @returns : boolean
   */

  checkPass(pass:any, confirmation:string):boolean{
    return pass === confirmation
  }

  handleConfirmPassword(event:any){
    this.confirmPass = event.target.value
  }

  toggleTerms(){
    this.termsAccepted = !this.termsAccepted
    this.registerForm.get('acceptedTermsAndConditions')?.setValue(!this.termsAccepted)
    this.cdr.detectChanges()
  }

  openFile() {
    window.open("assets/terms/AGB_Pebra_Reinigung_Schweiz_AG_2024.pdf")
  }

  toggleVisibility(action: string): void {
    if (action === 'password') {
     this.hidePass = !this.hidePass
     if(this.hidePass) {
       this.pass = 'password'
     } else {
       this.pass = 'text'
     }
   } else if (action === 'repeatpass') {
     this.hideRepeatPass = !this.hideRepeatPass
     if(this.hideRepeatPass) {
       this.repeatPass = 'password'
     } else {
       this.repeatPass = 'text'
     }
   }
 }
}
