import { CommonModule, registerLocaleData } from '@angular/common';
import { Component, OnInit, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { ModalComponent } from './modal/modal.component';
import { AuthService } from '../../services/auth/auth.service';
import { BookingService } from '../../services/core/booking.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CleaningTypePipe } from '../../pipes/cleaning-type.pipe';
import localeCh from '@angular/common/locales/de-CH';

registerLocaleData(localeCh);

import moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { AlertService } from '../../services/core/alert.service';
import { PricingService } from '../../services/core/pricing.service';
import { GoogleAuthService } from '../../services/OAuth/googleauth.service';
import { FacebookAuthService } from '../../services/OAuth/facebookauth.service';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    FormsModule,
    ReactiveFormsModule,
    CleaningTypePipe,
    TranslateModule,
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
  providers: [{ provide: LOCALE_ID, useValue: 'de-CH' }],
})
export class ProfileComponent implements OnInit {
  
  private token: string;
  private userId = localStorage.getItem('user');
  public selectedTab = 'Reservations';
  public reserVationTab = 'All';
  public openModal = false;
  public detailsOpen: boolean = false;
  public transactions: any = [];
  public hasReplacement = true;
  public dogCount = 0;
  public catCount = 0;
  public otherPetCount = 0;
  public serviceslimit: number = 0

  profileDisplay: boolean = false
  user: any;
  loadingBookings = false
  past = [];
  upcoming = [];
  bookings: any;
  selectedDetails: any;
  userName: string = '';
  freeTimeSlots: any;
  filteredTimeSlots: any = [];
  currentlyOpenCardId?: string | null = null;
  currentCleaningOpenId?: string | null = null
  cancelCleaningModal: boolean = false;
  cancellationLeft: number
  hasUnlimitedCancellations: boolean
  reactivationModal: boolean = false
  displayCancellationMessage: boolean = false
  additionalServicesMoadl:boolean = false
  displayInformation: boolean = false
  displayAddressInformation: boolean = false
  ironing: any = 0
  cabinets: any = 0
  cabinetRemark: any
  price: any
  fridge: boolean 
  oven: boolean
  requiredRemark: boolean = false
  duration: any = 0
  cleaningPrice: any = 0
  foundBooking: any;
  userAddress: any;
  cleaningReminder: number = 0;
  disabledUserFields: boolean = true;
  displayNewAddres: boolean = false;
  editedAddressIndex: number = -1;
  showSaveBtn: boolean = false;
  petsModal: boolean = false;
  selectedPets: any;
  newPetsModal: boolean = false;
  paymentType: any;
  requestedPaymentChange: any
  cardDetails: any = [];
  settingsOpen: { [key: string]: boolean } = {};
  defaultP: boolean = this.cardDetails.isDefault;
  all = this.past.concat(this.upcoming);
  today: Date = new Date();
  formattedDate: string = this.today.toISOString().split('T')[0];

  vacationDisplay: boolean = false

  public userForm = new FormGroup({
    firstName: new FormControl({ value: '', disabled: true },[Validators.required]),
    lastName: new FormControl({ value: '', disabled: true },[Validators.required]),
    companyName: new FormControl({ value: '' || null, disabled: true }),
    birthDate: new FormControl({ value: '', disabled: true }, [Validators.required]),
    email: new FormControl({ value: '', disabled: true },[Validators.required]),
    phoneNumber: new FormControl({ value: '', disabled: true },[Validators.required]),
    cleaningReminder: new FormControl({
      value: '',
      disabled: true,
    },[Validators.required]),
  });

  public additionalServicesForm = new FormGroup({
    ironingDuration: new FormControl(0),
    oven: new FormControl(false),
    fridge: new FormControl(false),
    cabinetDuration: new FormControl(0),
    cabinetRemark: new FormControl('' || null),
    wateringPlants: new FormControl(false)
  })

  public newAddresForm = new FormGroup({
    street: new FormControl('', [Validators.required]),
    streetNr: new FormControl('', [Validators.required]),
    zipCode: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    additionalInformation: new FormControl('' || null),
    entranceInstructions: new FormControl(''|| null),
    dog: new FormControl(0),
    cat: new FormControl(0),
    otherAnimal: new FormControl(0),
    dogText: new FormControl('' || null),
    catText: new FormControl('' || null),
    otherAnimalText: new FormControl('' || null),
  });

  public cancelCleaning = new FormGroup({
    cancellationReason: new FormControl('', [Validators.required]),
    otherReason: new FormControl('' || null)
  });

  changePasswordForm = new FormGroup({
    password: new FormControl('', [Validators.required]),
    confirmPassword: new FormControl('', [Validators.required]),
  });

  constructor(
    private route: Router,
    private auth: AuthService,
    private booking: BookingService,
    private fb: FormBuilder,
    private alertService: AlertService,
    private pricing: PricingService,
    private googleAuthService: GoogleAuthService,
    private fbAuthService: FacebookAuthService,
  ) {
    this.cardDetails.forEach((card: any) => {
      this.settingsOpen[card.id] = false;
    });
  }

  ngOnInit(): void {
    this.checkVacation()
    this.additionalServicesForm.get('cabinetDuration')?.valueChanges.subscribe(() => {
      this.handleRequiredRemark()
    })
    this.additionalServicesForm.get('cabinetRemark')?.valueChanges.subscribe(() => {
      const cabinetRemarkValue = this.additionalServicesForm.get('cabinetRemark')?.value;
      if (cabinetRemarkValue != null && cabinetRemarkValue !== '') {
        if ((cabinetRemarkValue as string).length < 5) {
          this.requiredRemark = true;
        } else {
          this.requiredRemark = false;
        }
      } else {
        this.requiredRemark = false;
      }
    });
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('token')
    this.auth.getUser(user, token).subscribe({
      next: (res) => {
        if (res) {
          this.user = res.data;
          this.cancellationLeft = this.user.cancellationsLeft
          this.hasUnlimitedCancellations = this.user.hasUnlimitedCancellations
          if(this.user.cancellationsLeft === 0 && !this.user.hasUnlimitedCancellations) {
            this.cancelCleaning.disable()
            this.displayCancellationMessage = true
          }
          this.paymentType = res.data.paymentType 
          if(res.data.paymentMethodChanges.length > 0) {
            if(res.data.paymentMethodChanges[0].status === 'TODO') {
              this.requestedPaymentChange = true
              this.paymentType = this.user.paymentMethodChanges[0].newPaymentMethod
            } else {
              this.requestedPaymentChange = false
              this.paymentType = res.data.paymentType
            }      
          } 
          const getDate = new Date(res.data.birthDate);
          const formatedDate = getDate.toISOString().split('T')[0];
          this.userName = res.data.firstName + ' ' + res.data.lastName;
          this.hasReplacement = res.data.stv;
          this.userForm.patchValue({
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            companyName: res.data.companyName,
            birthDate: formatedDate,
            email: res.data.email,
            phoneNumber: res.data.phoneNumber,
            cleaningReminder: res.data.cleaningReminder,
          });
            this.getUserAddresses(token)
            this.getPaymentMethods(token)
            this.auth.getUserTransactions(token).subscribe({
            next: (res) => {
              if (res) {
                this.transactions = res.data;
              }
            },
            error: (err) => {
              if (err) {
                this.alertService.error(err.error.error, { autoClose: true });
              }
            },
            });
        }
      },
      error: (err) => {
        if (err) {
          this.alertService.error(err.error.error, { autoClose: true });
        }
      },
    });
    this.listAllCleanings(token)
  }

  checkVacation() {
    const vacationDisplayDate = new Date("2025-01-04");
    const today = new Date();
  
    this.vacationDisplay = today < vacationDisplayDate;
  }

  getUserAddresses(token: any){
    this.auth.getUserAdressesForStandart(token).subscribe({
      next: (res) => {
        if (res) {
          const data = Object.values(res);
          this.userAddress = data[2];
          this.userAddress.forEach((address: any) => {
            this.addAddresses(address);
          });
        }
      },
      error: (err) => {
        if (err) {
          this.alertService.error(err.error.error, { autoClose: true });
        }
      },
    });
  }

  getPaymentMethods(token: any) {
    this.auth.getPaymentMethods(token).subscribe({
      next: (res) => {
        if (res) {
          this.cardDetails = res.data;
        }
      },
      error: (err) => {
        if (err) {
          this.alertService.error(err.error.error, { autoClose: true });
        }
      },
    });
  }

  getUser(user: any, token: any) {
    this.auth.getUser(user, token).subscribe({
      next: (res) => {
        if (res) {
          this.user = res.data;
          this.cancellationLeft = this.user.cancellationsLeft
          this.hasUnlimitedCancellations = this.user.hasUnlimitedCancellations
          if(this.user.cancellationsLeft === 0) {
            this.cancelCleaning.disable()
            this.displayCancellationMessage = true
          }
          this.paymentType = res.data.paymentType 
          if(res.data.paymentMethodChanges.length > 0) {
            if(res.data.paymentMethodChanges[0].status === 'TODO') {
              this.requestedPaymentChange = true
              this.paymentType = this.user.paymentMethodChanges[0].newPaymentMethod
            } else {
              this.requestedPaymentChange = false
              this.paymentType = res.data.paymentType
            }      
          } 
          const getDate = new Date(res.data.birthDate);
          const formatedDate = getDate.toISOString().split('T')[0];
          this.userName = res.data.firstName + ' ' + res.data.lastName;
          this.hasReplacement = res.data.stv;
          this.userForm.patchValue({
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            companyName: res.data.companyName,
            birthDate: formatedDate,
            email: res.data.email,
            phoneNumber: res.data.phoneNumber,
            cleaningReminder: res.data.cleaningReminder,
          });
        }
      },
      error: (err) => {
        if (err) {
          this.alertService.error(err.error.error, { autoClose: true });
        }
      }
    })
  }

  listAllCleanings(token: any) {
    this.loadingBookings = true
    this.booking.listCleanings(token).subscribe({
      next: (res: any) => { 
        if (res) {
          const data = res.data;
          data.sort((a: any, b: any) => {
            const dateA = new Date(a.startDate).getTime();
            const dateB = new Date(b.startDate).getTime();
            return dateB - dateA;
          });
          this.bookings = data;
          this.selectedDetails = data;
          let nextActiveCleaning = data.filter((booking: any) => booking.status !== "inaktiv" && booking.status !== "CANCELLED")
          this.freeTimeSlots = nextActiveCleaning.map((booking: any) => booking.startDate);
          this.filterTimeSlots();
        }
      },
      error: (err) => {
        this.alertService.error(err.error.error, { autoClose: true });
        this.loadingBookings = false
      },
      complete: () => {
        this.loadingBookings = false
      }
    });    
  }

  getBookings(type: string) {
    const today = new Date();
    if (type === 'past') {
      const past = this.bookings
        .filter((e: any) => {
          const date = new Date(e.startDate || e.appointmentDate);
          return date < today;
        })
        .sort((a: any, b: any) => {
          const dateA = new Date(a.startDate || a.appointmentDate).getTime();
          const dateB = new Date(b.startDate || b.appointmentDate).getTime();
          return dateB - dateA;
        });
        const mergedPast = past.filter((el: any) => el.appointmentDate !== null || undefined)
      return mergedPast;
    } else if (type === 'upcoming') {
      const upcoming = this.bookings
        .filter((e: any) => {
          const date = new Date(e.startDate || e.appointmentDate);
          return date > today;
        })
        .sort((a: any, b: any) => {
          const dateA = new Date(a.startDate || a.appointmentDate).getTime();
          const dateB = new Date(b.startDate || b.appointmentDate).getTime();
          return dateA - dateB;
        });
        const companyCleanings = this.bookings.filter((e: any) => e.appointmentDate === null || undefined)
        const mergedUpcoming = [...upcoming, ...companyCleanings];
      return mergedUpcoming;
    } else if (type === 'all') {
      return this.bookings;
    }
  }

  // Handle Tabs

  /**
   * 
   * @param selected 
   * handles profile tabs
   */

  handleTabs(selected: string) {
    this.selectedTab = selected;
    this.profileDisplay = false
    this.closeDetails()
  }

  getActiveTab(tab: string): boolean {
    return this.selectedTab === tab;
  }

  handleReservationTab(tabName: string) {
    this.reserVationTab = tabName;
  }

  /**
   * @param startdate 
   * @param enddate 
   * @returns 
   */

  formatCleaningDays(startdate: string, enddate: string): string {
    const startDate = new Date(startdate);
    const endDate = new Date(enddate);
    const differenceMs = endDate.getTime() - startDate.getTime();
    const hoursDifference = differenceMs / (1000 * 60 * 60);
  
    if (Number.isInteger(hoursDifference)) {
      return hoursDifference.toFixed(0);
    } else {
      return hoursDifference.toFixed(1);
    }
  }
  
  formatTimestamp(timestamp: string): string {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}.${month}.${year} – ${hours}:${minutes}`;
  }

  formatAmount(amount: number): string {
    const formattedAmount = (amount / 100).toFixed(2);
    return formattedAmount;
  }

  filterTimeSlots() {
    const today = new Date();
    const futureDays = this.freeTimeSlots
      .map((slot: any) => new Date(slot))
      .filter((date: Date) => date >= today)
      .sort((a: Date, b: Date) => a.getTime() - b.getTime());

    if (futureDays.length > 0) {
      const closestDate = futureDays[0];

      this.filteredTimeSlots = this.freeTimeSlots.filter((slot: any) => {
        const slotDate = new Date(slot);
        return slotDate.getTime() === closestDate.getTime();
      });
    } else {
      this.filteredTimeSlots = [];
    }
  }

  handleDisplayInfo() {
    this.displayInformation = !this.displayInformation
  }

  /**
   * Transforms data and calculates duration for Erp cleanings
   * @param additionalServices 
   * @param startdate 
   * @param enddate 
   * @returns 
   */

  transformDataAndCalculateDuration = (additionalServices: any, startdate: string, enddate: string): string => {
    let oven = additionalServices.oven;
    let fridge = additionalServices.fridge;
    let wateringPlants = additionalServices.wateringPlants;
    let cabinetDuration = additionalServices.cabinetDuration;
    let ironingDuration = additionalServices.ironingDuration;
    
    const startDate = new Date(startdate);
    const endDate = new Date(enddate);
    const differenceMs = endDate.getTime() - startDate.getTime();
    const baseDuration = differenceMs / (1000 * 60 * 60); 
  
    let additionalDuration = 0;
    if (oven) additionalDuration += 0.5;
    if (fridge) additionalDuration += 0.5;
    if (wateringPlants) additionalDuration += 0.5;
  
    cabinetDuration = cabinetDuration / 60;
    ironingDuration = ironingDuration / 60;

    let totalDuration = baseDuration + cabinetDuration + ironingDuration + additionalDuration;
  
    if (Number.isInteger(totalDuration)) {
        return totalDuration.toFixed(0);
    } else {
        return totalDuration.toFixed(1);
    }
  }

// Address

  handleAddressDisplayInfo() {
    this.displayAddressInformation = !this.displayAddressInformation
  }

  addressForm = this.fb.group({
    addresses: this.fb.array([]),
  });

  get addresses() {
    return this.addressForm.controls['addresses'] as FormArray;
  }

  get addressFormGroups(): FormGroup[] {
    return this.addresses.controls as FormGroup[];
  }

  addAddresses(address: any) {
    const addressForm = this.fb.group({
      street: [
        { value: address.street, disabled: true },
        [Validators.required],
      ],
      streetNr: [
        { value: address.streetNr, disabled: true },
        [Validators.required],
      ],
      zipCode: [
        { value: address.zipCode, disabled: true },
        [Validators.required],
      ],
      city: [{ value: address.city, disabled: true }, [Validators.required]],
      additionalInformation: [
        { value: address.additionalInformation || undefined, disabled: true }
      ],
      id: [{ value: address.id, disabled: true }],
      isDefaultAddress: [{ value: address.isDefaultAddress, disabled: true }],
      entranceInstructions: [
        { value: address.entranceInstructions, disabled: true },
      ],
      dog: [{ value: address.dog, disabled: true }],
      cat: [{ value: address.cat, disabled: true }],
      otherAnimal: [{ value: address.otherAnimal, disabled: true }],
      dogText: [{ value: address.dogText, disabled: true }],
      catText: [{ value: address.catText, disabled: true }],
      otherAnimalText: [{ value: address.otherAnimalText, disabled: true }],
    });
    this.addresses.push(addressForm);
  }

  addressFieldsEdit(index: number) {
    this.editedAddressIndex = index;
    this.addressFormGroups[index].enable();
    this.addressFormGroups[index].get('street')?.disable()
    this.addressFormGroups[index].get('streetNr')?.disable()
    this.addressFormGroups[index].get('zipCode')?.disable()
    this.addressFormGroups[index].get('city')?.disable()
  }

  /**
   * updates address
   * @param index 
   */

  handleSavedAddresses(index: number) {
    let token = localStorage.getItem('token')
    if (this.addressFormGroups[index].valid) {
      this.addressFormGroups[index].disable();
      const addressId = this.addressFormGroups[index].get('id')?.value;
      const updatedAddress: any = {
        street: this.addressFormGroups[index].get('street')?.value,
        streetNr: this.addressFormGroups[index].get('streetNr')?.value,
        zipCode: this.addressFormGroups[index].get('zipCode')?.value,
        city: this.addressFormGroups[index].get('city')?.value,
        isDefaultAddress:
          this.addressFormGroups[index].get('isDefaultAddress')?.value,
        additionalInformation: this.addressFormGroups[index].get(
          'additionalInformation'
        )?.value,
        dog: this.addressFormGroups[index].get('dog')?.value,
        dogText: this.addressFormGroups[index].get('dogText')?.value,
        cat: this.addressFormGroups[index].get('cat')?.value,
        catText: this.addressFormGroups[index].get('catText')?.value,
        otherAnimal: this.addressFormGroups[index].get('otherAnimal')?.value,
        otherAnimalText:
          this.addressFormGroups[index].get('otherAnimalText')?.value,
      };
      if (updatedAddress.isDefaultAddress) {
        this.addressFormGroups.forEach((control: any, i: any) => {
          if (i !== index && control.get('isDefaultAddress')?.value) {
            control.patchValue({ isDefaultAddress: false });
          }
        });
      }
      this.auth.updateAdress(updatedAddress, addressId, token).subscribe({
        next: (res) => {
          if (res) {
            this.alertService.success(res.msg, { autoClose: true });
          }
        },
        error: (err) => {
          if (err) {
            this.alertService.error(err.error.error, { autoClose: true });
          }
        },
      });
    } else {
      this.addressFormGroups[index].markAllAsTouched()
    }
  }

  /**
   * @param index 
   */

  deleteAddress(index: any) {
    if (
      this.addressFormGroups.length === 1 ||
      this.addressFormGroups[index].get('isDefaultAddress')?.value === true
    ) {
      return;
    }
    const deletedId = this.addressFormGroups[index].get('id')?.value;
    if (deletedId) {
      this.auth
        .deleteAdress(this.addressFormGroups[index].get('id')?.value)
        .subscribe({
          next: (res) => {
            if (res) {
              this.alertService.success(res.msg, { autoClose: true });
              this.addresses.removeAt(index);
            }
          },
          error: (err) => {
            if (err) {
              this.alertService.error(err.error.error, { autoClose: true });
            }
          },
        });
    }
  }

  /**
   * adds new address
   */

  addNewAddres() {
    this.displayNewAddres = true;
    if (this.displayNewAddres === true) {
      if (this.newAddresForm.valid) {
    let token = localStorage.getItem('token')
        this.auth.createAddress(this.newAddresForm.value, token).subscribe({
          next: (res) => {
            if (res) {
              this.alertService.success(res.msg, { autoClose: true });
              this.addresses.clear();
              this.getUserAddresses(token);
              this.displayNewAddres = false;
              this.newAddresForm.reset();
            }
          },
          error: (err) => {
            if (err) {
              this.alertService.error(err.error.error, { autoClose: true });
            }
          },
        });
      }
    }
  }

  cancelNewAddres() {
    this.displayNewAddres = false;
    this.newAddresForm.reset();
  }

  /**
   * 
   * @param action : increase/decrease
   * @param petType 
   * @param newPets 
   */

  setPets(action: string, petType: string, newPets: boolean) {
    if(newPets) {
      if (action === 'increase') {
        if (petType === 'dog') {
          this.dogCount = this.dogCount + 1;
          this.newAddresForm.get('dog')?.setValue(this.dogCount);
        } else if (petType === 'cat') {
          this.catCount = this.catCount + 1;
          this.newAddresForm.get('cat')?.setValue(this.catCount);
        } else if (petType === 'other') {
          this.otherPetCount = this.otherPetCount + 1;
          this.newAddresForm.get('otherAnimal')?.setValue(this.otherPetCount);
        }
      } else if (action === 'decrease') {
        if (petType === 'dog') {
          if (this.newAddresForm.get('dog')?.value !== 0) {
            this.dogCount = this.dogCount - 1;
            this.newAddresForm.get('dog')?.setValue(this.dogCount);
          }
        } else if (petType === 'cat') {
          if (this.newAddresForm.get('cat')?.value !== 0) {
            this.catCount = this.catCount - 1;
            this.newAddresForm.get('cat')?.setValue(this.catCount);
          }
        } else if (petType === 'other') {
          if (this.newAddresForm.get('otherAnimal')?.value !== 0) {
            this.otherPetCount = this.otherPetCount - 1;
            this.newAddresForm.get('otherAnimal')?.setValue(this.otherPetCount);
          }
        }
      }
    } else {
      if (action === 'increase') {
        if (petType === 'dog') {
          this.selectedPets.dog += 1;
        } else if (petType === 'cat') {
          this.selectedPets.cat += 1;
        } else if (petType === 'other') {
          this.selectedPets.otherAnimal += 1;
        }
      } else if (action === 'decrease') {
        if (petType === 'dog') {
          if (this.selectedPets.dog !== 0) {
            this.selectedPets.dog -= 1;
          }
        } else if (petType === 'cat') {
          if (this.selectedPets.cat !== 0) {
            this.selectedPets.cat -= 1;
          }
        } else if (petType === 'other') {
          if (this.selectedPets.otherAnimal !== 0) {
            this.selectedPets.otherAnimal -= 1;
          }
        }
      }
    }
  }

  removeDogs(newPets: boolean ,index?: any) {
    if(newPets) {
      this.dogCount = 0;
      this.newAddresForm.get('dog')?.setValue(0);
      this.newAddresForm.get('dogText')?.setValue('' || null);
    } else {
      if (this.addresses.enabled) {
        this.addressFormGroups[index].get('dog')?.setValue(0);
        this.addressFormGroups[index].get('dogText')?.setValue('' || null); 
      }
    }
  }

  removeCats(newPets: boolean, index?: any) {
    if(newPets) {
      this.catCount = 0;
      this.newAddresForm.get('cat')?.setValue(0);
      this.newAddresForm.get('catText')?.setValue('' || null);
    } else {
      if (this.addresses.enabled) {
        this.addressFormGroups[index].get('cat')?.setValue(0);
        this.addressFormGroups[index].get('catText')?.setValue('' || null);
      }
    }
  }

  removeOthers(newPets: boolean, index?: any) {
    if(newPets){
      this.otherPetCount = 0;
      this.newAddresForm.get('otherAnimal')?.setValue(0);
      this.newAddresForm.get('otherAnimalText')?.setValue('' || null);
    } else {
      if (this.addresses.enabled) {
        this.addressFormGroups[index].get('otherAnimal')?.setValue(0);
        this.addressFormGroups[index]
          .get('otherAnimalText')
          ?.setValue('' || null);
      }
    }
  }

  toggleModal(i?: any) {
    if (this.addressFormGroups[i].enabled) {
      this.selectedPets = this.addressFormGroups[i].value;
      this.petsModal = !this.petsModal;
    }
  }

  confirmPets(id: any) {
    if (this.addressForm instanceof FormGroup) {
      const addressesArray = this.addressForm.get('addresses') as FormArray;
      const index = addressesArray.controls.findIndex((control) => {
        const addressFormGroup = control as FormGroup;
        return addressFormGroup.get('id')?.value === id;
      });
      if (index !== -1) {
        const addressFormGroup = addressesArray.at(index) as FormGroup;
        addressFormGroup.get('dog')?.setValue(this.selectedPets.dog);
        addressFormGroup.get('cat')?.setValue(this.selectedPets.cat);
        addressFormGroup
          .get('otherAnimal')
          ?.setValue(this.selectedPets.otherAnimal);
        addressFormGroup.get('dogText')?.setValue(this.selectedPets.dogText);
        addressFormGroup.get('catText')?.setValue(this.selectedPets.catText);
        addressFormGroup
          .get('otherAnimalText')
          ?.setValue(this.selectedPets.otherAnimalText);
        this.petsModal = !this.petsModal;
      } else {
        console.log('Address with id', id, 'not found in addresses array.');
      }
    } else {
      console.log('addressForm is not initialized as a FormGroup.');
    }
  }

  cancelPetsModal() {
    this.petsModal = !this.petsModal;
  }

  toggleNewPetsModal() {
    this.newPetsModal = !this.newPetsModal;
  }

  // Handle Cleaning Types

  handleType(type: string): string {
    switch (type) {
      case 'WINDOW_CLEANING':
        return 'Fensterreinigung';
      case 'CLEAN_OUT':
        return 'Ausräumen / Ordnung schaffen';
      case 'BUY_CLEANING':
        return 'Einkaufen';
      case 'GARDEN_MAINTENANCE':
        return 'Gartenpflege';
      case 'ILLNESS_CLEANING':
        return 'Krankheit';
      case 'ACCIDENT_CLEANING':
        return 'Unfall';
      case 'PREGNANCY_CLEANING':
        return 'Schwangerschaft';
      case 'MATERNITY_CLEANING':
        return 'MUTTERSCHAFTEN';
      case 'COMPANY_CLEANING':
        return 'Firmenreinigung';
      case 'LINK_TO_RECYCLE':
        return 'Link zum Recycling';
      case 'WEEKEND_CLEANING':
        return 'Wochenende';
      case 'END_OF_TENANCY':
        return 'Umzugsreinigung';
      case 'BASIC_CLEANING':
        return 'Grundreinigung';
      case 'Fenster':
        return 'Fensterreinigung';
      case 'Umzug':
        return 'Umzugsreinigung';
      case 'Grund Reinigung (Heavy)':
        return 'Grundreinigung';
      default:
        return 'Standart';
    }
  }

  handleRecurrence (recurrence: string): string {
    switch (recurrence) {
      case 'WEEKLY':
        return 'Wöchentlich';
      case 'BI_WEEKLY': 
        return 'Alle zwei Wochen';
      case 'MONTHLY':
        return 'Monatlich';
      case 'MORE_OFTEN': 
        return 'Individuell'
      default:
        return 'Einmalig';
    }
  }

  handleCleaningStatus(status: string): string {
    switch (status) {
      case 'CANCELLED':
        return 'Abgesagt';
      case 'inaktiv': 
        return 'Abgesagt';
      case 'PENDING':
        return 'Ausstehend';
      case 'verrechnet': 
        return 'Verrechnet'
      case 'ACTIVE': 
        return 'Aktiv'
      case 'INVOICED': 
        return 'Verrechnet'
      case 'aktiv': 
        return 'Aktiv'
      default:
        return 'Ausstehend';
    }
  }

  handleTransactionStatus(status: string): string {
    switch (status) {
      case 'waiting':
        return 'Warten';
      case 'confirmed': 
        return 'Bestätigt';
      case 'cancelled':
        return 'Abgesagt';
      case 'declined': 
        return 'Abgelehnt'
      case 'authorized': 
        return 'Autorisiert'
      case 'reserved': 
        return 'Reserviert'
      case 'refunded': 
        return 'Zurückerstattet'
      case 'refundpending': 
        return 'Rückerstattungausstehend'
      case 'partially-refunded': 
        return 'Teilweise erstattet'
      case 'chargeback': 
        return 'Rückbuchung'
      default:
        return 'Error';
    }
  }

  handleSettings(booking: any, index: any) {
    this.detailsOpen = true;
    this.selectedDetails = booking;
    this.currentlyOpenCardId = null;
    this.currentCleaningOpenId = null;
  }
  
  // Cancel Cleanings

  handleCancelCleanings(booking: any, index: any){
    this.cancelCleaningModal = true
    this.selectedDetails = booking
    this.currentlyOpenCardId = null;
    this.currentCleaningOpenId = null;
  }

  // Cleaning Reactivation

  /**
   * 
   * @param booking : type of cleaning
   * @param index 
   */

  handleReactivationCleaning(booking: any, index: any) {
    this.reactivationModal = true
    this.selectedDetails = booking
    this.currentlyOpenCardId = null;
    this.currentCleaningOpenId = null; 
  }

  submitReactivation() {
    let token = localStorage.getItem('token')
    let reactivateObj: any = {}
    if(this.selectedDetails.projektnummer) {
      reactivateObj = {
      projektserie: this.selectedDetails.projektserie || null,
      projektnummer: this.selectedDetails.projektnummer || null,
      cleaningId: this.selectedDetails.id || null,
      startDate: this.selectedDetails.startDate,
      reactivationRequested: true,
      user: {
        erpUserId: this.selectedDetails.user.erpUserId,
        firstName:  this.selectedDetails.user.firstName || null,
        lastName: this.selectedDetails.user.lastName
       }
      }
    } else {
      reactivateObj = {
      projektserie: this.selectedDetails.projektserie || null,
      projektnummer: this.selectedDetails.projektnummer || null,
      cleaningId: this.selectedDetails.id || null,
      startDate: this.selectedDetails.startDate,
      reactivationRequested: true,
      user: { ...this.selectedDetails.user }
    }
    }
   this.auth.cleaningReactivation(reactivateObj, token).subscribe({
    next: (res) => {
      if (res){
        this.listAllCleanings(token)
        this.alertService.success(res.msg, { autoClose: true });
        this.closeDetails()
      }
    }, error: (err) => {
      if(err){
        this.alertService.error(err.error.error, { autoClose: true });
      }
    }
   })
  }

  closeDetails() {
    this.detailsOpen = false;
    this.cancelCleaningModal = false
    this.additionalServicesMoadl = false
    this.reactivationModal = false
    this.ironing = 0
    this.cabinets = 0
    this.duration = 0
    this.price = 0
    this.cleaningPrice = 0
    this.cabinetRemark = ''
    this.currentlyOpenCardId = null;
    this.currentCleaningOpenId = null;
    this.additionalServicesForm.reset()
    this.serviceslimit = 0
  }

  closeModal() {
    this.openModal = false;
  }

  openProfile() {
    this.profileDisplay = !this.profileDisplay;
  }

  // Personal Profile Edit

  personalDataEdit() {
    this.disabledUserFields = false;
    this.showSaveBtn = true;
    this.userForm.enable();
    if (this.userForm.valid) {
      this.userForm.enable();
      this.userForm.get('email')?.disable();
    } else {
      this.userForm.markAllAsTouched()
    }
  }

  setToCurrentTime(date: Date): Date {
    const currentTime = new Date();
    date.setHours(
      currentTime.getHours(),
      currentTime.getMinutes(),
      currentTime.getSeconds(),
      currentTime.getMilliseconds()
    );
    return date;
  }

  personalDataSave() {
    this.disabledUserFields = true;
    this.showSaveBtn = false;
    if (this.userForm.valid) {
      const formVal = this.userForm.value;
      const formatedDate = this.userForm.get('birthDate')?.value;

      if (formatedDate) {
        const currentDate = new Date(formatedDate);
        this.setToCurrentTime(currentDate);
        const dateISO = currentDate.toISOString();
        const cleaningReminderVal =
          this.userForm.get('cleaningReminder')?.value;

        if (cleaningReminderVal === '1') {
          this.cleaningReminder = 1;
        } else if (cleaningReminderVal === '4') {
          this.cleaningReminder = 4;
        }
        const formObj: any = {
          clientType: this.user.clientType,
          firstName: formVal.firstName,
          lastName: formVal.lastName,
          birthDate: dateISO,
          companyName: formVal.companyName || null,
          phoneNumber: formVal.phoneNumber,
          sex: this.user.sex,
          cleaningReminder: this.cleaningReminder,
        };
          let user = localStorage.getItem("user")
          let token = localStorage.getItem('token')
          this.auth.updateUser(formObj, this.userId, token).subscribe({
            next: (res) => {
              if (res) {
                this.getUser(user, token)
                this.alertService.success(res.msg, {
                  autoClose: true,
                });
              }
            },
            error: (err) => {
              if (err) {
                this.alertService.error(err.error.error, { autoClose: true });
                this.showSaveBtn = false;
              }
            },
          });
          this.userForm.disable();
      }
    } else { 
      this.userForm.disable();
      this.userForm.enable()
      this.userForm.get('email')?.disable();
      this.userForm.markAllAsTouched()
      this.showSaveBtn = true;
    }
  }

  // Settings Tab Functions

  onReplacementHandle(selectedValue: any) {
    this.hasReplacement = selectedValue;
    let token = localStorage.getItem('token')
    this.auth
      .updateUser({ stv: this.hasReplacement, sex: this.user.sex}, this.userId, token)
      .subscribe({
        next: (res) => {
          if (res) {
            this.alertService.success(res.msg, {
              autoClose: true,
            });
          }
        },
        error: (err) => {
          if (err) {
            this.alertService.error(err.error.error, { autoClose: true });
          }
        },
      });

    return this.hasReplacement;
  }

  changePassword() {
    let token = localStorage.getItem('token')
    if (this.changePasswordForm.valid) {
      if (
        this.changePasswordForm.get('password')?.value ===
        this.changePasswordForm.get('confirmPassword')?.value
      ) {
        const pass = this.changePasswordForm.get('confirmPassword')?.value;
        const body = {
          password: pass,
          token: token,
        };
        this.auth.resetPassword(body).subscribe({
          next: (res) => {
            if (res) {
              this.alertService.success(res.msg, { autoClose: true });
              this.changePasswordForm.reset();
            }
          },
          error: (err) => {
            if (err) {
              if(err.error.error === "ValidationError: \"password\" length must be at least 6 characters long") {
                this.alertService.error("Das Passwort muss mindestens 6 Zeichen lang sein", {autoClose: true})
              } else {
                this.alertService.error(err.error.error, {autoClose: true})
              }
            }
          },
        });
      }
    }
  }

  deleteAccount() {
    if (confirm('Sind Sie sicher, dass Sie Ihr Konto löschen möchten?')) {
      this.auth.deleteUser().subscribe({
        next: (res) => {
          if (res) {
            this.alertService.success(res.msg, { autoClose: true });
            this.logout();
          }
        },
        error: (err) => {
          if (err) {
            this.alertService.error(err.error.error, { autoClose: true });
          }
        },
      });
    }
  }

  // Payment Tab Functions

  /**
   * add new credit card
   * @param isPaymentMethodSwitch : checks if payment methode is creditcard
   */

  addNewC(isPaymentMethodSwitch: boolean) {
    let token = localStorage.getItem('token')
    this.auth.addNewCard(token, isPaymentMethodSwitch).subscribe({
      next: (res: any) => {
        if (res) {
          if (res && res.data && res.data.url) {
            this.alertService.success(res.msg, { autoClose: true });
            window.location.href = res.data.url;
          }
        }
      },
      error: (err) => {
        if (err) {
          this.alertService.error(err.error.error, { autoClose: true });
        }
      },
    });
  }

  setDefaultP(id: any) {
    this.defaultP = true;
    let token = localStorage.getItem('token')
    this.auth.setDefaultCard(id, { isDefault: this.defaultP }, token).subscribe({
      next: (res) => {
        if (res) {
          this.alertService.success(res.msg, { autoClose: true });
          this.getPaymentMethods(token)
        }
      },
      error: (err) => {
        if (err) {
          this.alertService.error(err.error.error, { autoClose: true });
        }
      },
    });
  }

  handleCreditDelete(id: string) {
    let token = localStorage.getItem('token')
    this.auth.deleteCard(id, token).subscribe({
      next: (res) => {
        if (res) {
          this.alertService.success('Erfolgreich', { autoClose: true });
          this.getPaymentMethods(token)
        }
      },
      error: (err) => {
        if (err) {
          this.alertService.error(err.error.error, { autoClose: true });
        }
      },
    });
  }

  /**
   * 
   * @param expiry :checks if its expired by date
   * @returns 
   */

  isCardExpired(expiry: string): boolean {
    const [year, month] = expiry.split('-').map(Number);
    const currentDate = new Date();
    const expiryDate = new Date(year, month);
    return expiryDate < currentDate;
  }

  /**
   * handle payment method card or invoice
   * @param type : type of payment 
   */

  handlePaymentMethod(type: string) {
    let token = localStorage.getItem('token');
    let user = localStorage.getItem("user");
  
    const hasExpiredCard = this.cardDetails.some((card: any) => this.isCardExpired(card.expiry));
  
    if(this.user.clientType === 'PRIVATE') {
      if(type === 'CARD') {
        if(this.cardDetails.length > 0 && !hasExpiredCard) {
          this.paymentType = type;
          let cardObj = {
            clientType: this.user.clientType,
            paymentType: this.paymentType
          };
          this.auth.updateUser(cardObj, this.user.id, token).subscribe({
            next: (res) => {
              if(res) {
                this.alertService.success(res.msg, { autoClose: true });
                this.getUser(user, token);
              }
            },
            error: (err) => {
              if(err){
                this.alertService.error(err.error.error, {autoClose: true});
              }
            }
          });
        } else {
          this.addNewC(true);
        }
      } else {
        this.paymentType = type;
        let cardObj = {
          clientType: this.user.clientType,
          paymentType: this.paymentType
        };
        this.auth.updateUser(cardObj, this.user.id, token).subscribe({
          next: (res) => {
            if(res) {
              this.alertService.success(res.msg, { autoClose: true });
              this.getUser(user, token);
            }
          },
          error: (err) => {
            if(err){
              this.alertService.error(err.error.error, {autoClose: true});
            }
          }
        });
      }
    } else {
      if(type === 'CARD') {
        if(this.cardDetails.length > 0 && !hasExpiredCard) { 
          this.paymentType = type;
          let cardObj = {
            clientType: this.user.clientType,
            paymentType: this.paymentType,
            companyName: this.user.companyName
          };
          this.auth.updateUser(cardObj, this.user.id, token).subscribe({
            next: (res) => {
              if(res) {
                this.alertService.success(res.msg, { autoClose: true });
                this.getUser(user, token);
              }
            },
            error: (err) => {
              if(err){
                this.alertService.error(err.error.error, {autoClose: true});
              }
            }
          });
        } else {
          this.addNewC(true);
        }
      } else {
        this.paymentType = type;
        let cardObj = {
          clientType: this.user.clientType,
          paymentType: this.paymentType,
          companyName: this.user.companyName
        };
        this.auth.updateUser(cardObj, this.user.id, token).subscribe({
          next: (res) => {
            if(res) {
              this.alertService.success(res.msg, { autoClose: true });
              this.getUser(user, token);
            }
          },
          error: (err) => {
            if(err){
              this.alertService.error(err.error.error, {autoClose: true});
            }
          }
        });
      }
    }
  }

  handleCardEdit(cardId: string) {
    if (this.currentlyOpenCardId === cardId) {
      this.currentlyOpenCardId = null;
    } else {
      this.currentlyOpenCardId = cardId;
    }
  }

  isSettingsOpen(cardId: string): boolean {
    return this.currentlyOpenCardId === cardId;
  }

  /**
   * 
   * @param cleaningId 
   * @param projektnummer 
   */

  handleCleaningEdit(cleaningId?: string, projektnummer?: string) {
    if(this.currentCleaningOpenId === cleaningId || this.currentCleaningOpenId === projektnummer) {
      this.currentCleaningOpenId = null
    } else {
      if(projektnummer) {
        this.currentCleaningOpenId = projektnummer
      }else {
        this.currentCleaningOpenId = cleaningId
      }
    }
  }

  isCleaningOpen(cleaningId: string, projektnummer: string): boolean {
    if(cleaningId) {
      return this.currentCleaningOpenId === cleaningId
    } else {
      return this.currentCleaningOpenId === projektnummer
    }
  }

  /**
   * cleaning cancellation
   */

  submitCancellation() {
    let token = localStorage.getItem('token')
    if (this.cancelCleaning.valid) {
      let cancelObj: any = {};
      if(this.selectedDetails.projektnummer) {
        cancelObj = {
          projektserie: this.selectedDetails.projektserie || null,
        projektnummer: this.selectedDetails.projektnummer || null,
        cleaningId: this.selectedDetails.id || null,
        cleaningType: this.selectedDetails.cleaningType,
        recurrence: this.selectedDetails.recurrence,
        startDate: this.selectedDetails.startDate,
        status: 'inaktiv',
        user: {
          erpUserId: this.selectedDetails.user.erpUserId,
          firstName:  this.selectedDetails.user.firstName || null,
          lastName: this.selectedDetails.user.lastName
         },
        cancellationReason: this.cancelCleaning.get('cancellationReason')?.value,
        otherReason: this.cancelCleaning.get('otherReason')?.value
        }
      } else {
        cancelObj = {
          projektserie: this.selectedDetails.projektserie || null,
        projektnummer: this.selectedDetails.projektnummer || null,
        cleaningId: this.selectedDetails.id || null,
        cleaningType: this.selectedDetails.cleaningType,
        recurrence: this.selectedDetails.recurrence,
        startDate: this.selectedDetails.startDate,
        status: 'inaktiv',
        user: { ...this.selectedDetails.user },
        cancellationReason: this.cancelCleaning.get('cancellationReason')?.value,
        otherReason: this.cancelCleaning.get('otherReason')?.value
        }
      }
      this.booking.cancelCleanings(cancelObj, token).subscribe({
        next: (res: any) => {
          if (res) {
            this.listAllCleanings(token)
            this.alertService.success(res.msg, { autoClose: true });
            this.cancelCleaning.reset();
            this.cancelCleaningModal = !this.cancelCleaningModal;
            this.getUser(this.userId, token)
          }
        },
        error: (err) => {
          if (err) {
            this.alertService.error(err.error.error, { autoClose: true });
          }
        },
      });
      this.cancelCleaning.reset();
    }
  }

  //  Check Functions

  checkForPastDate(cleaning: any): boolean {
    const currentDate = moment().startOf('day')
      const startDate = moment(cleaning).startOf('day')
      const dayDifference = startDate.diff(currentDate, 'days')
      const isWeekend = [5, 6, 0].includes(currentDate.day())
      if ((dayDifference < 3 && !isWeekend) || (dayDifference < 4 && isWeekend)) {
        return true;
      } else {
        return false
      }
  }

  checkCleaningType(type: string): string {
    let cleaningType = ''
    if(type === 'REGULAR') {
      return cleaningType = 'REGULAR'
    } else if (type === "Regelmässige Reinigung") {
     return cleaningType = 'Regelmässige Reinigung'
    }
    return cleaningType
  }

  checkCleaningStatus(status: string): boolean {
    if(status === "CANCELLED") {
      return true
    } else if(status === 'inaktiv') {
      return true
    } else {
      return false
    }
  }

  checkFormDisable() {
    if (this.serviceslimit >= 1.5) {
      this.additionalServicesForm.disable();
      this.alertService.error("Sie haben die maximale Dauer der Zusatzleistungen erreicht.", {autoClose: true})
    } else {
      this.additionalServicesForm.enable();
    }
  }

  // Additional Services Function

  handleAdditionalServices(booking: any, index: any){
    this.additionalServicesMoadl = true
    this.selectedDetails = booking
    this.currentlyOpenCardId = null;
    this.currentCleaningOpenId = null;
    if(this.selectedDetails.projektnummer) {
      let cleaningDuration = +this.transformDataAndCalculateDuration(this.selectedDetails.additionalServices, this.selectedDetails.startDate, this.selectedDetails.endDate)
      this.duration = cleaningDuration
      this.fetchPrice(this.duration, true)
      this.additionalServicesForm.get('oven')?.setValue(this.selectedDetails.additionalServices.oven)
      this.additionalServicesForm.get('fridge')?.setValue(this.selectedDetails.additionalServices.fridge)
      this.additionalServicesForm.get('ironingDuration')?.setValue(0)
      this.additionalServicesForm.get('cabinetDuration')?.setValue(this.selectedDetails.additionalServices.cabinetDuration)
      this.additionalServicesForm.get('cabinetRemark')?.setValue(this.selectedDetails.additionalServices.cabinetRemark)
      this.additionalServicesForm.get('wateringPlants')?.setValue(this.selectedDetails.additionalServices.wateringPlants)
      this.cabinetRemark = this.selectedDetails.additionalServices.cabinetRemark
      this.ironing = this.additionalServicesForm.get('ironingDuration')?.value
      this.cabinets = this.additionalServicesForm.get('cabinetDuration')?.value  
    } else {
      this.additionalServicesForm.get('oven')?.setValue(this.selectedDetails.additionalServices.oven)
      this.additionalServicesForm.get('fridge')?.setValue(this.selectedDetails.additionalServices.fridge)
      this.additionalServicesForm.get('ironingDuration')?.setValue(this.selectedDetails.additionalServices.ironingDuration)
      this.additionalServicesForm.get('cabinetDuration')?.setValue(this.selectedDetails.additionalServices.cabinetDuration)
      this.additionalServicesForm.get('cabinetRemark')?.setValue(this.selectedDetails.additionalServices.cabinetRemark)
      this.additionalServicesForm.get('wateringPlants')?.setValue(this.selectedDetails.additionalServices.wateringPlants)
      this.cabinetRemark = this.selectedDetails.additionalServices.cabinetRemark
      this.duration = this.totalDuration(this.selectedDetails.cleaningDays)
      this.ironing = this.additionalServicesForm.get('ironingDuration')?.value
      this.cabinets = this.additionalServicesForm.get('cabinetDuration')?.value  
    }
  }

  /**
   * @param action : increase/decrease
   * @param service 
   */

  handleIroningAndCabinets(action: string, service: any) {
    if(service === 'ironingDuration') {
      if(action === 'increase' && this.ironing !== 60) {
        if(this.selectedDetails.recurrence === 'MORE_OFTEN') {
          this.ironing += 30
          this.additionalServicesForm.get('ironingDuration')?.setValue(this.ironing)
          this.duration += (0.5 * this.selectedDetails.cleaningDays.length)          
        } else {
          this.ironing += 30
          this.additionalServicesForm.get('ironingDuration')?.setValue(this.ironing)
          this.duration += 0.5
        }
      } else if(action === "decrease") {
        if(this.ironing !== 0) {
          if(this.selectedDetails.recurrence === 'MORE_OFTEN') {
            this.ironing -= 30
            this.additionalServicesForm.get('ironingDuration')?.setValue(this.ironing)
            this.duration -= (0.5 * this.selectedDetails.cleaningDays.length)
          } else {
            this.ironing -= 30
            this.additionalServicesForm.get('ironingDuration')?.setValue(this.ironing)
            this.duration -= 0.5
          }
        }
      }
    } else {
      if(action === 'increase' && this.cabinets !== 60 && this.serviceslimit < 1.5) {
        if(this.selectedDetails.recurrence === 'MORE_OFTEN') {
          this.cabinets += 30
          this.additionalServicesForm.get('cabinetDuration')?.setValue(this.cabinets)
          this.duration += (0.5 * this.selectedDetails.cleaningDays.length)
          this.serviceslimit += 0.5
        } else {
          this.cabinets += 30
          this.additionalServicesForm.get('cabinetDuration')?.setValue(this.cabinets)
          this.duration += 0.5
          this.serviceslimit += 0.5
        }
      } else if(action === "decrease") {
        if(this.cabinets !== 0) {
          if(this.selectedDetails.recurrence === 'MORE_OFTEN') {
            this.cabinets -= 30
            this.additionalServicesForm.get('cabinetDuration')?.setValue(this.cabinets)
            this.duration -= (0.5 * this.selectedDetails.cleaningDays.length)
            this.serviceslimit -= 0.5
          } else {
            this.cabinets -= 30
            this.additionalServicesForm.get('cabinetDuration')?.setValue(this.cabinets)
            this.duration -= (0.5 * this.selectedDetails.cleaningDays.length)
            this.serviceslimit -= 0.5
          }
        }
      }
    }
    this.fetchPrice(this.duration)
    this.checkFormDisable()
  }

  handleFridgeAndOven(type: string) {
    if(type === 'oven') {
      if(this.additionalServicesForm.get('oven')?.value === true) {
        if (this.selectedDetails.recurrence === 'MORE_OFTEN') {
          this.duration += (0.5 * this.selectedDetails.cleaningDays.length)
        } else {
          this.duration += 0.5
          this.serviceslimit += 0.5
        }
      } else {
        if (this.selectedDetails.recurrence === 'MORE_OFTEN') {
          this.duration -= (0.5 * this.selectedDetails.cleaningDays.length)
        } else {
          this.duration -= 0.5
          this.serviceslimit -= 0.5
        }
      }
    } else if(type === 'wateringPlants') {
      if(this.additionalServicesForm.get('wateringPlants')?.value === true) {
        if (this.selectedDetails.recurrence === 'MORE_OFTEN') {
          this.duration += (0.5 * this.selectedDetails.cleaningDays.length)
        } else {
          this.duration += 0.5
          this.serviceslimit += 0.5
        }
      } else {
        if (this.selectedDetails.recurrence === 'MORE_OFTEN') {
          this.duration -= (0.5 * this.selectedDetails.cleaningDays.length)
        } else {
          this.duration -= 0.5
          this.serviceslimit -= 0.5
        }
      }
    } else {
      if(this.additionalServicesForm.get('fridge')?.value === true) {
        if(this.selectedDetails.recurrence === 'MORE_OFTEN') {
          this.duration += (0.5 * this.selectedDetails.cleaningDays.length)
        } else {
          this.duration += 0.5
          this.serviceslimit += 0.5
        }
      } else {
        if(this.selectedDetails.recurrence === 'MORE_OFTEN') {
          this.duration -= (0.5 * this.selectedDetails.cleaningDays.length)
        } else {
          this.duration -= 0.5
          this.serviceslimit -= 0.5
        }
      }
    }
    this.fetchPrice(this.duration)
    this.checkFormDisable()
  }

  cancelAdditionalServices() {
    this.detailsOpen = false;
    this.cancelCleaningModal = false
    this.additionalServicesMoadl = false
    this.ironing = 0
    this.cabinets = 0
    this.duration = 0
    this.cleaningPrice = 0
    this.price = 0
    this.cabinetRemark = ''
    this.currentlyOpenCardId = null;
    this.currentCleaningOpenId = null;
    this.serviceslimit = 0
    this.additionalServicesForm.reset()
  }

  handleRequiredRemark() {
    if(this.additionalServicesForm.get('cabinetDuration')?.value !== 0) {
      this.requiredRemark = true
    } else {
      this.requiredRemark = false
      this.additionalServicesForm.get('cabinetRemark')?.setValue(null) 
    }
  }

  /**
   * fetches price for ERP cleanings based on their duration
   * @param duration 
   * @param isInitialFetch 
   */

  fetchPrice(duration: any, isInitialFetch: boolean = false) {
    if(this.selectedDetails.projektnummer) {
      this.pricing.fetchPriceProfile(duration, 'WEEKLY', false, 'PRIVATE').pipe(
        switchMap(() => this.pricing.price$)
      ).subscribe((price) => {
        this.price = price;
        if (isInitialFetch && this.cleaningPrice === 0) {
          this.cleaningPrice = price;
          this.price = price;
        }
      });
    } else {
      this.pricing.fetchPrice(duration, 'WEEKLY', this.selectedDetails.materialsIncluded, 'PRIVATE');
      this.pricing.price$.subscribe((price) => {
        this.price = price;
      });
    }
  }

  totalDuration(duration: any): number {
    let totalDuration = 0;
    duration.forEach((el: any) => {
      totalDuration += el.duration;
    });
    return totalDuration;
  }

  postAdditionalServices() {
    if(this.selectedDetails.cleaningDays[0].timeFrame === 'AFTER_NOON' && this.duration >= 6.5) {
      this.alertService.error("Die Reinigung darf nicht länger als 6 Stunden dauern.", {autoClose: true})
    } else if (this.requiredRemark) {
      this.alertService.error("Mindestens 5 Zeichen.", {autoClose: true})
    } else {
    let token = localStorage.getItem('token')
      if(this.additionalServicesForm.valid) {
        if(this.selectedDetails.additionalServices.id) {
          let additionalServiceObj = {
            ...this.additionalServicesForm.value,
            userId: this.user.id,
            startDate: this.selectedDetails.startDate
          }
          this.auth.additionalServices(additionalServiceObj, this.selectedDetails.additionalServices.id, token).subscribe({
            next: (res) => {
              if(res) {
                this.alertService.success(res.msg, {autoClose: true})
                this.listAllCleanings(token)
                this.closeDetails()
              }
            },
            error: (err) => {
              if(err) {
                this.alertService.error(err.error.error, { autoClose: true });
              }
            }
          })
        } else {
          let additionalServiceObj = {
            ...this.additionalServicesForm.value,
            projektserie: this.selectedDetails.projektserie,
            projektnummer: this.selectedDetails.projektnummer,
            userId: this.user.id,
            startDate: this.selectedDetails.startDate
          }
          this.auth.erpAdditionalServices(additionalServiceObj, token).subscribe({
            next: (res) => {
              if(res) {
                this.alertService.success(res.msg, {autoClose: true})
                this.listAllCleanings(token)
                this.closeDetails()
              }
            },
            error: (err) => {
              if(err) {
                this.alertService.error(err.error.error, { autoClose: true });
              }
            }
          })
          
        }
      }
    }
  }

  logout() {
    this.auth.logout();
    this.googleAuthService.logout();
    this.fbAuthService.logout();
    this.route.navigate(['login']);
  }
}
