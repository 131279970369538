import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, tap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private token = localStorage.getItem('token');
  private _authToken = new BehaviorSubject<string>(
    this.token ? this.token : ''
  );
  authToken$ = this._authToken.asObservable();


  private _newAddress = new BehaviorSubject<any>({});
  newAddress$ = this._newAddress.asObservable();

  constructor(private http: HttpClient, private router: Router) {
    this.token = localStorage.getItem('token');
  }

  register(credentials: any): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/auth/register`, credentials.value, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': '123',
        }),
      })
      .pipe(
        catchError((error: any) => {
          console.error(error);
          return throwError(error);
        })
      );
  }

  createAddress(credentials: any, token:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/users/addresses`, credentials, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '123',
      }),
    })
    .pipe(
      tap((res:any) => {
        console.log('RESPONSE:',res)
        this._newAddress.next(res.data)
      })
    )
  }

  login(credentials: any): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/auth/login`, credentials.value, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': '123',
        }),
      })
      .pipe(
        tap((res:any) => {
          this._authToken.next(res.data.token)
        }),
        catchError((error: any) => {
          console.error(error);
          return throwError(error);
        })
      );
  }

  resetPassword(credentials: any): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/auth/reset-password`, credentials)
      .pipe(
        catchError((error: any) => {
          console.error(error);
          return throwError(error);
        })
      );
  }

  forgotPassword(credentials: any): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/auth/forgot-password`, credentials)
      .pipe(
        catchError((error: any) => {
          console.error(error);
          return throwError(error);
        })
      );
  }

  updateUser(credentials: any, id: any, token: any): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}/users/${id}`, credentials, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '123',
        }),
      })
      .pipe(
        catchError((error: any) => {
          console.error(error);
          return throwError(error);
        })
      );
  }

  deleteUser(): Observable<any> {
    return this.http
      .delete(`${environment.apiUrl}/users/`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
          'ngrok-skip-browser-warning': '123',
        }),
      })
      .pipe(
        catchError((error: any) => {
          console.error(error);
          return throwError(error);
        })
      );
  }

  changePassword(credentials: any, token: any) {
    return this.http
      .post(`${environment.apiUrl}/auth/change-password`, credentials, {
        headers: new HttpHeaders({
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '123',
        }),
      })
      .pipe(
        catchError((error: any) => {
          console.error(error);
          return throwError(error);
        })
      );
  }

  getUser(id: any, token: any): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/users/${id}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '123',
        }),
      })
      .pipe(
        catchError((error: any) => {
          console.error(error);
          return throwError(error);
        })
      );
  }

  getAddress(id: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/addresses/${id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
        'ngrok-skip-browser-warning': '123',
      }),
    });
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  getUserAdresses() {
    return this.http
      .get(`${environment.apiUrl}/users/addresses`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
          'ngrok-skip-browser-warning': '123',
        }),
      })
      .pipe(
        catchError((error: any) => {
          console.error(error);
          return throwError(error);
        })
      );
  }

  getUserAdressesForStandart(token: any) {
    return this.http
      .get(`${environment.apiUrl}/users/addresses`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '123',
        }),
      })
      .pipe(
        catchError((error: any) => {
          console.error(error);
          return throwError(error);
        })
      );
  }

  getAddresById(id: any, token: any): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/users/addresses/${id}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '123',
        }),
      })
      .pipe(
        catchError((error: any) => {
          console.error(error);
          return throwError(error);
        })
      );
  }

  updateAdress(credentials: any, id: any, token: any): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}/users/addresses/${id}`, credentials, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '123',
        }),
      })
      .pipe(
        catchError((error: any) => {
          console.error(error);
          return throwError(error);
        })
      );
  }

  deleteAdress(id: any): Observable<any> {
    return this.http
      .delete(`${environment.apiUrl}/users/addresses/${id}`, {
        headers: new HttpHeaders({
          'Content-type': 'application/json',
          Authorization: `Bearer ${this.token}`,
          'ngrok-skip-browser-warning': '123',
        }),
      })
      .pipe(
        catchError((err: any) => {
          console.error(err);
          return throwError(err);
        })
      );
  }

  getUserTransactions(token: any): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/users/transactions/`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '123',
        }),
      })
      .pipe(
        catchError((err: any) => {
          console.log(err);
          return throwError(err);
        })
      );
  }

  getPaymentMethods(token: any): Observable<any> {
    return this.http
      .get(` ${environment.apiUrl}/payment/methods`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '123',
        }),
      })
      .pipe(
        catchError((err: any) => {
          console.log(err);
          return throwError(err);
        })
      );
  }

  addNewCard(token:any, isPaymentMethodSwitch: boolean): Observable<any> {
    return this.http
      .post(
        `${environment.apiUrl}/payment/create-gateway?${isPaymentMethodSwitch ? 'isPaymentMethodSwitch=true' : 'isPaymentMethodSwitch=false'}`,
        {},
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'ngrok-skip-browser-warning': '123',
          }),
        }
      )
      .pipe(
        catchError((err: any) => {
          console.log(err);
          return throwError(err);
        })
      );
  }

  setDefaultCard(id: string, credentials: any, token: any): Observable<any> {
    return this.http
      .put(
        `${environment.apiUrl}/payment/default/${id}`,
        credentials,

        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'ngrok-skip-browser-warning': '123',
          }),
        }
      )
      .pipe(
        catchError((err: any) => {
          console.log(err);
          return throwError(err);
        })
      );
  }

  deleteCard(id: string, token: any) {
    return this.http
      .delete(`${environment.apiUrl}/payment/methods/${id}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '123',
        }),
      })
      .pipe(
        catchError((err: any) => {
          console.log(err);
          return throwError(err);
        })
      );
  }

  termsAndConditions(credentials: any): Observable<any> {
    return this.http
      .post(
        `${environment.apiUrl}/users/accept-terms-and-conditions`,
        credentials
      )
      .pipe(
        catchError((err: any) => {
          console.log(err);
          return throwError(err);
        })
      );
  }

  additionalServices(credentials: any, id: any, token: any): Observable<any> {
    return this.http
    .put(
      `${environment.apiUrl}/cleanings/additional-services/${id}`,
      credentials, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555',
        }),
      }
    )
    .pipe(
      catchError((err: any) => {
        console.log(err);
        return throwError(err);
      })
    );
  }

  erpAdditionalServices(credentials: any, token: any): Observable<any> {
    return this.http
    .post(
      `${environment.apiUrl}/cleanings/additional-services`,
      credentials, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555',
        }),
      }
    )
    .pipe(
      catchError((err: any) => {
        console.log(err);
        return throwError(err);
      })
    );
  }

  cleaningReactivation(credentials: any, token:any): Observable<any> {
    return this.http
      .put(
        `${environment.apiUrl}/cleanings/reactivate`,
        credentials, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'ngrok-skip-browser-warning': '555',
          }),
        }
      )
      .pipe(
        catchError((err: any) => {
          console.log(err);
          return throwError(err);
        })
      );
  }
  
  socialRegister(credentials: any): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/auth/social/register`, credentials, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': '123',
        }),
      })
      .pipe(
        catchError((error: any) => {
          console.error(error);
          return throwError(error);
        })
      );
  }
}
