import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../layout/header/header.component';
import { StepperComponent } from '../components/stepper/stepper.component';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth/auth.service';
import { AlertComponent } from '../components/alert/alert.component';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,HeaderComponent,StepperComponent,CommonModule,AlertComponent, TranslateModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  public token = localStorage.getItem('token')
  public isOpen: boolean = false
  public isLanguageOpen: boolean = false
  private defaultLanguage: string = "DE"
  public selectedLanguage: string = 'DE'
  public website = environment.websiteUrl
  
  title = 'pebra-web-app';

  constructor(private route: Router, private translate: TranslateService, private auth: AuthService){
    this.auth.authToken$.subscribe(token => {
      this.token = token
    })
  }
  ngOnInit(): void {
    this.selectedLanguage = localStorage.getItem("selectedLanguage") || this.defaultLanguage;
  }
  
  login() {
    if(this.token){
      this.route.navigate(['profile'])
    } else {
      this.route.navigate(['login'])
    }
  }
  
  handleIsOpenChange(isOpen:boolean){
    this.isOpen = isOpen
  }

  /**
   * 
   * @param lang 
   */
  
  selectLang(lang: string) {
    if (lang === 'EN') {
    this.selectedLanguage = 'EN';
    this.translate.use('en');
    localStorage.setItem("selectedLanguage", "EN");
  } else if (lang === 'DE') {
    this.selectedLanguage = 'DE';
    this.translate.use('de');
    localStorage.setItem("selectedLanguage", "DE")
  }
  this.isLanguageOpen = false;
}

  handleOpenLanguage(){
    this.isLanguageOpen = !this.isLanguageOpen
  }

}
