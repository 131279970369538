<div class="container">
  <div
    class="mx-3 bg-white rounded-tl-lg my-3 rounded-br-lg items-center justify-center"
  >
    <div
      class="flex flex-col items-center py-32 justify-center h-[70vh] text-start"
    >
      <span class="flex flex-col items-start gap-4 md:w-[25%]">
        <h1 class="text-[28px] font-bold">{{ 'AUTH.resetpass' | translate }}</h1>
        <form [formGroup]="resetPasswordForm" class="w-full relative">
          <div class="relative">
            <input
            formControlName="password"
            [type]="newpass"
            class="bg-[#e8eaf3] w-full p-4 mb-4 rounded-lg text-black mt-2"
            placeholder="{{ 'AUTH.password' | translate }}"
          />
            <img *ngIf="!hideNewPass" class="absolute cursor-pointer w-6 right-2 top-[35%]" src="../../assets/icons/profile/hidepass.svg" (click)="toggleVisibility('newpass')" alt="hidden" />
            <img *ngIf="hideNewPass" class="absolute cursor-pointer w-[27px] right-[6.5px] top-[34%]" src="../../assets/icons/profile/showpass.svg" (click)="toggleVisibility('newpass')" alt="show" />
          </div>
          @if(resetPasswordForm.get('password')?.hasError('password') ||
          (resetPasswordForm.get('password')?.hasError('required') &&
          resetPasswordForm.get('password')?.touched)){
          <span class="text-red-500 absolute left-0 bottom-16"
            >{{ 'AUTH.passrequired' | translate }}</span
          >
          }
          <div class="relative">
            <input
            formControlName="secondPassword"
            [type]="newpassSecond"
            class="bg-[#e8eaf3] w-full p-4 rounded-lg text-black my-2"
            placeholder="{{ 'AUTH.repeatpass' | translate }}"
          />
            <img *ngIf="!hideNewPassSecond" class="absolute cursor-pointer w-6 right-2 top-[35%]" src="../../assets/icons/profile/hidepass.svg" (click)="toggleVisibility('newpassSecond')" alt="hidden" />
            <img *ngIf="hideNewPassSecond" class="absolute cursor-pointer w-[27px] right-[6.5px] top-[34%]" src="../../assets/icons/profile/showpass.svg" (click)="toggleVisibility('newpassSecond')" alt="show" />
          </div>
          @if(resetPasswordForm.get('password')?.value !==
          resetPasswordForm.get('secondPassword')?.value){
          <span class="text-red-500 absolute left-0 -bottom-4"
            >{{ 'AUTH.passmatch' | translate }}</span
          >
          }
        </form>
        <button
          (click)="submitReset()"
          class="border-[1px] box-border disabled:bg-[#61bfd185] w-full bg-[#61BFD1] text-white font-bold rounded-[6px] px-8 py-2 hover:bg-[#61bfd185]"
        >
        {{ 'AUTH.login' | translate }}
        </button>
      </span>
    </div>
  </div>
</div>
