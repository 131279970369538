@if(detailsOpen){
  <div
    class="absolute p-2 py-6 md:p-6 w-[80%] max-h-[600px] md:w-[50%] md:h-[80%] cleaningmodal bg[#61bfd1] z-20 bg-white shadow-lg"
  >
    <div class="w-full flex justify-end">
      <span
        class="self-end text-[22px] cursor-pointer"
        (click)="closeDetails()"
        ><img src="../../assets/icons/exit.svg" alt=""
      /></span>
    </div>
    @if(selectedDetails){
    <div class="w-full overflow-y-auto max-h-[300px] gap-6 flex flex-col">
      <p class="text-black text-[22px] font-bold">{{ 'PROFILE.details' | translate }}</p>
      <span
        class="flex flex-col py-5 gap-4 px-2 md:px-10 overflow-y-auto w-full"
      >
        <span
          class="flex flex-col md:flex-row md:justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ 'PROFILE_MODALS.cleaningtype' | translate }}</p>
          @if(selectedDetails.cleaningType) {
            <p class="text-black">
            @if(selectedDetails.cleaningType === "REGULAR" || selectedDetails.cleaningType === "Regelmässige Reinigung"){
              {{ 'PROFILE_MODALS.regularcleaning' | translate }}
            } @else if(selectedDetails.cleaningType === "EXPRESS" || selectedDetails.cleaningType === "Express") {
              {{ 'PROFILE_MODALS.expresscleaning' | translate }}
            }
            </p>
          } @else {
            <p class="text-black">{{ 'SERVICE_TYPE.' + handleType(selectedDetails.type) | translate }}</p>
          }
        </span>
        <span
          *ngIf="selectedDetails.serviceType === 'BUSINESS'"
          class="flex flex-col md:flex-row md:justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ 'PROFILE_MODALS.propertytype' | translate }}</p>
          <p class="text-black">
            {{ selectedDetails.propertyType }}
          </p>
        </span>
        <span
          class="flex flex-col md:flex-row md:justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{ 'PROFILE_MODALS.oftencleaning' | translate }}</p>
          <p class="text-black">
            {{ 'RECURRENCE_TYPE.' + handleRecurrence(selectedDetails.recurrence) | translate }}
          </p>
        </span>
        <span
        class="flex flex-col md:flex-row md:justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
        <p class="w-[50%]">Status</p>
        <p class="text-black capitalize">
            @if (selectedDetails.cleaningDays) {
            {{ 'STATUS_TYPE.' + handleCleaningStatus(selectedDetails.status) | translate }}
          } @else {
            {{selectedDetails.isSynchronizedCreate ? ('STATUS_TYPE.Aktiv' | translate) : ('STATUS_TYPE.Ausstehend' | translate)}}
          }
        </p>
        </span>
        @if(selectedDetails.specialInstruction) {
          <span
            class="flex flex-col md:flex-row md:justify-between w-full py-2 border-b-2 border-[#acacac]"
          >
            <p class="w-[50%]">{{ 'AUTH.additionalinfo' | translate }}</p>
            <p class="text-black">
              {{ selectedDetails.specialInstruction }}
            </p>
          </span>
        }
        <span
          class="flex flex-col md:flex-row md:justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
        @if(selectedDetails.startDate) {
          <p class="w-[50%]">{{ 'PROFILE_MODALS.startdate' | translate }}</p>
          <p class="text-black">
            {{'DATE.' + (selectedDetails.startDate | date : "EEEE") | translate}} {{  'DATE.' + (selectedDetails.startDate | date : "MMMM") | translate }} {{selectedDetails.startDate | date : "d, y"}}
          </p>
        } @else {
          <p class="w-[50%]">{{ 'PROFILE_MODALS.appointment' | translate }}</p>
          @if (selectedDetails.appointmentDate === null) {
            <p class="text-black">{{ 'PROFILE.date' | translate }} {{ 'PROFILE.requested' | translate }}</p>  
          } @else {
            <p class="text-black">
              {{'DATE.' + (selectedDetails.startDate | date : "EEEE") | translate}} {{  'DATE.' + (selectedDetails.startDate | date : "MMMM") | translate }} {{selectedDetails.startDate | date : "d, y"}}
            </p>
          }
        }
        </span>
        @if(selectedDetails.cleaningDays) {
          @if(selectedDetails.cleaningDays.length > 1) {
          <span
            class="flex flex-col md:flex-row md:justify-between w-full py-2 border-b-2 border-[#acacac]"
          >
          <p class="w-full md:w-[50%]">{{'PRIVATE.Reinigungstage' | translate}}</p>
            
         <p class="flex flex-col"> 
          @for(days of selectedDetails.cleaningDays; track $index; let i = $index) {   
            <p class="text-black text-left md:text-right">
              {{ "PRIVATE." + days.day | translate }} - {{ days.duration }} {{'PROFILE.hours' | translate}}
            </p>

          }
        </p>
        </span>
          }

        <span
          class="flex flex-col md:flex-row md:justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PROFILE_MODALS.cleaningmaterials' | translate}}</p>
          <p class="text-black">
            {{ selectedDetails.materialsIncluded ? ('PROFILE_MODALS.no' | translate) : ('PROFILE_MODALS.yes' | translate) }}
          </p>
        </span>
        }
        @if(selectedDetails.totalPrice) {
          <span
            class="flex flex-col md:flex-row md:justify-between w-full py-2 border-b-2 border-[#acacac]"
          >
            <p class="w-[50%]">{{'PROFILE_MODALS.totalprice' | translate}}</p>
            <p class="text-black">{{ selectedDetails.totalPrice }}</p>
          </span>
        }
        @if(selectedDetails.cleaningDays) {
          @if(selectedDetails.recurrence !== "MORE_OFTEN") {
            @if(selectedDetails.projektnummer) {
              <span
                class="flex flex-col md:flex-row md:justify-between w-full py-2 border-b-2 border-[#acacac]"
              >
                <p class="w-[50%]">{{'PROFILE.duration' | translate}}</p>
                <p class="text-black">
                    {{ transformDataAndCalculateDuration(selectedDetails.additionalServices, selectedDetails.startDate, selectedDetails.endDate) + " " + ('PRIVATE.Stunden' | translate) }}
                </p>
              </span>
            } @else {
              <span
                class="flex flex-col md:flex-row md:justify-between w-full py-2 border-b-2 border-[#acacac]"
              >
                <p class="w-[50%]">{{'PROFILE.duration' | translate}}</p>
                <p class="text-black">
                    {{selectedDetails.cleaningDays.length  > 0 ? selectedDetails.cleaningDays[0].duration + " " + ('PRIVATE.Stunden' | translate) : formatCleaningDays(selectedDetails.startDate, selectedDetails.endDate) + " " + ('PRIVATE.Stunden' | translate) }}
                </p>
              </span>
            }
          }
        }
        @if(selectedDetails.additionalServices) {

            <h1 class="font-bolD text-[20px]">{{ 'PROFILE.additional' | translate }}</h1>
            <span
              class="flex flex-col md:flex-row md:justify-between w-full py-2 border-b-2 border-[#acacac]"
            >
              <p class="w-[50%]">{{ 'PROFILE_MODALS.cabinetremark' | translate }}</p>
              <p class="text-black">
                {{ selectedDetails.additionalServices.cabinetRemark }}
              </p>
            </span>
            <span
            class="flex flex-col md:flex-row md:justify-between w-full py-2 border-b-2 border-[#acacac]"
          >
            <p class="w-[50%]">{{ 'PROFILE_MODALS.cabinetduration' | translate }}</p>
            <p class="text-black">
              {{ selectedDetails.additionalServices.cabinetDuration }} min
            </p>
          </span>
            <span
              class="flex flex-col md:flex-row md:justify-between w-full py-2 border-b-2 border-[#acacac]"
            >
              <p class="w-[50%]">{{ 'PROFILE_MODALS.fridge' | translate }}</p>
              <p class="text-black">
                {{ selectedDetails.additionalServices.fridge ? ( 'PROFILE_MODALS.yes' | translate ) : ( 'PROFILE_MODALS.no' | translate ) }}
              </p>
            </span>
            <span
              class="flex flex-col md:flex-row md:justify-between w-full py-2 border-b-2 border-[#acacac]"
            >
              <p class="w-[50%]">{{ 'PROFILE_MODALS.ironingduration' | translate }}</p>
              <p class="text-black">
                {{ selectedDetails.additionalServices.ironingDuration }} min
              </p>
            </span>
            <span
              class="flex flex-col md:flex-row md:justify-between w-full py-2 border-b-2 border-[#acacac]"
            >
              <p class="w-[50%]">{{'PROFILE_MODALS.oven' | translate}}</p>
              <p class="text-black">
                {{ selectedDetails.additionalServices.oven ? ( 'PROFILE_MODALS.yes' | translate ) : ( 'PROFILE_MODALS.no' | translate ) }}
              </p>
            </span>
            <span
              class="flex flex-col md:flex-row md:justify-between w-full py-2 border-b-2 border-[#acacac]"
            >
              <p class="w-[50%]">{{'PRIVATE.Pflanzen giessen' | translate}}</p>
              <p class="text-black">
                {{ selectedDetails.additionalServices.wateringPlants ? ( 'PROFILE_MODALS.yes' | translate ) : ( 'PROFILE_MODALS.no' | translate ) }}
              </p>
            </span>
          }
      </span>
    </div>
    }@else {
    <p>{{ 'PROFILE_MODALS.nodetails' | translate }}</p>
    }
  </div>
} 
@if(cancelCleaningModal) {
  <div
  class="absolute p-2 py-6 md:p-6 w-[80%] max-h-[600px] md:w-[50%] md:h-[80%] cleaningmodal bg[#61bfd1] z-20 bg-white shadow-lg"
>
  <div class="w-full flex justify-end">
    <span
      class="self-end text-[22px] cursor-pointer"
      (click)="closeDetails()"
      ><img src="../../assets/icons/exit.svg" alt=""
    /></span>
  </div>
  @if(selectedDetails){
  <div class="w-full max-h-[300px] overflow-y-auto gap-6 flex flex-col">
    <p class="text-black text-[22px] font-bold">
      {{'PROFILE_MODALS.cancelcleaning' | translate}}</p>
    <span
      class="flex flex-col gap-4 md:px-10 overflow-y-auto w-full"
    >
          <span
          class="flex flex-col justify-between w-full py-2 border-b-2 border-[#acacac]"
        >
          <p class="w-[50%]">{{'PROFILE_MODALS.reasonforcancellation' | translate}}</p>
          <span class="text-gray-900 text-[14px]"><span *ngIf="!this.hasUnlimitedCancellations">{{'PROFILE_MODALS.Noch' | translate}} {{ this.cancellationLeft }} {{'PROFILE_MODALS.von' | translate}} 5</span>{{ this.hasUnlimitedCancellations ? '&infin;' : ''}} {{'PROFILE_MODALS.Annullierungen verfügbar' | translate}}</span>
        </span>
      
        <form 
        (submit)="submitCancellation()"
        [formGroup]="cancelCleaning"
        >
        <select
          class="w-full"
          formControlName="cancellationReason"
        >
          <option value="" selected disabled hidden>{{'PROFILE_MODALS.selectreason' | translate}}</option>
          <option value="I_WONT_BE_HOME">{{'PROFILE_MODALS.wontbehome' | translate}}</option>
          <option value="IM_SICK">{{'PROFILE_MODALS.imsick' | translate}}</option>
          <option value="CHANGE_NOT_SUITABLE">{{'PROFILE_MODALS.changenotsuitable' | translate}}</option>
          <option value="MISTAKE_WHEN_BOOKING">{{'PROFILE_MODALS.mistakebooking' | translate}}</option>
          <option value="WOULD_LIKE_TO_CANCEL">{{'PROFILE_MODALS.wouldliketocancel' | translate}}</option>
          <option value="OTHER">{{'AUTH.other' | translate}}</option>
        </select>
        @if(this.cancelCleaning.get('cancellationReason')?.value === "OTHER") {
          <textarea
              formControlName="otherReason"
              name=""
              id=""
              cols="35"
              rows="5"
              placeholder="{{'AUTH.remark' | translate}}"
              class="bg-[#E9F3F4] rounded-3xl p-4 w-full my-5"
            ></textarea>
        }

        <div 
        *ngIf="displayCancellationMessage"
        class="text-sm rounded-lg shadow-xl text-gray-900 my-5 border border-[#61BFD1]"
        >
          <div class="px-3 py-2 border-b rounded-t-lg border-[#61BFD1] flex gap-2 text-gray-900">
            <img class="w-4 mb-1" src="../../../assets/icons/profile/information.svg" alt="info">
            <h3 class="font-semibold">Information</h3>
          </div>
          <div class="px-3 py-2">
            <p>{{'PROFILE_MODALS.limitinfo' | translate}} <a class="underline" href="mailto: info@pebra.ch">info&#64;pebra.ch</a>
            </p>
          </div>
        </div>
        <button type="submit" class="border-[#00829a] mt-2 p-2 box-border font-bold rounded-md border-[1px] hover:opacity-55 text-[#00829a]">{{'PROFILE.save' | translate}}</button>
        </form>
    </span>
  </div>

  }@else {
  <p>{{'PROFILE_MODALS.nodetails' | translate}}</p>
  }
</div>
}
@if(additionalServicesMoadl) {
  <div
  class="absolute p-2 py-6 md:p-6 w-[80%] max-h-[600px] md:w-[50%] md:h-[80%] cleaningmodal bg[#61bfd1] z-20 bg-white shadow-lg"
>
  <div class="w-full flex justify-end">
    <span
      class="self-end text-[22px] cursor-pointer"
      (click)="closeDetails()"
      ><img src="../../assets/icons/exit.svg" alt=""
    /></span>
  </div>
  <div class="w-full max-h-[300px] overflow-y-auto gap-6 flex flex-col">
    <p class="text-black text-[22px] font-bold">
      {{ 'PROFILE.additional' | translate }}
    </p>
    <form
      [formGroup]="additionalServicesForm"
      class="flex flex-col gap-4 md:px-10 overflow-y-auto w-full"
    >
    <span
      class="flex w-full justify-between items-center py-2 border-b-2 border-[#acacac]"
    >
      <p class="w-[50%]">{{ 'PROFILE_MODALS.oven' | translate }}</p>
        <input
          formControlName="oven"
          type="checkbox"
          class="regular-checkbox"
          (change)="handleFridgeAndOven('oven')"
        />
    </span>
    <span
      class="flex flex-col w-full py-2 border-b-2 border-[#acacac]"
    >
      <p class="w-[50%]">{{ 'PROFILE_MODALS.closet' | translate }}</p>
      <span class="flex justify-between">
        <span class="text-black">{{ 'PROFILE.duration' | translate }}</span>
        <span class="flex gap-4">
          <button class="cursor-pointer" (click)="handleIroningAndCabinets('decrease', 'cabinetDuration')">
            <img src="../../assets/icons/minus-trq.svg" alt="minus">
          </button>
          <span>{{ additionalServicesForm.get('cabinetDuration')?.value }} min</span>
          <button class="cursor-pointer" (click)="handleIroningAndCabinets('increase', 'cabinetDuration')" >
            <img src="../../assets/icons/plus-trq.svg" alt="plus">
          </button>
        </span>
      </span>
      <span class="flex justify-between items-center mt-3 relative">
        <span class="text-black">{{ 'AUTH.remark' | translate }}:</span>
        <textarea class="bg-gray-200 rounded-3xl p-4" formControlName="cabinetRemark" name="bemerkung" placeholder="{{ 'AUTH.remark' | translate }}"></textarea>
        <span *ngIf="requiredRemark" class="text-red-600 absolute bottom-0">{{ 'PROFILE_MODALS.minletters' | translate }}</span>
      </span>
    </span>
    <span
      class="flex w-full justify-between items-center py-2 border-b-2 border-[#acacac]"
    >
      <p class="w-[50%]">{{ 'PROFILE_MODALS.fridge' | translate }}</p>
        <input
          formControlName="fridge"
          type="checkbox"
          class="regular-checkbox"
          (change)="handleFridgeAndOven('fridge')"
        />
      </span>
    <span
      class="flex w-full justify-between items-center py-2 border-b-2 border-[#acacac]"
    >
      <p class="w-[50%]">{{'PRIVATE.Pflanzen giessen' | translate}}</p>
        <input
          formControlName="wateringPlants"
          type="checkbox"
          class="regular-checkbox"
          (change)="handleFridgeAndOven('wateringPlants')"
        />
      </span>
      <span
      *ngIf="selectedDetails.additionalServices"
      class="flex flex-col md:flex-row md:justify-between w-full py-2 border-b-2 border-[#acacac]"
    >
      <p class="w-[50%]">{{ 'PROFILE_MODALS.oldprice' | translate }}</p>
      <p *ngIf="selectedDetails.totalPrice" class="text-black">{{ selectedDetails.totalPrice }}</p>
      <p *ngIf="!selectedDetails.totalPrice" class="text-black">{{this.cleaningPrice }}</p>
    </span>
      <span
      class="flex flex-col w-full py-2 border-b-2 border-[#acacac]"
    >
      <span class="flex flex-col md:flex-row md:justify-between">
        <p class="w-[50%]">{{ 'PROFILE_MODALS.newprice' | translate }}</p>
        <p class="text-black"><span *ngIf="this.price > 0">{{ this.price }}</span></p>

      </span>
      <p class="text-[12px] py-2">
        {{ 'PROFILE_MODALS.vat' | translate }}
      </p>
    </span>
      <span class="flex gap-4">
        <button (click)="cancelAdditionalServices()" class="border-[#00829a] mt-2 w-fit p-2 box-border font-bold rounded-md border-[1px] hover:opacity-55 text-[#00829a]">{{ 'PROFILE.cancel' | translate }}</button>
        <button (click)="postAdditionalServices()" class="border-[#61BFD1] mt-2 w-fit p-2 box-border font-bold rounded-md border-[1px] hover:opacity-55 bg-[#61BFD1] text-white hover:bg-[#61bfd185]">{{ 'AUTH.confirm' | translate }}</button>
      </span>
    </form>
  </div>
</div>
}
@if(reactivationModal) {
  <div
  class="absolute p-2 py-6 md:p-6 w-[80%] md:w-[50%] cleaningmodal bg[#61bfd1] z-20 bg-white shadow-lg"
>
  <div class="w-full flex justify-end">
    <span
      class="self-end text-[22px] cursor-pointer"
      (click)="closeDetails()"
      ><img src="../../assets/icons/exit.svg" alt=""
    /></span>
  </div>
  @if(selectedDetails){
  <div class="w-full overflow-y-auto gap-6 flex flex-col">
    <p class="text-black text-[22px] font-bold">
      {{ 'PROFILE_MODALS.reactivationmodal' | translate }}
    </p>
    <span
      class="flex flex-col gap-4 md:px-10 overflow-y-auto w-full border-b-2 border-[#acacac]"
    >
        <span
          class="flex justify-between w-full py-2">
          <p class="w-full">{{ 'PROFILE_MODALS.reactivationreason' | translate }}</p>
        </span>
        <span class="flex justify-center gap-4 pb-5">
          <button (click)="closeDetails()" class="border-[#00829a] mt-2 w-fit p-2 box-border font-bold rounded-md border-[1px] hover:opacity-55 text-[#00829a]">{{ 'PRIVATE.Abbrechen' | translate }}</button>
          <button (click)="submitReactivation()" class="border-[#61BFD1] mt-2 w-fit p-2 box-border font-bold rounded-md border-[1px] hover:opacity-55 bg-[#61BFD1] text-white hover:bg-[#61bfd185]">{{ 'AUTH.confirm' | translate }}</button>
        </span>
    </span>
  </div>

  }@else {
  <p>Details konnten nicht abgerufen werden</p>
  }
</div>
}